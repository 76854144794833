import { FC } from 'react';
import { DocumentRes } from 'interfaces/documents';

import { TagInfo } from './TagInfo';

const TagList: FC<{ document: DocumentRes }> = ({ document }) => (
  <>
    {Object.entries(document.tags).map(([category, count]) => (
      <TagInfo
        key={`${document.ID}-${category}`}
        document={document}
        category={category}
        count={count}
      />
    ))}
  </>
);

export default TagList;
