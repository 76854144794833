import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGroupList, getSelfUser } from 'api/access';
import { getPrivileges, login } from 'api/auth';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  ILoginResponse,
  ILoginValues,
  IUserPrivileges,
  PublicUserInfo,
} from 'interfaces';

import { createThunk } from 'utils';

export const loginThunk = createThunk<ILoginResponse, ILoginValues>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const getPrivilegesThunk = createThunk<IUserPrivileges, undefined>(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_PRIVILEGES}`,
  getPrivileges,
  errorMessages.GET_PRIVILEGES_ERROR
);

export const getSelfUserThunk = createAsyncThunk<
  PublicUserInfo,
  void,
  { rejectValue: string }
>(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_SELF_USER}`,
  async (_, thunkAPI) => {
    try {
      const response = await getSelfUser();
      const linkedGroups = await getGroupList();
      const isAdminOrOwner = linkedGroups.some(
        (el) =>
          el.myMembership &&
          (el.myMembership.role === 'admin' || el.myMembership.role === 'owner')
      );

      return { isGroupAdminOrOwner: isAdminOrOwner, ...response.data };
    } catch (error) {
      console.error(errorMessages.GET_PRIVILEGES_ERROR, error);
      return thunkAPI.rejectWithValue(errorMessages.GET_PRIVILEGES_ERROR);
    }
  }
);
