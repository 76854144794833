import { EntityParameter } from 'types/entities';

import {
  FilterCriteria,
  FilterCriteriaType,
  FilterCriteriaValue,
} from './types';

export const getFilterableParameters = (
  parameters: EntityParameter[],
  filterableParametersMap: Record<number, string>
): EntityParameter[] =>
  parameters.filter((parameter) => filterableParametersMap[parameter.id]);

export const getFilterCriteriaValueLabel = (
  type: FilterCriteriaType,
  value: unknown
): FilterCriteriaValue<string | number | unknown> => {
  if (type === 'text') {
    return {
      label: value as string,
      value: value as string,
    };
  }

  if (type === 'number') {
    return {
      label: String(value),
      value: value as number,
    };
  }

  if (type === 'date') {
    return {
      label: 'Дата',
      value: value,
    };
  }

  return { label: 'Неизвестное значение', value: value };
};

export const getFilterCriteria = (
  parameter: EntityParameter
): FilterCriteria => ({
  id: parameter.id,
  name: parameter.title,
  type: parameter.parameterType,
  label: parameter.title,
  values:
    parameter.settings.allowedValues?.map((value) =>
      getFilterCriteriaValueLabel(parameter.parameterType, value)
    ) ?? [],
});
