import {
  ILangCorpus,
  ILangCorpusGroup,
  ILangCorpusTreeGroup,
  ISearchRequest,
} from 'interfaces';
import axios from 'services/axios';

import { handleAxiosResponse } from './apiUtils';

export const getFilters = () => axios.get('api/elk/filter');

export const searchPosts = ({ values, params }: ISearchRequest) =>
  axios.post('api/elk/search', values, { params });

export const getParsersRequest = () => axios.get('api/elk/parsers');

export const getLangCorpusTree = () =>
  handleAxiosResponse<ILangCorpusTreeGroup[]>(axios.get('/api/lang/tree'));

export const getLangCorpus = (corpusId: number) =>
  axios.get(`/api/lang/corpus/${corpusId}`);
export const createLangCorpus = (corpus: ILangCorpus) =>
  axios.post(`/api/lang/corpus`, corpus);
export const updateLangCorpus = (corpusId: number, corpus: ILangCorpus) =>
  axios.put(`/api/lang/corpus/${corpusId}`, corpus);
export const deleteLangCorpus = (corpusId: number) =>
  axios.delete(`/api/lang/corpus/${corpusId}`);

export const createLangCorpusGroup = (group: ILangCorpusGroup) =>
  axios.post(`/api/lang/group`, group);
export const updateLangCorpusGroup = (
  groupId: number,
  group: ILangCorpusGroup
) => axios.put(`/api/lang/group/${groupId}`, group);
export const deleteLangCorpusGroup = (groupId: number) =>
  axios.delete(`/api/lang/group/${groupId}`);

export const getBookmarkIds = () => axios.get('api/elk/bookmark');

export const addBookmark = (id: string) => axios.post(`api/elk/bookmark/${id}`);

export const deleteBookmark = (id: string) =>
  axios.delete(`api/elk/bookmark/${id}`);
