import { IMediaFile } from 'interfaces';

export const sanitizeFoundIndex = (index: number) =>
  index === -1 ? Number.MAX_SAFE_INTEGER : index;

export const safeSortFiles = (files: IMediaFile[], mediaOrder?: number[]) => {
  if (mediaOrder) {
    return files.slice().sort((current, next) => {
      const indexCurrent = (mediaOrder ?? []).indexOf(current.id || -1);
      const indexNext = (mediaOrder ?? []).indexOf(next.id || -1);

      return sanitizeFoundIndex(indexCurrent) - sanitizeFoundIndex(indexNext);
    });
  }

  return files;
};

// TODO: Remove after BE send actual media types
export const getDefaultMediaFile = (mediaFile: IMediaFile): IMediaFile => ({
  ...mediaFile,
  type: 'image',
});
