import { FC } from 'react';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { boundaryGridActions } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice';
import { imageryActions } from 'store/slices/mapV2/tabsReducer/imagerySlice';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import { entitiesMapSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { EntitiesMap } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';

import { getSelectedObjectEntitiesCount } from './utils';

interface CounterProps {
  count: number;
  title: string;
  onReset: () => void;
}

const Counter: FC<CounterProps> = ({ count, title, onReset }) => (
  <div className="flex gap-3 items-center tpg-c2">
    <span
      role="button"
      className="cursor-pointer text-bright_product hover:text-dark_product"
      onClick={onReset}
    >
      Сбросить
    </span>
    <span className="text-tpg_base">{`${title} ${count}`}</span>
  </div>
);

export const Counters = () => {
  const entitiesMap = useAppSelector(entitiesMapSelector);
  const { visibleObjects: visibleImageries } = useAppSelector(imagerySelector);

  const dispatch = useAppDispatch();

  const selectedEntitiesCount = useMemo(
    () => getSelectedObjectEntitiesCount(entitiesMap),
    [entitiesMap]
  );

  const selectedImageries = useMemo(
    () => new Set(visibleImageries.map((value) => value.id)),
    [visibleImageries]
  );

  const selectedImageriesCount = visibleImageries.length;

  const showEntitiesCounter = !!selectedEntitiesCount;
  const showImageryCounter = !!selectedImageriesCount;

  const resetEntitiesSelection = () => {
    const deselectedEntitiesMap: EntitiesMap = {};

    for (const id in entitiesMap) {
      const entity = entitiesMap[id];

      deselectedEntitiesMap[id] = {
        ...entity,
        state: { ...entity.state, active: false },
      };
    }

    dispatch(mapEntitiesActions.setEntitiesMap(deselectedEntitiesMap));
  };

  const resetImageriesSelection = () => {
    dispatch(imageryActions.setVisibleObjects([]));
    dispatch(
      boundaryGridActions.updateBoundaryGrids({
        relatedFeatureIds: Array.from(selectedImageries),
        properties: { visible: false },
      })
    );
  };

  return (
    <div className="flex gap-6">
      {showEntitiesCounter && (
        <Counter
          count={selectedEntitiesCount}
          title="Выбрано объектов:"
          onReset={resetEntitiesSelection}
        />
      )}
      {showImageryCounter && (
        <Counter
          count={selectedImageriesCount}
          title="Активно снимков:"
          onReset={resetImageriesSelection}
        />
      )}
    </div>
  );
};
