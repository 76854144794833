import { FC } from 'react';

import { EntityControl, EntityControlProps } from './EntityControl';

export interface EntityDetailsControlsProps {
  controls: EntityControlProps[];
}

export const EntityDetailsControls: FC<EntityDetailsControlsProps> = ({
  controls,
}) => (
  <div className="flex gap-3 justify-end w-full px-4 py-1 bg-light">
    {controls.map((control) => (
      <EntityControl key={control.title} {...control} />
    ))}
  </div>
);
