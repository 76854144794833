import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as Sort } from 'images/newIcons/arrow.svg';

import { TSortDirectionUnion } from '../../../types';
import EntityParameterFilter from '../Filters/EntityParameterFilter';
import { Checkbox } from '../index';

import styles from './AstraTable.module.scss';

export interface AstraTableHeadCell<S> {
  disablePadding: boolean;
  id: string;
  sortableProperty?: S;
  label: string;
  numeric: boolean;
  allowedValues?: string[];
}
interface AstraTableHeaderProps<K> {
  allowSelectRows: boolean;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: K | undefined
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortDirection: TSortDirectionUnion;
  sortBy: K | undefined;
  rowCount: number;
  headCells: readonly AstraTableHeadCell<K>[];
  onFilterAdd?: (parameterId: number, value: string) => void;
  onFilterRemove?: (parameterId: number, value: string) => void;
  resetFilters?: () => void;
}

export function AstraTableHeader<S>(props: AstraTableHeaderProps<S>) {
  const {
    onSelectAllClick,
    sortDirection,
    sortBy,
    numSelected,
    rowCount,
    onRequestSort,
    onFilterAdd,
    onFilterRemove,
    resetFilters,
  } = props;

  return (
    <TableHead className={styles['table-root__header']}>
      <TableRow>
        <TableCell>
          {props.allowSelectRows && (
            <Checkbox
              className="flex justify-center"
              isChecked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              theme="dark"
            />
          )}
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={String(headCell.id)}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              sortBy === headCell.sortableProperty ? sortDirection : false
            }
          >
            <div className="relative flex justify-between items-center">
              <div className="tpg-c1 colored-title">{headCell.label}</div>
              <div className="flex gap-1">
                {headCell.sortableProperty && (
                  <Sort
                    className="icon-container cursor-pointer"
                    onClick={(e) => onRequestSort(e, headCell.sortableProperty)}
                  />
                )}
                {headCell.allowedValues &&
                  onFilterAdd &&
                  onFilterRemove &&
                  resetFilters && (
                    <div className="mr-3 mb-4">
                      <EntityParameterFilter
                        field={Number(headCell.id)}
                        allowedValues={headCell.allowedValues}
                        onFilterAdd={onFilterAdd}
                        onRemoveFilter={onFilterRemove}
                        resetFilters={resetFilters}
                      />
                    </div>
                  )}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
