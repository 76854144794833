import {
  CSSProperties,
  FC,
  memo,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  SyntheticEvent,
} from 'react';
import cn from 'classnames';
import { ElementThemeUnion } from 'constants/routes';

import './style.scss';

interface TextAreaProps {
  label?: string;
  value: string;
  readOnly?: boolean;
  onChange?: (e: string) => void;
  onClick?: (e: ReactMouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  rows: number;
  placeholder?: string;
  style?: CSSProperties;
  classNames?: { body?: string; text?: string };
  theme?: ElementThemeUnion;
}

const TextArea: FC<PropsWithChildren<TextAreaProps>> = ({
  label,
  onChange,
  children,
  theme = 'light',
  classNames,
  ...props
}) => {
  const handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    onChange?.(e.currentTarget.value);
  };

  return (
    <label className={cn('textarea', classNames?.body)}>
      {label}
      <div
        className={cn('w-full flex flex-row rounded-md ', {
          'bg-dark': theme === 'dark',
          'bg-light': theme === 'light',
        })}
      >
        <textarea
          className={cn(
            'rounded-md',
            {
              'bg-dark': theme === 'dark',
              'bg-light': theme === 'light',
            },
            classNames?.text
          )}
          {...props}
          onChange={handleChange}
        />
        {children && (
          <div className="textarea__children icon-container">{children}</div>
        )}
      </div>
    </label>
  );
};

export default memo(TextArea);
