import { FC, useEffect, useRef, useState } from 'react';
import { getDocumentTags } from 'api/documents';
import cn from 'classnames';
import { errorMessages } from 'constants/errors';
import { SIDEBAR_WIDTH } from 'constants/ui';
import { useClickOutside } from 'hooks';
import { DocumentRes, Tag as ITag } from 'interfaces/documents';
import { useDocumentContext } from 'routes/Documents';

import { Tag } from 'components/ui';
import { notify } from 'utils';

interface TagInfoProps {
  document: DocumentRes;
  category: string;
  count: number;
}

export const TagInfo: FC<TagInfoProps> = ({ document, category, count }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { nerEnabled } = useDocumentContext();

  const [isShowInfo, setShowInfo] = useState(false);
  const [isLeftAlign, setIsLeftAlign] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setShowInfo(false));

  const loadTags = async () =>
    await getDocumentTags(document.ID, category)
      .then((res) => setTags(res.content))
      .catch(() => notify.error(errorMessages.GET_TAGS_ERROR));

  const handleClickTag = () => {
    loadTags();
    setShowInfo(!isShowInfo);
  };

  const getTagLabels = () => tags.map((el) => el.value).join(', ');

  const calculatePosition = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const viewerWidth = window.innerWidth;
      const leftAlign =
        (nerEnabled
          ? viewerWidth - rect.right - SIDEBAR_WIDTH
          : viewerWidth - rect.right) < 150;
      setIsLeftAlign(leftAlign);
    }
  };

  useEffect(() => {
    isShowInfo && calculatePosition();
  }, [isShowInfo]);

  return (
    <div ref={containerRef} className="relative">
      <Tag
        label={`(${count})`}
        title={category}
        closeIcon={false}
        onClick={handleClickTag}
        className={cn(
          'bg-ultrablack [&>span]:text-tpg_title cursor-pointer !py-[5px]',
          {
            '!bg-dark_product': isShowInfo,
          }
        )}
      />
      {isShowInfo && tags && (
        <div
          className={cn(
            'absolute max-h-[96px] w-[300px] py-[8px] bottom-[40px] px-[16px] overflow-auto bg-ultrablack border border-solid border-tpg_light rounded-[10px] z-[1]',
            { 'left-[-200px]': isLeftAlign }
          )}
        >
          <span className="text-tpg_base">{getTagLabels()}</span>
        </div>
      )}
    </div>
  );
};
