import React, { FC, useEffect, useState } from 'react';
import Html from 'react-pdf-html';
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import * as marked from 'marked';

import { getPersonReport } from '../../../api/reports';
import { PersonReportBodySegment } from '../../../interfaces/reports';
import { notify } from '../../../utils';
import Spinner from '../../ui/Spinner';
import { PersonReportCardModel } from '../PersonReportCard/PersonReportCard';
import { ReportCardHelpers } from '../reportCardHelpers';

import './style.scss';

interface PersonReportPreviewProps {
  reportCard?: PersonReportCardModel;
  isLoading: boolean;
}

// Create styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    fontFamily: 'Roboto',
  },
  page: {
    flexDirection: 'column',
    padding: 40,
  },
  section: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  sectionNameAndPhoto: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  chapterLink: {},
  personPhoto: {
    width: 200,
    height: 200,
  },
  pageTitle: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  tableDataRow: {
    borderBottom: '1px black solid',
    paddingVertical: 2,
  },
});
// Register Font
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf',
      fontStyle: 'italic',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf',
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

const renderTabledData = (title: string, datas: string[]) => {
  if (datas.length) {
    return (
      <View style={{ marginBottom: 10, width: '50%' }}>
        <Text>{title}</Text>
        {datas.map((data, idx) => (
          <View key={idx} style={styles.tableDataRow}>
            <Text>{data}</Text>
          </View>
        ))}
      </View>
    );
  } else return null;
};

interface DocumentHeadingProps {
  card: PersonReportCardModel;
}

const DocumentsSection = (props: { card: PersonReportCardModel }) => {
  const sectionId = ReportCardHelpers.getSegmentDisplayName(
    {
      type: 'documentsSegment',
    },
    props.card.relations
  );

  return (
    <View style={styles.section}>
      <Text id={sectionId} style={styles.pageTitle}>
        Документы
      </Text>
      {renderTabledData('Документы:', props.card.documents)}
      {renderTabledData('Телефоны:', props.card.phones)}
      {renderTabledData('Соцсети:', props.card.socialNetworks)}
      {renderTabledData('Адреса:', props.card.addresses)}
      {renderTabledData('Адреса собственности:', props.card.propertyAddresses)}
      {renderTabledData('Автомобили', props.card.autos)}
      {renderTabledData('Почты:', props.card.emails)}
      <Html>{props.card.additionalInfoContent}</Html>
    </View>
  );
};
const AboutSection = (props: { card: PersonReportCardModel }) => {
  const sectionId = ReportCardHelpers.getSegmentDisplayName(
    {
      type: 'aboutSegment',
    },
    props.card.relations
  );

  const conclusion = marked.parse(props.card.generalConclusions);

  return (
    <View style={styles.section}>
      <Text id={sectionId} style={styles.pageTitle}>
        О персоне
      </Text>
      {}
      <Text>
        Дата рождения:{' '}
        {props.card.birthDate?.format('DD.MM.YYYY') || 'Неизвестна'}
      </Text>
      <Text>Место рождения: {props.card.birthPlace || 'Неизвестно'}</Text>
      <Html>{conclusion}</Html>
    </View>
  );
};

const CustomBlockPage = (props: {
  card: PersonReportCardModel;
  segment: {
    type: 'customTextBlock';
    title: string;
  };
}) => {
  const title = ReportCardHelpers.getSegmentDisplayName(
    props.segment,
    props.card.relations
  );
  const matchingCustomBlock = props.card.customTextBlocks.find(
    (c) => c.title === props.segment.title
  );
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text id={title} style={styles.pageTitle}>
          {title}
        </Text>
        <Html>
          {matchingCustomBlock ? marked.parse(matchingCustomBlock.text) : ''}
        </Html>
      </View>
    </Page>
  );
};

interface LinkedReportPageProps {
  originalReportCard: PersonReportCardModel;
  card: PersonReportCardModel;
  segment: PersonReportBodySegment;
}

const LinkedReportPage: FC<LinkedReportPageProps> = ({
  segment,
  card,
  originalReportCard,
}) => {
  const title = ReportCardHelpers.getSegmentDisplayName(
    segment,
    originalReportCard.relations
  );
  return (
    <>
      <Page size="A4" style={styles.page}>
        <Text id={title} style={styles.pageTitle}>
          {title}
        </Text>
        <View style={styles.sectionNameAndPhoto}>
          <View style={styles.section}>
            <Text>{card.position}</Text>
          </View>
          {card.photo && (
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Image src={card.photo.url} style={styles.personPhoto} />
            </View>
          )}
        </View>
        {renderCardSections(card, [], true)}
      </Page>
    </>
  );
};

const DocumentHeading = ({ card }: DocumentHeadingProps) => {
  return (
    <View style={styles.section}>
      <Link src="#title" style={styles.chapterLink}>
        1. Общая справка
      </Link>
      {card.ordering.map((o, idx) => {
        const title = ReportCardHelpers.getSegmentDisplayName(
          o,
          card.relations
        );
        return (
          <Link src={'#' + title} key={idx}>
            {idx + 2}. {title}
          </Link>
        );
      })}
    </View>
  );
};
const renderCardSections = (
  card: PersonReportCardModel,
  relatedReportCards: PersonReportCardModel[],
  isAdditional?: boolean
) => {
  const wrapNonAdditionalIntoPage = (jsx: JSX.Element) => {
    return isAdditional ? (
      jsx
    ) : (
      <Page size="A4" style={styles.page}>
        {jsx}
      </Page>
    );
  };
  return card.ordering.map((o, idx) => {
    if (o.type === 'aboutSegment') {
      return wrapNonAdditionalIntoPage(<AboutSection card={card} />);
    } else if (o.type === 'documentsSegment') {
      return wrapNonAdditionalIntoPage(<DocumentsSection card={card} />);
    } else if (o.type === 'customTextBlock') {
      return <CustomBlockPage card={card} segment={o} key={idx} />;
    } else if (o.type === 'linkedReport') {
      const matchingCard = relatedReportCards.find(
        (c) => c.headerId === o.headerId
      );
      const isUsedInRender =
        card.relations.find((r) => r.toHeaderId === o.headerId)?.useInRender ||
        false;
      return matchingCard && isUsedInRender ? (
        <LinkedReportPage
          card={matchingCard}
          originalReportCard={card}
          key={idx}
          segment={o}
        />
      ) : null;
    }
  });
};
const PDFDocumentRenderer: FC<{
  card: PersonReportCardModel;
  relatedReportCards: PersonReportCardModel[];
}> = ({ card, relatedReportCards }) => {
  return (
    <Document style={styles.container}>
      <Page size="A4" style={styles.page}>
        <Text id="title" style={styles.pageTitle}>
          {card.name}
        </Text>
        <View style={styles.sectionNameAndPhoto}>
          <View style={styles.section}>
            <Text>{card.position}</Text>
          </View>
          {card.photo && (
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Image src={card.photo.url} style={styles.personPhoto} />
            </View>
          )}
        </View>
        <DocumentHeading card={card} />
      </Page>
      {renderCardSections(card, relatedReportCards)}
    </Document>
  );
};
export const PersonReportPreview: FC<PersonReportPreviewProps> = (props) => {
  const [relatedReportCards, setRelatedReportCards] = useState<
    PersonReportCardModel[]
  >([]);

  useEffect(() => {
    const loadData = async (relations: { to: number; versionId: number }[]) => {
      const reports = await Promise.all(
        relations.map((rel) => getPersonReport(rel.to, rel.versionId))
      );
      setRelatedReportCards(
        reports.map(ReportCardHelpers.makeReportCardFromAPIRepresentation)
      );
    };
    if (props.reportCard?.relations && props.reportCard.headerId) {
      loadData(
        props.reportCard?.relations.flatMap((rel) =>
          rel.toHeaderId && rel.toHeaderLastVersionId
            ? [{ to: rel.toHeaderId, versionId: rel.toHeaderLastVersionId }]
            : []
        )
      ).catch((e) => {
        console.log(e);
        notify.error(`Не удалось загрузить все связанные отчеты`);
      });
    }
  }, [props.reportCard?.relations]);

  return (
    <div className="person-reports__report-preview">
      {props.isLoading && (
        <div className="report-preview__spinner-container">
          <Spinner size={96} />
        </div>
      )}
      {!props.isLoading && props.reportCard && (
        <PDFViewer className="report-preview__pdf-renderer">
          <PDFDocumentRenderer
            card={props.reportCard}
            relatedReportCards={relatedReportCards}
          />
        </PDFViewer>
      )}
    </div>
  );
};
