import { Link } from 'react-router-dom';
import { appRoutes } from 'constants/routes';

import './style.scss';

const NotFound = () => (
  <div className="not-found">
    <div className="not-found__body">
      <span className="tpg-h1">404</span>
      <h1 className="tpg-h2">Страница не найдена</h1>
      <Link className="tpg-b1" to={appRoutes.MAP}>
        Вернуться на главную
      </Link>
    </div>
  </div>
);

export default NotFound;
