import { combineReducers } from '@reduxjs/toolkit';

import { historyReducer } from './historySlice';
import { searchReducer } from './searchSlice';
import { stateReducer } from './stateSlice';

export const geospoofReducer = combineReducers({
  state: stateReducer,
  search: searchReducer,
  history: historyReducer,
});
