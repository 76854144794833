import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { searchEntities } from 'api/entities';
import {
  MAP_ENTITY_HIERARCHY_NEST_LEVEL,
  predefinedTemplates,
} from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import {
  DocumentEntity,
  DocumentNode,
  DocumentNodeData,
} from 'types/documents';
import { EntityListResponse } from 'types/entities';
import useResizeObserver from 'use-resize-observer';

import { NODE_INDENT } from 'components/Map/Tabs/Layers/utils';
import { Tree } from 'components/Tree';
import { Node } from 'components/Tree/Node';
import { notify } from 'utils';

import {
  renderNodeArrow,
  renderNodeIcon,
  renderNodeName,
} from './NodeElements';
import {
  getDocumentEntitiesTree,
  updateDocumentsTree,
  updateDocumentTreeSelection,
} from './utils';

interface DocumentSidebarProps {
  data: EntityListResponse;
  activeNodes: DocumentNode[];
  setActiveNodes: Dispatch<SetStateAction<DocumentNode[]>>;
  search: string;
}

const DocumentsTree: FC<DocumentSidebarProps> = ({
  data,
  activeNodes,
  setActiveNodes,
  search,
}) => {
  const { ref, height } = useResizeObserver();
  const directoryTemplateId = useAppSelector((state) =>
    predefinedTemplateIdSelector(state, predefinedTemplates.DOCUMENT_DIRECTORY)
  );
  const documentTemplateId = useAppSelector((state) =>
    predefinedTemplateIdSelector(state, predefinedTemplates.DOCUMENT)
  );

  const [documentsTree, setDocumentsTree] = useState<DocumentNodeData[]>(
    getDocumentEntitiesTree(
      data,
      activeNodes,
      directoryTemplateId,
      documentTemplateId
    )
  );

  const getAllChildren = (node: DocumentNode): DocumentNode[] => {
    return (node.children || []).reduce((acc, child) => {
      return [...acc, child, ...getAllChildren(child)];
    }, [] as DocumentNode[]);
  };

  const isNodeOrChild = (
    nodeToCheck: DocumentNode,
    parentNode: DocumentNode
  ): boolean => {
    if (nodeToCheck.id === parentNode.id) return true;
    return (parentNode.children || []).some((child) =>
      isNodeOrChild(nodeToCheck, child)
    );
  };

  const updateChildrenState = useCallback(
    (node: DocumentNode, isActive: boolean) => {
      const newNodes = isActive
        ? [...activeNodes, node, ...getAllChildren(node)]
        : activeNodes.filter((n) => !isNodeOrChild(n, node));
      return newNodes;
    },
    [activeNodes]
  );

  const handleNodeClick = useCallback(
    (e: unknown, node: DocumentNode) => {
      if (!node.data.isFolder) {
        setActiveNodes((prevNodes) => {
          const isCurrentlySelected = prevNodes.some((n) => n.id === node.id);
          if (isCurrentlySelected) {
            return prevNodes.filter((n) => n.id !== node.id);
          } else {
            const folderNodes = prevNodes.filter((el) => el.data.isFolder);
            return [...folderNodes, node];
          }
        });
      } else {
        setActiveNodes((prevNodes) => {
          const isCurrentlySelected = prevNodes.some((n) => n.id === node.id);
          if (isCurrentlySelected) {
            return prevNodes.filter((n) => n.id !== node.id);
          } else {
            return [...prevNodes, node];
          }
        });
      }
    },
    [activeNodes, updateChildrenState, setActiveNodes]
  );

  const onFolderOpen = (node: DocumentNode) => {
    const shouldGetEntityChildren = !!(
      node.data.info.level &&
      !((node.data.info.level + 1) % (MAP_ENTITY_HIERARCHY_NEST_LEVEL - 1)) &&
      node.data.state.storage === 'none'
    );

    if (shouldGetEntityChildren) {
      searchEntities({
        parentEntityID: Number(node.id),
        query: search,
        maxDepth: MAP_ENTITY_HIERARCHY_NEST_LEVEL,
      })
        .then((childrenResp) => {
          const newTreeChildren: DocumentNodeData[] = getDocumentEntitiesTree(
            childrenResp,
            activeNodes,
            directoryTemplateId,
            documentTemplateId
          );
          const updatedDocumentsTree = updateDocumentsTree(
            documentsTree,
            newTreeChildren
          );
          setDocumentsTree(updatedDocumentsTree);
        })
        .catch(() => {
          notify.error(errorMessages.GET_DOCUMENTS_ERROR);
        });
    }
  };

  const onNodeOpen = (isOpen: boolean, node: DocumentNode) => {
    if (!node.data.isFolder) {
      return;
    }

    onFolderOpen(node);
  };

  useEffect(() => {
    setDocumentsTree((prevTree) =>
      updateDocumentTreeSelection(prevTree, activeNodes)
    );
  }, [activeNodes, search]);

  useEffect(() => {
    setDocumentsTree(
      getDocumentEntitiesTree(
        data,
        activeNodes,
        directoryTemplateId,
        documentTemplateId
      )
    );
  }, [data]);

  return (
    <div
      className={`absolute w-[474px] !h-[calc(100vh-204px)] bg-dark overflow-auto`}
    >
      <div ref={ref} className="w-full h-full px-4 pb-4">
        {documentsTree.length ? (
          <Tree<DocumentEntity, DocumentEntity>
            data={documentsTree}
            height={height}
            indent={NODE_INDENT}
            disableEdit={true}
            disableDrag={true}
          >
            {(nodeRendererProps) => (
              <Node<DocumentEntity, DocumentEntity>
                renderArrow={renderNodeArrow}
                renderIcon={renderNodeIcon}
                renderName={renderNodeName}
                onOpen={onNodeOpen}
                onClick={handleNodeClick}
                className="w-full h-full pr-4 flex items-center"
                {...nodeRendererProps}
              />
            )}
          </Tree>
        ) : (
          <span className="tpg-c1 text-tpg_base">
            {NOTHING_WAS_FOUND_MESSAGE}
          </span>
        )}
      </div>
    </div>
  );
};

export default DocumentsTree;
