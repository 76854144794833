import { FC } from 'react';

import { Switch } from 'components/ui';

import './style.scss';

interface ITypeItemProps {
  active: boolean;
  onSwitch: () => void;
  label: string;
  description?: string;
}

const TypeItem: FC<ITypeItemProps> = ({
  active,
  onSwitch,
  label,
  description,
}) => (
  <div className="type-item">
    <div className="type-item__content">
      <h2 className="type-item__content__name tpg-c1">{label}</h2>
      <p className="tpg-text-four">{description}</p>
    </div>
    <Switch checked={active} onChange={onSwitch} />
  </div>
);

export default TypeItem;
