import { FC } from 'react';
import { TDateRange } from 'types';

import TagContainer from 'components/TagContainer';
import { getFormattedDateRange } from 'utils';

import { ActiveFilterCriteria } from './types';

interface ActiveFilterCriteriasProps {
  activeCriterias: ActiveFilterCriteria[];
  onCriteriaRemove: (criteria: ActiveFilterCriteria) => void;
}

const getLabel = (criteria: ActiveFilterCriteria) => {
  switch (criteria.type) {
    case 'date':
      return getFormattedDateRange(criteria.value.value as TDateRange);
    case 'number':
    case 'text':
    default:
      return criteria.value.label;
  }
};

export const ActiveFilterCriterias: FC<ActiveFilterCriteriasProps> = ({
  activeCriterias,
  onCriteriaRemove,
}) => (
  <TagContainer
    tags={activeCriterias.map((criteria) => ({
      title: criteria.label,
      label: getLabel(criteria),
      value: criteria.value.label,
      onRemove: () => onCriteriaRemove(criteria),
    }))}
    className="!mx-4 !my-2 !overflow-visible !max-w-[calc(410px-32px)]"
    tagClassName="bg-ultrablack"
  />
);
