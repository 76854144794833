import { useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_SEARCH_STEP,
  mediaType,
  postType,
  sourceMedia,
} from 'constants/filters';
import dayjs from 'dayjs';
import { IStaticFilters } from 'interfaces';
import { monitoringActions } from 'store';
import { searchPostsThunk } from 'store/slices/monitoring/actions';
import {
  bookmarkIdsSelector,
  checkedLangCorpusesSelector,
  parserSelector,
  postListSelector,
  showOnlyBookmarksSelector,
  staticFiltersSelector,
} from 'store/slices/monitoring/selectors';
import { TMonitoringTag } from 'types';
import { TMediaUnion, TPublicationUnion, TSourceUnion } from 'types';
import { useDebouncedCallback } from 'use-debounce';

import { DATE_FORMAT } from '../constants/map';

import { useAppDispatch, useAppSelector } from './redux';

export const useFilters = () => {
  const dispatch = useAppDispatch();
  const { source, startDate, endDate, media, post, searchMode } =
    useAppSelector(staticFiltersSelector);
  const parsers = useAppSelector(parserSelector);
  const showOnlyBookmarks = useAppSelector(showOnlyBookmarksSelector);
  const bookmarkIds = useAppSelector(bookmarkIdsSelector);
  const checkedLangCorpuses = useAppSelector(checkedLangCorpusesSelector);
  const { total, pending } = useAppSelector(postListSelector);

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchValue = (value: string) => {
    if (searchValue && value === searchValue && !pending) {
      setPage(0);
      dispatch(searchPostsThunk({ values: requestPayload }));
      dispatch(monitoringActions.clearSearchList(true));
    }

    setSearchValue(value);
  };

  const getCheckedValues = <T>(array: IStaticFilters<T>[]) => {
    const result = array
      .filter((item) => item.checked)
      .map((item) => item.value);
    return result.length ? result : null;
  };

  const selectedParsers = useMemo(
    () => getCheckedValues<string>(parsers),
    [parsers]
  );

  const selectedSource = useMemo(
    () => getCheckedValues<TSourceUnion>(source),
    [source]
  );

  const selectedMedia = useMemo(
    () => getCheckedValues<TMediaUnion>(media),
    [media]
  );

  const selectedPost = useMemo(
    () => getCheckedValues<TPublicationUnion>(post),
    [post]
  );

  const selectedBookmarkIds = useMemo(
    () => (showOnlyBookmarks ? bookmarkIds : []),
    [bookmarkIds, showOnlyBookmarks]
  );

  const selectedDate = useMemo(() => {
    if (startDate && endDate) {
      return [
        dayjs(startDate).format(DATE_FORMAT),
        dayjs(endDate).format(DATE_FORMAT),
      ];
    }

    return null;
  }, [startDate, endDate]);

  const tags = useMemo(() => {
    let staticFilters: TMonitoringTag[] = [];

    if (selectedParsers) {
      const parsersFilter: TMonitoringTag[] = selectedParsers.map((value) => {
        const parser = parsers.find((e) => e.value === value);

        return {
          label: parser?.label ?? '',
          path: 'parser',
          value,
        };
      });

      staticFilters = staticFilters.concat(parsersFilter);
    }

    if (selectedSource) {
      const sources: TMonitoringTag[] = selectedSource.map((value) => {
        const source = sourceMedia.find((e) => e.value === value);

        return {
          label: source?.label ?? '',
          path: 'source',
          value,
        };
      });

      staticFilters = staticFilters.concat(sources);
    }

    if (selectedMedia?.length) {
      const value = selectedMedia[0];
      const type = mediaType.find((e) => e.value === value);

      staticFilters.push({
        label: type?.label ?? '',
        path: 'media',
        value,
      });
    }

    if (selectedPost) {
      const posts: TMonitoringTag[] = selectedPost.map((value) => {
        const post = postType.find((e) => e.value === value);

        return {
          label: post?.label ?? '',
          path: 'post',
          value,
        };
      });

      staticFilters = staticFilters.concat(posts);
    }

    if (selectedDate) {
      const dateTag = selectedDate
        .map((item) => dayjs(item).format('DD.MM.YY'))
        .join('-');

      staticFilters.push({
        label: dateTag,
        path: 'date',
        value: '',
      });
    }

    if (showOnlyBookmarks) {
      const bookmarksFilter: TMonitoringTag = {
        label: 'Закладки',
        path: 'id',
        value: 'Закладки',
      };

      staticFilters = staticFilters.concat(bookmarksFilter);
    }

    return staticFilters;
  }, [
    selectedPost,
    selectedMedia,
    selectedSource,
    selectedDate,
    selectedParsers,
    showOnlyBookmarks,
  ]);

  const yadroFilters = useMemo(
    () => (checkedLangCorpuses.length ? checkedLangCorpuses : null),
    [checkedLangCorpuses]
  );

  const requestPayload = {
    data_type: searchMode,
    yadro_filters: yadroFilters,
    document_source: selectedSource,
    document_media_type: selectedMedia,
    publication_type: selectedPost,
    text_query: searchValue || null,
    date_range: selectedDate,
    parsers: selectedParsers,
    doc_ids: selectedBookmarkIds,
  };

  const setDebouncedValue = useDebouncedCallback(() => {
    dispatch(
      searchPostsThunk({
        values: requestPayload,
      })
    );
  }, 500);

  useEffect(() => {
    if (page && page * DEFAULT_SEARCH_STEP <= total) {
      dispatch(
        searchPostsThunk({
          values: requestPayload,
          params: {
            start: page * DEFAULT_SEARCH_STEP,
          },
        })
      );
    }
  }, [page]);

  useEffect(() => {
    setPage(0);
    dispatch(monitoringActions.clearSearchList(true));
    setDebouncedValue();
  }, [
    searchMode,
    selectedPost,
    selectedMedia,
    selectedSource,
    searchValue,
    selectedDate,
    selectedParsers,
    showOnlyBookmarks,
    yadroFilters,
  ]);

  return { onChangeSearchValue, setPage, tags };
};
