export class KeyDown {
  subscribers: { id: number; cb: (e: KeyboardEvent) => void }[] = [];

  constructor(key: string) {
    window.addEventListener('keydown', (e) => {
      if (e.key === key) {
        const lastSubscriber = this.subscribers[this.subscribers.length - 1];

        lastSubscriber?.cb(e);
        this.unsubscribe(lastSubscriber?.id);
      }
    });
  }

  subscribe(cb: () => void) {
    const id = Date.now();
    this.subscribers.push({ id, cb });

    return id;
  }

  unsubscribe(id?: number | null) {
    if (!id) return;

    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber.id !== id
    );
  }
}
