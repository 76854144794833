import { createSlice } from '@reduxjs/toolkit';

import { notify } from 'utils';

import { COMMON_REDUCER_NAMES } from '../constants';

import { getAttributesThunk } from './actions';
import { AttributesState, SetClientsAction, SetReportsAction } from './types';

const initialState: AttributesState = {
  reports: [],
  clients: [],
};

const attributesSlice = createSlice({
  name: COMMON_REDUCER_NAMES.ATTRIBUTES,
  initialState: initialState,
  reducers: {
    setReports(state, action: SetReportsAction) {
      state.reports = action.payload;
    },
    setClients(state, action: SetClientsAction) {
      state.clients = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAttributesThunk.fulfilled, (state, action) => {
        const { reports, clients } = action.payload;

        state.reports = reports;
        state.clients = clients;
      })
      .addCase(getAttributesThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      }),
});

export const { actions: attributesActions, reducer: attributesReducer } =
  attributesSlice;
