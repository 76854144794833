import {
  GetDocCoordinatesRes,
  GetDocTagsRes,
  SearchDocumentsReq,
  SearchDocumentsRes,
  SearchTagReq,
  SearchTagRes,
} from 'interfaces/documents';
import axios from 'services/axios';

import { handleAxiosResponse } from './apiUtils';
import { GO_API_PREFIX } from './entities';

export const getDocuments = (params: SearchDocumentsReq) =>
  handleAxiosResponse<SearchDocumentsRes>(
    axios.post(`${GO_API_PREFIX}/doc-api/documents`, params)
  );

export const getDocumentTags = (docID: number, category: string) =>
  handleAxiosResponse<GetDocTagsRes>(
    axios.get(`${GO_API_PREFIX}/doc-api/document/${docID}/tags`, {
      params: { category },
    })
  );

export const getTags = (params: SearchTagReq) =>
  handleAxiosResponse<SearchTagRes>(
    axios.post(`${GO_API_PREFIX}/doc-api/tags`, params)
  );

export const getDocumentInPdf = (
  docId: number,
  byEntityId: boolean,
  returnRaw = false
) =>
  axios.get(`${GO_API_PREFIX}/doc-api/document/${docId}/pdf`, {
    responseType: 'blob',
    params: {
      byEntityId: byEntityId,
      returnRaw: returnRaw,
    },
  });

export const getDocumentCoordinates = (docId: number) => {
  return mockDocumentCoordinates;
  //  handleAxiosResponse<GetDocCoordinatesRes>(axios.get(${GO_API_PREFIX}/doc-api/document/${docId}/coordinates));
};

const emptyDocCoordinates: GetDocCoordinatesRes = { coordinates: [] };

const mockDocumentCoordinates: GetDocCoordinatesRes = {
  coordinates: [
    {
      title: 'Первый объект',
      coordinates: [48.575484, 37.483465],
    },
    {
      title: 'Второй объект',
      coordinates: [48.587764, 37.482923],
    },
    {
      title: 'Третий объект',
      coordinates: [48.591169, 37.513545],
    },
    {
      title: 'Четвертый объект',
      coordinates: [48.571629, 37.520048],
    },
    {
      title: 'Пятый объект',
      coordinates: [48.570643, 37.503789],
    },
    {
      title: 'Шестой объект',
      coordinates: [48.578532, 37.503247],
    },
  ],
};
