import { FC, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as UpArrow } from 'images/newIcons/up.svg';

import { DocumentRes } from '../../../interfaces/documents';

import { Card } from './Card';

interface DropdownFolderProps {
  documents: DocumentRes[];
  title: string;
  onDocumentClick: (d: DocumentRes) => void;
  hideArrow?: boolean;
}

export const DropdownFolder: FC<DropdownFolderProps> = ({
  documents,
  title,
  onDocumentClick,
  hideArrow = false,
}) => {
  const [isOpen, setOpen] = useState(true);

  const handleOpenHistory = () => setOpen((prev) => !prev);

  return (
    <div className="flex w-full flex-col gap-4">
      <div
        className="flex w-full flex-row justify-between items-center gap-1 cursor-pointer"
        onClick={handleOpenHistory}
      >
        <span className="text-tpg_title tpg-b1">{title}</span>
        <div>
          {!hideArrow && (
            <UpArrow
              className={cn('scale-110 [&>path]:fill-tpg_base', {
                'rotate-90': !isOpen,
              })}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-4">
            {documents.map((doc) => (
              <Card
                key={doc.ID}
                document={doc}
                onClick={() => onDocumentClick(doc)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
