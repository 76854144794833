import { FC, useState } from 'react';
import {
  Box,
  Slider,
  SliderValueLabelProps,
  SxProps,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { ASTRA_COLORS } from 'constants/routes';
import { useDebouncedCallback } from 'use-debounce';

interface SliderProps {
  sx?: SxProps;
  opacityCallback: (opacity: number) => void;
}

const StyledBox = styled(Box)({
  backgroundColor: ASTRA_COLORS.DARK,
  padding: '1em',
  border: 'none',
});

const StyledSlider = styled(Slider)({
  color: ASTRA_COLORS.MAIN_PRODUCT,
  padding: '8px 8px',
  margin: 'auto',
  '& .MuiSlider-track': {
    border: 'none',
    height: '4px',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    height: '4px',
    backgroundColor: ASTRA_COLORS.BRIGHT,
  },
  '& .MuiSlider-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: '#1D65D4',
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },
});

const SliderLabel = ({ children, value }: SliderValueLabelProps) => (
  <Tooltip enterTouchDelay={0} placement="bottom" title={value}>
    {children}
  </Tooltip>
);

export const OpacitySlider: FC<SliderProps> = ({ sx, opacityCallback }) => {
  const [opacity, setOpacity] = useState(100);

  const handleOpacityChange = (opacity: number) => {
    setOpacity(opacity);
    opacityCallback(opacity);
  };

  const debouncedOpacityChange = useDebouncedCallback(handleOpacityChange, 50);

  return (
    <StyledBox
      sx={{
        width: '100%',
        height: '20px',
        flexDirection: 'row',
        display: 'flex',
        padding: 0,
        ...sx,
      }}
    >
      <StyledSlider
        defaultValue={100}
        min={0}
        max={100}
        onChange={(e, opacity) => debouncedOpacityChange(opacity as number)}
        valueLabelDisplay={'auto'}
        slots={{ valueLabel: SliderLabel }}
      />
      <div className="tpg-text-six w-12 ml-2 mt-1 text-right">{opacity}%</div>
    </StyledBox>
  );
};
