import {
  IReportsTableRequest,
  PersonReport,
  PersonReportRelation,
  PersonReportVersion,
  ReportsTableResponse,
} from '../interfaces/reports';
import axios from '../services/axios';
import { TReportSortUnion } from '../types/report';

import { handleAxiosResponse } from './apiUtils';

export const getReportsTable = (
  params: IReportsTableRequest<TReportSortUnion>
) => axios.get('/api/report', { params });

export const getReportsTableTyped = (
  params: IReportsTableRequest<TReportSortUnion>
) =>
  handleAxiosResponse<ReportsTableResponse>(
    axios.get('/api/report', { params })
  );

export const getReportVersionHistory: (
  headerId: number
) => Promise<PersonReportVersion[]> = (headerId: number) =>
  handleAxiosResponse<PersonReportVersion[]>(
    axios.get(`/api/report/${headerId}`)
  );

export const getReportRelations: (
  headerId: number
) => Promise<PersonReportRelation[]> = (headerId: number) =>
  handleAxiosResponse<PersonReportRelation[]>(
    axios.get(`/api/report-relation/${headerId}`)
  );

export const updateReportRelations: (
  fromHeaderId: number,
  relations: { to: number; title: string }[]
) => Promise<any> = (
  fromHeaderId: number,
  relations: { to: number; title: string }[]
) =>
  handleAxiosResponse<any>(
    axios.post(`/api/report-relation/${fromHeaderId}`, relations)
  );

export const getPersonReport: (
  headerId: number,
  versionId: number
) => Promise<PersonReport> = (headerId: number, versionId: number) =>
  handleAxiosResponse<PersonReport>(
    axios.get(`/api/report/${headerId}/${versionId}`)
  );

export const uploadReportVersion: (
  report: PersonReport
) => Promise<PersonReport> = (report: PersonReport) =>
  handleAxiosResponse(axios.post(`/api/report`, report));

export const updateReportName: (
  headerId: number,
  newName: string
) => Promise<any> = (headerId: number, newName: string) =>
  handleAxiosResponse(
    axios.put(`/api/report/${headerId}`, {
      name: newName,
    })
  );

export const deleteReport: (headerId: number) => Promise<PersonReport> = (
  headerId: number
) => handleAxiosResponse(axios.delete(`/api/report/${headerId}`));
