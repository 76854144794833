import React, { memo } from 'react';

import Button from '../Button';
import { Modal } from '../index';

import './style.scss';

interface ConfirmModalProps {
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title = 'Вы уверены, что хотите удалить?',
  description = 'Вы можете потерять данные',
  cancelText = 'Нет, я передумал',
  confirmText = 'Да, я хочу удалить',
  onConfirm,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} isBlurred>
      <div className="confirm-modal ">
        <div className="tpg-h4">{title}</div>
        <div className="tpg-b2 text-tpg_base pt-3">{description}</div>
        <div className="flex flex-row gap-2.5 pt-6 w-full justify-between">
          <Button
            onClick={onClose}
            title={cancelText}
            className="w-full bg-dark border border-solid border-dark_product"
          />
          <Button onClick={onConfirm} title={confirmText} className="w-full" />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ConfirmModal);
