import { constants } from '@mapbox/mapbox-gl-draw';
import { Position } from '@turf/turf';

import { CircleDrawnFeature, CommonDrawnFeature } from './types';

export function createVertex(
  parentId: string | number,
  coordinates: Position,
  path: any,
  selected: boolean
) {
  return {
    type: constants.geojsonTypes.FEATURE,
    properties: {
      meta: constants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected
        ? constants.activeStates.ACTIVE
        : constants.activeStates.INACTIVE,
    },
    geometry: {
      type: constants.geojsonTypes.POINT,
      coordinates: coordinates,
    },
  };
}

export function createSupplementaryPointsForCircle(
  drawnFeature: CommonDrawnFeature | CircleDrawnFeature
) {
  const { properties, geometry } = drawnFeature;

  if (properties.user_subtype !== 'Circle') return null;

  const supplementaryPoints = [];
  const vertices = geometry.coordinates[0].slice(0, -1);

  for (
    let index = 0;
    index < vertices.length;
    index += Math.round(vertices.length / 4)
  ) {
    supplementaryPoints.push(
      createVertex(properties.id, vertices[index], `0.${index}`, false)
    );
  }

  return supplementaryPoints;
}
