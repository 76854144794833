import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs_plugin_utc from 'dayjs/plugin/utc';
import { TDateRange } from 'types';

import { DATE_FORMAT } from '../../constants/map';

dayjs.extend(dayjs_plugin_utc);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

export const getFormattedDate = (
  date: Date | string | null | undefined,
  format = 'DD.MM.YY HH:mm',
  keepUTCLocalTime = false
) => {
  if (date) {
    return dayjs(date).utc(keepUTCLocalTime).format(format);
  }

  return null;
};

export const getFormattedDateRange = (
  dateRange: TDateRange,
  format: string = DATE_FORMAT,
  formatter: (startDate: string, endDate: string) => string = (
    startDate,
    endDate
  ) => (startDate === endDate ? startDate : `с ${startDate} по ${endDate}`)
) => {
  const startDate = dayjs(dateRange[0]).format(format);
  const endDate = dayjs(dateRange[1]).format(format);

  return formatter(startDate, endDate);
};

export const getTagDate = (date: string) => {
  const newDates = date.split('-').map((d) => {
    return dayjs(d, 'DD.MM.YY').format(DATE_FORMAT);
  });

  if (newDates.length >= 1) {
    return newDates[0] === newDates[1]
      ? newDates[0]
      : `с ${newDates[0]} по ${newDates[1]}`;
  }

  return '';
};

export const formatDate = (date: Date) => dayjs(date).format(DATE_FORMAT);
