import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import cn from 'classnames';
import DOMpurify from 'dompurify';
import { ReactComponent as Chevron } from 'images/icons/chevron-down.svg';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import './style.scss';

interface AccordionProps {
  text: string;
  searchedText: string;
}

const INITIAL_CONTENT_HEIGHT = 266;

const Accordion: FC<AccordionProps> = ({ text, searchedText }) => {
  const [isShowMore, setShowMore] = useState(false);
  const [isOpenMore, setOpenMore] = useState(false);
  const [contentHeight, setContentHeight] = useState(INITIAL_CONTENT_HEIGHT);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const { parentElement, clientHeight } = textRef.current;
      const parentHeight = parentElement?.clientHeight;

      if (parentHeight !== undefined && clientHeight > parentHeight) {
        setShowMore(true);
      }
    }
  }, []);

  useEffect(() => {
    let contentSize = INITIAL_CONTENT_HEIGHT;

    if (isOpenMore && textRef.current) {
      contentSize = textRef.current.clientHeight;
    }

    setContentHeight(contentSize);
  }, [isOpenMore]);

  const handleShowMore = () => {
    setOpenMore(!isOpenMore);
  };

  const transformedQuery = useMemo(() => {
    const searchedWords = searchedText
      .replaceAll(/['"“”‘’«»„‟]/g, '')
      .split(' ');

    return searchedWords
      .map((word) => {
        const searchedWordRegexp = new RegExp(word, 'gi');
        return DOMpurify.sanitize(
          text
            .replaceAll('\n', '<br />')
            .replaceAll('__', '*')
            .replaceAll('', '')
            .replaceAll(searchedWordRegexp, (str) =>
              str ? `<mark>${str}</mark>` : ''
            )
        );
      })
      .toString();
  }, [text]);

  return (
    <div className="accordion">
      <div style={{ maxHeight: contentHeight }} className="accordion__content">
        <div className="tpg-text-one" ref={textRef}>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          >
            {transformedQuery}
          </ReactMarkdown>
        </div>
      </div>
      {isShowMore && (
        <span
          className={cn('accordion__hidden-box', {
            'accordion__hidden-box_visible': isOpenMore,
          })}
        >
          <Chevron
            className={cn('accordion__icon-show-more', {
              'accordion__icon-show-more_active': isOpenMore,
            })}
            onClick={handleShowMore}
          />
        </span>
      )}
    </div>
  );
};

export default memo(Accordion);
