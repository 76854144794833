import { Layer, Source } from 'react-map-gl';
import { googleLabelLayer, googleLabelSource } from 'configs/map/google';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { showLabelsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const LabelsSource = () => {
  const showLabels = useAppSelector(showLabelsSelector);
  const { mapRef } = useMapRef();

  if (!showLabels) {
    return null;
  }

  return (
    <Source {...googleLabelSource}>
      <Layer {...googleLabelLayer} />
    </Source>
  );
};
