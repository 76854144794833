import { FC } from 'react';
import cn from 'classnames';

import ClosableTooltip, {
  ClosableTooltipProps,
} from 'components/ui/ClosableTooltip';

export interface IContextMenuTooltipItem {
  title: string;
  onClick: () => void;
}

export interface IContextMenuTooltipProps
  extends Omit<ClosableTooltipProps, 'overlay'> {
  items: IContextMenuTooltipItem[];
}

export const ContextMenuTooltip: FC<IContextMenuTooltipProps> = ({
  items,
  children,
  ...props
}) => (
  <ClosableTooltip
    overlay={
      <div className="flex flex-col w-full">
        {items.map((item) => (
          <button
            key={item.title}
            className={cn(
              'w-full px-4 tpg-c2 bg-transparent border-none text-left justify-normal'
            )}
            onClick={item.onClick}
          >
            {item.title}
          </button>
        ))}
      </div>
    }
    {...props}
  >
    {children}
  </ClosableTooltip>
);
