import { memo } from 'react';

import './style.scss';

const CardSkeleton = () => (
  <div className="card-skeleton">
    <div className="card-skeleton__head">
      <span className="skeleton card-skeleton__head_source" />
      <span className="skeleton card-skeleton__head_origin" />
    </div>
    <div className="card-skeleton__content">
      <div className="card-skeleton__content_media">
        <div className="skeleton card-skeleton__content__slide" />
        <div className="card-skeleton__content__preview">
          <span className="skeleton card-skeleton__content__file" />
          <span className="skeleton card-skeleton__content__file" />
          <span className="skeleton card-skeleton__content__file" />
          <span className="skeleton card-skeleton__content__file" />
        </div>
      </div>
      <div className="card-skeleton__content_accordion">
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
        <span className="skeleton card-skeleton__content_text" />
      </div>
    </div>
    <div className="skeleton card-skeleton_actions" />
  </div>
);

export default memo(CardSkeleton);
