import { useAppDispatch } from 'hooks';
import { boundaryGridActions } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice';
import { BoundaryGrid, BoundaryGridCellGeoJSON } from 'types';

import { ContextPopup, ContextPopupOption } from './ContextPopup';

interface BoundaryGridContextMenuProps {
  longitude: number;
  latitude: number;
  options: ContextPopupOption[];
  boundaryGrid: BoundaryGrid;
  boundaryGridCell: BoundaryGridCellGeoJSON;
  onClose: () => void;
}

export const BoundaryGridContextMenu = ({
  longitude,
  latitude,
  options: optionsProp,
  boundaryGrid,
  boundaryGridCell,
  onClose,
}: BoundaryGridContextMenuProps) => {
  const dispatch = useAppDispatch();

  const hideBoundaryGrid = () => {
    dispatch(
      boundaryGridActions.updateBoundaryGrid({
        relatedFeatureId: boundaryGridCell.properties.relatedFeatureId,
        properties: { visible: false },
      })
    );
  };

  const removeBoundaryGrid = () => {
    dispatch(
      boundaryGridActions.removeBoundaryGrid(
        boundaryGridCell.properties.relatedFeatureId
      )
    );
  };

  const toggleBoundaryGridLabels = () => {
    dispatch(
      boundaryGridActions.updateBoundaryGrid({
        relatedFeatureId: boundaryGrid.relatedFeatureId,
        properties: {
          showLabels: !boundaryGrid.showLabels,
          features: boundaryGrid.features.map((feature) => ({
            ...feature,
            properties: {
              ...feature.properties,
              showLabel: !boundaryGrid.showLabels,
            },
          })),
        },
      })
    );
  };

  const options = [
    ...optionsProp,
    { title: 'Скрыть сетку', onClick: hideBoundaryGrid },
    { title: 'Удалить сетку', onClick: removeBoundaryGrid },
    {
      title: boundaryGrid.showLabels ? 'Скрыть номера' : 'Показать номера',
      onClick: toggleBoundaryGridLabels,
    },
  ];

  return (
    <ContextPopup
      options={options}
      longitude={longitude}
      latitude={latitude}
      keyboard
      onClose={onClose}
    />
  );
};
