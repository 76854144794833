import { renderFigure, renderLegendElement } from '../index';

const DemMapInfo = () => (
  <div className="flex flex-row justify-between">
    {renderLegendElement(
      'Слой высот 2.0',
      <div className="info__block tpg-c2">
        {renderFigure(<>{'<= -20'}</>, '#000102', 'figure__circle')}
        {renderFigure(<>{'0'}</>, '#003150', 'figure__circle')}
        {renderFigure(<>{'50'}</>, '#2b83ba', 'figure__circle')}
        {renderFigure(<>{'120'}</>, '#d1ecb0', 'figure__circle')}
        {renderFigure(<>{'185'}</>, '#fee9a5', 'figure__circle')}
        {renderFigure(<>{'230'}</>, '#ef7848', 'figure__circle')}
        {renderFigure(<>{'300'}</>, '#e34932', 'figure__circle')}
        {renderFigure(<>{'=> 400'}</>, '#d7191c', 'figure__circle')}
      </div>
    )}
  </div>
);

export default DemMapInfo;
