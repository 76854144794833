import { PureComponent, ReactNode } from 'react';
import { ReactComponent as Boundary } from 'images/icons/boundary.svg';

import './style.scss';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error.toString());
  }

  onReload() {
    location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <Boundary className="error-boundary_image" />
          <h1 className="error-boundary_label">Упс!</h1>
          <span className="error-boundary_description">
            Что-то пошло не так.
          </span>
          <a className="error-boundary_link" onClick={this.onReload}>
            Перезагрузить страницу
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
