import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import { getReportsTableTyped } from '../../../api/reports';
import { appRoutes } from '../../../constants/routes';
import { ReactComponent as LineIcon } from '../../../images/icons/line.svg';
import { PersonReportSummary } from '../../../interfaces/reports';
import { Checkbox, TextInput } from '../../ui';
import { MultiLineEditor } from '../../ui/MultiLineEditor';

import './style.scss';

interface PersonReportLike {
  title: string;
  toHeaderId: number;
  useInRender: boolean;
}

interface Props {
  values: EditorPersonReportRelation[];
  onChange: (values: EditorPersonReportRelation[]) => void;
  filterValue: (v: PersonReportSummary) => boolean;
}

interface PersonOption {
  value: PersonReportLike;
  label: string;
}

export type EditorPersonReportRelation = {
  toHeaderId?: number;
  toHeaderLastVersionId?: number;
  headerReportName?: string;
  title?: string;
  useInRender: boolean;
};
export const PersonReportRelationSelect: FC<Props> = ({
  filterValue,
  onChange,
  values,
}) => {
  const loadPersonOptions: (queryStr: string) => Promise<PersonOption[]> =
    useMemo(
      () => async (queryStr: string) => {
        const reports = await getReportsTableTyped({
          start: 0,
          size: 10,
          sort_by: 'last_version_timestamp',
          direction: 'desc',
          keyword: queryStr,
        });
        return reports.objects.filter(filterValue).map((summary) => ({
          label: summary.report_name,
          value: {
            title: summary.report_name,
            toHeaderId: summary.header_id,
            toHeaderLastVersionId: summary.last_version_id,
            useInRender: true,
          },
        }));
      },
      [filterValue]
    );

  return (
    <div className="related-person-select">
      <div className="description-warning">
        Обратите внимание: связь с другим отчетом всегда указывает на его
        последнюю версию!
      </div>
      <MultiLineEditor<EditorPersonReportRelation>
        title="Связанные отчёты"
        values={values}
        setter={onChange}
        renderInput={(currentValue, onValueChange) => {
          return (
            <div className="related-person-select__inputs">
              <Checkbox
                label={''}
                tooltipText={'Использовать в отчёте'}
                isChecked={currentValue.useInRender}
                onChange={() =>
                  onValueChange({
                    ...currentValue,
                    useInRender: !currentValue.useInRender,
                  })
                }
              />
              <AsyncSelect
                className={'related-person-select__inputs__person-select'}
                defaultOptions
                menuPlacement="top"
                value={{
                  label: currentValue.headerReportName || '',
                  value: currentValue,
                }}
                loadOptions={loadPersonOptions}
                onChange={(v) => {
                  onValueChange({
                    ...currentValue,
                    toHeaderId: v?.value.toHeaderId,
                    headerReportName: v?.label,
                    toHeaderLastVersionId: v?.value.toHeaderLastVersionId,
                  });
                }}
              />
              <TextInput
                placeholder="Вид связи..."
                onChange={(v) =>
                  onValueChange({
                    ...currentValue,
                    title: v,
                  })
                }
                value={currentValue.title || ''}
              />
              {currentValue.toHeaderId &&
                currentValue.toHeaderLastVersionId && (
                  <Link
                    className="related-person-select__inputs__report-link"
                    to={
                      appRoutes.REPORT +
                      `/${currentValue.toHeaderId}/version/${currentValue.toHeaderLastVersionId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LineIcon />
                  </Link>
                )}
            </div>
          );
        }}
        emptyValue={{ useInRender: true }}
      />
    </div>
  );
};
