import { createSlice } from '@reduxjs/toolkit';

import { notify } from 'utils';

import { TABS_REDUCER_NAMES } from '../constants';

import {
  getImageryPreviewThunk,
  getImageryThunk,
  updateImageryThunk,
} from './actions';
import {
  AddVisibleObjectsAction,
  ImageryState,
  RemoveVisibleObjectsAction,
  SetObjectsAction,
  SetPreviewsAction,
  SetShowPreviewAction,
  SetVisibleObjectsAction,
  UpdateVisibleObjectsAction,
} from './types';

const initialState: ImageryState = {
  objects: [],
  visibleObjects: [],
  total: 0,
  pending: false,
  showPreview: false,
  previews: [],
};

const imagerySlice = createSlice({
  name: TABS_REDUCER_NAMES.IMAGERY,
  initialState: initialState,
  reducers: {
    setObjects(state, action: SetObjectsAction) {
      state.objects = action.payload;
    },
    setVisibleObjects(state, action: SetVisibleObjectsAction) {
      state.visibleObjects = action.payload;
    },
    setShowPreview(state, action: SetShowPreviewAction) {
      state.showPreview = action.payload;
    },
    addVisibleImageryObjects(state, action: AddVisibleObjectsAction) {
      state.visibleObjects = [...state.visibleObjects, action.payload];
    },
    removeVisibleImageryObjects(state, action: RemoveVisibleObjectsAction) {
      state.visibleObjects = [
        ...state.visibleObjects.filter(
          (value) => value.id !== action.payload.item.id
        ),
      ];
      if (action.payload.showOnlyActive) {
        const removedObject = state.objects.find(
          (item) => item.id === action.payload.item.id
        );
        if (removedObject) {
          const removedObjectIndex = state.objects.indexOf(removedObject);
          state.objects.splice(removedObjectIndex, 1);
        }
      }
    },
    updateVisibleImageryObjects(state, action: UpdateVisibleObjectsAction) {
      state.visibleObjects = [
        ...state.visibleObjects.filter(
          (value) => value.id !== action.payload.id
        ),
        action.payload,
      ];
    },
    setPreviews(state, action: SetPreviewsAction) {
      state.previews = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getImageryThunk.fulfilled, (state, action) => {
        if (action.meta.arg.isPreviewRequest) {
          state.visibleObjects.push(...action.payload.objects);
        } else {
          state.objects.push(...action.payload.objects);
          state.total = action.payload.total;
        }
        state.pending = false;
      })
      .addCase(getImageryThunk.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(getImageryThunk.rejected, (state, action) => {
        state.pending = false;
        notify.error(action.payload?.message);
      })
      .addCase(updateImageryThunk.fulfilled, (state, action) => {
        state.objects = state.objects.map((value) =>
          value.id === action.payload.id ? action.payload : value
        );
      })
      .addCase(getImageryPreviewThunk.fulfilled, (state, action) => {
        state.previews = action.payload.objects;
      })
      .addCase(getImageryPreviewThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      }),
});

export const { actions: imageryActions, reducer: imageryReducer } =
  imagerySlice;
