import { constants, lib, modes } from '@mapbox/mapbox-gl-draw';

import {
  CircleDrawnFeature,
  CommonDrawnFeature,
  DrawCustomModeOverride,
} from './types';
import { createSupplementaryPointsForCircle } from './utils';

const SimpleSelectModeOverride = Object.assign(
  {},
  modes.simple_select as DrawCustomModeOverride
);

const { createSupplementaryPoints, moveFeatures } = lib;

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
SimpleSelectModeOverride.dragMove = function (state, e) {
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  moveFeatures(this.getSelected(), delta);

  this.getSelected()
    .filter((feature) => feature?.properties?.subtype === 'Circle')
    .map((circle) => circle?.properties?.center)
    .forEach((center) => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });

  state.dragMoveLocation = e.lngLat;
};

SimpleSelectModeOverride.toDisplayFeatures = function (
  state,
  drawnFeature: CommonDrawnFeature | CircleDrawnFeature,
  display
) {
  drawnFeature.properties.active = this.isSelected(drawnFeature.properties.id)
    ? constants.activeStates.ACTIVE
    : constants.activeStates.INACTIVE;

  display(drawnFeature);

  this.fireActionable();

  if (
    drawnFeature.properties.active !== constants.activeStates.ACTIVE ||
    drawnFeature.geometry.type === constants.geojsonTypes.POINT
  ) {
    return;
  }

  const supplementaryPoints =
    drawnFeature.properties.user_subtype === 'Circle'
      ? createSupplementaryPointsForCircle(drawnFeature)
      : createSupplementaryPoints(drawnFeature);

  supplementaryPoints?.forEach(display);
};

export { SimpleSelectModeOverride };
