import React from 'react';

interface Props<V> {
  title: string;
  values: V[];
  setter: (v: V[]) => void;
  emptyValue: V;
  renderInput: (
    currentValue: V,
    onValueChange: (v: V) => void
  ) => React.ReactNode;
  isFullScreen?: boolean;
}

export function MultiLineEditor<V>({
  title,
  values,
  setter,
  emptyValue,
  renderInput,
  isFullScreen,
}: Props<V>) {
  const className = isFullScreen
    ? 'profile-card__input-block__row_full-screen'
    : 'profile-card__input-block__row_full';
  return (
    <>
      <div className={className}>
        <p className="" style={{ marginRight: 10 }}>
          {title}
        </p>
        {!values.length && (
          <button
            type="button"
            className="profile-card__input__control-button"
            onClick={() => setter([emptyValue])}
          >
            +
          </button>
        )}
      </div>
      {values.map((value, idx) => (
        <div key={idx} className={className}>
          {renderInput(value, (v) => {
            const newV = [...values];
            newV[idx] = v;
            setter(newV);
          })}
          <button
            type="button"
            className="profile-card__input__control-button"
            onClick={() => {
              const newV = [...values];
              newV.splice(idx, 1);
              setter(newV);
            }}
          >
            -
          </button>
          {idx === values.length - 1 ? (
            <button
              type="button"
              className="profile-card__input__control-button"
              onClick={() => setter(values.concat([emptyValue]))}
            >
              +
            </button>
          ) : null}
        </div>
      ))}
    </>
  );
}
