import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteImagery } from 'api/map';
import cn from 'classnames';
import { DATE_FORMAT } from 'constants/map';
import { IMAGERY_UPLOAD_MODAL } from 'constants/modals';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks';
import { ReactComponent as ImportIcon } from 'images/icons/import.svg';
import { ReactComponent as Up } from 'images/newIcons/up.svg';
import { IImageryFilter, IImageryObject } from 'interfaces';
import { imageryActions } from 'store/slices/mapV2/tabsReducer/imagerySlice';
import {
  getImageryPreviewThunk,
  getImageryThunk,
} from 'store/slices/mapV2/tabsReducer/imagerySlice/actions';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { TDateRange } from 'types';

import TagContainer, { TDrawableTag } from 'components/Monitoring/TagContainer';
import { Switch, TextInput } from 'components/ui';
import { DatePickerDropdown } from 'components/ui/DatePickerDropdown/DatePickerDropdown';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import SidebarWindowWithTabs from 'components/ui/SidebarWindowWithTabs';
import { getTagDate, notify } from 'utils';

import InfiniteImageryList from './InfiniteImageryList';

import './style.scss';

const DEFAULT_PAGE_SIZE = 20;

const MapImagery = () => {
  const dispatch = useAppDispatch();
  const imagery = useSelector(imagerySelector);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<TDateRange>([null, null]);
  const [processedImagery, setProcessedImagery] = useState<IImageryObject>();
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [tags, setTags] = useState<TDrawableTag>();
  const [showSettings, setShowSettings] = useState(false);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [page, setPage] = useState(0);
  const [startDate, endDate] = dateRange;

  const dateOrUndef = (date: Date | null) =>
    date ? dayjs(date).format(DATE_FORMAT) : undefined;

  const visibleIDs = useMemo(
    () => imagery.visibleObjects.map((item) => item.id),
    [imagery.visibleObjects]
  );

  const searchImagery = useCallback(
    (searchPayload: IImageryFilter) => dispatch(getImageryThunk(searchPayload)),
    []
  );

  const searchPreviews = () =>
    dispatch(
      getImageryPreviewThunk({
        params: {
          ...getSearchPayload().params,
          start: undefined,
          size: 99999,
        },
      })
    );

  const getSearchPayload = (forceStartZero?: boolean): IImageryFilter => {
    const includeDate = startDate && endDate;
    return {
      id_list: showOnlyActive ? visibleIDs : undefined,
      params: {
        name: searchValue || undefined,
        fdate: includeDate && dateOrUndef(startDate),
        tdate: includeDate && dateOrUndef(endDate),
        start: forceStartZero ? 0 : page * DEFAULT_PAGE_SIZE,
      },
    } as IImageryFilter;
  };

  const searchWithFilters = (forceStartZero?: boolean) => {
    !imagery.pending && searchImagery(getSearchPayload(forceStartZero));
  };

  useEffect(() => {
    searchPreviews();
    return () => {
      dispatch(imageryActions.setObjects([]));
      setPage(0);
    };
  }, []);

  useEffect(() => {
    dispatch(imageryActions.setObjects([]));
    dispatch(imageryActions.setPreviews([]));
    setPage(0);
    searchWithFilters(true);
    searchPreviews();
  }, [searchValue, showOnlyActive, dateRange]);

  useEffect(() => {
    if (page !== 0) searchWithFilters();
  }, [page]);

  useEffect(() => {
    if (Boolean(startDate) == Boolean(endDate)) {
      if (startDate && endDate) {
        const dateRangeString = getTagDate(
          `${dayjs(startDate).format('DD.MM.YY')}-${dayjs(endDate).format(
            'DD.MM.YY'
          )}`
        );
        setTags({
          label: dateRangeString,
          value: dateRangeString,
          onRemove: () => setDateRange([null, null]),
          title: 'Дата',
        });
      } else {
        setTags(undefined);
      }
    }
  }, [dateRange]);

  const handleOpenUploadModal = useCallback(() => {
    dispatch(
      modalsActions.addModal({ id: IMAGERY_UPLOAD_MODAL, isOpen: true })
    );
  }, []);

  const handleDelete = async () => {
    if (processedImagery) {
      await deleteImagery(processedImagery.id).catch(() =>
        notify.error('Не удалось удалить снимок')
      );
      await dispatch(getImageryThunk(getSearchPayload()));
    }
  };

  const getTooltipOverlay = (imagery: IImageryObject) => (
    <div className={'astra-dropdown__actions'}>
      <button
        className="tpg-body-one astra-dropdown__actions__danger"
        onClick={() => {
          setDeleteModal(true);
          setProcessedImagery(imagery);
        }}
      >
        Удалить снимок
      </button>
    </div>
  );

  const toggleImageryPreview = () =>
    dispatch(imageryActions.setShowPreview(!imagery.showPreview));

  const handleOnlyActiveSwitch = () => {
    setShowOnlyActive(!showOnlyActive);
  };

  const imageryTab = (
    <div className="imagery-body flex flex-col">
      <div className="flex flex-row justify-between p-4">
        <div
          className="flex flex-row gap-1 hover:cursor-pointer"
          onClick={() => setShowSettings(!showSettings)}
        >
          <Up
            className={cn(
              'h-4 w-4 mt-[2px]',
              showSettings
                ? 'rotate-180 [&>path]:fill-bright_product'
                : 'rotate-90 [&>path]:fill-tpg_base'
            )}
          />
          <div
            className={cn(
              'tpg-c2',
              showSettings ? 'text-bright_product' : 'text-tpg_base'
            )}
          >
            Настройки
          </div>
        </div>
        <div className="flex flex-row tpg-c2 gap-1">
          <div className="text-tpg_title">Сортировка</div>
          <div className=" text-tpg_light">По дате загрузки</div>
        </div>
      </div>
      {showSettings && (
        <div className="flex items-center justify-between p-4 pt-0">
          <div className="tpg-c2">Показывать только активные</div>
          <Switch checked={showOnlyActive} onChange={handleOnlyActiveSwitch} />
        </div>
      )}
      <div className="imagery-body__filter pb-4 px-4">
        <div className="imagery-body__filter__item">
          <TextInput
            placeholder="Начните поиск"
            searchIcon
            classNames={{ container: 'search-container' }}
            value={searchValue}
            onChange={setSearchValue}
          >
            <DatePickerDropdown
              className="mt-5 top-0 right-0"
              onValueChange={setDateRange}
            />
          </TextInput>
        </div>
      </div>
      {tags && (
        <div className="px-4 pb-4 ">
          <TagContainer
            tags={[tags]}
            className="!m-0"
            tagClassName="bg-ultrablack"
          />
        </div>
      )}
      <div className="imagery-body__items grow">
        <InfiniteImageryList
          getTooltipOverlay={getTooltipOverlay}
          showOnlyActive={showOnlyActive}
          setPage={setPage}
        />
        {imagery.objects.length === 0 && (
          <div className="text-tpg_base tpg-c1 px-4">
            {NOTHING_WAS_FOUND_MESSAGE}
          </div>
        )}
      </div>
      <div className="imagery-body__footer flex justify-between items-center bottom-0 tpg-c2 self-end">
        <div className="flex flex-row gap-3">
          <div className="flex cursor-pointer" onClick={handleOpenUploadModal}>
            <ImportIcon />
            <div className="pl-1 text-tpg_base">Импорт</div>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <div className="pr-2">Превью на карте</div>
            <Switch
              checked={imagery.showPreview}
              onChange={toggleImageryPreview}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SidebarWindowWithTabs
        tabs={[imageryTab]}
        tabOptions={['Снимки']}
        hideOptions={true}
      />
      {isDeleteModal && (
        <ConfirmModal
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default MapImagery;
