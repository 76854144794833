import { FC, useEffect, useMemo, useState } from 'react';
import { getGroupList, getUserList, upsertUserToGroupLink } from 'api/access';
import { errorMessages, successMessages } from 'constants/errors';
import { USER_ROLE_OPTIONS } from 'constants/profile';
import { useAppSelector } from 'hooks';
import { GroupInfo, GroupMember, PublicUserInfo } from 'interfaces';
import { accountSelector } from 'store/slices/auth/selectors';

import { Button, Modal, Select, TextInput } from 'components/ui';
import { notify } from 'utils';
import { handleNotify } from 'utils/notifications';

export interface GroupMemberDetailsModalProps {
  group: GroupInfo;
  member?: GroupMember;
  onClose: () => void;
  onSuccess: () => void;
}

const GroupMemberDetailsModal: FC<GroupMemberDetailsModalProps> = ({
  group,
  onClose,
  member,
  onSuccess,
}) => {
  const account = useAppSelector(accountSelector);

  const [formState, setFormState] = useState({
    userId: member?.id || 0,
    groupId: group.id || 0,
    position: member?.position || '',
    role: member?.role || 'user',
  });

  const { userId, groupId, position, role } = formState;

  const [groupLinkedUsers, setGroupLinkedUsers] = useState<PublicUserInfo[]>(
    []
  );
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);

  const handleChange = (key: keyof typeof formState, value: any) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [linkedUsers, groups] = await Promise.all([
          getUserList(),
          getGroupList(),
        ]);
        setGroupLinkedUsers(linkedUsers);
        setGroupList(groups);
      } catch (error) {
        notify.error(errorMessages.GET_GROUP_LIST_ERROR);
      }
    };

    fetchData();
  }, [group.id]);

  const userOptions = useMemo(
    () =>
      groupLinkedUsers
        .filter((user) => user.id !== account?.id)
        .map((linkedUser) => ({
          label: linkedUser.verboseName,
          value: linkedUser.id,
        })),
    [groupLinkedUsers]
  );

  const groupOptions = useMemo(
    () =>
      groupList.map((group) => ({
        label: group.title,
        value: group.id,
      })),
    [groupList]
  );

  const handleSave = async () => {
    try {
      await handleNotify(
        upsertUserToGroupLink({ role, position }, groupId, userId),
        successMessages.USER_TO_GROUP_LINK_SUCCESS,
        errorMessages.USER_TO_GROUP_LINK_ERROR
      );
      onSuccess();
    } finally {
      onClose();
    }
  };

  const isSaveButtonDisabled =
    formState.userId === 0 ||
    formState.groupId === 0 ||
    formState.role === '' ||
    formState.position === '';

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center">
          {member
            ? 'Изменить роль пользователя'
            : 'Добавить пользователя в группу'}
        </div>
        <div className="flex flex-col gap-3 py-6">
          <Select
            withSearch
            value={userId}
            onSelect={(v) => handleChange('userId', v)}
            options={userOptions}
            theme="light"
            placeholder="Позывной"
          />
          <Select
            withSearch
            value={groupId}
            onSelect={(v) => handleChange('groupId', v)}
            options={groupOptions}
            theme="light"
            placeholder="Группа"
          />
          <Select
            value={role}
            onSelect={(v) => handleChange('role', v)}
            options={USER_ROLE_OPTIONS}
            theme="light"
            placeholder="Роль"
          />
          <TextInput
            value={position}
            onChange={(v) => handleChange('position', v)}
            placeholder="Должность"
          />
        </div>
        <Button
          onClick={handleSave}
          title={'Добавить'}
          className="w-full"
          disabled={isSaveButtonDisabled}
        />
      </div>
    </Modal>
  );
};

export default GroupMemberDetailsModal;
