import { FC } from 'react';
import { ReactComponent as Close } from 'images/newIcons/close.svg';
import { ISelectOption } from 'interfaces';

import { Select } from 'components/ui';

import {
  AccessRuleCode,
  accessRuleOptions,
  verboseAccessRule,
} from '../../constants/entities';

interface CustomAccessRuleProps {
  subject: string;
  rule: AccessRuleCode;
  onUpdateSubject: (subject: string) => void;
  onUpdateRule: (rule: AccessRuleCode) => void;
  onRemove: () => void;
  subjectSelectOptions: ISelectOption[];
  enforcedAccessRule?: AccessRuleCode;
}

const ACCESS_SELECT_CLASSNAME = {
  body: 'ml-[-28px] text-tpg_base',
  text: '!text-tpg_base',
};

const CustomAccessRule: FC<CustomAccessRuleProps> = ({
  subject,
  rule,
  onUpdateSubject,
  onUpdateRule,
  onRemove,
  subjectSelectOptions,
  enforcedAccessRule,
}) => (
  <div className="w-full flex items-center justify-between">
    <Select
      placeholder="Выберите субъекта"
      value={subject}
      onSelect={onUpdateSubject}
      options={subjectSelectOptions}
      withSearch
      withEmpty={false}
      classNames={ACCESS_SELECT_CLASSNAME}
    />
    <Select
      disabled={enforcedAccessRule !== undefined}
      // cause the default value for RESTRICT == 0, which is a false-ish value, Select renders a placeholder instead of SelectOption label
      placeholder={verboseAccessRule(AccessRuleCode.RESTRICT)}
      value={enforcedAccessRule === undefined ? rule : AccessRuleCode.READWRITE}
      onSelect={onUpdateRule}
      options={accessRuleOptions}
      withEmpty={false}
      classNames={ACCESS_SELECT_CLASSNAME}
    />

    <Close
      className="icon-close cursor-pointer min-w-[16px] min-h-[16px] fill-current text-error"
      onClick={onRemove}
    />
  </div>
);

export default CustomAccessRule;
