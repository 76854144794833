import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { authRoutes } from 'constants/routes';

import { getToken, removeToken } from 'utils';

const { REACT_APP_API_URL } = process.env;

export const baseURL = REACT_APP_API_URL;

const responseSuccessHandler = (response: AxiosResponse) => {
  return response;
};

const responseErrorHandler = (error: AxiosError) => {
  if (error.request?.status === 401) {
    setTimeout(() => {
      (window as Window).location = authRoutes.SIGN_IN;
      removeToken();
    }, 250);
  }
  return Promise.reject(error);
};

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  const authToken = getToken();

  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    } as AxiosRequestHeaders;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export const createLoadSource = () => axios.CancelToken.source();

export default instance;
