import { FC, PropsWithChildren, ReactNode } from 'react';

import { OverflowTooltipWrapper } from './OverflowTooltipWrapper';

interface INameProps {
  value: string;
  postfix?: ReactNode;
  className?: string;
}

export const Name: FC<PropsWithChildren<INameProps>> = ({
  value,
  postfix,
  className,
  children,
}) => (
  <span className="flex overflow-hidden">
    <OverflowTooltipWrapper value={value} className={className}>
      {children}
    </OverflowTooltipWrapper>
    {postfix && <>&nbsp;{postfix}</>}
  </span>
);
