import { FC } from 'react';

export interface IDatePickerCustomDay {
  day: number;
  date?: Date;
  className?: string;
}

export const DatePickerCustomDay: FC<IDatePickerCustomDay> = ({
  day,
  className,
}) => <span className={className}>{day}</span>;
