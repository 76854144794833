import { FC, Fragment, memo, useState } from 'react';
import cn from 'classnames';
import { FEATURE_COLORS } from 'constants/map';
import { useDebouncedCallback } from 'use-debounce';

import './style.scss';

interface ColorPikerProps {
  initialColor: string;
  onChange: (color: string) => void;
  withEmpty?: boolean;
  label?: string;
}

const ColorPiker: FC<ColorPikerProps> = ({
  initialColor,
  onChange,
  withEmpty,
  label,
}) => {
  const [activeColor, setActiveColor] = useState(initialColor);

  const handleChangeColor = (color: string) => {
    if (activeColor !== color) {
      setActiveColor(color);
      onUpdateColor();
    }
  };

  const onUpdateColor = useDebouncedCallback(() => {
    onChange(activeColor);
  }, 300);

  const renderItem = (color: string, idx: number) => (
    <Fragment key={`color-${idx}`}>
      <input
        id={`radio-${idx + 1}`}
        checked={color === activeColor}
        type="radio"
        name="group"
        readOnly
      />
      <label
        htmlFor={`radio-${idx + 1}`}
        style={{
          backgroundColor: color || '#E0E0E0',
          border: color === '#ffffff' ? '1px solid black' : '',
        }}
        className={cn({ 'empty-color': withEmpty && !color })}
        onClick={() => handleChangeColor(color)}
      />
    </Fragment>
  );

  return (
    <div className="color-picker">
      {label && <h2 className="color-picker__title">{label}</h2>}
      <fieldset className="color-picker__container">
        {withEmpty && renderItem('', -1)}
        {FEATURE_COLORS.map(renderItem)}
      </fieldset>
    </div>
  );
};

export default memo(ColorPiker);
