import { CancelToken } from 'axios';
import {
  IGetUSerLocationRequest,
  ILatLonCoordinates,
  IRequestEntityLinksPictures,
  IRequestIdEntityPictures,
  IRequestUserLocation,
} from 'interfaces';
import axios from 'services/axios';

export const getGeospoof = ({ lat, lon }: ILatLonCoordinates) =>
  axios.get(`/api/geospoof/locationSearch?lat=${lat}&long=${lon}`);

export const getSearchHistory = () =>
  axios.get('/api/geospoof/history?size=100');

export const getSearchInfo = (searchId: number, token: CancelToken) =>
  axios.get(`/api/geospoof/searchInfo?search_id=${searchId}`, {
    cancelToken: token,
  });

export const getRequestIdUserPictures = (params: IRequestIdEntityPictures) =>
  axios.post('/api/geospoof/userPicturesDownloadRequest', {}, { params });

export const getRequestIdGroupPictures = (params: IRequestIdEntityPictures) =>
  axios.post('/api/geospoof/groupPicturesDownloadRequest', {}, { params });

export const getLinksUserPictures = (params: IRequestEntityLinksPictures) =>
  axios.get('/api/geospoof/userPicturesDownloadRequestLinks', { params });

export const getLinksGroupPictures = (params: IRequestEntityLinksPictures) =>
  axios.get('/api/geospoof/groupPicturesDownloadRequestLinks', { params });

export const getUserLocation = (
  params: Pick<IGetUSerLocationRequest, 'request_id'>,
  cancelToken: CancelToken
) => axios.get('/api/geospoof/userLocation', { params, cancelToken });

export const postUserLocation = (params: IRequestUserLocation) =>
  axios.post('/api/geospoof/userLocation', {}, { params });
