// TODO: unused until features are ready
import { FC, HTMLAttributes, ReactNode } from 'react';
import { ReactComponent as ImportIcon } from 'images/newIcons/import.svg';
import { ReactComponent as ObjectIcon } from 'images/newIcons/objectsPassive.svg';
import { ReactComponent as ShareIcon } from 'images/newIcons/shareEntity.svg';

interface IObjectPreviewFooter {
  handleShare: () => void;
  handleExport: () => void;
  handleRemove: () => void;
}

interface IIconButtonProps extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode;
}

const IconButton: FC<IIconButtonProps> = ({ icon, children, ...props }) => (
  <div className="flex gap-1 items-center cursor-pointer" {...props}>
    {icon}
    <span className="w-full tpg-c2">{children}</span>
  </div>
);

export const ObjectPreviewFooter: FC<IObjectPreviewFooter> = ({
  handleShare,
  handleExport,
  handleRemove,
}) => {
  const OBJECT_PREVEIW_FOOTER_BUTTONS: IIconButtonProps[] = [
    {
      icon: <ImportIcon />,
      children: 'Скачать в KMZ',
      onClick: handleExport,
    },
    {
      icon: <ShareIcon />,
      children: 'Поделиться',
      onClick: handleShare,
    },
    {
      icon: <ObjectIcon className="h-[17px]" />,
      children: 'Скрыть с карты',
      onClick: handleRemove,
    },
  ];

  return (
    <div
      className={`flex justify-center gap-3 h-[32px]
  m-auto w-[calc(100%+2px)]
  bg-light border-0 border-t border-solid border-tpg_light 
  rounded-b-[inherit] tpg-c2 [&>svg]:w-4 [&>svg]:h-4`}
    >
      {OBJECT_PREVEIW_FOOTER_BUTTONS.map((props, idx) => (
        <IconButton key={idx} {...props} />
      ))}
    </div>
  );
};
