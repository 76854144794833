import { FC, memo } from 'react';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from 'images/newIcons/close.svg';

import './style.scss';

interface TagProps {
  label?: string;
  title?: string;
  closeIcon?: boolean;
  onRemove?: () => void;
  className?: string;
  onClick?: () => void;
}

const Tag: FC<TagProps> = ({
  label,
  title,
  onRemove,
  onClick,
  closeIcon = true,
  className = 'bg-dark',
}) => (
  <div
    className={cn('tag-container title-color tpg-c2', className)}
    onClick={onClick}
  >
    {title && <span>{title}</span>}
    {label && <span>{label}</span>}
    {closeIcon && (
      <CloseIcon className="tag-container__close-icon" onClick={onRemove} />
    )}
  </div>
);

export default memo(Tag);
