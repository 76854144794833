import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ClientAttributeEntity } from 'interfaces';
import { getAttributesThunk } from 'store/slices/map/actions';
import { mapAttributesSelector } from 'store/slices/map/selectors';
import { useDebounce } from 'use-debounce';

import { Header } from 'components/Header/Header';
import { Button, Select, TextArea } from 'components/ui';
import SwitchWithTitle from 'components/ui/Switch/SwitchWithTitle';
import { copyToClipboard, getAttributeOptionsFromClient } from 'utils';
import {
  CustomerSpecificConverterResult,
  getCustomerSpecificConverterValue,
} from 'utils/converterUtils';

const EncryptorScreen = () => {
  const dispatch = useAppDispatch();

  const [isDegree, setDegree] = useState(
    localStorage.getItem('wgs_degree_active') === 'true'
  );

  const COORDINATES_PLACEHOLDER = isDegree
    ? 'WGS: 48°14′22″ 37°25′57\n' + 'СК-42: X=000000, Y=000000'
    : 'WGS: 47.00000, 37.00000\n' + 'СК-42: X=000000, Y=000000';

  const clients = useAppSelector(mapAttributesSelector).clients;

  const { clientOption } = useMemo(
    () => ({
      clientOption: getAttributeOptionsFromClient(clients),
    }),
    [clients]
  );

  const [client, setClient] = useState<ClientAttributeEntity>();
  const [clientInputValue, setClientInputValue] = useState('');
  const [clientInputTransformed, setClientInputTransformed] = useState<
    CustomerSpecificConverterResult[]
  >([]);
  const [copyTitle, setCopyTitle] = useState('Скопировать');

  const [debouncedClientInputValue] = useDebounce(clientInputValue, 300);
  const clientInputTransformedFormatted = clientInputTransformed
    .map((v) => v.str)
    .join('');

  const [isCopyDisabled, setCopyDisabled] = useState(true);

  useEffect(() => {
    setCopyDisabled(clientInputTransformedFormatted === '');
  }, [clientInputTransformedFormatted]);

  useEffect(() => {
    isCopyDisabled && setCopyTitle('Скопировано');
    !isCopyDisabled && setCopyTitle('Скопировать');
  }, [isCopyDisabled]);

  const onSelectClient = (opt: number | null) => {
    const foundClient = clients.find((c) => c.id === opt);
    localStorage.setItem('encryptor_client', `${opt}`);
    setClient(foundClient);
  };

  const renderCustomerConverter = () => (
    <div className="converter-customer w-full">
      <TextArea
        value={clientInputValue}
        onChange={setClientInputValue}
        rows={6}
        placeholder={COORDINATES_PLACEHOLDER}
        style={{ height: '256px' }}
      />
      <div className="pt-6 pb-3">
        <SwitchWithTitle
          active={isDegree}
          onChange={() => setDegree(!isDegree)}
          title="Показывать WGS в формате градусов"
          localStorageTag="wgs_degree_active"
        />
      </div>
      <TextArea
        value={clientInputTransformedFormatted}
        placeholder={COORDINATES_PLACEHOLDER}
        rows={5}
        readOnly
        style={{ cursor: 'pointer', height: '256px', resize: 'none' }}
      />
      <Button
        title={copyTitle}
        className="w-full mt-6"
        onClick={() => {
          copyToClipboard(clientInputTransformedFormatted);
          setCopyDisabled(true);
        }}
        disabled={isCopyDisabled}
      />
    </div>
  );

  useEffect(() => {
    dispatch(getAttributesThunk());
    onSelectClient(
      parseInt(localStorage.getItem('encryptor_client') || '') || null
    );
  }, []);

  useEffect(() => {
    async function process() {
      if (client?.code && debouncedClientInputValue) {
        const v = await getCustomerSpecificConverterValue(
          debouncedClientInputValue,
          client.code
        );
        setClientInputTransformed(v);
      } else {
        return Promise.resolve([]);
      }
    }

    process().finally();
  }, [client, debouncedClientInputValue, isDegree]);

  return (
    <>
      <Header />
      <div className="flex justify-center items-center h-screen">
        <div className="astra-single-page-modal" style={{ width: '640px' }}>
          <h1 className="tpg-h4 pb-6">Шифрование координат</h1>
          <div className="w-full pt-3 pb-6">
            <Select
              placeholder="Не выбран"
              options={clientOption}
              value={client?.id || null}
              onSelect={onSelectClient}
              theme="light"
              withSearch
              withEmpty={false}
            />
          </div>
          {renderCustomerConverter()}
        </div>
      </div>
    </>
  );
};

export default EncryptorScreen;
