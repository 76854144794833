import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import {
  AddBoundaryGridAction,
  BoundaryGridState,
  RemoveBoundaryGridAction,
  SetBoundaryGridMapAction,
  UpdateBoundaryGridAction,
  UpdateBoundaryGridCellAction,
  UpdateBoundaryGridsAction,
} from './types';

const initialState: BoundaryGridState = {
  boundaryGridsMap: {},
};

const boundaryGridSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.BOUNDARY_GRID,
  initialState: initialState,
  reducers: {
    setBoundaryGridMap(state, action: SetBoundaryGridMapAction) {
      state.boundaryGridsMap = action.payload;
    },
    addBoundaryGrid(state, action: AddBoundaryGridAction) {
      state.boundaryGridsMap = {
        ...state.boundaryGridsMap,
        [action.payload.relatedFeatureId]: action.payload,
      };
    },
    updateBoundaryGrid(state, action: UpdateBoundaryGridAction) {
      const { relatedFeatureId, properties } = action.payload;
      const boundaryGrid = state.boundaryGridsMap[relatedFeatureId];

      boundaryGrid &&
        (state.boundaryGridsMap = {
          ...state.boundaryGridsMap,
          [relatedFeatureId]: { ...boundaryGrid, ...properties },
        });
    },
    updateBoundaryGrids(state, action: UpdateBoundaryGridsAction) {
      const { relatedFeatureIds, properties } = action.payload;

      relatedFeatureIds.forEach((id) => {
        const boundaryGrid = state.boundaryGridsMap[id];

        boundaryGrid &&
          (state.boundaryGridsMap[id] = {
            ...state.boundaryGridsMap[id],
            ...properties,
          });
      });
    },
    updateBoundaryGridCell(state, action: UpdateBoundaryGridCellAction) {
      const { relatedFeatureId, id, properties } = action.payload;
      const boundaryGrid = state.boundaryGridsMap[relatedFeatureId];
      const boundaryGridCell = boundaryGrid.features.find(
        (feature) => feature.id === id || feature.properties.id === id
      );

      if (boundaryGridCell) {
        boundaryGridCell.properties = {
          ...boundaryGridCell.properties,
          ...properties,
        };
      }
    },
    removeBoundaryGrid(state, action: RemoveBoundaryGridAction) {
      const { [action.payload]: _removedBoundaryGrid, ...restBoundaryGridMap } =
        state.boundaryGridsMap;

      state.boundaryGridsMap = restBoundaryGridMap;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: boundaryGridActions, reducer: boundaryGridReducer } =
  boundaryGridSlice;
