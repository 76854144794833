import { useCallback, useRef } from 'react';

interface IBaseClickEvent {
  detail: number;
}

export interface IMapClickEvent {
  originalEvent: IBaseClickEvent;
}

export const useDoubleTap = <T extends IBaseClickEvent | IMapClickEvent>(
  onSingleTap: (e: T) => void,
  onDoubleTap: (e: T) => void,
  threshold = 250
) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handler = useCallback(
    (event: T) => {
      if (!timer.current) {
        timer.current = setTimeout(() => {
          if (onSingleTap) {
            onSingleTap(event);
          }
          timer.current = null;
        }, threshold);
      } else {
        clearTimeout(timer.current);
        timer.current = null;
        onDoubleTap && onDoubleTap(event);
      }
    },
    [onDoubleTap, threshold, onSingleTap]
  );

  return handler;
};
