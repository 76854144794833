import { ReactComponent as CircleIcon } from 'images/newIcons/circle.svg';
import { ReactComponent as FolderIcon } from 'images/newIcons/folder.svg';

import { EntityNode } from './types';

export const renderNodeIcon = (node: EntityNode) => {
  const isFolder = node.data.isFolder;

  /*
  TODO: temporary hidden until access sharing is ready
  if (!node.level) {
    return null;
  }
  */

  if (isFolder) {
    return (
      <div className="[&>svg>path]:fill-tpg_base group-[.empty:not(.selected)]:[&>svg>path]:fill-tpg_light group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
        <FolderIcon />
      </div>
    );
  }

  return (
    <div className="[&>svg>path]:fill-tpg_base group-[.empty:not(.selected)]:[&>svg>path]:fill-tpg_light group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
      <CircleIcon />
    </div>
  );
};
