import { memo } from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as Close } from 'images/newIcons/close.svg';

import './style.scss';

const NotificationContainer = () => (
  <ToastContainer
    position="top-center"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={4}
    draggable
    pauseOnHover
    theme="dark"
    className="toast-container"
    toastClassName="toast"
    bodyClassName="tpg-b1 toast-body"
    icon={false}
    closeButton={<Close className="close-icon" />}
  />
);

export default memo(NotificationContainer);
