import { FC, useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { IMediaFile } from 'interfaces';

import { Loader } from 'components/ui';

import { MediaGalleryControls } from './MediaGalleryControls';
import { MediaGalleryPreview } from './MediaGalleryPreview';
import { MediaGallerySlider } from './MediaGallerySlider';
import { MediaGalleryControlsSkeleton, SliderSkeleton } from './Skeleton';
import { IMediaGalleryAction } from './types';

export interface IMediaGalleryProps {
  mediaFiles: IMediaFile[];
  actions?: IMediaGalleryAction[];
  isDraggable?: boolean;
  isLoading?: boolean;
  onSlideDrop?: (mediaFiles: IMediaFile[]) => void;
}

export const MediaGallery: FC<IMediaGalleryProps> = ({
  mediaFiles,
  actions,
  isDraggable,
  isLoading,
  onSlideDrop,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = mediaFiles.length;

  const isPrevSlideDisabled = currentSlide === 0;
  const isNextSlideDisabled = currentSlide === slidesCount - 1;

  const handlePrevSlide = useCallback(
    () =>
      setCurrentSlide((index) => {
        const updatedIndex = index - 1;

        if (updatedIndex < 0) {
          return index;
        }

        return updatedIndex;
      }),
    []
  );

  const handleNextSlide = useCallback(
    () =>
      setCurrentSlide((index) => {
        const updatedIndex = index + 1;

        if (updatedIndex > slidesCount - 1) {
          return index;
        }

        return updatedIndex;
      }),
    [slidesCount]
  );

  const handleDragEnd = (dropResult: DropResult) => {
    if (!dropResult.source || !dropResult.destination) {
      return;
    }

    const {
      source: { index: source },
      destination: { index: destination },
    } = dropResult;

    const reorderedItems = [...mediaFiles];
    const [movedItem] = reorderedItems.splice(source, 1);

    reorderedItems.splice(destination, 0, movedItem);

    onSlideDrop?.(reorderedItems);
  };

  const onNavigationKeyDown = useCallback((e: KeyboardEvent) => {
    if (
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const onNavigationKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        handlePrevSlide();
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        handleNextSlide();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [handlePrevSlide, handleNextSlide]
  );

  const handleSlideClick = (index: number) => setCurrentSlide(index);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    document.addEventListener('keydown', onNavigationKeyDown);
    document.addEventListener('keyup', onNavigationKeyUp);

    return () => {
      document.removeEventListener('keydown', onNavigationKeyDown);
      document.removeEventListener('keyup', onNavigationKeyUp);
    };
  }, [isLoading, handlePrevSlide, handleNextSlide]);

  useEffect(() => {
    const isCurrentSlideOutStart = currentSlide < -1;
    const isCurrentSlideOutEnd = currentSlide > slidesCount - 1;
    const noCurrentSlideIsSelected = currentSlide == -1 && slidesCount > 0;

    if (isCurrentSlideOutStart || noCurrentSlideIsSelected) {
      setCurrentSlide(0);
    }

    if (isCurrentSlideOutEnd) {
      setCurrentSlide(currentSlide - 1);
    }
  }, [slidesCount, currentSlide]);

  return (
    <div className="w-full h-full p-6 pb-0 border border-solid border-tpg_light rounded-[5px]">
      <div className="w-full h-full flex gap-3">
        {isLoading ? (
          <SliderSkeleton />
        ) : (
          <MediaGallerySlider
            mediaFiles={mediaFiles}
            currentSlide={currentSlide}
            isDraggable={isDraggable}
            onDragEnd={handleDragEnd}
            onSlideClick={(slide) => handleSlideClick(slide)}
          />
        )}
        <div className="w-full h-full flex flex-col">
          <div className="w-full h-full flex justify-center items-center overflow-hidden rounded-[10px] bg-dark">
            {isLoading ? (
              <Loader />
            ) : (
              <MediaGalleryPreview mediaFile={mediaFiles[currentSlide]} />
            )}
          </div>
          {isLoading ? (
            <MediaGalleryControlsSkeleton />
          ) : (
            <MediaGalleryControls
              slidesCount={slidesCount}
              currentSlide={currentSlide}
              isPrevSlideDisabled={isPrevSlideDisabled}
              isNextSlideDisabled={isNextSlideDisabled}
              actions={actions}
              onCurrentSlideChange={setCurrentSlide}
              onPrevSlide={handlePrevSlide}
              onNextSlide={handleNextSlide}
            />
          )}
        </div>
      </div>
    </div>
  );
};
