import { RootState } from 'store';

export const geospoofSelector = (state: RootState) =>
  state.map_v2.tabs.geospoof;

export const imagerySelector = (state: RootState) => state.map_v2.tabs.imagery;
export const operationalSelector = (state: RootState) =>
  state.map_v2.tabs.operational;

export const layersSelector = (state: RootState) => state.map_v2.tabs.layers;
