import { mediaType, postType, sourceMedia } from 'constants/filters';
import { ReactComponent as Filter } from 'images/icons/filter.svg';
import { ReactComponent as Star } from 'images/icons/star.svg';
import { IStaticFiltersState } from 'interfaces';

import { monitoringRoutes } from './routes';

export const monitoringTabs = [
  {
    link: monitoringRoutes.FEED,
    icon: <Filter />,
    title: 'feed',
  },
  {
    link: monitoringRoutes.BOOKMARKS,
    icon: <Star />,
    title: 'favorites',
  },
];

export const initialStaticFilters = {
  searchMode: 'raw',
  source: sourceMedia,
  media: mediaType,
  post: postType,
  startDate: null,
  endDate: null,
} as IStaticFiltersState;

export const initialMonitoringPosts = {
  total: 0,
  list: [],
  pending: false,
};
