import { ILoginValues } from 'interfaces';
import axios from 'services/axios';

export const login = (values: ILoginValues) =>
  axios.post('api/auth', values, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getPrivileges = () => axios.get('api/privileges');
