import axios from 'services/axios';

import { BadTranslationFeedback } from '../interfaces/translator';

import { handleAxiosResponse } from './apiUtils';

export const makeTranslation = (input: string) =>
  handleAxiosResponse<string>(
    axios.post('/api/external/translate', input, {
      headers: { 'Content-Type': 'application/json' },
    })
  );

export const sendBadTranslationFeedback = (payload: BadTranslationFeedback) =>
  axios.post('/api/external/feedback', payload);
