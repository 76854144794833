import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { labelConfig, lineConfig, polygonFillConfig } from 'configs/map/common';
import { POLYGON_SOURCE } from 'constants/map';
import { MapGeoJSONObject } from 'interfaces';

interface PolygonSourceProps extends MapGeoJSONObject {
  mainFillConfig?: LayerProps;
  mainLineConfig?: LayerProps;
  mainLabelConfig?: LayerProps;
}

export const PolygonSource: FC<PolygonSourceProps> = memo(
  ({
    id = POLYGON_SOURCE,
    data,
    mainFillConfig = polygonFillConfig,
    mainLineConfig = lineConfig,
    mainLabelConfig = labelConfig,
  }) => (
    <Source id={id} type="geojson" data={data}>
      <Layer id={`${id}-main-fill`} {...mainFillConfig} />
      <Layer id={`${id}-main-line`} {...mainLineConfig} />
      <Layer id={`${id}-main-label`} {...mainLabelConfig} />
    </Source>
  )
);

PolygonSource.displayName = 'PolygonSource';
