import dayjs from 'dayjs';

import { ITableColumn } from 'components/ui/Table';

import { PersonReportSummary } from '../../interfaces/reports';

export const getReportColumns = (): ITableColumn<PersonReportSummary>[] => [
  {
    title: 'Название',
    sortable: true,
    accessor: 'report_name',
    render: ({ report_name }) => (
      <div className="feature-table__row__description">
        {report_name === null ? 'Не указано' : report_name}{' '}
      </div>
    ),
  },
  {
    title: 'Автор',
    sortable: true,
    accessor: 'header_created_by',
    render: ({ header_created_by }) => (
      <div className="feature-table__row__description">
        {header_created_by === null ? 'Не указан' : header_created_by}{' '}
      </div>
    ),
  },
  {
    title: 'Должность',
    accessor: 'position',
    render: ({ position }) => (
      <div className="feature-table__row__description">
        {position === '' ? 'Не указана' : position}{' '}
      </div>
    ),
  },
  {
    title: 'Автор последних изменений',
    accessor: 'last_version_created_by',
    render: ({ last_version_created_by }) => (
      <div className="feature-table__row__description">
        {last_version_created_by === null
          ? 'Не указан'
          : last_version_created_by}{' '}
      </div>
    ),
  },
  {
    title: 'Создан',
    sortable: true,
    accessor: 'header_timestamp',
    render: ({ header_timestamp }) => (
      <div className="feature-table__row__description">
        {header_timestamp
          ? dayjs(header_timestamp).format('DD.MM.YYYY HH:mm:ss')
          : null}
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    sortable: true,
    accessor: 'last_version_timestamp',
    render: ({ last_version_timestamp }) => (
      <div className="feature-table__row__description">
        {last_version_timestamp
          ? dayjs(last_version_timestamp).format('DD.MM.YYYY HH:mm:ss')
          : null}
      </div>
    ),
  },
];
