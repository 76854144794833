import { IAmmunition } from 'interfaces';

export const Ammunitions: IAmmunition[] = [
  {
    name: 'M777',
    caliber: '155mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M107', status: 'На вооружении', range: '0.78-22.70 км' },
      { name: 'M795', status: 'На вооружении', range: '0.78-30.10 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-30.10 км' },
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-40.00 км',
      },
    ],
  },
  {
    name: 'M101',
    caliber: '105mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M1130', status: 'На вооружении', range: '0.78-12.00 км' },
    ],
  },
  {
    name: 'L119',
    caliber: '105mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M1130E1', status: 'На вооружении', range: '0.78-17.00 км' },
    ],
  },
  {
    name: 'FH70',
    caliber: '155mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M107', status: 'На вооружении', range: '0.78-22.70 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-30.10 км' },
    ],
  },
  {
    name: 'AHS Krab',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-40.00 км',
      },
      { name: 'M107', status: 'На вооружении', range: '0.78-27.00 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-31.00 км' },
    ],
  },
  {
    name: 'CAESAR',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-42.00 км' },
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-47.00 км',
      },
    ],
  },
  {
    name: 'M109',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'M712 Copperhead',
        status: 'Не на вооружении',
        range: '3.00-16.00 км',
      },
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-30.10 км' },
    ],
  },
  {
    name: 'DANA M1',
    caliber: '152mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: '152EOF', status: 'На вооружении', range: '0.78-18.00 км' },
      { name: '152EOFd', status: 'На вооружении', range: '0.00-21.00 км' },
    ],
  },
  {
    name: 'ZUZANA 2',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-39.00 км',
      },
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      { name: 'M549', status: 'На вооружении', range: '0.78-41.00 км' },
    ],
  },
  {
    name: 'PzH 2000',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      { name: 'SMArt 155', status: 'Не на вооружении', range: '0.78-24.00 км' },
      {
        name: 'DM121 Boattail',
        status: 'Не на вооружении',
        range: '0.78-36.00 км',
      },
      { name: 'M549', status: 'На вооружении', range: '0.78-40.00 км' },
      { name: 'L152A', status: 'Не на вооружении', range: '0.78-47.00 км' },
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-48.00 км',
      },
      { name: 'V-LAP', status: 'Не на вооружении', range: '0.78-67.00 км' },
    ],
  },
  {
    name: 'Archer',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      {
        name: 'Bofors 155 Bonus',
        status: 'На вооружении',
        range: '0.78-35.00 км',
      },
      { name: 'M549', status: 'На вооружении', range: '0.78-40.00 км' },
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-42.00 км',
      },
    ],
  },
  {
    name: '2С22 Богдана',
    caliber: '155mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'ОФ-540', status: 'На вооружении', range: '0.78-18.00 км' },
      { name: 'M107', status: 'На вооружении', range: '0.78-24.00 км' },
      {
        name: 'M982 Excalibur',
        status: 'На вооружении',
        range: '0.78-40.00 км',
      },
      { name: 'M549', status: 'На вооружении', range: '0.78-50.00 км' },
    ],
  },
  {
    name: '2С3 Акация',
    caliber: '152mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: '3ВОФ27', status: 'На вооружении', range: '0.78-24.00 км' },
    ],
  },
  {
    name: '2С19 Мста-С',
    caliber: '152mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'ОФ-540', status: 'На вооружении', range: '0.78-25.00 км' },
      { name: '3ВОФ27', status: 'На вооружении', range: '0.78-28.00 км' },
    ],
  },
  {
    name: '2A65 Мста-Б',
    caliber: '152mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'ОФ-540', status: 'На вооружении', range: '0.78-25.00 км' },
      { name: '3ВОФ27', status: 'На вооружении', range: '0.78-28.00 км' },
    ],
  },
  {
    name: '2С5 Гиацинт-С',
    caliber: '152mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'ОФ-540', status: 'На вооружении', range: '0.78-25.00 км' },
      { name: '3ВОФ27', status: 'На вооружении', range: '0.78-28.00 км' },
    ],
  },
  {
    name: '2А36 Гиацинт-Б',
    caliber: '152mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: '3ОФ29', status: 'На вооружении', range: '0.78-28.50 км' },
      { name: '3ОФ59', status: 'На вооружении', range: '0.78-30.50 км' },
    ],
  },
  {
    name: '2С7 Пион',
    caliber: '203mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: '3ВОФ42', status: 'На вооружении', range: '0.78-25.50 км' },
      { name: '3ВОФ34', status: 'На вооружении', range: '0.78-37.40 км' },
      { name: '3ВОФ35', status: 'На вооружении', range: '0.78-47.50 км' },
    ],
  },
  {
    name: '2С4 Тюльпан',
    caliber: '240mm',
    type: 'Самоходная гаубица',
    status: 'Не на вооружении',
    bulletRange: [
      { name: '53-Ф-864', status: 'Не на вооружении', range: '0.50-9.65 км' },
      { name: '3Ф2', status: 'Не на вооружении', range: '0.50-20.00 км' },
    ],
  },
  {
    name: '2А1 Д-30',
    caliber: '122mm',
    type: 'Буксированная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: 'OF-462', status: 'На вооружении', range: '0.50-20.00 км' },
      { name: 'ХМ09', status: 'На вооружении', range: '0.50-21.50 км' },
    ],
  },
  {
    name: '2С1 Гвоздика',
    caliber: '122mm',
    type: 'Самоходная гаубица',
    status: 'На вооружении',
    bulletRange: [
      { name: '3ОФ7', status: 'На вооружении', range: '0.50-15.20 км' },
      {
        name: 'ERBB 122 HB',
        status: 'Не на вооружении',
        range: '0.50-20.00 км',
      },
    ],
  },
  {
    name: 'RM-70 Vampire',
    caliber: '122mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: '9М28С', status: 'На вооружении', range: '1.65-11.00 км' },
      { name: '9М28Ф', status: 'На вооружении', range: '0.00-15.00 км' },
      { name: '9М28С', status: 'На вооружении', range: '1.65-15.07 км' },
      { name: '9М22С', status: 'На вооружении', range: '1.50-19.80 км' },
      { name: 'JROF', status: 'На вооружении', range: '1.65-20.07 км' },
      { name: '9М22У', status: 'На вооружении', range: '5.00-20.10 км' },
      { name: '9М22', status: 'На вооружении', range: '5.00-20.40 км' },
    ],
  },
  {
    name: 'БМ-27 Ураган',
    caliber: '220mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: '9М51', status: 'На вооружении', range: '5.00-13.00 км' },
      { name: '9М27Ф', status: 'На вооружении', range: '10.00-35.00 км' },
      { name: '9М27K', status: 'На вооружении', range: '10.00-35.00 км' },
      { name: '9М59', status: 'На вооружении', range: '10.00-35.00 км' },
      { name: '9М27С', status: 'На вооружении', range: '8.00-35.00 км' },
    ],
  },
  {
    name: 'TRG-230',
    caliber: '230mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: 'THR-230', status: 'На вооружении', range: '20.00-70.00 км' },
    ],
  },
  {
    name: 'БМ-30 Смерч',
    caliber: '300mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: '9М55К', status: 'На вооружении', range: '20.00-70.00 км' },
    ],
  },
  {
    name: '9К51М Торнадо-Г',
    caliber: '122mm',
    type: 'РСЗВ',
    status: 'Не на вооружении',
    bulletRange: [
      { name: '9М42', status: 'На вооружении', range: '1.00-5.00 км' },
      { name: '9М23', status: 'На вооружении', range: '0.00-19.00 км' },
      { name: '9М53Ф', status: 'На вооружении', range: '5.00-20.50 км' },
      { name: '9М217', status: 'На вооружении', range: '0.00-30.00 км' },
      { name: '9М521', status: 'На вооружении', range: '0.00-40.00 км' },
    ],
  },
  {
    name: '9К515 Торнадо-С',
    caliber: '300mm',
    type: 'РСЗВ',
    status: 'Не на вооружении',
    bulletRange: [
      { name: '9М55К', status: 'Не на вооружении', range: '20.00-70.00 км' },
      { name: '9М55Ф', status: 'Не на вооружении', range: '25.00-70.00 км' },
      { name: '9М55С', status: 'Не на вооружении', range: '25.00-70.00 км' },
      { name: '9М528', status: 'Не на вооружении', range: '25.00-90.00 км' },
      { name: '9М534', status: 'Не на вооружении', range: '20.00-90.00 км' },
      { name: '9М542', status: 'Не на вооружении', range: '40.00-120.00 км' },
    ],
  },
  {
    name: 'LARS-2',
    caliber: '110mm',
    type: 'РСЗВ',
    status: 'Не на вооружении',
    bulletRange: [
      { name: 'DM-711', status: 'Не на вооружении', range: '0.00-14.00 км' },
      { name: 'DM-21', status: 'Не на вооружении', range: '0.00-14.00 км' },
      {
        name: 'DM-CASSETE',
        status: 'Не на вооружении',
        range: '0.00-25.00 км',
      },
      { name: '9М55С', status: 'Не на вооружении', range: '25.00-70.00 км' },
    ],
  },
  {
    name: 'M270 MLRS',
    caliber: '227mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M30А1', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'M31', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'M31А1', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'GLSDB', status: 'Не на вооружении', range: '0.00-150.00 км' },
      {
        name: 'M57 ATACMS TACMS 2000',
        status: 'Не на вооружении',
        range: '0.00-300.00 км',
      },
      {
        name: 'M48 ATACMS QRU',
        status: 'Не на вооружении',
        range: '0.00-300.00 км',
      },
    ],
  },
  {
    name: 'М142 HIMARS',
    caliber: '227mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: 'M30А1', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'M31', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'M31А1', status: 'На вооружении', range: '0.00-84.00 км' },
      { name: 'GLSDB', status: 'Не на вооружении', range: '0.00-150.00 км' },
      {
        name: 'M57 ATACMS TACMS 2000',
        status: 'Не на вооружении',
        range: '0.00-300.00 км',
      },
      {
        name: 'M48 ATACMS QRU',
        status: 'Не на вооружении',
        range: '0.00-300.00 км',
      },
    ],
  },
  {
    name: 'LAR-160',
    caliber: '160mm',
    type: 'РСЗВ',
    status: 'Не на вооружении',
    bulletRange: [
      {
        name: 'Mk. I Rocket',
        status: 'Не на вооружении',
        range: '0.00-45.00 км',
      },
      {
        name: 'Mk. II Rocket',
        status: 'На вооружении',
        range: '0.00-45.00 км',
      },
    ],
  },
  {
    name: '9К79-1 Точка-У',
    type: 'Тактический ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      { name: '9M79-1', status: 'На вооружении', range: '0.00-120.00 км' },
    ],
  },
  {
    name: '9К720 Искандер',
    type: 'Тактический ракетный комплекс',
    status: 'Не на вооружении',
    bulletRange: [
      {
        name: '9M723 (M)-1',
        status: 'Не на вооружении',
        range: '0.00-500.00 км',
      },
      {
        name: '9M729 (K)',
        status: 'Не на вооружении',
        range: '0.00-2500.00 км',
      },
    ],
  },
  {
    name: 'Storm Shadow (SCALP)',
    type: 'Крылатая ракета',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'Storm Shadow',
        status: 'На вооружении',
        range: '0.00-250.00 км',
      },
    ],
  },
  {
    name: 'С-300',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      { name: '5В55Р', status: 'На вооружении', range: '0.00-90.00 км' },
      { name: '48Н6', status: 'На вооружении', range: '0.00-200.00 км' },
    ],
  },
  {
    name: 'Patriot',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      { name: 'PAC-3 MSE', status: 'На вооружении', range: '0.00-45.00 км' },
      { name: 'PAC-2', status: 'На вооружении', range: '0.00-160.00 км' },
    ],
  },
  {
    name: 'NASAMS',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'AIM-120 AMRAAM',
        status: 'На вооружении',
        range: '0.00-160.00 км',
      },
    ],
  },
  {
    name: 'IRIS-T',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      { name: 'IRIS-T SLM', status: 'На вооружении', range: '0.00-40.00 км' },
    ],
  },
  {
    name: 'Crotale',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      { name: 'Crotale Mk.3', status: 'На вооружении', range: '0.00-16.00 км' },
    ],
  },
  {
    name: 'MIM-23 Hawk',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'MIM-23 Hawk I-Hawk',
        status: 'На вооружении',
        range: '0.00-45.00 км',
      },
    ],
  },
  {
    name: 'AN/TWQ-1 Avenger',
    type: 'Зенитно-ракетный комплекс',
    status: 'На вооружении',
    bulletRange: [
      {
        name: 'MIM-23 Hawk I-Hawk',
        status: 'На вооружении',
        range: '0.00-8.00 км',
      },
    ],
  },
  {
    name: 'Ольха',
    caliber: '300mm',
    type: 'РСЗВ',
    status: 'На вооружении',
    bulletRange: [
      { name: 'Vilkha', status: 'На вооружении', range: '0.00-70.00 км' },
      { name: 'Vilkha-M', status: 'На вооружении', range: '0.00-130.00 км' },
      { name: 'Vilkha-M1', status: 'На вооружении', range: '0.00-154.00 км' },
      { name: 'Vilkha-M2', status: 'На вооружении', range: '0.00-202.00 км' },
    ],
  },
];
