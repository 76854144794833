import { combineReducers } from '@reduxjs/toolkit';

import { artilleryReducer } from './artillerySlice';
import { boundaryGridReducer } from './boundaryGridSlice';
import { documentCoordinatesReducer } from './documentCoordinateSlice';
import { drawReducer } from './drawSlice';
import { geocoderReducer } from './geocoderSlice';
import { measurementReducer } from './measurementSlice';

export const toolsReducer = combineReducers({
  geocoder: geocoderReducer,
  draw: drawReducer,
  artillery: artilleryReducer,
  measurement: measurementReducer,
  boundaryGrid: boundaryGridReducer,
  documentCoordinates: documentCoordinatesReducer,
});
