import { CSSProperties, ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACCESS_TITLE, DOCUMENTS_TITLE, NAVS } from 'constants/navs';
import { appRoutes } from 'constants/routes';
import { useAppSelector, usePermission } from 'hooks';
import { accountSelector } from 'store/slices/auth/selectors';

import { ASTRA_COLORS } from '../../constants/routes';
import ConverterModal from '../ConverterModals/ConverterModal';

import { HeaderLink } from './HeaderLink';

import './style.scss';

type TNavItem = {
  link: string;
  icon?: ReactElement;
  title: string;
};

export const NavigationBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAccessConverterCustomerReports = usePermission(
    'converter-customer-reports'
  );
  const account = useAppSelector(accountSelector);
  const [isConverterOpen, setConverterOpen] = useState(false);

  const renderNavItem = ({ link, title, icon }: TNavItem, idx: number) => (
    <HeaderLink
      isActive={!!link && pathname.includes(link)}
      link={link}
      title={title}
      passiveIcon={icon || undefined}
      key={`link-${idx}`}
    />
  );

  const handleConverterClick = () => {
    setConverterOpen(!isConverterOpen);
    if (isConverterOpen) {
      handleCloseConverterModal();
    }
  };

  const handleCloseConverterModal = () => {
    setConverterOpen(false);
  };

  const renderHeaderLink = (
    title: string,
    onClick: () => void,
    style: CSSProperties
  ) => (
    <div
      className={'header__links header-link__title tpg-b2 pr-4'}
      style={style}
      onClick={onClick}
    >
      {title}
    </div>
  );

  const showAccessPage =
    account?.isGroupAdminOrOwner ||
    account?.sysRole === 'admin' ||
    account?.sysRole === 'superadmin';

  const showDocumentsPage = !!account?.hasAccessToDocuments;

  const filteredNavs = NAVS.filter(
    (v) =>
      (v.title !== ACCESS_TITLE || showAccessPage) &&
      (v.title !== DOCUMENTS_TITLE || showDocumentsPage)
  );

  return (
    <>
      <nav className="header flex justify-between">
        <div
          className="tpg-h3 cursor-pointer flex flex-row content-center"
          onClick={() => navigate(appRoutes.MAP)}
        >
          ASTRA<div className="header__m">M</div>
        </div>
        <div className="header__links">
          <div className="absolute left-1/2 -translate-x-1/2 flex flex-row ">
            {filteredNavs.map(renderNavItem)}
          </div>
        </div>
        <div className="flex flex-row">
          {renderHeaderLink(
            'Переводчик',
            () => navigate(appRoutes.TRANSLATOR),
            pathname === appRoutes.TRANSLATOR
              ? { color: ASTRA_COLORS.BRIGHT_PRODUCT }
              : {}
          )}
          {renderHeaderLink(
            'Конвертер',
            handleConverterClick,
            isConverterOpen ? { color: ASTRA_COLORS.BRIGHT_PRODUCT } : {}
          )}
          {isAccessConverterCustomerReports &&
            renderHeaderLink(
              'Шифрование',
              () => navigate(appRoutes.ENCRYPTOR),
              pathname === appRoutes.ENCRYPTOR
                ? { color: ASTRA_COLORS.BRIGHT_PRODUCT }
                : {}
            )}
        </div>
      </nav>
      {isConverterOpen && (
        <ConverterModal onClose={handleCloseConverterModal} />
      )}
    </>
  );
};
