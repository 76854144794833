import { FC, memo } from 'react';
import { MAP_CREATE_MODES, MAP_MEASURE_MODES } from 'constants/mapControl';

import { isMobileDevice } from 'utils';

import { MapControlToggle } from './MapControlToggle';

import './Controls.scss';

type Control = {
  id: string;
  className: string;
  slug: string;
};

interface DrawControlsProps {
  measureMode: string;
  onToggle: (measureMode: string, slug: string) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  forceUpdate?: () => void;
}

const controls: Control[] = [
  {
    id: 'create-line-string',
    className: 'mapboxgl-ctrl-create-line-string',
    slug: MAP_CREATE_MODES.create_line_string,
  },
  {
    id: 'create-polygon',
    className: 'mapboxgl-ctrl-create-polygon',
    slug: MAP_CREATE_MODES.create_polygon,
  },
  {
    id: 'measure-artillery',
    className: 'mapboxgl-ctrl-measure-artillery',
    slug: MAP_MEASURE_MODES.measure_artillery,
  },
  {
    id: 'measure-polygon',
    className: 'mapboxgl-ctrl-measure-polygon',
    slug: MAP_MEASURE_MODES.measure_polygon,
  },
  {
    id: 'measure-line-string',
    className: 'mapboxgl-ctrl-measure-line-string',
    slug: MAP_MEASURE_MODES.measure_line_string,
  },
  {
    id: 'create-radius',
    className: 'mapboxgl-ctrl-measure-circle',
    slug: MAP_MEASURE_MODES.measure_circle,
  },
];

export const DrawControls: FC<DrawControlsProps> = memo(
  ({ measureMode, onToggle, onSubmit, onCancel, forceUpdate }) => {
    // temporary hide measure_circle for mobile devices
    const isMobile = isMobileDevice();

    const controlToggles = isMobile
      ? controls.filter(
          (control) => control.slug !== MAP_MEASURE_MODES.measure_circle
        )
      : controls;

    return (
      <>
        {controlToggles.map((control) => (
          <MapControlToggle
            key={control.id}
            id={control.id}
            isActive={control.slug === measureMode}
            position="bottom-left"
            className={control.className}
            onToggle={() => onToggle(measureMode, control.slug)}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        ))}
      </>
    );
  }
);

DrawControls.displayName = 'DrawControls';
