import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { convertCoordinates } from 'api/converter';
import { exportEntity } from 'api/entities';
import cn from 'classnames';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import {
  MAP_ENTITY_PARAM_VALUES,
  mapEntityParams,
  predefinedTemplates,
} from 'constants/entities';
import {
  convertValidationErrors,
  errorMessages,
  infoMessages,
} from 'constants/errors';
import {
  DATE_FORMAT_RU,
  DEFAULT_DATE_FORMAT,
  FEATURE_COLORS,
  FeatureTypes,
  opacityOptions,
} from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';
import { ReactComponent as CrossIcon } from 'images/newIcons/cross.svg';
import { ReactComponent as DoneIcon } from 'images/newIcons/doneMark.svg';
import { ReactComponent as ImportIcon } from 'images/newIcons/import.svg';
import { ReactComponent as ShareIcon } from 'images/newIcons/shareEntity.svg';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';
import { IMediaFile, ISelectOption, XYHCoordinates } from 'interfaces';
import {
  addMediaToEntityThunk,
  deleteEntityThunk,
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { MapEntity, TPosition } from 'types';
import { Entity, PredefinedTemplate } from 'types/entities';
import { useDebouncedCallback } from 'use-debounce';

import { MediaGalleryModal } from 'components/MediaGalleryModal';
import {
  ColorPiker,
  DatePicker,
  Modal,
  Select,
  TextArea,
  TextInput,
} from 'components/ui';
import Dropzone from 'components/ui/Dropzone';
import { EntityDetailsControls } from 'components/ui/EntityCard/EntityDetailsControls';
import EntityFieldsGroup from 'components/ui/EntityCard/EntityFieldsGroup';
import { EntityHeader } from 'components/ui/EntityCard/EntityHeader';
import { EntityParamGroupTabs } from 'components/ui/EntityCard/EntityParamGroupTabs';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import TreeSelect from 'components/ui/TreeSelect';
import {
  copyToClipboard,
  downloadObject,
  formatDate,
  getCoordinatesCenter,
  getFormattedDate,
  notify,
  openShareModal,
} from 'utils';
import { constructIdentifier } from 'utils/entity';
import {
  changeEntityParam,
  getEntityParametersIdTitleMap,
  getEntityParameterValue,
  getMapObjectEntityValues,
  getSelectOptionsByMapEntityParam,
  getSelectOptionsFromSearch,
} from 'utils/entity';

import { FieldWrapper } from './FieldWrapper';
import { reverseGeocodeFromCoords } from './utils';

import './style.scss';

interface EntityDetailsProps {
  entity: MapEntity;
  mapObjectTemplate: PredefinedTemplate;
  onClose: () => void;
}

type DebouncedEntitySaveParams = [MapEntity, number, number, boolean, boolean];

const entityParamGroupTabs = ['Все', 'Разведотчет', 'Расположение'];

const entityFieldsGroupMixin =
  'border border-solid border-dark_product rounded-[10px] overflow-hidden';

const entityFieldWrapperMixin = 'border-b border-solid border-dark_product';

const constructEntityTitle = (type: string, date: string) =>
  `${type}, ${getRuDate(date)}`;

const getRuDate = (date: string | null) =>
  getFormattedDate(date ?? new Date(), DATE_FORMAT_RU, true) as string;

const getFieldClassName = <T = any,>(
  value1: T,
  value2: T,
  cnTouched?: string,
  cnUntouched?: string
) =>
  value1 === value2
    ? cnUntouched ?? '!text-tpg_base'
    : cnTouched ?? '!text-tpg_title';

const handleExportEntityKMZ = async (entity: Entity) => {
  notify.success(infoMessages.ENTITY_EXPORT_STARTED);

  await exportEntity({
    type: 'kmz',
    entityID: entity.id,
  })
    .then((response) => {
      const objectName = `${entity.title}-${getFormattedDate(
        new Date(),
        DEFAULT_DATE_FORMAT,
        true
      )}.kmz`;

      downloadObject(response.data, objectName);
    })
    .catch(() => notify.error(errorMessages.ENTITY_EXPORT_ERROR));
};

export const EntityDetails: FC<EntityDetailsProps> = ({
  entity,
  mapObjectTemplate,
  onClose,
}) => {
  const mapLayerTemplateId =
    useAppSelector((s) =>
      predefinedTemplateIdSelector(s, predefinedTemplates.MAP_LAYER)
    ) ?? 0;
  const [activeTab, setActiveTab] = useState(entityParamGroupTabs[0]);
  const [mapEntity, setEntity] = useState<MapEntity>(entity);
  const [prevDate, setPrevDate] = useState('');
  const [sk42, setSk42] = useState<XYHCoordinates | null>(null);
  const [layerOptions, setLayerOptions] = useState<ISelectOption[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [isSaveAllowed, setSaveAllowed] = useState(false);

  const parentEntityID = mapEntity.parentIDs[0];

  const dispatch = useAppDispatch();

  const paramIdMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        mapObjectTemplate.parameters,
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [mapObjectTemplate.parameters]
  );

  const mapEntityInitialValues = useMemo(
    () => getMapObjectEntityValues(paramIdMap, entity.entity),
    [paramIdMap, entity]
  );

  const mapEntityValues = getMapObjectEntityValues(
    paramIdMap,
    mapEntity.entity
  );

  const mediaFiles = mapEntityValues.media ?? [];

  const date = useMemo(
    () => (mapEntityValues.date ? new Date(mapEntityValues.date) : null),
    [mapEntity?.entity?.parameters]
  );

  const creationDate = new Date(entity.entity.createdAt ?? new Date());

  const [longitude, latitude]: TPosition = useMemo(() => {
    const geometry = mapEntityValues.geometry;

    if (!geometry || geometry.coordinates.length == 0) {
      return [0, 0];
    }

    switch (geometry.type) {
      case FeatureTypes.POINT:
        return geometry.coordinates as TPosition;
      case FeatureTypes.LINE:
        return getCoordinatesCenter(geometry.coordinates as TPosition[]);
      case FeatureTypes.POLYGON:
        return getCoordinatesCenter(geometry.coordinates[0] as TPosition[]);
      default:
        return [0, 0];
    }
  }, [paramIdMap]);

  const decimalWGS = useMemo(
    () => `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`,
    [longitude, latitude]
  );

  const decimalSk42 = useMemo(() => `X:${sk42?.x}, Y:${sk42?.y}`, [sk42]);

  const isPoint = useMemo(
    () =>
      mapEntityValues.geometry &&
      mapEntityValues.geometry.type == FeatureTypes.POINT,
    [mapEntityValues]
  );

  const isPolygon = useMemo(
    () =>
      mapEntityValues.geometry &&
      mapEntityValues.geometry.type == FeatureTypes.POLYGON,
    [mapEntityValues]
  );

  const showMainGroup = activeTab === entityParamGroupTabs[0];

  const showReconReportGroup =
    activeTab === entityParamGroupTabs[0] ||
    activeTab === entityParamGroupTabs[1];

  const showLocationGroup =
    activeTab === entityParamGroupTabs[0] ||
    activeTab === entityParamGroupTabs[2];

  const isCreatingEntity = !!mapEntity.state.draft;
  const isAvailableDelete = !!mapEntity?.entity.id;

  const convertToSk42 = useCallback(async () => {
    if (!latitude || !longitude) {
      return;
    }

    try {
      const data = await convertCoordinates('wgs', 'sk42', {
        b: latitude,
        l: longitude,
        h: DEFAULT_CONVERTER_HEIGHT,
      });

      if (data.to === 'sk42') {
        setSk42({
          x: Math.round(data?.payload?.x),
          y: Math.round(data?.payload?.y),
          h: data?.payload?.h,
        });
      }
    } catch (error) {
      notify.error(convertValidationErrors.GET_RESULT_ERROR);
    }
  }, [latitude, longitude]);

  const checkIsEntityTitleDefault = () => {
    const entityTitle = mapEntity.entity.title;
    const [currType, date] = entityTitle.split(', ', 2);

    return (
      entityTitle === '' ||
      (date === prevDate &&
        typeSelectOptions
          .map((option) => option.label)
          .find((type) => currType === type))
    );
  };

  const checkIsSaveAllowed = () => {
    const isParentIdPresent = !!parentEntityID;
    const isTitlePresent = !!mapEntity?.entity.title;
    const areRequiredParamsPresent = mapObjectTemplate.parameters
      .filter((param) => param.required)
      .map((param) => param.title as mapEntityParams)
      .filter(
        (paramTitle) => Object.values(mapEntityParams).indexOf(paramTitle) >= 0
      )
      .every(
        (paramTitle) =>
          !!getEntityParameterValue(mapEntity.entity, paramIdMap, paramTitle)
      );

    return (
      !isLoading &&
      isParentIdPresent &&
      isTitlePresent &&
      areRequiredParamsPresent
    );
  };

  const changeEntity = (
    field: keyof MapEntity,
    value: MapEntity[keyof MapEntity]
  ) => {
    const changedEntity = { ...mapEntity, [field]: value };

    setEntity(changedEntity);

    return changedEntity;
  };

  const changeEntityParamWrapper = (
    field: mapEntityParams,
    value: any
  ): MapEntity => {
    const changedEntity = changeEntityParam(
      mapEntity,
      paramIdMap,
      field,
      value
    );

    setEntity(changedEntity);

    return changedEntity;
  };

  const excludeNewMediaFromEntity = (entity: Entity): Entity => {
    const mediaFiles: IMediaFile[] =
      entity.parameters[paramIdMap[mapEntityParams.MEDIA]].value;

    return {
      ...entity,
      parameters: {
        ...entity.parameters,
        [paramIdMap[mapEntityParams.MEDIA]]: {
          ...entity.parameters[paramIdMap[mapEntityParams.MEDIA]],
          value: mediaFiles.filter((v) => v.file === undefined),
        },
      },
    };
  };

  const uploadPendingMedia = async (entity: Entity) => {
    const mediaFiles: IMediaFile[] =
      entity.parameters[paramIdMap[mapEntityParams.MEDIA]].value;

    for (let index = 0; index < mediaFiles.length; index++) {
      const curMedia = mediaFiles[index];

      curMedia.file &&
        (await dispatch(
          addMediaToEntityThunk({
            entityID: entity.id,
            paramID: Number(paramIdMap[mapEntityParams.MEDIA]),
            file: curMedia.file,
            mediaArrayIndex: index,
          })
        ).catch(() => notify.error(errorMessages.ENTITY_ADD_MEDIA_ERROR)));
    }
  };

  const handleSaveEntity = async (
    mapEntity: MapEntity,
    prevParentEntityId: number,
    parentEntityID: number,
    handleLoading: boolean,
    isCreating: boolean
  ) => {
    const isRelinking =
      !isCreating &&
      prevParentEntityId &&
      parentEntityID &&
      prevParentEntityId !== parentEntityID;
    // exclude new media to upload them explicitly
    const savedEntity = {
      ...excludeNewMediaFromEntity(mapEntity.entity),
      ...(isCreating && { parentEntityID: parentEntityID }),
    };

    handleLoading && setLoading(true);

    await dispatch(upsertEntityThunk(savedEntity))
      .then(async (r) => {
        if (r.payload && 'id' in r.payload) {
          const entityId = r.payload.id;
          // explicitly insert potentially missing id and parentIDs for new entity
          const upsertedMapEntity = {
            ...mapEntity,
            entity: { ...mapEntity.entity, id: entityId },
            parentIDs: [parentEntityID],
          };

          if (isRelinking) {
            await dispatch(
              relinkEntityThunk({
                entityId: entityId,
                oldParentEntityId: prevParentEntityId,
                newParentEntityId: parentEntityID,
              })
            );
          }

          // explicitly get actual media of local entity
          await uploadPendingMedia(upsertedMapEntity.entity);

          setEntity(upsertedMapEntity);
        }
      })
      .finally(() => {
        handleLoading && setLoading(false);
        handleLoading && onClose();
      });
  };

  const handleDeleteEntity = () => {
    if (mapEntity?.entity.id) {
      dispatch(deleteEntityThunk(mapEntity.entity.id));
      setDeleteModal(false);
      onClose();
    }
  };

  const handleSaveEntityDebounced = useDebouncedCallback(
    handleSaveEntity,
    1000
  );

  const handleChangeEntity = (
    field: keyof MapEntity,
    value: any,
    debounced?: boolean
  ) => {
    const changedEntity = changeEntity(field, value);
    const prevParentEntityId = mapEntity.parentIDs[0];
    const newParentEntityId = changedEntity.parentIDs[0];

    if (!isCreatingEntity && isSaveAllowed) {
      const params: DebouncedEntitySaveParams = [
        changedEntity,
        prevParentEntityId,
        newParentEntityId,
        isCreatingEntity,
        isCreatingEntity,
      ];

      debounced
        ? handleSaveEntityDebounced(...params)
        : handleSaveEntity(...params);
    }
  };

  const handleChangeEntityParam = (
    field: mapEntityParams,
    value: any,
    debounced?: boolean
  ) => {
    const changedEntity = changeEntityParamWrapper(field, value);
    const prevParentEntityId = mapEntity.parentIDs[0];
    const newParentEntityId = changedEntity.parentIDs[0];

    if (!isCreatingEntity && isSaveAllowed) {
      const params: DebouncedEntitySaveParams = [
        changedEntity,
        prevParentEntityId,
        newParentEntityId,
        isCreatingEntity,
        isCreatingEntity,
      ];

      debounced
        ? handleSaveEntityDebounced(...params)
        : handleSaveEntity(...params);
    }
  };

  const handleChangeEntityParent = (parentId: number) =>
    handleChangeEntity('parentIDs', [parentId], false);

  const handleChangeEntityTitle = (title: string, debounced?: boolean) =>
    handleChangeEntity(
      'entity',
      { ...mapEntity.entity, title: title },
      debounced
    );

  const constructAndSetDefaultEntityTitle = useDebouncedCallback(
    (type: string | null, date: string | null) =>
      handleChangeEntityTitle(
        constructEntityTitle(type ?? 'Другое', date ?? '01.01.2000'),
        false
      ),
    500
  );

  const changeEntityPlace = async () => {
    const place = mapEntityValues.place;

    if (!place) {
      const result = await reverseGeocodeFromCoords(longitude, latitude);
      const foundPlace = result.data.features[0]?.properties.name;

      handleChangeEntityParam(mapEntityParams.PLACE, foundPlace);
    }
  };

  const handleChangeDate = (date: Date) =>
    handleChangeEntityParam(mapEntityParams.DATE, formatDate(date));

  const handleAddMedia = (newMedia: IMediaFile[]) =>
    handleChangeEntityParam(mapEntityParams.MEDIA, [
      ...mediaFiles,
      ...newMedia,
    ]);

  const handleReorderMedia = (media: IMediaFile[]) =>
    handleChangeEntityParam(mapEntityParams.MEDIA, media);

  const handleDeleteMedia = (deletedMedia: IMediaFile) =>
    handleChangeEntityParam(
      mapEntityParams.MEDIA,
      mediaFiles.filter((v) => v.url !== deletedMedia.url)
    );

  const handleDeleteMediaByIndex = (index: number) =>
    index < mediaFiles.length && handleDeleteMedia(mediaFiles[index]);

  const statusSelectOptions = useMemo(
    () =>
      getSelectOptionsByMapEntityParam(
        mapEntityParams.STATUS,
        mapObjectTemplate
      ),
    [mapObjectTemplate]
  );

  const typeSelectOptions = useMemo(
    () =>
      getSelectOptionsByMapEntityParam(mapEntityParams.TYPE, mapObjectTemplate),
    [mapObjectTemplate]
  );

  const creatingControls = [
    {
      icon: <CrossIcon className="text-error" />,
      title: 'Отменить',
      onClick: onClose,
    },
    {
      icon: <DoneIcon className="text-success" />,
      title: 'Сохранить',
      disabled: !isSaveAllowed || isLoading,
      onClick: () =>
        handleSaveEntity(mapEntity, parentEntityID, parentEntityID, true, true),
    },
  ];

  const editingControls = [
    {
      icon: <ImportIcon />,
      title: 'Скачать KMZ',
      onClick: () => handleExportEntityKMZ(mapEntity.entity),
    },
    {
      icon: <ShareIcon />,
      title: 'Поделиться',
      onClick: () => openShareModal(dispatch, entity.entity.id),
    },
    {
      icon: <TrashIcon />,
      title: 'Удалить',
      disabled: !isAvailableDelete || isLoading,
      onClick: () => setDeleteModal(true),
    },
  ];

  const controls = isCreatingEntity ? creatingControls : editingControls;

  const mediaGalleryActions = [
    {
      title: 'Удалить',
      onClick: (_: number, currentFileIndex: number) =>
        handleDeleteMediaByIndex(currentFileIndex),
    },
  ];

  // this duplicates in MapLayerDetails, we can convert this to custom hook later
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      await getSelectOptionsFromSearch(
        mapObjectTemplate.template.id,
        setLayerOptions,
        [mapLayerTemplateId]
      ).finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (checkIsEntityTitleDefault())
      constructAndSetDefaultEntityTitle(
        mapEntityValues.type,
        mapEntityValues.date
      );

    setPrevDate(getRuDate(mapEntityValues.date));
  }, [mapEntityValues.type, mapEntityValues.date]);

  useEffect(() => {
    if (!mapEntityValues.place) {
      changeEntityPlace();
    }
  }, [mapEntityValues.place]);

  useEffect(() => {
    convertToSk42();
  }, [convertToSk42]);

  useEffect(() => {
    setSaveAllowed(checkIsSaveAllowed());
  }, [mapEntity, isLoading]);

  return (
    <>
      <div className="feature-details relative z-[5]">
        <Modal
          width="410px"
          keyboard
          closeOnOutsideClick={false}
          className="overflow-hidden"
          onClose={onClose}
        >
          <div className="w-full flex flex-col gap-px shrink-0 overflow-hidden">
            <EntityHeader
              title={mapEntityValues.name}
              subtitle={getRuDate(mapEntityValues.date)}
              onClose={onClose}
            />
            <EntityParamGroupTabs
              tabs={entityParamGroupTabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
            <div className="flex flex-col flex-1 gap-4 p-4 overflow-y-auto">
              {showMainGroup && (
                <EntityFieldsGroup
                  contentClassName={cn(
                    entityFieldsGroupMixin,
                    'overflow-visible'
                  )}
                >
                  <FieldWrapper
                    label="Проект"
                    element={
                      <Select
                        value={mapEntity.parentIDs?.[0]}
                        options={layerOptions}
                        searchPlaceholder="Введите название"
                        placeholder="Выберите проект"
                        classNames={{
                          text: getFieldClassName(
                            entity.parentIDs?.[0],
                            mapEntity.parentIDs?.[0]
                          ),
                        }}
                        withSearch
                        isExpandable
                        disabled={isLoading}
                        onSelect={handleChangeEntityParent}
                      />
                    }
                    className="entity-card-select pl-4"
                  />
                </EntityFieldsGroup>
              )}
              {showMainGroup && (
                <EntityFieldsGroup
                  title="Основная информация"
                  contentClassName={entityFieldsGroupMixin}
                >
                  <FieldWrapper
                    label="Идентификатор"
                    element={
                      <TextInput
                        value={constructIdentifier(mapEntity.entity.id)}
                        theme="dark"
                        size="s"
                        classNames={{
                          container: 'pl-0',
                          input: '-ml-4 placeholder-tpg_title',
                        }}
                        onChange={() => null}
                        readOnly
                      />
                    }
                    className={cn('pl-4', entityFieldWrapperMixin)}
                  />
                  <FieldWrapper
                    label={mapEntityParams.TYPE}
                    element={
                      <TreeSelect
                        value={mapEntityValues.type}
                        options={typeSelectOptions}
                        classNames={{
                          text: getFieldClassName(
                            mapEntityInitialValues.type,
                            mapEntityValues.type
                          ),
                        }}
                        withSearch
                        withEmpty={false}
                        onSelect={(v) =>
                          handleChangeEntityParam(mapEntityParams.TYPE, v)
                        }
                      />
                    }
                    className={cn(
                      'entity-card-select pl-4',
                      entityFieldWrapperMixin
                    )}
                  />
                  <FieldWrapper
                    label={mapEntityParams.NAME}
                    element={
                      <TextInput
                        value={mapEntityValues.name}
                        placeholder="Введите название"
                        theme="dark"
                        size="s"
                        classNames={{
                          container: 'pl-0',
                          input: cn(
                            '-ml-4 placeholder-tpg_title',
                            getFieldClassName(
                              mapEntityInitialValues.name,
                              mapEntityValues.name
                            )
                          ),
                        }}
                        onChange={(v) => handleChangeEntityTitle(v, true)}
                      />
                    }
                    className={cn('pl-4', entityFieldWrapperMixin)}
                  />
                  <FieldWrapper
                    label={mapEntityParams.STATUS}
                    element={
                      <TreeSelect
                        value={mapEntityValues.status}
                        options={statusSelectOptions}
                        withEmpty={false}
                        classNames={{
                          text: getFieldClassName(
                            mapEntityInitialValues.status,
                            mapEntityValues.status
                          ),
                        }}
                        onSelect={(v) =>
                          handleChangeEntityParam(mapEntityParams.STATUS, v)
                        }
                      />
                    }
                    className={cn(
                      'entity-card-select pl-4',
                      entityFieldWrapperMixin
                    )}
                  />
                  <FieldWrapper
                    label="Обнаружено"
                    element={
                      <DatePicker selected={date} onChange={handleChangeDate} />
                    }
                    className={cn(
                      'entity-card-datepicker pl-4',
                      entityFieldWrapperMixin,
                      getFieldClassName(
                        mapEntityInitialValues.date,
                        mapEntityValues.date,
                        'entity-card-datepicker__untouched',
                        'entity-card-datepicker__touched'
                      )
                    )}
                  />
                  <FieldWrapper
                    label="Внесено"
                    element={
                      <DatePicker
                        selected={creationDate}
                        onChange={() => null}
                        disabled
                      />
                    }
                    className={cn(
                      'entity-card-datepicker pl-4',
                      entityFieldWrapperMixin
                    )}
                  />
                  {isPolygon && (
                    <FieldWrapper
                      label={mapEntityParams.OPACITY}
                      element={
                        <TreeSelect
                          value={mapEntityValues.opacity}
                          options={opacityOptions}
                          withEmpty={false}
                          classNames={{
                            text: getFieldClassName(
                              mapEntityInitialValues.opacity,
                              mapEntityValues.opacity
                            ),
                          }}
                          onSelect={(v) =>
                            handleChangeEntityParam(mapEntityParams.OPACITY, v)
                          }
                        />
                      }
                      className={cn(
                        'entity-card-select pl-4',
                        entityFieldWrapperMixin
                      )}
                    />
                  )}
                  {!isPoint && (
                    <FieldWrapper
                      label={mapEntityParams.COLOR}
                      element={
                        <ColorPiker
                          initialColor={
                            mapEntityValues.color ?? FEATURE_COLORS[0]
                          }
                          onChange={(v) =>
                            handleChangeEntityParam(mapEntityParams.COLOR, v)
                          }
                        />
                      }
                      className="pl-4"
                    />
                  )}
                </EntityFieldsGroup>
              )}
              {showReconReportGroup && (
                <EntityFieldsGroup
                  title="Разведотчет"
                  contentClassName={entityFieldsGroupMixin}
                >
                  <FieldWrapper
                    label={mapEntityParams.MEDIA}
                    element={
                      <Dropzone
                        files={mediaFiles}
                        acceptedFormat=".mp4,.jpeg,.jpg,.png,.gif,.avi"
                        theme="dark"
                        classNames={{
                          body: '!p-0',
                          preview: 'pt-2',
                          text: 'tpg-input !text-tpg_base',
                        }}
                        onAdd={handleAddMedia}
                        onDelete={handleDeleteMedia}
                        onPreviewClick={() => setPreviewOpen(true)}
                        onMediaReorder={handleReorderMedia}
                      />
                    }
                    className={cn('pl-4', entityFieldWrapperMixin)}
                  />
                  <FieldWrapper
                    label={mapEntityParams.DESCRIPTION}
                    element={
                      <TextArea
                        value={mapEntityValues.description ?? ''}
                        placeholder="Введите описание"
                        rows={5}
                        theme="dark"
                        classNames={{
                          text: cn(
                            '!p-0 !bg-transparent',
                            getFieldClassName(
                              mapEntityInitialValues.description,
                              mapEntityValues.description
                            )
                          ),
                        }}
                        onChange={(v) =>
                          handleChangeEntityParam(
                            mapEntityParams.DESCRIPTION,
                            v,
                            true
                          )
                        }
                      />
                    }
                    className="pl-4"
                  />
                </EntityFieldsGroup>
              )}
              {showLocationGroup && (
                <EntityFieldsGroup
                  title="Расположение"
                  contentClassName={entityFieldsGroupMixin}
                >
                  <FieldWrapper
                    label="Координата WGS"
                    element={
                      <TextInput
                        value={decimalWGS}
                        theme="dark"
                        size="s"
                        classNames={{ input: '-ml-4' }}
                        onChange={() => null}
                      >
                        <div
                          className="icon-container -mr-1"
                          onClick={() => copyToClipboard(decimalWGS)}
                        >
                          <Copy />
                        </div>
                      </TextInput>
                    }
                    className={cn(
                      'entity-card-input pl-4',
                      entityFieldWrapperMixin
                    )}
                  />
                  <FieldWrapper
                    label="Координата СК42"
                    element={
                      <TextInput
                        value={decimalSk42}
                        theme="dark"
                        size="s"
                        classNames={{ input: '-ml-4' }}
                        onChange={() => null}
                      >
                        <div
                          className="icon-container -mr-1"
                          onClick={() => copyToClipboard(decimalSk42)}
                        >
                          <Copy />
                        </div>
                      </TextInput>
                    }
                    className="pl-4"
                  />
                </EntityFieldsGroup>
              )}
            </div>
            <EntityDetailsControls controls={controls} />
          </div>
        </Modal>
      </div>
      {isPreviewOpen && (
        <MediaGalleryModal
          onClose={() => setPreviewOpen(false)}
          mediaFiles={mediaFiles}
          isLoading={isLoading}
          containerClassName="!absolute"
          isDraggable
          onSlideDrop={handleReorderMedia}
          actions={mediaGalleryActions}
        />
      )}
      {isDeleteModal && (
        <ConfirmModal
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDeleteEntity}
        />
      )}
    </>
  );
};
