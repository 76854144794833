export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const getBase64FromUrl: (url: string) => Promise<string> = async (
  url: string
) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      if (base64data) {
        if (typeof base64data === 'string') {
          resolve(base64data);
        } else {
          resolve(Buffer.from(base64data).toString('base64'));
        }
      } else {
        reject('Unable to load data from URL');
      }
    };
  });
};
