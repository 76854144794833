import { combineReducers } from '@reduxjs/toolkit';

import { geospoofReducer } from './geospoofReducer';
import { imageryReducer } from './imagerySlice';
import { layersReducer } from './layersReducer';
import { operationalReducer } from './operationalSlice';

export const tabsReducer = combineReducers({
  geospoof: geospoofReducer,
  imagery: imageryReducer,
  operational: operationalReducer,
  layers: layersReducer,
});
