import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem } from '@mui/x-tree-view';
import { TreeView } from '@mui/x-tree-view/TreeView';

import { notify } from 'utils';

import {
  deleteLangCorpusGroup,
  getLangCorpusTree,
} from '../../../../../api/monitoring';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
  ILangCorpusFeature,
  ILangCorpusGroupFeature,
  ILangCorpusTreeGroup,
  ILangCorpusTreeItem,
} from '../../../../../interfaces';
import { monitoringActions } from '../../../../../store';
import { checkedLangCorpusesSelector } from '../../../../../store/slices/monitoring/selectors';
import { Checkbox } from '../../../../ui';
import ClosableTooltip from '../../../../ui/ClosableTooltip';
import ConfirmModal from '../../../../ui/Modal/ConfirmModal';
import LangCorpusGroupModal from '../../../LangCorpuses/LangCorpusGroupModal';
import LangCorpusModal from '../../../LangCorpuses/LangCorpusModal';

import './style.scss';

export interface LangModalProps<T> {
  feature: T | undefined;
  handleCloseModal: () => void;
}

const LangCorpusTree = () => {
  const dispatch = useAppDispatch();

  const [corpusTree, setCorpusTree] = useState<ILangCorpusTreeGroup[]>([]);
  const [corpusModalFeature, setCorpusModalFeature] =
    useState<ILangCorpusFeature>();
  const [corpusGroupModalFeature, setCorpusGroupModalFeature] =
    useState<ILangCorpusGroupFeature>();
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [processedGroup, setProcessedGroup] = useState<ILangCorpusTreeGroup>();

  const checkedCorpusesArrayState = useAppSelector(checkedLangCorpusesSelector);
  const checkedCorpuses = useMemo(
    () => new Set(checkedCorpusesArrayState),
    [checkedCorpusesArrayState]
  );

  const installCorpusTree = async () => {
    await getLangCorpusTree()
      .then((response) => setCorpusTree(response))
      .catch(() => notify.error('Не удалось получить список ЛСЯ'));
  };

  useEffect(() => {
    installCorpusTree();
  }, []);

  const closeModalUpdateTree =
    (setFeature: (feature: undefined) => void) => async () => {
      setFeature(undefined);
      await installCorpusTree();
    };

  const handleCreateSubgroup = (parentGroupId: number | null) => () => {
    setCorpusGroupModalFeature({
      parentGroupId: parentGroupId,
    });
  };

  const handleUpdateGroup =
    ({ name, id, parent_group_id }: ILangCorpusTreeGroup) =>
    () => {
      setCorpusGroupModalFeature({
        name,
        id,
        parentGroupId: parent_group_id,
      });
    };

  const handleOpenCorpus = (corpus: ILangCorpusTreeItem) => {
    setCorpusModalFeature({
      id: corpus.id,
      groupId: corpus.group_id,
    });
  };

  const handleCorpusClick = (corpus: ILangCorpusTreeItem) => () => {
    if (checkedCorpuses.has(corpus.id)) {
      checkedCorpuses.delete(corpus.id);
    } else {
      checkedCorpuses.add(corpus.id);
    }
    dispatch(
      monitoringActions.setCheckedLangCorpuses(Array.from(checkedCorpuses))
    );
  };

  const handleCorpusContextMenu =
    (corpus: ILangCorpusTreeItem) => (e: SyntheticEvent) => {
      e.preventDefault();
      handleOpenCorpus(corpus);
    };

  const handleCreateCorpus = (groupId: number) => () => {
    setCorpusModalFeature({
      groupId,
    });
  };

  const getTooltipOverlay = (group: ILangCorpusTreeGroup) => (
    <div className={'astra-dropdown__actions'}>
      <button className="tpg-body-one" onClick={handleCreateSubgroup(group.id)}>
        Создать подгруппу
      </button>
      <button className="tpg-body-one" onClick={handleCreateCorpus(group.id)}>
        Новый ЛСЯ
      </button>
      <button className="tpg-body-one" onClick={handleUpdateGroup(group)}>
        Редактировать группу
      </button>
      <button
        className="tpg-body-one astra-dropdown__actions__danger"
        onClick={() => {
          setDeleteModal(true);
          setProcessedGroup(group);
        }}
      >
        Удалить группу
      </button>
    </div>
  );

  const renderCorpus = (corpus: ILangCorpusTreeItem) => (
    <TreeItem
      nodeId={`corpus-${corpus.id}`}
      key={`corpus-${corpus.id}`}
      label={
        <div className={'corpus-tree-item'}>
          <Checkbox
            label={<div className={'corpus-tree-item'}>{corpus.name}</div>}
            isChecked={checkedCorpuses.has(corpus.id)}
            onChange={handleCorpusClick(corpus)}
          />
        </div>
      }
      onContextMenu={handleCorpusContextMenu(corpus)}
    />
  );

  const renderGroup = (group: ILangCorpusTreeGroup) => (
    <TreeItem
      nodeId={`group-${group.id}`}
      key={`group-${group.id}`}
      label={
        <ClosableTooltip overlay={getTooltipOverlay(group)}>
          <div className={'corpus-tree-item corpus-tree-group'}>
            {group.name}
          </div>
        </ClosableTooltip>
      }
    >
      {group.subgroups.map(renderGroup)}
      {group.corpuses.map(renderCorpus)}
    </TreeItem>
  );

  const getTreeContent = () => <div>{corpusTree.map(renderGroup)}</div>;

  const handleDeleteGroup = async () => {
    if (processedGroup) {
      await deleteLangCorpusGroup(processedGroup.id).catch(() =>
        notify.error('Не удалось удалить группу')
      );
      await installCorpusTree();
    }
    setDeleteModal(false);
  };

  return (
    <>
      {isDeleteModal && (
        <ConfirmModal
          onConfirm={handleDeleteGroup}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <h2 className={'tpg-title-four subtitle colored-title'}>ЛСЯ</h2>
        {getTreeContent()}
        <TreeItem
          className={'no-icon-space'}
          nodeId={'root-create-new-group'}
          label={
            <div className={'corpus-tree-item corpus-tree-group'}>
              {'+ новая группа'}
            </div>
          }
          onClick={handleCreateSubgroup(null)}
        />
      </TreeView>
      {corpusModalFeature && (
        <LangCorpusModal
          feature={corpusModalFeature}
          handleCloseModal={closeModalUpdateTree(setCorpusModalFeature)}
        />
      )}
      {corpusGroupModalFeature && (
        <LangCorpusGroupModal
          feature={corpusGroupModalFeature}
          handleCloseModal={closeModalUpdateTree(setCorpusGroupModalFeature)}
        />
      )}
    </>
  );
};

export default LangCorpusTree;
