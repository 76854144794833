import { FC } from 'react';
import { IMediaFile } from 'interfaces';

import MediaPlayer from 'components/MediaPlayer';

interface IMediaGalleryPreviewProps {
  mediaFile?: IMediaFile;
}

export const MediaGalleryPreview: FC<IMediaGalleryPreviewProps> = ({
  mediaFile,
}) => {
  const isImage = mediaFile?.type === 'image';
  const isVideo = mediaFile?.type === 'video';

  return (
    <div className="w-full h-full rounded-[inherit] cursor-pointer">
      {isImage && (
        <img
          src={mediaFile.url}
          alt="Media gallery preview"
          className="w-full h-full overflow-hidden object-contain"
        />
      )}
      {isVideo && (
        <MediaPlayer url={mediaFile.url} width="100%" height="100%" />
      )}
    </div>
  );
};
