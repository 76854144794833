import { createSlice } from '@reduxjs/toolkit';

import { SERVICE_REDUCER_NAMES } from '../constants';

import {
  AddModalAction,
  ModalsState,
  RemoveModalAction,
  SetModalsAction,
  UpdateModalAction,
} from './types';

export const initialState: ModalsState = {
  modals: {},
};

const modalsSlice = createSlice({
  name: SERVICE_REDUCER_NAMES.MODALS,
  initialState: initialState,
  reducers: {
    setModals(state, action: SetModalsAction) {
      state.modals = action.payload;
    },
    addModal(state, action: AddModalAction) {
      const modal = action.payload;

      state.modals = { ...state.modals, [modal.id]: modal };
    },
    updateModal(state, action: UpdateModalAction) {
      const { id, modal } = action.payload;

      state.modals[id] = { ...state.modals[id], ...modal };
    },
    removeModal(state, action: RemoveModalAction) {
      const id = action.payload;
      const { [id]: _removedModal, ...restModals } = state.modals;

      state.modals = restModals;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: modalsActions, reducer: modalsReducer } = modalsSlice;
