import { useMemo } from 'react';
import * as turf from '@turf/turf';
import { circleOptions } from 'constants/map';
import { MAP_MEASURE_MODES } from 'constants/mapControl';
import { Feature } from 'geojson';
import { useAppSelector } from 'hooks';
import { ReactComponent as ArtilleryIcon } from 'images/features/artillery.svg';
import { drawModeSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { artillerySelector } from 'store/slices/mapV2/mapReducer/toolsReducer/selectors';

import { CustomMarker } from 'components/ui';

import { PolygonSource } from '../PolygonSource';

export const ArtillerySource = () => {
  const drawMode = useAppSelector(drawModeSelector);
  const { name, position, range } = useAppSelector(artillerySelector);

  const showArtillery =
    drawMode === MAP_MEASURE_MODES.measure_artillery && name && range;

  const activeArtilleryCircles: Feature[] = useMemo(
    () =>
      range.map((range, idx) => {
        const activeCircle = turf.circle(position, range, circleOptions);

        activeCircle.id = idx;

        return activeCircle;
      }),
    [position, range]
  );

  if (!showArtillery) {
    return null;
  }

  return (
    <>
      {activeArtilleryCircles.map((circle) => (
        <PolygonSource
          key={`artillery-circle-${circle.id}`}
          id={`artillery-circle-${circle.id}`}
          data={circle}
        />
      ))}
      <CustomMarker
        longitude={position[0]}
        latitude={position[1]}
        icon={<ArtilleryIcon />}
      />
    </>
  );
};
