import { FC, useState } from 'react';
import { addGroup } from 'api/access';

import { Button, Modal, TextInput } from 'components/ui';

export interface IUserEditModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const GroupDetailsModal: FC<IUserEditModalProps> = ({ onClose, onSuccess }) => {
  const [title, setTitle] = useState('');

  const handleSave = () => {
    addGroup({ title: title }).then(() => onSuccess());
    onClose();
  };

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center">Создание группы</div>
        <div className="flex flex-col gap-3 py-6">
          <TextInput
            value={title}
            onChange={setTitle}
            placeholder="Название группы"
          />
        </div>
        <Button
          onClick={handleSave}
          title="Создать"
          className="w-full"
          disabled={!title}
        />
      </div>
    </Modal>
  );
};

export default GroupDetailsModal;
