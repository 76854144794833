import { IGeospoof } from 'interfaces';

export const initialGeospoofObjects = {
  groups: [],
  users: [],
  history: [],
  userLocationHistory: [],
};

export const initialGeospoofState: IGeospoof = {
  isActive: false,
  searchPosition: null,
  runExternalRequest: false,
  current: {
    users: null,
    groups: null,
    history: null,
  },
  currentTab: 'history',
  objects: initialGeospoofObjects,
  objectCoordinates: null,
  visibleObjects: [],
  pending: false,
  isHistoryLoading: false,
  isSearchInfoLoading: false,
  error: '',
  loadingUserLocation: {},
};

export const GEOSPOOF_COLOR = {
  baseFill: 'rgba(245, 191, 141, 0.2)',
  baseLine: 'rgba(242, 153, 74, 0.7)',
  activeFill: 'rgba(66, 130, 230, 0.40)',
  activeLine: 'rgba(66, 130, 230, 1)',
};

export const GEOSPOOF_TABS = ['Пользователи', 'Группы', 'История'];
