import { ISelectOption } from '../interfaces';
import { AccessRules } from '../types/entities';

export const ROOT_HIERARCHY_ID = 0;

export const MAP_ENTITY_HIERARCHY_NEST_LEVEL = 3;

export enum predefinedTemplates {
  MAP_LAYER = 'mapLayer',
  MAP_OBJECT = 'mapObject',
  DOCUMENT_DIRECTORY = 'directory',
  DOCUMENT = 'document',
}

export enum mapEntityParams {
  NAME = 'Название',
  GEOMETRY = 'Координаты',
  DATE = 'Дата',
  OPACITY = 'Прозрачность',
  DESCRIPTION = 'Описание',
  PLACE = 'Место',
  COLOR = 'Цвет',
  TYPE = 'Тип объекта',
  STATUS = 'Статус',
  MEDIA = 'Медиа',
}

export const MAP_ENTITY_PARAM_VALUES = Object.values(mapEntityParams);

export const MAP_ENTITY_FILTERABLE_PARAMS = [
  mapEntityParams.STATUS,
  mapEntityParams.DATE,
  mapEntityParams.TYPE,
];

export enum AccessRuleCode {
  RESTRICT = 0,
  READONLY = 2,
  READWRITE = 15,
}

export const DUMMY_ACCESS_RULES: AccessRules = {
  accessType: AccessRuleCode.READWRITE,
  isUserBranchAdmin: true,
  isPersonalSpace: true,
};

export const accessRuleOptions: ISelectOption[] = [
  {
    label: verboseAccessRule(AccessRuleCode.RESTRICT),
    value: AccessRuleCode.RESTRICT,
  },
  {
    label: verboseAccessRule(AccessRuleCode.READONLY),
    value: AccessRuleCode.READONLY,
  },
  {
    label: verboseAccessRule(AccessRuleCode.READWRITE),
    value: AccessRuleCode.READWRITE,
  },
];

export function verboseAccessRule(rule: AccessRuleCode) {
  switch (rule) {
    case AccessRuleCode.RESTRICT:
      return 'Ограничить доступ';
    case AccessRuleCode.READONLY:
      return 'Только чтение';
    case AccessRuleCode.READWRITE:
      return 'Чтение и запись';
  }
}
