import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { convertCoordinates } from 'api/converter';
import cn from 'classnames';
import { convertValidationErrors } from 'constants/errors';
import { converterDirectionOptions } from 'constants/map';
import { useAppDispatch } from 'hooks';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';
import {
  BLHCoordinates,
  CoordinateSystem,
  IConverterResponse,
  ICoordinates,
  XYHCoordinates,
} from 'interfaces';
import { measurementActions } from 'store/slices/mapV2/mapReducer/toolsReducer/measurementSlice';

import CsvConverter from 'components/ui/CsvConverter';
import SwitchWithTitle from 'components/ui/Switch/SwitchWithTitle';
import { copyToClipboard, getMonospacedString, notify } from 'utils';
import {
  convertWgsDecimalToDegree,
  getCoordinatesFromString,
  getPlaceholderForCoordinateSystem,
  prepareConvertRequestPayload,
} from 'utils/converterUtils';

import { Button, Modal, Select, TextArea, TextInput } from '../ui';

import './style.scss';

interface ConverterModalProps {
  onClose: () => void;
}

interface ConverterSettings {
  isDegree: boolean;
  isMarkdown: boolean;
  converterDirection: string;
}

const ConverterModal: React.FC<ConverterModalProps> = ({ onClose }) => {
  const [settings, setSettings] = useState<ConverterSettings>(() => ({
    isDegree: localStorage.getItem('wgs_degree_active') === 'true',
    isMarkdown: localStorage.getItem('coordinates_markdown_active') === 'true',
    converterDirection:
      localStorage.getItem('converter_direction') || 'wgsTosk42',
  }));

  const [coordinates, setCoordinates] = useState('');
  const [result, setResult] = useState<IConverterResponse>();
  const [longResult, setLongResult] = useState('');
  const [from, setFrom] = useState<CoordinateSystem>();
  const [to, setTo] = useState<CoordinateSystem>();
  const [wgsCoordinates, setWgsCoordinates] = useState<{
    lng: number;
    lat: number;
  }>();

  const dispatch = useAppDispatch();

  const isDirectionsUndef = from === undefined || to === undefined;

  const renderCopyIcon = (value: string) => {
    return (
      <div
        className={cn('cursor-pointer', {
          'cursor-not-allowed': !result,
        })}
        onClick={() => value && copyToClipboard(value)}
      >
        <Copy />
      </div>
    );
  };

  const handleCoordinatesChange = useCallback(
    (e: string) => {
      setResult(undefined);
      setCoordinates(
        e.replace(from === 'mgrs' ? /[^0-9.°′″,A-Z ]+/g : /[^0-9.°′″, ]+/g, '')
      );
    },
    [from]
  );

  const handleBoolSettingsChange = (propName: keyof ConverterSettings) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]:
        typeof prevSettings[propName] === 'boolean'
          ? !prevSettings[propName]
          : prevSettings[propName],
    }));
  };

  const handleDirectionChange = (direction: string) => {
    setSettings((prevState) => ({
      ...prevState,
      converterDirection: direction,
    }));
    localStorage.setItem('converter_direction', direction);
  };

  const prepareResultString = (value: string) =>
    settings.isMarkdown ? getMonospacedString(value) : value;

  const shortResult = useMemo(() => {
    if (!result) return '';
    switch (result.to) {
      case 'wgs': {
        const { h, ...payload } = result.payload;
        const coordinates = Object.values(payload).map((v) =>
          settings.isDegree
            ? convertWgsDecimalToDegree(v.toString())
            : v.toFixed(6)
        );
        return coordinates.join(settings.isDegree ? ' ' : ', ');
      }
      case 'sk42':
        return prepareResultString(
          `X=${result.payload.x}, Y=${result.payload.y}`
        );
      case 'usk2000':
        return prepareResultString(
          `X=${result.payload.x}, Y=${result.payload.y}`
        );
      case 'mgrs':
        return result.payload;
      default:
        return '';
    }
  }, [result, settings]);

  const sendConvertionRequest = async (
    from: CoordinateSystem,
    to: CoordinateSystem,
    payload: ICoordinates
  ) => {
    try {
      const resp = await convertCoordinates(from, to, payload);
      setResult(resp);
    } catch (e) {
      notify.error(convertValidationErrors.GET_RESULT_ERROR);
    }
  };

  useEffect(() => {
    const prepareLongResult = async () => {
      if (!result) return '';

      const originalCoordinates = getCoordinatesFromString(coordinates);
      if (!originalCoordinates) return '';

      const prepareSk42Response = (payload: XYHCoordinates) => {
        const coordinatesResultString = prepareResultString(
          `X=${payload.x}, Y=${payload.y}`
        );
        return `СК-42: ${coordinatesResultString}`;
      };

      const prepareWgsResponse = (payload: BLHCoordinates) => {
        setWgsCoordinates({
          lng: payload.b,
          lat: payload.l,
        });
        const wgs = prepareResultString(
          Object.values(payload)
            .map((v) => v.toFixed(6))
            .slice(0, 2)
            .join(', ')
        );
        const degreeWgs = prepareResultString(
          wgs.split(',').map(convertWgsDecimalToDegree).join(', ')
        );
        return settings.isDegree ? `WGS: ${degreeWgs}` : `WGS: ${wgs}`;
      };

      const firstString = await (async () => {
        if (from === 'wgs') {
          setWgsCoordinates({
            lng: originalCoordinates[0],
            lat: originalCoordinates[1],
          });
          return `WGS: ${prepareResultString(
            settings.isDegree
              ? originalCoordinates
                  .map((c) => convertWgsDecimalToDegree(c.toString()))
                  .join(', ')
              : originalCoordinates.join(', ')
          )}`;
        } else if (to === 'wgs') {
          return prepareWgsResponse(result.payload as BLHCoordinates);
        } else {
          try {
            if (isDirectionsUndef) return;
            const payload =
              from === 'mgrs'
                ? coordinates
                : prepareConvertRequestPayload(from, 'wgs', coordinates);
            if (payload) {
              const resp = await convertCoordinates(from, 'wgs', payload);
              return prepareWgsResponse(resp.payload as BLHCoordinates);
            }
          } catch (e) {
            notify.error(convertValidationErrors.GET_RESULT_ERROR);
          }
        }
      })();

      const secondString = await (async () => {
        if (from === 'sk42') {
          const coordinatesResultString = prepareResultString(
            `X=${originalCoordinates[0]}, Y=${originalCoordinates[1]}`
          );
          return `СК-42: ${coordinatesResultString}`;
        } else if (to === 'sk42') {
          return prepareSk42Response(result.payload as XYHCoordinates);
        } else {
          try {
            if (isDirectionsUndef) return;
            const payload =
              from === 'mgrs'
                ? coordinates
                : prepareConvertRequestPayload(from, 'sk42', coordinates);
            if (payload) {
              const resp = await convertCoordinates(from, 'sk42', payload);
              return prepareSk42Response(resp.payload as XYHCoordinates);
            }
          } catch (e) {
            notify.error(convertValidationErrors.GET_RESULT_ERROR);
          }
        }
      })();

      setLongResult(
        `${firstString}\n${secondString}`.replaceAll('undefined', '')
      );
    };

    prepareLongResult();
  }, [result, coordinates, settings]);

  const onConvertClicked = async () => {
    if (isDirectionsUndef) return;
    if (from === 'mgrs') {
      await sendConvertionRequest(from, to, coordinates);
    }

    const requestPayload = prepareConvertRequestPayload(from, to, coordinates);
    requestPayload && (await sendConvertionRequest(from, to, requestPayload));
  };

  const onTextareaContentClick = (
    e: ReactMouseEvent<HTMLTextAreaElement, MouseEvent>
  ) => {
    if (e.currentTarget.value.length === e.currentTarget.selectionStart) {
      return;
    }
    copyToClipboard(e.currentTarget.value);
  };

  useEffect(() => {
    const [from, to]: CoordinateSystem[] = settings.converterDirection
      .split('To')
      .map((item) => item as CoordinateSystem);
    setFrom(from);
    setTo(to);
  }, [settings.converterDirection]);

  useEffect(() => {
    coordinates && onConvertClicked();
  }, [settings]);

  const onFlyToClicked = () => {
    wgsCoordinates?.lng &&
      wgsCoordinates.lat &&
      dispatch(
        measurementActions.setConverterPosition([
          wgsCoordinates.lat,
          wgsCoordinates.lng,
        ])
      );
    onClose();
  };

  return (
    <Modal onClose={onClose} width={450} keyboard isBlurred>
      <div className="converter__modal">
        <h1 className="tpg-h4 pb-6">Конвертер координат</h1>
        <div className="grid gap-3 w-full">
          <Select
            placeholder="WGS в СК-42"
            options={converterDirectionOptions}
            value={settings.converterDirection}
            onSelect={handleDirectionChange}
            withEmpty={false}
            theme="light"
          />
          <TextInput
            value={coordinates}
            onChange={handleCoordinatesChange}
            placeholder={getPlaceholderForCoordinateSystem(
              from || 'wgs',
              settings.isDegree
            )}
          >
            <CsvConverter />
          </TextInput>
          <SwitchWithTitle
            active={settings.isDegree}
            onChange={() => handleBoolSettingsChange('isDegree')}
            title="Показывать WGS в формате градусов"
            localStorageTag="wgs_degree_active"
          />
          <SwitchWithTitle
            active={settings.isMarkdown}
            onChange={() => handleBoolSettingsChange('isMarkdown')}
            title="Кавычки markdown"
            localStorageTag="coordinates_markdown_active"
          />
          <Button
            disabled={!!result || !coordinates}
            title="Конвертировать"
            onClick={onConvertClicked}
          />
        </div>
        <h1 className="tpg-b1 pt-8 pb-6">Результат</h1>
        <div className="grid gap-3 w-full">
          <TextArea
            style={{
              resize: 'none',
              cursor: 'pointer',
              height: '40px',
              overflowY: 'hidden',
            }}
            value={shortResult}
            rows={1}
            readOnly
            placeholder={getPlaceholderForCoordinateSystem(
              to || 'wgs',
              settings.isDegree
            )}
          >
            <div className="mt-[-4px]">{renderCopyIcon(shortResult)}</div>
          </TextArea>
          <TextArea
            value={longResult}
            placeholder={
              settings.isDegree
                ? 'WGS: 50°26′35″ 30°30′16″\n' + 'СК-42: X=5593226, Y=6322859'
                : 'WGS: 50.443151, 30.504524\n' + 'СК-42: X=5593226, Y=6322859'
            }
            rows={2}
            readOnly
            style={{ resize: 'none', cursor: 'pointer', height: '128px' }}
            onClick={onTextareaContentClick}
          >
            {renderCopyIcon(longResult)}
          </TextArea>
          <Button
            disabled={!result || !coordinates}
            title="Показать на карте"
            onClick={onFlyToClicked}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConverterModal;
