import { IErrorHandler } from 'interfaces';

import { notify } from 'utils';

interface ApiError {
  detail: string;
}

const notifyWithServerMessage = ({ error, message }: IErrorHandler) => {
  try {
    const responseData = error.response?.data as ApiError;
    notify.error(responseData.detail ?? message, {
      autoClose: 10000,
    });
  } catch {
    notify.error(message);
  }
};

export const geospoofErrorHandler = ({ error, message }: IErrorHandler) => {
  switch (error.response?.status) {
    case 429:
      notify.error('Слишком частые запросы! Попробуйте позже');
      break;
    case 504:
      notify.error('Данные не найдены');
      break;
    case 423:
      notify.error('Другой поиск уже в процессе обработки');
      break;
    case 400:
      notify.error('Неправильный формат ввода');
      break;
    case 500:
      notifyWithServerMessage({ error, message });
      break;
    default:
      notify.error(message);
  }
};
