import { FC, useEffect, useState } from 'react';

import { getScaleBarInfoFromZoomLevel } from './utils';

import './style.scss';

interface ScaleBarProps {
  zoom: number;
  latitude: number;
}

export const ScaleBar: FC<ScaleBarProps> = ({ latitude, zoom }) => {
  const [scaleLabel, setScaleLabel] = useState<string>();
  const [scaleSize, setScaleSize] = useState<number>();

  useEffect(() => {
    const { scaleBarSizeInMeters, scaleBarTextInMeters } =
      getScaleBarInfoFromZoomLevel(zoom, latitude);

    setScaleLabel(scaleBarTextInMeters);
    setScaleSize(scaleBarSizeInMeters);
  }, [zoom, latitude]);

  return (
    <div
      className="scale-bar pr-2 flex flex-col items-end justify-center"
      style={{ width: scaleSize }}
    >
      <span>{scaleLabel}</span>
    </div>
  );
};
