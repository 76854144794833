import { createContext } from 'react';
import { ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { Style } from 'mapbox-gl';
import { TSetState } from 'types';

export interface IMapContext {
  viewport: ViewState;
  setViewport: TSetState<ViewState>;
  isTerrain: boolean;
  setTerrain: TSetState<boolean>;
  isLegend: boolean;
  setLegend: TSetState<boolean>;
  isMeasure: boolean;
  setMeasure: TSetState<boolean>;
  defaultConfig: {
    mapboxAccessToken: string;
    locale: { [key: string]: string };
    mapStyle?: string | Style | undefined;
    dragRotate: boolean;
    onLoad: (event: mapboxgl.MapboxEvent<undefined>) => void;
    onMove: (evt: ViewStateChangeEvent) => void;
  };
}

export const MapContext = createContext<IMapContext | undefined>(undefined);
