import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { logisticLayers } from '../../../../constants/mapTypes';
import SidebarWindowWithTabs from '../../../ui/SidebarWindowWithTabs';

import MapTypeElement from './MapTypeElement';

import './style.scss';

const MapLogistic = () => {
  const dispatch = useAppDispatch();
  const mapTypes = useAppSelector(typesSelector);
  const handleSetMapTypes = useCallback(
    (type: string) => () => {
      const currentType = mapTypes[type];
      dispatch(
        settingsActions.setTypes({
          ...mapTypes,
          [type]: {
            ...currentType,
            active: !currentType.active,
          },
        })
      );
    },
    [mapTypes]
  );

  const logisticTab = (
    <div className="p-4">
      {logisticLayers.map((item, index) => (
        <MapTypeElement
          key={index}
          index={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          name={item.name}
          active={mapTypes[item.name].active}
          onSwitch={handleSetMapTypes(item.name)}
        />
      ))}
    </div>
  );

  return (
    <SidebarWindowWithTabs
      tabs={[logisticTab]}
      tabOptions={['Логистика']}
      hideOptions={true}
    />
  );
};

export default MapLogistic;
