import { renderFigure, renderLegendElement } from '../index';

const ElevationInfo = () => (
  <div className="flex flex-row justify-between">
    {renderLegendElement(
      'Слой высот',
      <div className="info__block tpg-c2">
        {renderFigure(<>Уровень моря</>, '#1f1f1f', 'figure__circle')}
        {renderFigure(<>Равнинная местность</>, '#47594e', 'figure__circle')}
        {renderFigure(<>Холмистая местность</>, '#74a287', 'figure__circle')}
        {renderFigure(<>Гора</>, '#b13937', 'figure__circle')}
      </div>
    )}
  </div>
);
export default ElevationInfo;
