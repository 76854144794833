import { ReactComponent as GroupIcon } from 'images/newIcons/group.svg';
import { ReactComponent as PersonIcon } from 'images/newIcons/person.svg';

import { GroupNode } from './AccessSidebar';

export const renderNodeIcon = (node: GroupNode) => {
  const isFolder = node.data.isFolder;
  const commonClasses =
    '[&>svg>path]:fill-tpg_base group-[.empty:not(.selected)]:[&>svg>path]:fill-tpg_light group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product';
  const NodeIcon = isFolder ? GroupIcon : PersonIcon;

  return (
    <div className={commonClasses}>
      <NodeIcon />
    </div>
  );
};
