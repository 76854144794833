import { PASSWORD_LIMIT, USER_NAME_LIMIT } from 'constants/profile';
import { ILoginValues } from 'interfaces';

export const validate = (values: ILoginValues) => {
  const errors = {} as ILoginValues;

  if (!values.username) {
    errors.username = 'Имя пользователя обязательно';
  } else if (values.username.length > USER_NAME_LIMIT) {
    errors.username = `Имя пользователя быть не более ${USER_NAME_LIMIT} символов`;
  }

  if (!values.password) {
    errors.password = 'Пароль обязателен';
  } else if (values.password.length > PASSWORD_LIMIT) {
    errors.password = `Должно быть не более ${PASSWORD_LIMIT} символов`;
  }

  return errors;
};
