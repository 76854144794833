import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as CrossIcon } from 'images/newIcons/cross.svg';

interface ICloseButtonProps {
  className?: string;
  onClick?: () => void;
}

export const CloseButton: FC<ICloseButtonProps> = ({ className, onClick }) => (
  <div
    className={cn(
      'group flex justify-center items-center absolute m-[5px] top-0 right-0 z-[1] cursor-pointer',
      className
    )}
    onClick={onClick}
  >
    <CrossIcon className="[&>path]:fill-tpg_base group-hover:[&>path]:fill-dark_product" />
  </div>
);
