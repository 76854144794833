import {
  AddPermissionGroupRequest,
  AddUserRequest,
  GroupInfo,
  GroupLinkedEntity,
  GroupLinkedUser,
  GroupUserLink,
  PermissionGroup,
  PublicUserInfo,
  UpdatePermissionGroupRequest,
  UpdateUserPasswordRequest,
  UpdateUserRequest,
  UpsertUserToGroupRequest,
} from 'interfaces/profile';
import axios from 'services/axios';

import { EntityAccessRule } from '../interfaces/entity';

import { handleAxiosResponse } from './apiUtils';
import { GO_API_PREFIX } from './entities';

export const getGroupList = () =>
  handleAxiosResponse<GroupInfo[]>(
    axios.get(`${GO_API_PREFIX}/permission-group`)
  );

export const addGroup = (req: AddPermissionGroupRequest) =>
  handleAxiosResponse<GroupInfo>(
    axios.post(`${GO_API_PREFIX}/permission-group`, req)
  );

export const updateGroup = (req: UpdatePermissionGroupRequest) =>
  handleAxiosResponse<PermissionGroup>(
    axios.put(`${GO_API_PREFIX}/permission-group/${req.id}`, {
      title: req.title,
    })
  );

export const deleteGroup = (id: number) =>
  handleAxiosResponse<null>(
    axios.delete(`${GO_API_PREFIX}/permission-group/${id}`)
  );

export const getGroupedLinkedUsers = (groupId: number) =>
  handleAxiosResponse<GroupLinkedUser[]>(
    axios.get(`${GO_API_PREFIX}/permission-group/${groupId}/users`)
  );

export const upsertUserToGroupLink = (
  req: UpsertUserToGroupRequest,
  groupID: number,
  userID: number
) =>
  handleAxiosResponse<GroupUserLink>(
    axios.post(
      `${GO_API_PREFIX}/permission-group/${groupID}/users/${userID}`,
      req
    )
  );

export const deleteUserToGroupLink = (groupId: number, userId: number) =>
  handleAxiosResponse<null>(
    axios.delete(`${GO_API_PREFIX}/permission-group/${groupId}/users/${userId}`)
  );

export const getGroupLinkedEntities = (groupID: number) =>
  handleAxiosResponse<GroupLinkedEntity[]>(
    axios.get(`${GO_API_PREFIX}/permission-group/${groupID}/entities`)
  );

export const getSelfUser = () =>
  axios.get<PublicUserInfo>(`${GO_API_PREFIX}/user/self`);

export const addUser = (req: AddUserRequest) =>
  handleAxiosResponse<PublicUserInfo>(axios.post(`${GO_API_PREFIX}/user`, req));

export const updateUser = (req: UpdateUserRequest, id: number) =>
  handleAxiosResponse<PublicUserInfo>(
    axios.put(`${GO_API_PREFIX}/user/${id}`, req)
  );

export const updateUserPassword = (
  req: UpdateUserPasswordRequest,
  id: number
) =>
  handleAxiosResponse<PublicUserInfo>(
    axios.patch(`${GO_API_PREFIX}/user/${id}/password`, req)
  );

export const deleteUser = (id: number) =>
  handleAxiosResponse<null>(axios.delete(`${GO_API_PREFIX}/user/${id}`));

export const getEntityAccessRules = (entityID: number) =>
  handleAxiosResponse<EntityAccessRule[]>(
    axios.get(`${GO_API_PREFIX}/entity-V2/${entityID}/access-rules`)
  );

export const getUserList = () =>
  handleAxiosResponse<PublicUserInfo[]>(
    axios.get(`${GO_API_PREFIX}/user/list`)
  );

export const getPermissionGroups = () =>
  handleAxiosResponse<GroupInfo[]>(
    axios.get(`${GO_API_PREFIX}/permission-group`)
  );
