import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { TTabTitle } from 'constants/map';
import { appRoutes } from 'constants/routes';
import { NavLinkProps } from 'interfaces';

import { TooltipWrap } from 'components/ui/Tooltip';

interface SidebarTabProps extends NavLinkProps {
  isActive: boolean;
}

const getToolTipTextFromTitle = (title: TTabTitle) => {
  switch (title) {
    case 'layers':
      return 'СПИСОК ОБЪЕКТОВ';
    case 'types':
      return 'СЛОИ';
    case 'imagery':
      return 'СПУТНИКОВЫЕ СНИМКИ';
    case 'search':
      return 'ГЕОСПУФ';
    case 'operational':
      return 'ОПЕРАТИВНАЯ СИТУАЦИЯ';
    case 'logistic':
      return 'ЛОГИСТИКА';
    case 'features':
      return 'ТАБЛИЦА ОБЪЕКТОВ';
    default:
      return '';
  }
};

export const SidebarTab: FC<SidebarTabProps> = ({
  title,
  link,
  passiveIcon,
  activeIcon,
  isActive,
}) => (
  <TooltipWrap
    title={getToolTipTextFromTitle(title as TTabTitle)}
    placement="bottom-start"
  >
    <Link
      to={isActive ? appRoutes.MAP : link}
      className={
        passiveIcon && cn('map-sidebar__tabs__icon', isActive && 'active')
      }
    >
      {isActive ? activeIcon : passiveIcon}
    </Link>
  </TooltipWrap>
);
