import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import {
  SIDEBAR_CONTENT_WIDTH,
  SIDEBAR_INITIAL_CONTAINER_WIDTH,
} from 'constants/map';
import { monitoringTabs } from 'constants/monitoring';
import { appRoutes, monitoringRoutes } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as Close } from 'images/icons/close.svg';
import { monitoringActions } from 'store';
import { showOnlyBookmarksSelector } from 'store/slices/monitoring/selectors';

import Filters from './Filters';

import './style.scss';

const Sidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const showOnlyBookmarks = useAppSelector(showOnlyBookmarksSelector);

  const [isShowSidebar, setShowSidebar] = useState(false);
  const [containerWidth, setContainerWidth] = useState(
    SIDEBAR_INITIAL_CONTAINER_WIDTH
  );

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pathname === monitoringRoutes.BOOKMARKS) {
      dispatch(monitoringActions.setShowOnlyBookmarks(true));
    } else {
      setShowSidebar(Object.values(monitoringRoutes).includes(pathname));
      if (showOnlyBookmarks && pathname !== monitoringRoutes.FEED) {
        dispatch(monitoringActions.setShowOnlyBookmarks(false));
      }
    }
  }, [pathname, showOnlyBookmarks]);

  useEffect(() => {
    let contentSize = SIDEBAR_INITIAL_CONTAINER_WIDTH;

    if (isShowSidebar && contentRef.current) {
      contentSize += SIDEBAR_CONTENT_WIDTH;
    }

    setContainerWidth(contentSize);
  }, [isShowSidebar]);

  const sidebarTitle = useMemo(() => {
    switch (pathname) {
      case monitoringRoutes.FEED:
      case monitoringRoutes.BOOKMARKS:
        return 'Фильтры';
      default:
        return '';
    }
  }, [pathname]);

  return (
    <aside style={{ width: containerWidth }} className="sidebar">
      <nav className="sidebar__tabs p-3">
        {monitoringTabs.map(({ link, icon }, index) => (
          <Link
            key={`tab-${index}`}
            className={cn('sidebar__tabs__icon', {
              active: pathname === link,
            })}
            to={link}
          >
            {icon}
          </Link>
        ))}
      </nav>
      {isShowSidebar && (
        <div ref={contentRef} className="sidebar__body">
          <div className="sidebar__body_title">
            <h2>{sidebarTitle}</h2>
            <Link
              to={
                showOnlyBookmarks
                  ? monitoringRoutes.BOOKMARKS
                  : appRoutes.MONITORING
              }
              className="close_icon"
            >
              <Close onClick={() => setShowSidebar(false)} />
            </Link>
          </div>
          <div className="sidebar__body_content">
            <Filters />
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;
