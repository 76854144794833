import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  initialMonitoringPosts,
  initialStaticFilters,
} from 'constants/monitoring';
import { reducersNames } from 'constants/reducers';
import { WritableDraft } from 'immer/dist/types/types-external';
import {
  ICheckFilterPayload,
  IDateRangePayload,
  IFilters,
  IMediaFilePayload,
  IMonitoringState,
  IStaticFilters,
} from 'interfaces';
import {
  TDynamicFilterAction,
  TPublicationUnion,
  TSearchModeUnion,
  TSourceUnion,
} from 'types';

import {
  getBookmarkIdsThunk,
  getDynamicFilters,
  getFiltersThunk,
  getParsersThunk,
  searchPostsThunk,
  setDynamicFilters,
} from './actions';

const initialState: IMonitoringState = {
  dynamicFilters: {},
  staticFilters: initialStaticFilters,
  posts: initialMonitoringPosts,
  mediaFiles: [],
  parsers: [],
  checkedLangCorpuses: [],
  showOnlyBookmarks: false,
  bookmarkIds: [],
};

// here for next store draft typing if needed.
type FiltersStateDraft = WritableDraft<IMonitoringState>;

const monitoringSlice = createSlice({
  name: reducersNames.MONITORING,
  initialState,
  reducers: {
    setSearchMode(state, action: PayloadAction<TSearchModeUnion>) {
      state.staticFilters.searchMode = action.payload;

      if (action.payload === 'raw') {
        const resetDynamicFilters = (filters: IFilters = {}) => {
          for (const key in filters) {
            if (typeof filters[key] === 'object') {
              if ((filters[key] as IFilters).active) {
                (filters[key] as IFilters).active = false;
              }

              if (isNaN(+key)) {
                resetDynamicFilters(filters[key] as IFilters);
              }
            }
          }
        };

        resetDynamicFilters(state.dynamicFilters);
      }
    },
    setDateRange(state, action: PayloadAction<IDateRangePayload>) {
      state.staticFilters[action.payload.field] = action.payload.date;
    },
    resetDateRange(state) {
      state.staticFilters.startDate = null;
      state.staticFilters.endDate = null;
    },
    toggleMediaCheckbox(state, action) {
      state.staticFilters.media = state.staticFilters.media.map((item) => {
        item.checked = item.value === action.payload ? !item.checked : false;
        return item;
      });
    },
    clearSearchList(state, action: PayloadAction<boolean | undefined>) {
      state.posts.pending = !!action.payload;
      state.posts.list = [];
    },
    toggleCheckbox(
      state,
      action: PayloadAction<
        ICheckFilterPayload<TSourceUnion | TPublicationUnion>
      >
    ) {
      const field = action.payload.field;
      const filterIdx = state.staticFilters[field].findIndex(
        (item) => item.value === action.payload.value
      );

      state.staticFilters[field][filterIdx].checked =
        !state.staticFilters[field][filterIdx].checked;
    },
    onChangeDynamicFilters(state, action: PayloadAction<TDynamicFilterAction>) {
      const { path, value, isSection = false } = action.payload;

      if (state.staticFilters.searchMode !== 'raw') {
        state.dynamicFilters = setDynamicFilters({
          obj: state.dynamicFilters,
          path,
          value,
          isSection,
        });
      }
    },
    setMediaFiles(state, action: PayloadAction<IMediaFilePayload>) {
      state.mediaFiles = action.payload.files;
    },
    setParsers(state, action: PayloadAction<IStaticFilters<string>[]>) {
      state.parsers = action.payload;
    },
    setShowOnlyBookmarks(state, action: PayloadAction<boolean>) {
      state.showOnlyBookmarks = action.payload;
    },
    resetMonitoringState(state) {
      state.dynamicFilters = {};
      state.staticFilters = initialStaticFilters;
      state.posts = initialMonitoringPosts;
      state.mediaFiles = [];
      state.parsers = [];
    },
    setCheckedLangCorpuses(state, action: PayloadAction<number[]>) {
      state.checkedLangCorpuses = action.payload;
    },
    resetBookmarks(state) {
      state.showOnlyBookmarks = false;
    },
    resetParsers(state) {
      state.parsers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiltersThunk.fulfilled, (state, action) => {
        state.dynamicFilters = getDynamicFilters(action.payload?.filters);
      })
      .addCase(getParsersThunk.fulfilled, (state, action) => {
        state.parsers = action.payload.map((value) => ({
          value: value,
          label: value,
          checked: false,
        }));
      })
      .addCase(searchPostsThunk.fulfilled, (state, action) => {
        state.posts.pending = false;
        state.posts.total = action.payload.total_count;
        state.posts.list.push(...action.payload.documents);
      })
      .addCase(searchPostsThunk.pending, (state) => {
        state.posts.pending = true;
      })
      .addCase(searchPostsThunk.rejected, (state) => {
        state.posts.total = 0;
        state.posts.pending = false;
      })
      .addCase(getBookmarkIdsThunk.fulfilled, (state, action) => {
        state.bookmarkIds = action.payload;
      })
      .addCase(getBookmarkIdsThunk.rejected, (state) => {
        state.bookmarkIds = [];
      });
  },
});

export const { actions: monitoringActions, reducer: monitoringReducer } =
  monitoringSlice;
