import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mapSidebarTabs } from 'constants/map';
import { appRoutes, mapRoutes, monitoringRoutes } from 'constants/routes';
import { useMapRef } from 'hooks/map';

import { SideBarBody } from './SidebarBody';
import { SidebarTab } from './SidebarTab';

import './style.scss';

export const MapSidebar = () => {
  const { pathname } = useLocation();
  const { mapRef } = useMapRef();
  const contentRef = useRef<HTMLDivElement>(null);
  const [showBody, setShowBody] = useState(false);

  const navigate = useNavigate();

  const mapCanvas = mapRef.current?.getCanvas();

  const onEscapeDown = () => navigate(appRoutes.MAP);

  useEffect(() => {
    const isActiveTab = Object.values({
      ...mapRoutes,
      ...monitoringRoutes,
    }).includes(pathname);

    setShowBody(isActiveTab);
  }, [pathname]);

  useEffect(() => {
    if (mapCanvas) {
      mapCanvas.setAttribute(
        'style',
        `width: 100%; height: -webkit-fill-available;`
      );

      setTimeout(() => mapRef.current?.resize(), 300);
    }
  }, [mapCanvas, showBody]);

  return (
    <aside className="map-sidebar">
      <div className="flex flex-col h-full">
        <nav className="map-sidebar__tabs">
          {mapSidebarTabs.map((tab, index) => (
            <SidebarTab
              {...tab}
              key={index}
              link={tab.link}
              title={tab.title}
              isActive={tab.link === pathname}
            />
          ))}
        </nav>
        {/*TODO enable after modules development*/}
        {/*<nav className="map-sidebar__bottom-tabs p-3 pb-16">*/}
        {/*  {bottomSidebarTabs.map(renderTabs)}*/}
        {/*</nav>*/}
        {/*<nav className="map-sidebar__bottom-tabs p-3 pb-16">{renderTabs(clockLink)}</nav> */}
      </div>
      {showBody && <SideBarBody ref={contentRef} onEscapeDown={onEscapeDown} />}
    </aside>
  );
};
