import { useRef } from 'react';
import { MapRefContext } from 'contexts/map/MapRefContext';
import { Map } from 'mapbox-gl';

export interface MapRefContextProps {
  children: JSX.Element;
}

export const MapRefProvider = ({ children }: MapRefContextProps) => {
  const mapRef = useRef<Map>(null);

  const mapRefContext = {
    mapRef,
  };

  return (
    <MapRefContext.Provider value={mapRefContext}>
      {children}
    </MapRefContext.Provider>
  );
};

export default MapRefProvider;
