import { IUserTableRequest, IUserTableResponse } from '../interfaces';
import { DumpSearchTableResponse } from '../interfaces/dumps';
import axios from '../services/axios';
import { TUsersSortUnion } from '../types';
import { DumpSearchRequest } from '../types/dumps';

import { handleAxiosResponse } from './apiUtils';

export const getGeospoofUserTable = (
  params: IUserTableRequest<TUsersSortUnion>
) =>
  handleAxiosResponse<IUserTableResponse>(
    axios.get('/api/geospoof/user/table', { params })
  );

export const searchMaigretUser = (username: string) =>
  handleAxiosResponse<string>(
    axios.get(`/maigret-searcher/search?username=${username}&id_type=username`)
  );
export const searchDumps = (req: DumpSearchRequest) =>
  handleAxiosResponse<DumpSearchTableResponse>(
    axios.get(
      `/api/elk/dump_search?query=${req.query}&start=${req.start}&size=${req.size}`
    )
  );
