import { genericMemo } from 'utils';

import Checkbox from '../Checkbox';

import './style.scss';

interface IRadioGroupProps<T> {
  options: {
    value: T;
    label: string;
  }[];
  currentId: T;
  onChange: (id: T) => void;
  title?: string;
}

const RadioGroup = <T extends string | number>({
  options,
  currentId,
  onChange,
  title,
}: IRadioGroupProps<T>) => {
  const renderItem = (
    { label, value }: { value: T; label: string },
    index: number
  ) => (
    <Checkbox
      label={label}
      isChecked={value === currentId}
      onChange={() => onChange(value)}
      key={`radio-button-${index}`}
    />
  );

  return (
    <div className="radio-group">
      {title && <h2 className="radio-group__header tpg-title-four">{title}</h2>}
      {options.map(renderItem)}
    </div>
  );
};

export default genericMemo(RadioGroup);
