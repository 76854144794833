import { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';

export interface ContextMenuState {
  mouseX: number;
  mouseY: number;
  row: any;
  rowId: string;
}
export interface MenuItemProps {
  title: string;
  onClick: () => void;
}

interface TableContextMenuProps {
  contextMenu?: ContextMenuState;
  onClose: () => void;
  menuItems: MenuItemProps[];
}

const MENU_ITEM_SX = {
  padding: '0px',
  border: 'none',
  backgroundColor: 'inherit',
  color: '#9599A6',
  fontSize: '11px',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.88px',
  textTransform: 'uppercase',
};

const TableContextMenu: FC<TableContextMenuProps> = ({
  contextMenu,
  onClose,
  menuItems,
}) => (
  <Menu
    open={contextMenu !== undefined}
    onClose={onClose}
    anchorReference="anchorPosition"
    anchorPosition={
      contextMenu
        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
        : undefined
    }
    sx={{
      '& .MuiPaper-root': {
        borderRadius: '10px',
        border: '1px solid #9599A6',
        width: '215px !important',
        backgroundColor: '#0a0c17',
      },
      '& .MuiList-root': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: '6px 16px',
        width: '215px',
      },
    }}
  >
    {menuItems.map((item, idx) => (
      <MenuItem
        onClick={item.onClick}
        sx={MENU_ITEM_SX}
        key={`menu-item-${idx}`}
      >
        {item.title}
      </MenuItem>
    ))}
  </Menu>
);

export default TableContextMenu;
