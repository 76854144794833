import { useRef } from 'react';
import { Tree as ReactArboristTree } from 'react-arborist';
import { TreeApi } from 'react-arborist/dist/module/interfaces/tree-api';
import { TreeProps as IReactArboristTreeProps } from 'react-arborist/dist/module/types/tree-props';
import cn from 'classnames';

import { INodeInfo, INodeMoveData, INodeState, TNodeData } from './types';

import styles from './tree.module.scss';

export type TTreeProps<
  F,
  O,
  I extends INodeInfo = INodeInfo,
  S extends INodeState = INodeState
> = IReactArboristTreeProps<TNodeData<F, O, I, S>>;

export const Tree = <
  F,
  O,
  I extends INodeInfo = INodeInfo,
  S extends INodeState = INodeState
>({
  openByDefault = false,
  width = '100%',
  rowHeight = 28,
  indent = 20,
  disableEdit = true,
  disableDrag = true,
  className,
  onMove,
  ...props
}: TTreeProps<F, O, I, S>) => {
  const treeRef = useRef<TreeApi<TNodeData<F, O, I, S>>>();

  const handleMove = (data: INodeMoveData<F, O, I, S>) => {
    onMove?.(data);
    treeRef.current?.deselectAll();
  };

  return (
    <ReactArboristTree<TNodeData<F, O, I, S>>
      ref={treeRef}
      openByDefault={openByDefault}
      width={width}
      rowHeight={rowHeight}
      indent={indent}
      disableEdit={disableEdit}
      disableDrag={disableDrag}
      onMove={handleMove}
      className={cn(styles['tree'], className)}
      {...props}
    />
  );
};
