import { FC, memo } from 'react';
import ReactPlayer from 'react-player';

interface MediaPlayerProps {
  url: string;
  width?: number | string;
  height?: number | string;
}

const MediaPlayer: FC<MediaPlayerProps> = ({
  url,
  width = 750,
  height = '100%',
}) => (
  <ReactPlayer
    width={width}
    height={height}
    url={url}
    playing={false}
    controls
    light
  />
);

export default memo(MediaPlayer);
