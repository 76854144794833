import { PropsWithChildren, useState } from 'react';
import cn from 'classnames';

import { TooltipWrap } from 'components/ui/Tooltip';

interface IOverflowTooltipWrapperProps {
  value: string;
  className?: string;
}

export const OverflowTooltipWrapper = ({
  value,
  className,
  children,
}: PropsWithChildren<IOverflowTooltipWrapperProps>) => {
  const [isOverflowed, setOverflowed] = useState(false);

  const onElementChange = (elem: HTMLSpanElement | null) =>
    elem && setOverflowed(elem.offsetWidth < elem.scrollWidth);

  if (isOverflowed) {
    return (
      <TooltipWrap title={value} placement="top">
        <span
          ref={onElementChange}
          className={cn(
            'block overflow-hidden whitespace-nowrap text-ellipsis',
            className
          )}
        >
          {children || value}
        </span>
      </TooltipWrap>
    );
  }

  return (
    <span
      ref={onElementChange}
      className={cn(
        'block overflow-hidden whitespace-nowrap text-ellipsis',
        className
      )}
    >
      {children || value}
    </span>
  );
};
