export const fireId = 'VIIRS_NOAA20_Thermal_Anomalies_375m_All';

export const fireLayer = {
  id: fireId,
  source: fireId,
  type: 'raster',
  minzoom: 0,
};

export const fireSource = (date?: string) => ({
  type: 'raster',
  tiles: [
    `https://gibs.earthdata.nasa.gov/wms/epsg4326/best/wms.cgi?service=WMS&request=GetMap&version=1.1.1&layers=VIIRS_SNPP_Thermal_Anomalies_375m_All&styles=&format=image%2Fpng&transparent=true&srs=EPSG:3857&width=480&height=480&bbox={bbox-epsg-3857}&time=${date}`,
  ],
  name: fireId,
  id: fireId,
});
