import { ReactComponent as ArrowIcon } from 'images/newIcons/down.svg';

import { GroupNode } from './AccessSidebar';

export const renderNodeArrow = (node: GroupNode) => {
  if (node.data.isFolder) {
    return (
      <div className="[&>svg>path]:fill-tpg_base group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
        <ArrowIcon />
      </div>
    );
  }

  return null;
};
