import cn from 'classnames';
import { Option } from 'interfaces';

export interface AutocompleteDropdownOptionProps<T = any> {
  option: Option<T>;
  onClick: (option: Option<T>) => void;
  className?: string;
}

export const AutocompleteDropdownOption = <T,>({
  option,
  onClick,
  className,
}: AutocompleteDropdownOptionProps<T>) => (
  <li
    className={cn(
      'px-4 py-[3px] list-none bg-ultrablack tpg-c2 text-tpg_base cursor-pointer hover:bg-light',
      className
    )}
    // prevents auto blur on input
    onMouseDown={(e) => e.preventDefault()}
    onClick={() => onClick(option)}
  >
    <span>{option.label}</span>
  </li>
);
