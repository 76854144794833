import React, { CSSProperties, memo, ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as FilterIcon } from 'images/newIcons/filter.svg';
import { ReactComponent as FilterActiveIcon } from 'images/newIcons/filter-active.svg';

import { ElementDirectionUnion } from '../../../constants/routes';

import styles from './Filters.module.scss';

interface BaseFilterProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dropdownContent: ReactNode;
  iconSize?: number;
  dropdownDirection?: ElementDirectionUnion;
  containerStyle?: CSSProperties;
}

const BaseFilter = ({
  isOpen,
  setIsOpen,
  dropdownContent,
  iconSize = 16,
  containerStyle,
}: BaseFilterProps) => {
  return (
    <div
      className={`cursor-pointer ${styles['filters-icon']}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen ? (
        <FilterActiveIcon width={iconSize} height={iconSize} />
      ) : (
        <FilterIcon width={iconSize} height={iconSize} />
      )}
      <div
        className={cn(
          `absolute flex flex-col ${styles['filters-container']}`,
          isOpen ? '' : 'hidden'
        )}
        style={containerStyle}
        onClick={(e) => e.stopPropagation()}
      >
        {dropdownContent}
      </div>
    </div>
  );
};

export default memo(BaseFilter);
