import { FC } from 'react';
import { IMediaFile } from 'interfaces';

import PreviewMediaFiles from 'components/PreviewMediaFiles';
import { getMediaFiles } from 'utils';

interface IObjectPreviewMediaProps {
  media: IMediaFile[];
  mediaOrder?: number[];
  onMediaClick?: () => void;
}

export const ObjectPreviewMedia: FC<IObjectPreviewMediaProps> = ({
  media,
  mediaOrder,
  onMediaClick,
}) => {
  return media.length ? (
    <div className="p-3 border-[inherit] border-solid border-tpg_light">
      <PreviewMediaFiles
        visible={4}
        size="m"
        showFullImage={false}
        files={getMediaFiles(media)}
        mediaOrder={mediaOrder}
        onClick={onMediaClick}
        isVertical
      />
    </div>
  ) : null;
};
