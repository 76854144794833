import { FC, useEffect, useState } from 'react';

interface DocumentViewerProps {
  blob: Blob;
  width: number;
  height: number;
}

const DocumentViewer: FC<DocumentViewerProps> = ({ blob, width, height }) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    const url = URL.createObjectURL(blob);
    setBlobUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [blob]);

  return (
    <>
      {blobUrl && (
        <iframe
          src={blobUrl}
          width={width}
          height={height}
          title="Document Viewer"
        />
      )}
    </>
  );
};

export default DocumentViewer;
