import booleanValid from '@turf/boolean-valid';
import rectangleGrid from '@turf/rectangle-grid';
import {
  area,
  bbox,
  center,
  feature,
  Id,
  LineString,
  Point,
  Polygon,
  Position,
  Properties,
} from '@turf/turf';
import {
  ENTITY_TYPE_OBJECT,
  FeatureTypes,
  MAP_MAX_ZOOM_LEVEL,
  MAP_MIN_ZOOM_LEVEL,
} from 'constants/map';
import { SHARE_MODAL } from 'constants/modals';
import type {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  GeometryCollection,
} from 'geojson';
import { ClientAttributeEntity, IProjectile, ISelectOption } from 'interfaces';
import { LngLat, MapboxGeoJSONFeature } from 'mapbox-gl';
import { AppDispatch } from 'store';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { MapObject, TPosition } from 'types';
import {
  Entity,
  GeometryParameter,
  NumberParameter,
  ParametersIdTitleMap,
  TextParameter,
} from 'types/entities';
import {
  BoundaryGrid,
  MapboxGeoJSONCompletedFeatureProperties,
  MapboxGeoJSONFeature as CustomMapboxGeoJSONFeature,
  MapEntity,
  MapFeatureLine,
  MapFeaturePoint,
  MapFeaturePolygon,
  MapFeatureProperties,
} from 'types/map';

import { formatDate, notify } from 'utils';

import { mapEntityParams } from '../constants/entities';

import { getEntityParameterValue } from './entity';
import { constructIdentifier } from './entity';

interface GetFeaturesFromMapEntitiesReturnType {
  points: Record<string, FeatureCollection<Point, MapFeatureProperties>>;
  lines: FeatureCollection<LineString, MapFeatureProperties>;
  polygons: FeatureCollection<Polygon, MapFeatureProperties>;
}

type TGeometry = Exclude<Geometry, GeometryCollection>;

const getFeaturesFromMapEntitiesInitial: GetFeaturesFromMapEntitiesReturnType =
  {
    points: {},
    lines: { type: 'FeatureCollection', features: [] },
    polygons: { type: 'FeatureCollection', features: [] },
  };

export const prepareFeatureCollection = (
  features?: Feature[]
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: features ?? [],
});

export const preparePolygonFeature = <T = any>(
  id: string | number | undefined,
  coordinates: Position[][],
  properties: T
): Feature<Polygon, T> => ({
  type: 'Feature',
  id,
  geometry: { type: 'Polygon', coordinates: coordinates },
  properties,
});

export const getCoordinatesCenter = (coordinates: TPosition[]): TPosition => {
  const sum = coordinates.reduce(
    (acc, coordinate) => [acc[0] + coordinate[0], acc[1] + coordinate[1]],
    [0, 0]
  );

  return [sum[0] / coordinates.length, sum[1] / coordinates.length];
};

export const roundCoordinates = (
  coordinates: TPosition | TPosition[]
): TPosition | TPosition[] => {
  if (typeof coordinates[0] === 'number') {
    const [lat, lon] = coordinates as TPosition;
    return [parseFloat(lat.toFixed(6)), parseFloat(lon.toFixed(6))];
  } else {
    return (coordinates as TPosition[]).map(
      (position) => roundCoordinates(position) as TPosition
    );
  }
};

export const getZoomPercent = (
  zoom: number,
  minZoom = MAP_MIN_ZOOM_LEVEL,
  maxZoom = MAP_MAX_ZOOM_LEVEL
) => ((zoom - minZoom) / (maxZoom - minZoom)) * 100;

export const getMetersPerPixel = (latitude: number, zoomLevel: number) => {
  const EARTH_RADIUS = 6378137;
  const TILESIZE = 256;
  const EARTH_CIRCUMFERENCE = 2 * Math.PI * EARTH_RADIUS;

  const scale = Math.pow(2, zoomLevel);
  const worldSize = TILESIZE * scale;
  const latitudeRadians = latitude * (Math.PI / 180);

  return (EARTH_CIRCUMFERENCE * Math.cos(latitudeRadians)) / worldSize;
};

export const getPixelValueByMeters = (
  meters: number,
  latitude: number,
  zoom: number
) => meters / getMetersPerPixel(latitude, zoom);

export const downloadObject = (
  object: string,
  name: string,
  fileOptions?: BlobPropertyBag
) => {
  try {
    const blob = new Blob([object], fileOptions);
    const link = document.createElement('a');

    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch {
    notify.error('Не удалось скопировать файл');
  }
};

export const getAttributeOptionsFromClient = (
  attributes: ClientAttributeEntity[]
): ISelectOption[] =>
  attributes.map(({ name, id, code }) => ({
    label: code === null ? name : code + ' ' + name,
    value: id,
  }));

export const getRadiusFromString = (radius: string) => {
  const regex = /^(\d+(\.\d+)?)-(\d+(\.\d+)?) км$/;
  const match = radius.match(regex);
  if (match) {
    const min_range = parseFloat(match[1]);
    const max_range = parseFloat(match[3]);

    return [min_range, max_range];
  }
  throw new Error('Invalid input format');
};

export const getListOfRangeInMeters = (projectiles: IProjectile[]) =>
  projectiles.map((el) => 1000 * getRadiusFromString(el.range)[1]);

export const getMapObject = (
  entity: MapEntity,
  parametersIdTitleMap: ParametersIdTitleMap
): MapObject => {
  const geometry = getEntityParameterValue<GeometryParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.GEOMETRY
  ) ?? {
    type: 'Point',
    coordinates: [0, 0],
  };

  const type = getEntityParameterValue<TextParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.TYPE
  );

  const color = getEntityParameterValue<TextParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.COLOR
  );

  const opacity = getEntityParameterValue<NumberParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.OPACITY
  );

  return {
    id: String(entity.entity.id),
    name: entity.entity.title,
    geometry: geometry,
    type: type,
    color: color,
    opacity: opacity,
  };
};

export const createFeature = <
  G extends TGeometry = TGeometry,
  P extends Properties = Properties
>(
  geometry: G,
  properties: P,
  id?: Id
) => feature(geometry, properties, { id });

export const isFeatureValid = (feature: Feature<any>) => booleanValid(feature);

export const calculateFeatureCenter = (feature: Feature<any>): Position =>
  center(feature).geometry.coordinates;

export const calculateGeometryCenter = (geometry: TGeometry): Position => {
  const feature = createFeature(geometry, null);

  return center(feature).geometry.coordinates;
};

export const getFeaturesFromMapEntities = (
  entities: MapEntity[],
  parametersIdTitleMap: ParametersIdTitleMap
) =>
  entities.reduce((acc, curr) => {
    if (curr.info.type !== ENTITY_TYPE_OBJECT || !curr.state.active) {
      return acc;
    }

    const mapObject = getMapObject(curr, parametersIdTitleMap);
    const mapFeature = createFeature(
      mapObject.geometry,
      {
        title: `${constructIdentifier(mapObject.id)}\n${mapObject.name}`,
        width: 5,
        type: mapObject.type,
        lineColor: mapObject.color,
        fillColor: mapObject.color,
        opacity: getProperOpacity(mapObject, 0.7),
      },
      mapObject.id
    );

    if (mapFeature.geometry.type === 'Point') {
      const type = 'FeatureCollection' as const;
      const mapObjectType = mapObject.type ?? 'Неизвестный тип';

      return {
        ...acc,
        points: {
          ...acc.points,
          [mapObjectType]: {
            type: type,
            features: [
              ...(acc.points[mapObjectType]?.features ?? []),
              mapFeature as MapFeaturePoint,
            ],
          },
        },
      };
    }

    if (mapFeature.geometry.type === 'LineString') {
      return {
        ...acc,
        lines: {
          ...acc.lines,
          features: [...acc.lines.features, mapFeature as MapFeatureLine],
        },
      };
    }

    if (mapFeature.geometry.type === 'Polygon') {
      return {
        ...acc,
        polygons: {
          ...acc.polygons,
          features: [...acc.polygons.features, mapFeature as MapFeaturePolygon],
        },
      };
    }

    return acc;
  }, getFeaturesFromMapEntitiesInitial);

export const getSafeBeforeId = (map: mapboxgl.Map | null, layerId: string) =>
  map?.getLayer(layerId) ? layerId : undefined;

export const getInitialEntity = (
  id = 0,
  mapObjectTemplateID: number,
  paramIdMap: Record<mapEntityParams, string>,
  geometry: GeoJSON.Geometry,
  parentEntityID?: number
): Entity => ({
  id: id,
  parentEntityID,
  templateID: mapObjectTemplateID,
  title: '',
  parameters: {
    [paramIdMap[mapEntityParams.GEOMETRY]]: { value: geometry },
    [paramIdMap[mapEntityParams.OPACITY]]: { value: 25 },
    [paramIdMap[mapEntityParams.DESCRIPTION]]: { value: '' },
    [paramIdMap[mapEntityParams.DATE]]: { value: formatDate(new Date()) },
    [paramIdMap[mapEntityParams.COLOR]]: { value: '#2196f3' },
    [paramIdMap[mapEntityParams.TYPE]]: { value: 'Другое' },
    [paramIdMap[mapEntityParams.STATUS]]: { value: 'Выявлено' },
    [paramIdMap[mapEntityParams.MEDIA]]: { value: [] },
    [paramIdMap[mapEntityParams.PLACE]]: { value: '' },
  },
});

export const getProperOpacity = (
  item: {
    opacity?: number | null;
    [key: string]: any;
  },
  defaultOpacity = 1
) =>
  item.opacity === undefined || item.opacity === null
    ? defaultOpacity
    : item.opacity / 100;

export const getRectangleGrid = <T extends GeoJsonProperties = any>(
  feature: MapboxGeoJSONFeature,
  size: number,
  properties?: T
) => {
  const featureBbox = bbox(feature);
  const [west, south, east, north] = featureBbox;
  const width = (east - west) / size;
  const height = (north - south) / size;

  const grid = rectangleGrid<T>(featureBbox, width, height, {
    units: 'degrees',
    properties,
  });

  return grid;
};

export const getBoundaryGrid = (
  feature: MapboxGeoJSONFeature,
  size: number,
  properties?: GeoJsonProperties
) => {
  const boundaryGrid = getRectangleGrid<GeoJsonProperties>(
    feature,
    size,
    properties
  ) as BoundaryGrid;

  const featureId = feature.id ?? feature.properties?.id;

  boundaryGrid.relatedFeatureId = featureId;
  boundaryGrid.visible = true;
  boundaryGrid.showLabels = false;
  boundaryGrid.features.forEach((boundaryCell, index) => {
    const column = size - (index % size);
    const row = Math.floor(index / size) + 1;
    const label = `${column}${row}`;
    const id = `${featureId}-${index}-${label}`;
    const sq = area(feature);

    boundaryCell.id = id;
    boundaryCell.properties = {
      ...properties,
      id: id,
      relatedFeatureId: featureId,
      index: index,
      size: size,
      area: sq,
      label: label,
      showLabel: false,
      filled: false,
    };
  });

  return boundaryGrid;
};

export const getGeoJSONCompletedFeature = <
  G extends Geometry = any,
  P extends MapboxGeoJSONCompletedFeatureProperties = any
>(
  feature: CustomMapboxGeoJSONFeature<G, P>
): CustomMapboxGeoJSONFeature<G, Omit<P, 'geometry'>> => {
  const { geometry, ...restProperties } = feature.properties;

  return {
    ...feature,
    geometry: JSON.parse(geometry),
    properties: restProperties,
  };
};

export const getQueriedFeaturesBounds = (
  position: { x: number; y: number },
  offset = 3
): [[number, number], [number, number]] => [
  [position.x - offset, position.y - offset],
  [position.x + offset, position.y + offset],
];

export const lngLatFromCoords = (coordinates: TPosition) => {
  return new LngLat(coordinates[0], coordinates[1]);
};

export const openShareModal = (dispatch: AppDispatch, entityId: number) => {
  dispatch(
    modalsActions.addModal({
      id: SHARE_MODAL,
      isOpen: true,
      props: { entityId },
    })
  );
};

export const getEntityCoordinates = (
  geometry: GeometryParameter | null
): TPosition => {
  if (!geometry || geometry.coordinates.length == 0) {
    return [0, 0];
  }

  switch (geometry.type) {
    case FeatureTypes.POINT:
      return geometry.coordinates as TPosition;
    case FeatureTypes.LINE:
      return getCoordinatesCenter(geometry.coordinates as TPosition[]);
    case FeatureTypes.POLYGON:
      return getCoordinatesCenter(geometry.coordinates[0] as TPosition[]);
    default:
      return [0, 0];
  }
};
