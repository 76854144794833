import { createSlice } from '@reduxjs/toolkit';

import { GEOSPOOF_REDUCER_NAMES } from '../constants';

import {
  SetSelectedObjectAction,
  SetSelectedTabAction,
  SetVisibleObjectsAction,
  StateState,
} from './types';

const initialState: StateState = {
  selectedTab: 'history',
  selectedObject: null,
  visibleObjects: [],
};

const stateSlice = createSlice({
  name: GEOSPOOF_REDUCER_NAMES.STATE,
  initialState: initialState,
  reducers: {
    setSelectedTab(state, action: SetSelectedTabAction) {
      const { selectedTab } = action.payload;

      state.selectedTab = selectedTab;
    },
    setSelectedObject(state, action: SetSelectedObjectAction) {
      const { selectedObject } = action.payload;

      state.selectedObject = selectedObject;
    },
    setVisibleObjects(state, action: SetVisibleObjectsAction) {
      const { visibleObjects } = action.payload;

      state.visibleObjects = visibleObjects;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: stateActions, reducer: stateReducer } = stateSlice;
