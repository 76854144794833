import { forwardRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useEscapeDown } from 'hooks/useEscapeDown';

interface ISidebarBodyProps {
  onEscapeDown: () => void;
}

export const SideBarBody = forwardRef<HTMLDivElement, ISidebarBodyProps>(
  ({ onEscapeDown }, ref) => {
    useEscapeDown(onEscapeDown);

    return (
      <div ref={ref} className="map-sidebar__body">
        <div className="map-sidebar__body_content">
          <Outlet />
        </div>
      </div>
    );
  }
);

SideBarBody.displayName = 'SideBarBody';
