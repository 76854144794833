import { RootState } from 'store';

import { Modal } from './types';

export const modalsSelector = (state: RootState) => state.service.modals.modals;

export const modalSelector = (
  state: RootState,
  id: string
): Modal | undefined => state.service.modals.modals[id];
