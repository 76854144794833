import { FC, ReactNode } from 'react';
import { ReactComponent as ObjectIcon } from 'images/newIcons/objectPreview.svg';

import { constructIdentifier } from 'utils/entity';

interface IObjectPreviewHeader {
  icon?: ReactNode;
  id: number;
  name: string;
}

export const ObjectPreviewHeader: FC<IObjectPreviewHeader> = ({
  icon = <ObjectIcon />,
  id,
  name,
}) => (
  <div
    className={`flex m-[-1px] rounded-t-[inherit] 
border border-solid border-tpg_light justify-start
 bg-light gap-2 px-3 py-2 h-[40px]`}
  >
    <div
      className={`flex justify-center 
items-center bg-light rounded-l-[inherit]`}
    >
      {icon}
    </div>
    <p className="tpg-b2 text-tpg_base">{constructIdentifier(id)}</p>
    <p className="tpg-b2 text-tpg_title line-clamp-1">{name}</p>
  </div>
);
