import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity, getSafeBeforeId } from 'utils';

export const MapboxSource = () => {
  const mapTypes = useAppSelector(typesSelector);
  const { mapRef } = useMapRef();

  const showMapbox = mapTypes.mapboxSatelliteUpper.active;

  if (!showMapbox) {
    return null;
  }

  return (
    <Source url="mapbox://mapbox.satellite" type="raster">
      <Layer
        type="raster"
        beforeId={getSafeBeforeId(mapRef.current, 'google-streets-layer')}
        paint={{
          'raster-opacity': getProperOpacity(mapTypes.mapboxSatelliteUpper),
        }}
      />
    </Source>
  );
};
