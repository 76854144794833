import { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { ElementPlacementUnion } from 'constants/routes';
import { useClickOutside } from 'hooks';
import { ISelectOption } from 'interfaces';
import { TDateRange } from 'types';

import DatePicker from '../DatePicker';
import TreeSelect from '../TreeSelect';

interface TreeSelectWithDatepickerProps<T> {
  datepickerClassName?: string;
  datepickerValue: T;
  selectorValue: T;
  selectorOptions: ISelectOption[];
  setDate: (v: TDateRange) => void;
  onSelect: (v: any) => void;
  onCalendarClose: () => void;
  theme?: 'dark' | 'light';
  minDate?: Date | null;
  maxDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  visibleBackPropagate?: (isVisible: boolean) => void;
  menuPlacement?: ElementPlacementUnion;
  optionsClassName?: string;
}

const TreeSelectWithDatepicker = <T extends string | number | null>({
  datepickerClassName,
  selectorValue,
  selectorOptions,
  onSelect,
  setDate,
  onCalendarClose,
  theme = 'dark',
  minDate,
  maxDate,
  startDate,
  endDate,
  visibleBackPropagate,
  optionsClassName,
  menuPlacement,
  datepickerValue,
}: TreeSelectWithDatepickerProps<T>) => {
  const [isSelectorPressed, setIsSelectorPressed] = useState(false);
  const [canShowDatepicker, setCanShowDatepicker] = useState(false);

  const datepickerRef = useRef(null);

  const showDatepicker = useMemo(() => {
    return canShowDatepicker ? selectorValue === datepickerValue : false;
  }, [canShowDatepicker, selectorValue, datepickerValue]);

  const handleSelectorPressed = (isVisible: boolean) => {
    setIsSelectorPressed(isVisible);
  };

  const handleSelect = (v: any) => {
    setCanShowDatepicker(true);
    onSelect(v);
  };

  useClickOutside(datepickerRef, () => {
    setCanShowDatepicker(false);
    visibleBackPropagate && visibleBackPropagate(false);
  });

  const classNames = useMemo(() => {
    return {
      body: 'bg-light group-hover:fill-main_product',
      text: cn('group-hover:text-main_product', {
        '!text-dark_product': isSelectorPressed,
      }),
      options: optionsClassName,
    };
  }, [isSelectorPressed]);

  return (
    <div ref={datepickerRef}>
      {!showDatepicker ? (
        <TreeSelect
          classNames={classNames}
          withEmpty={false}
          value={selectorValue}
          options={selectorOptions}
          onSelect={handleSelect}
          theme={theme}
          visibleBackPropagate={handleSelectorPressed}
          onlyRenderOptions
          menuPlacement={menuPlacement}
        />
      ) : (
        <DatePicker
          calendarClassName={cn('ml-4 mt-4', datepickerClassName)}
          selectsRange={true}
          minDate={minDate}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          onChange={setDate}
          onCalendarClose={onCalendarClose}
          inline
        />
      )}
    </div>
  );
};

export default TreeSelectWithDatepicker;
