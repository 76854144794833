import { FC, memo } from 'react';
import { ReactComponent as ImportIcon } from 'images/icons/import.svg';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';

interface ILayersFooterProps {
  onAddProject: () => void;
  onImportKML: () => void;
}

export const LayersFooter: FC<ILayersFooterProps> = memo(
  ({ onAddProject, onImportKML }) => (
    <div className="w-full h-8 py-[6px] px-4 flex flex-row items-center rounded-b-[10px] bg-light cursor-pointer">
      <div className="flex gap-1 w-full justify-between">
        <div className={'flex gap-1 items-center'} onClick={onImportKML}>
          <ImportIcon className={'[&>g>path]:fill-tpg_base'} />
          <div className="tpg-c2 text-tpg_base">ИМПОРТ KML</div>
        </div>
        <div className={'flex gap-1 items-center'} onClick={onAddProject}>
          <PlusIcon className={'[&>g>path]:fill-tpg_base'} />
          <div className="tpg-c2 text-tpg_base">ПРОЕКТ</div>
        </div>
      </div>
    </div>
  )
);

LayersFooter.displayName = 'LayersFooter';
