import { FC, memo } from 'react';

import './style.scss';

interface SpinnerProps {
  size?: number;
  color?: string;
}

const Spinner: FC<SpinnerProps> = ({ size = 25, color = '#333333' }) => {
  const styles = {
    width: size + 'px',
    height: size + 'px',
  };

  return (
    <div className="spinner">
      <div className="spinner__icon" style={styles}>
        <svg x="0" y="0" viewBox="25 25 50 50" style={styles}>
          <circle
            cx="50"
            cy="50"
            r="17"
            fill={color}
            stroke={color}
            strokeWidth="5"
            strokeMiterlimit="10"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default memo(Spinner);
