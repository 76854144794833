import { MapCreateModes, MapDrawModes, MapMeasureModes } from 'interfaces';

export const MAP_DRAW_MODES: MapDrawModes = {
  draw_line_string: 'draw_line_string',
  draw_polygon: 'draw_polygon',
  draw_point: 'draw_point',
  simple_select: 'simple_select',
  direct_select: 'direct_select',
};

export const MAP_CREATE_MODES: MapCreateModes = {
  create_line_string: 'create_line_string',
  create_polygon: 'create_polygon',
};

export const MAP_MEASURE_MODES: MapMeasureModes = {
  measure_line_string: 'measure_line_string',
  measure_polygon: 'measure_polygon',
  measure_artillery: 'measure_artillery',
  measure_circle: 'measure_circle',
};

export const MAP_CREATE_MODE_PREFIX = 'create';

export const MAPBOX_CTRL_PREFIX = 'mapboxgl-ctrl';
