import { FC, ReactNode } from 'react';
import { TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { verboseAccessRule } from 'constants/entities';
import { GroupLinkedEntity } from 'interfaces/profile';

import AstraTable, {
  AstraTableFetchParams,
} from 'components/ui/Table/AstraTable';
import { AstraTableHeadCell } from 'components/ui/Table/AstraTableHeader';

interface ILinkedEntitiesTableProps {
  data: GroupLinkedEntity[];
}

const LINKED_ENTITIES_HEAD_CELLS: AstraTableHeadCell<any>[] = [
  {
    id: 'linkedEntityTitle',
    numeric: false,
    disablePadding: false,
    label: 'Сущность',
  },
  {
    id: 'linkedEntityRule',
    numeric: false,
    disablePadding: false,
    label: 'Правило',
  },
];

const LinkedEntitiesTable: FC<ILinkedEntitiesTableProps> = ({ data }) => {
  const fetchRows = async (params: AstraTableFetchParams<any>) => {
    return Promise.resolve({
      rows: data,
      total: data.length,
    });
  };

  const renderRowCells: (row: GroupLinkedEntity) => ReactNode = (row) => {
    return (
      <>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {row.entity.title}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {verboseAccessRule(row.link.acl)}
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <TableContainer
        style={{ maxHeight: '35vh', overflowY: 'auto', width: 'full' }}
      >
        <AstraTable
          handleRowClick={(row) => console.log('click on ', row)}
          fetchRows={fetchRows}
          rowIdExtractor={(row) => row.entity.id}
          renderRowCells={renderRowCells}
          headCells={LINKED_ENTITIES_HEAD_CELLS}
        />
      </TableContainer>
    </div>
  );
};

export default LinkedEntitiesTable;
