import LangCorpusTree from './LangCorpusTree';
import StaticFilters from './StaticFilters';

import './style.scss';

const Filters = () => {
  return (
    <div className="filters">
      <LangCorpusTree />
      <StaticFilters />
    </div>
  );
};

export default Filters;
