import { useEffect } from 'react';
import { ControlPosition } from 'react-map-gl';
import { Control, IControl } from 'mapbox-gl';

import { useMapRef } from './map';

/* 
    useCustomControl is alternative for useControl. 
    useControl keeps old arguments because of redundant control memoization,
    so it's impossible to use them with reactive args.
 */

export const useCustomControl = (
  control: Control | IControl,
  position: ControlPosition
) => {
  const mapRef = useMapRef();

  useEffect(() => {
    mapRef.mapRef.current?.addControl(control, position);

    return () => {
      mapRef.mapRef.current?.removeControl(control);
    };
  }, [control, position]);
};
