import { FC, memo } from 'react';
import cn from 'classnames';
import { ReactComponent as RoundSync } from 'images/newIcons/roundSync.svg';

import './style.scss';

interface ILoaderProps {
  className?: string;
}

const Loader: FC<ILoaderProps> = ({ className }) => (
  <div className={cn('round-loader', className)}>
    <RoundSync className="round-loader__spinner" />
    <h4 className="tpg-c2 mt-3.5">Данные загружаются</h4>
  </div>
);

export default memo(Loader);
