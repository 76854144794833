import { RootState } from 'store';

import { Loader } from './types';

export const loadersSelector = (state: RootState) =>
  state.service.loaders.loaders;

export const loaderSelector = (
  state: RootState,
  id: string
): Loader | undefined => state.service.loaders.loaders[id];
