import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MarkdownEditor from '@uiw/react-markdown-editor';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';

import {
  getReportRelations,
  getReportVersionHistory,
} from '../../../api/reports';
import { MARKDOWN_COMMANDS } from '../../../constants/reports';
import { appRoutes } from '../../../constants/routes';
import { IMediaFile, ISelectOption } from '../../../interfaces';
import {
  PersonReportBody1,
  PersonReportBodySegment,
  PersonReportCustomTextBlockValue,
  PersonReportMedia,
  PersonReportSummary,
  PersonReportVersion,
} from '../../../interfaces/reports';
import { notify } from '../../../utils';
import { Button, DatePicker, Select, TextInput } from '../../ui';
import Dropzone from '../../ui/Dropzone';
import { MultiLineEditor } from '../../ui/MultiLineEditor';
import { PersonReportCardCustomText } from '../PersonReportCardCustomText';
import {
  EditorPersonReportRelation,
  PersonReportRelationSelect,
} from '../PersonReportRelationSelect/PersonReportRelationSelect';
import PersonReportReorderableHeading from '../PersonReportReorderableHeading';

import './style.scss';

export type PersonReportCardModel = Omit<
  PersonReportBody1,
  'birthDate' | 'bodyVersion'
> & {
  photo?: IMediaFile;
  headerId?: number;
  versionId?: number;
  birthDate?: dayjs.Dayjs;
  name: string;
  media: Readonly<PersonReportMedia>;
  relations: EditorPersonReportRelation[];
};

export type PersonReportCardProps = {
  report: PersonReportCardModel;
  onSaveReport: (c: PersonReportCardModel) => void;
  onDeleteReport: (headerId: number) => void;
  setReport: (c: PersonReportCardModel) => void;
};

function renderMultilineInput(
  title: string,
  values: string[],
  setter: (v: string[]) => void
) {
  return (
    <MultiLineEditor
      title={title}
      values={values}
      setter={setter}
      renderInput={(value, onValueChange) => (
        <TextInput
          classNames={{ container: 'profile-card__input-block_text-input' }}
          value={value}
          onChange={onValueChange}
        />
      )}
      emptyValue=""
    />
  );
}

function constructOrdering(
  report: PersonReportCardModel,
  reportRelations: EditorPersonReportRelation[] | undefined
): PersonReportBodySegment[] {
  const ABOUT_SEGMENT: PersonReportBodySegment = {
    type: 'aboutSegment',
  };
  const DOCUMENTS_SEGMENT: PersonReportBodySegment = {
    type: 'documentsSegment',
  };
  const orderings = [...report.ordering];
  if (!orderings.find((o) => o.type === 'aboutSegment')) {
    orderings.push(ABOUT_SEGMENT);
  }
  if (!orderings.find((o) => o.type === 'documentsSegment')) {
    orderings.push(DOCUMENTS_SEGMENT);
  }
  if (reportRelations) {
    const headerIdsForPossibleRelations: number[] = reportRelations.flatMap(
      (rel) => {
        if (rel.toHeaderId) return [rel.toHeaderId];
        else return [];
      }
    );
    orderings.forEach((o, idx) => {
      // cleanup unlinked orphan reports
      if (
        o.type === 'linkedReport' &&
        !headerIdsForPossibleRelations.includes(o.headerId)
      ) {
        orderings.splice(idx, 1);
      } else if (
        o.type === 'customTextBlock' &&
        !report.customTextBlocks.find(
          (textBlock) => textBlock.title === o.title
        )
      ) {
        orderings.splice(idx, 1);
      }
    });

    headerIdsForPossibleRelations.forEach((headerId) => {
      // add new reports
      if (
        !orderings.find(
          (o) => o.type === 'linkedReport' && o.headerId === headerId
        )
      ) {
        orderings.push({
          type: 'linkedReport',
          headerId,
        });
      }
    });

    report.customTextBlocks.forEach((customTextBlock) => {
      // add new custom text blocks
      if (
        !orderings.find(
          (o) =>
            o.type === 'customTextBlock' && o.title === customTextBlock.title
        )
      ) {
        orderings.push({
          type: 'customTextBlock',
          title: customTextBlock.title,
        });
      }
    });
  }
  return orderings;
}

const PersonReportCard: FC<PersonReportCardProps> = (
  props: PersonReportCardProps
) => {
  const [showAdditionalInfoEditor, setShowAdditionalInfoEditor] =
    useState(false);
  const [name, setName] = useState(props.report.name || '');
  const [position, setPosition] = useState(props.report.position || '');
  const [generalConclusions, setGeneralConclusions] = useState(
    props.report.generalConclusions || ''
  );
  const [birthDate, setBirthDate] = useState<dayjs.Dayjs | undefined>(
    props.report.birthDate
  );
  const [birthPlace, setBirthPlace] = useState(props.report.birthPlace);
  const [phones, setPhones] = useState(props.report.phones || []);
  const [documents, setDocuments] = useState(props.report.documents || []);
  const [socialNetworks, setSocialNetworks] = useState(
    props.report.socialNetworks || []
  );
  const [additionalInfoContent, setAdditionalInfoContent] = useState<string>(
    props.report.additionalInfoContent || ''
  );
  const [emails, setEmails] = useState(props.report.emails || []);
  const [photo, setPhoto] = useState<IMediaFile | undefined>(
    props.report.photo
  );
  const [addresses, setAddresses] = useState(props.report.addresses || []);
  const [propertyAddresses, setPropertyAddresses] = useState(
    props.report.propertyAddresses || []
  );
  const [autos, setAutos] = useState(props.report.autos || []);
  const [other, setOther] = useState(props.report.other || '');
  const [customTextBlocks, setCustomTextBlocks] = useState<
    PersonReportCustomTextBlockValue[]
  >(props.report.customTextBlocks);
  const [reportRelations, setReportRelations] = useState<
    EditorPersonReportRelation[] | undefined
  >(undefined);
  const [ordering, setOrdering] = useState<PersonReportBodySegment[]>(
    constructOrdering(props.report, reportRelations)
  );

  useEffect(() => {
    // dangerous - may lead to circular data reload because of dependency ordering <-> report
    const newOrdering = constructOrdering(props.report, reportRelations);
    if (!isEqual(newOrdering, ordering)) setOrdering(newOrdering);
  }, [reportRelations, props.report.ordering, props.report.customTextBlocks]);

  const currentModelRepresentation: PersonReportCardModel = useMemo(
    () => ({
      addresses,
      propertyAddresses,
      autos,
      birthDate,
      birthPlace,
      documents,
      emails,
      generalConclusions: generalConclusions,
      name: name,
      other: other,
      phones: phones,
      photo: photo,
      position,
      socialNetworks,
      headerId: props.report.headerId,
      versionId: props.report.versionId,
      media: props.report.media,
      ordering: ordering,
      versionMedia: props.report.versionMedia,
      relations: reportRelations || [],
      customTextBlocks,
      additionalInfoContent: additionalInfoContent,
    }),
    [
      name,
      position,
      generalConclusions,
      birthDate,
      birthPlace,
      phones,
      documents,
      socialNetworks,
      emails,
      addresses,
      propertyAddresses,
      autos,
      other,
      photo,
      reportRelations,
      ordering,
      customTextBlocks,
      additionalInfoContent,
    ]
  );

  useEffect(() => {
    props.setReport(currentModelRepresentation);
  }, [currentModelRepresentation]);

  const [reportVersionHistory, setReportVersionHistory] = useState<
    PersonReportVersion[]
  >([]);
  const reportVersionSelectOptions: ISelectOption[] = useMemo(
    () =>
      reportVersionHistory.map((v) => ({
        label: `${v.version_id}. ${v.username} от ${dayjs(v.created_at).format(
          'DD.MM.YYYY'
        )}`,
        value: v.version_id,
      })),
    [reportVersionHistory]
  );

  const handleAddPhoto = (mediaFiles: IMediaFile[]) => setPhoto(mediaFiles[0]);

  useEffect(() => {
    const reloadData = async () => {
      if (props?.report?.headerId) {
        const headerId = props.report.headerId;
        const versionHistory = await getReportVersionHistory(headerId);
        const reportRelations = await getReportRelations(headerId);
        setReportVersionHistory(versionHistory);
        setReportRelations(
          reportRelations
            .filter((rr) => rr.from.header_id === headerId) // TODO - properly handle this place when we want to render it on both sides
            .map((r) => ({
              toHeaderId: r.to.header_id,
              headerReportName: r.to.report_name,
              toHeaderLastVersionId: r.to.last_version_id,
              title: r.title,
              useInRender: true,
            }))
        );
      }
    };
    reloadData().catch((err) =>
      notify.error('Не удалось загрузить историю отчёта')
    );
  }, [props.report.headerId]);
  const navigate = useNavigate();

  const onGoToReportVersionClicked = (versionId: number) => {
    if (props.report.headerId) {
      if (
        confirm(
          'Сменить версию отчёта? Все несохраненные данные будут утрачены'
        )
      ) {
        navigate(
          appRoutes.REPORT + `/${props.report.headerId}/version/${versionId}`
        );
      }
    }
  };

  const onAddNewField = () => {
    setShowAdditionalInfoEditor(!showAdditionalInfoEditor);
  };

  return (
    <div className="person-reports__report-card" key={props.report.headerId}>
      <h1 className="profile-card__title">Конструктор отчетов</h1>
      <div className="profile-card__input-block__name-row">
        <TextInput
          classNames={{ container: 'profile-card__input-block_text-input' }}
          labelText={'Имя'}
          value={name}
          onChange={setName}
        />
        <div style={{ width: '100%' }}>
          <label className={cn('tpg-body-one input-container__label')}>
            {'Версия отчёта'}
          </label>
          {/* Временное решение, не идеально подходит под это */}
          <Select
            withEmpty={false}
            searchPlaceholder="Введите название"
            options={reportVersionSelectOptions}
            onSelect={(v) => v && onGoToReportVersionClicked(v)}
            value={props.report.versionId || null}
          />
        </div>
      </div>

      <h1 className="profile-card__title">Общая справка</h1>
      <div className="profile-card__input-block__name-row">
        <TextInput
          classNames={{ container: 'profile-card__input-block_text-input' }}
          labelText={'Должность'}
          value={position}
          onChange={setPosition}
        />
        <div style={{ width: '100%' }}>
          <label className={cn('tpg-body-one input-container__label')}>
            {'Фото'}
          </label>
          <Dropzone
            onDelete={() => setPhoto(undefined)}
            files={photo ? [photo] : []}
            onAdd={handleAddPhoto}
            acceptedFormat=".jpeg,.jpg,.png"
          />
        </div>
      </div>
      <h1 className="profile-card__title">О персоне</h1>
      <div className="profile-card__input-block__row_full">
        <label className={cn('tpg-body-one input-container__label')}>
          {'Дата рождения'}
        </label>
        <DatePicker
          selected={birthDate?.toDate()}
          className="date-picker"
          dateFormat="dd.MM.yyyy"
          calendarStartDay={1}
          onChange={(v) => {
            if (v instanceof Date) {
              setBirthDate(dayjs(v));
            }
          }}
        />
      </div>
      <div className="profile-card__input-block__row">
        <TextInput
          classNames={{ container: 'profile-card__input-block_text-input' }}
          labelText={'Место рождения'}
          value={birthPlace || ''}
          onChange={setBirthPlace}
        />
      </div>
      <MarkdownEditor
        className="profile-card__markdown-editor"
        value={generalConclusions}
        height="250px"
        onChange={setGeneralConclusions}
        toolbars={MARKDOWN_COMMANDS}
        theme="light"
      />
      <h1 className="profile-card__title">Документы</h1>
      {renderMultilineInput('Документы', documents, setDocuments)}
      {renderMultilineInput('Телефоны', phones, setPhones)}
      {renderMultilineInput('Соцсети', socialNetworks, setSocialNetworks)}
      {renderMultilineInput('Адреса', addresses, setAddresses)}
      {renderMultilineInput(
        'Адреса собственности',
        propertyAddresses,
        setPropertyAddresses
      )}
      {renderMultilineInput('Автомобили', autos, setAutos)}
      {renderMultilineInput('Почты', emails, setEmails)}
      <Button
        className="add-custom-block-button"
        title={
          showAdditionalInfoEditor
            ? 'Убрать информационный блок'
            : 'Добавить информационный блок'
        }
        onClick={onAddNewField}
      />
      {showAdditionalInfoEditor && (
        <MarkdownEditor
          className="profile-card__markdown-editor"
          value={additionalInfoContent}
          height="250px"
          onChange={setAdditionalInfoContent}
          toolbars={MARKDOWN_COMMANDS}
          theme="light"
        />
      )}
      <h1 className="profile-card__title">Дополнительная информация</h1>
      <PersonReportCardCustomText
        values={customTextBlocks}
        onChange={setCustomTextBlocks}
        isFullScreen
      />
      {reportRelations && (
        <div
          className="profile-card__input-block__row_full"
          style={{ gap: 10 }}
        >
          <label className={cn('tpg-body-one input-container__label')}>
            {'Оглавление отчета:'}
          </label>
          <PersonReportReorderableHeading
            values={ordering}
            reportRelations={reportRelations}
            onChange={setOrdering}
          />
        </div>
      )}

      {reportRelations && (
        <div className="profile-card__related-reports">
          <PersonReportRelationSelect
            values={reportRelations}
            onChange={setReportRelations}
            filterValue={(v: PersonReportSummary) => {
              // not current report and not in already selected items list
              const filterArray = [props.report.headerId]
                .concat(reportRelations.map((r) => r.toHeaderId))
                .filter((v) => v);

              return !filterArray.includes(v.header_id);
            }}
          />
        </div>
      )}

      <div
        className="profile-card__input-block__row_full"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          title={'Сохранить'}
          onClick={() => props.onSaveReport(currentModelRepresentation)}
        />
        {props?.report?.headerId && (
          <Button
            title={'Удалить'}
            className={'profile-card__delete-button'}
            onClick={() =>
              props.onDeleteReport(props?.report?.headerId as number)
            }
          />
        )}
      </div>
    </div>
  );
};

export default PersonReportCard;
