import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { ElementThemeUnion } from 'constants/routes';
import { ReactComponent as ImportIcon } from 'images/newIcons/import.svg';
import { IMediaFile } from 'interfaces';

import PreviewMediaFiles from 'components/PreviewMediaFiles';
import { getMediaFiles, isVideo } from 'utils';

interface IDropzoneProps {
  files: IMediaFile[];
  mediaOrder?: number[];
  acceptedFormat?: string;
  placeholder?: string;
  withoutPreview?: boolean;
  theme?: ElementThemeUnion;
  classNames?: { body?: string; preview?: string; text?: string };
  onAdd: (file: IMediaFile[]) => void;
  onDelete: (file: IMediaFile) => void;
  onPreviewClick?: (files: IMediaFile[], mediaOrder?: number[]) => void;
  onMediaReorder?: (media: IMediaFile[]) => void;
}

const Dropzone: FC<IDropzoneProps> = ({
  files,
  mediaOrder,
  placeholder,
  withoutPreview,
  theme = 'light',
  classNames,
  onPreviewClick,
  onDelete,
  onAdd,
  onMediaReorder = () => {},
}) => {
  const [isPreview, setPreview] = useState(false);

  const previewList = useMemo(() => getMediaFiles(files), [files]);

  const title = placeholder ?? 'Загрузите изображения';

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (acceptedFiles?.length) {
        const mediaFiles: IMediaFile[] = acceptedFiles.map((file) => ({
          file: file,
          type: isVideo(file.name) ? 'video' : 'image',
          url: URL.createObjectURL(file),
        }));

        onAdd(mediaFiles);
      }
    },
    [onAdd]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handlePreviewClick = () => {
    if (onPreviewClick) {
      onPreviewClick(previewList, mediaOrder);
    }
  };

  const renderDropzoneBody = () => {
    const dropzoneTitle = isDragActive ? 'Перетащите файлы' : title;

    return isPreview ? (
      <PreviewMediaFiles
        files={previewList}
        mediaOrder={mediaOrder}
        isDelete
        isExpanded
        onClick={handlePreviewClick}
        onDelete={onDelete}
        showFullImage={false}
        visible={2}
        size="m"
        useDnD
        onMediaReorder={onMediaReorder}
        className={classNames?.preview}
      />
    ) : (
      <span className={cn('text-tpg_title', classNames?.text)}>
        {dropzoneTitle}
      </span>
    );
  };

  useEffect(() => {
    setPreview(!!previewList.length && !withoutPreview);
  }, [previewList]);

  return (
    <div
      className={cn(
        'w-full h-10 py-3 px-4 rounded-md cursor-pointer',
        {
          'bg-dark': theme === 'dark',
          'bg-light': theme === 'light',
        },
        classNames?.body
      )}
      style={isPreview ? { height: '98px' } : {}}
      {...getRootProps({
        onClick: (e) => (e.defaultPrevented ? null : open()),
      })}
    >
      <input {...getInputProps()} />
      <div className="flex flex-row justify-between tpg-input text-tpg_light">
        {renderDropzoneBody()}
        <div className="icon-container ">
          <ImportIcon />
        </div>
      </div>
    </div>
  );
};

export default memo(Dropzone);
