import { FC } from 'react';
import cn from 'classnames';
import { IMediaFile } from 'interfaces';

import { ObjectPreviewMedia } from './ObjectPreviewMedia';

type MediaOrDescription =
  | {
      media?: IMediaFile[];
      description: string;
    }
  | {
      media: IMediaFile[];
      description?: string;
    };

type IPreviewContentProps = MediaOrDescription & {
  setMediaGalleryOpen?: (payload: boolean) => void;
};

export const ObjectPreviewContent: FC<IPreviewContentProps> = ({
  media,
  setMediaGalleryOpen,
  description,
}) => (
  <div className="flex justify-start border-solid border-b border-t-0 border-tpg_light w-full">
    {!!media?.length && setMediaGalleryOpen && (
      <div
        className="border-[inherit] border-solid border-tpg_light border-r"
        style={{ width: description ? '370px' : '100%' }}
      >
        <ObjectPreviewMedia
          media={media}
          onMediaClick={() => setMediaGalleryOpen(true)}
        />
      </div>
    )}
    {description && (
      <div
        className={`border-[inherit] border-solid border-tpg_light ${
          media?.length ? 'w-1/2' : 'w-full'
        } h-[352px] max-h-[352px]`}
      >
        <div
          className={cn(
            'scrollbar_hidden p-3 whitespace-pre-line overflow-scroll line-clamp-[14] tpg-b2 text-tpg_title max-h-[inherit] break-words'
          )}
          style={{ width: media?.length ? '368px' : '100%' }}
        >
          {description}
        </div>
      </div>
    )}
  </div>
);
