import { useContext } from 'react';
import { MapContext } from 'contexts/map/MapContext';
import { MapRefContext } from 'contexts/map/MapRefContext';

export const useMap = () => {
  const mapContext = useContext(MapContext);

  if (typeof mapContext === 'undefined') {
    throw new Error('Hook should be called inside MapProvider');
  }

  return mapContext;
};

export const useMapRef = () => {
  const mapRefContext = useContext(MapRefContext);

  if (typeof mapRefContext === 'undefined') {
    throw new Error('Hook should be called inside MapRefProvider');
  }

  return mapRefContext;
};
