import { createSlice } from '@reduxjs/toolkit';
import { MAP_DRAW_MODES } from 'constants/mapControl';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { DrawState, SetDrawModeAction } from './types';

const initialState: DrawState = {
  drawMode: MAP_DRAW_MODES.simple_select,
};

const drawSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.DRAW,
  initialState: initialState,
  reducers: {
    setDrawMode(state, action: SetDrawModeAction) {
      state.drawMode = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: drawActions, reducer: drawReducer } = drawSlice;
