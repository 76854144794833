import { constants, lib, modes } from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';

import {
  CircleDrawnFeature,
  CommonDrawnFeature,
  DrawCustomModeOverride,
} from './types';
import { createSupplementaryPointsForCircle } from './utils';

const DirectSelectModeOverride = Object.assign(
  {},
  modes.direct_select as DrawCustomModeOverride
);

const { createSupplementaryPoints, moveFeatures, constrainFeatureMovement } =
  lib;

// based on https://github.com/iamanvesh/mapbox-gl-draw-circle
DirectSelectModeOverride.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  this.getSelected()
    .filter((feature) => feature?.properties?.subtype === 'Circle')
    .map((circle) => circle?.properties?.center)
    .forEach((center) => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });
  state.dragMoveLocation = e.lngLat;
};

DirectSelectModeOverride.dragVertex = function (state, e, delta) {
  if (state.feature.properties.subtype === 'Circle') {
    const center = state.feature.properties.center;
    const movedVertex = [e.lngLat.lng, e.lngLat.lat];
    const radiusInM = distance(point(center), point(movedVertex), {
      units: 'meters',
    });
    const radiusInKm = radiusInM / 1000;
    const circleFeature = circle(center, radiusInKm);

    state.feature.incomingCoords(circleFeature.geometry.coordinates);
    state.feature.properties.radiusInM = radiusInM;
  } else {
    const selectedCoords = state.selectedCoordPaths.map((coord_path: any) =>
      state.feature.getCoordinate(coord_path)
    );

    const selectedCoordPoints = selectedCoords.map((coords: any) => ({
      type: constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: constants.geojsonTypes.POINT,
        coordinates: coords,
      },
    }));

    const constrainedDelta = constrainFeatureMovement(
      selectedCoordPoints,
      delta
    );

    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(
        state.selectedCoordPaths[i],
        coord[0] + constrainedDelta.lng,
        coord[1] + constrainedDelta.lat
      );
    }
  }
};

DirectSelectModeOverride.toDisplayFeatures = function (
  state,
  drawnFeature: CommonDrawnFeature | CircleDrawnFeature,
  display
) {
  if (state.featureId === drawnFeature.properties.id) {
    const supplementaryPointsOptions: any = {
      map: this.map,
      midpoints: true,
      selectedPaths: state.selectedCoordPaths,
    };

    const supplementaryPoints =
      drawnFeature.properties.user_subtype === 'Circle'
        ? createSupplementaryPointsForCircle(drawnFeature)
        : createSupplementaryPoints(drawnFeature, supplementaryPointsOptions);

    drawnFeature.properties.active = constants.activeStates.ACTIVE;

    display(drawnFeature);
    supplementaryPoints?.forEach(display);
  } else {
    drawnFeature.properties.active = constants.activeStates.INACTIVE;
    display(drawnFeature);
  }

  this.fireActionable(state);
};

export { DirectSelectModeOverride };
