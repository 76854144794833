import { useEffect, useRef, useState } from 'react';
import { Layer, MapboxGeoJSONFeature, Source, useMap } from 'react-map-gl';
import { gcFrontlineLayer, gcPlacemarksLayer } from 'configs/map/common';
import { GEOCONFIRMED_OBJECT_DETAILS_POPUP } from 'constants/modals';
import { ASTRA_COLORS } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { mapActions } from 'store';
import { getAllGCObjectsThunk } from 'store/slices/map/actions';
import {
  gcObjectsSelector,
  mapTypesSelector,
  openedPopupSelector,
  operationalRangeSelector,
} from 'store/slices/map/selectors';

import { formatDate } from 'utils';

import { GeoconfirmedObjectPreviewPopup } from './GeoconfirmedObjectPreviewPopup';

import './style.scss';

export const GeoconfirmedObjectsSource = () => {
  const mapRef = useMap();
  const mapTypes = useAppSelector(mapTypesSelector);
  const objects = useAppSelector(gcObjectsSelector);
  const dateRange = useAppSelector(operationalRangeSelector);
  const activePopup = useAppSelector(openedPopupSelector);

  const [popupPlacemark, setPopupPlacemark] =
    useState<MapboxGeoJSONFeature | null>(null);

  const loadedIcons = useRef(new Set<string>());

  const dispatch = useAppDispatch();

  const map = mapRef.current;

  const [rangeStart, rangeEnd] = dateRange;
  const isGeoconfirmedObjectPreviewPopupOpen = !!(
    activePopup === GEOCONFIRMED_OBJECT_DETAILS_POPUP && popupPlacemark
  );

  const loadIcon = (iconUrl: string) => {
    loadedIcons.current.add(iconUrl);

    map?.loadImage(iconUrl, (error, result) => {
      if (error || !result) {
        loadedIcons.current.delete(iconUrl);
      } else if (!map.hasImage(iconUrl)) {
        map.addImage(iconUrl, result);
      }
    });
  };

  const onGeoconfirmedObjectPreviewClose = () => {
    dispatch(mapActions.setOpenedPopup(null));
    setPopupPlacemark(null);
  };

  useEffect(() => {
    if (!map) return;

    map.on('click', gcPlacemarksLayer.id, (e) => {
      if (e.features && e.features.length > 0) {
        const feature = e.features[0];

        e.preventDefault();
        setPopupPlacemark(feature);
        dispatch(mapActions.setOpenedPopup(GEOCONFIRMED_OBJECT_DETAILS_POPUP));
      }
    });
  }, [map, dispatch]);

  useEffect(() => {
    if (!rangeStart || !rangeEnd) return;

    dispatch(
      getAllGCObjectsThunk({
        fdate: formatDate(rangeStart),
        tdate: formatDate(rangeEnd),
      })
    );
  }, [rangeStart, rangeEnd, dispatch]);

  useEffect(() => {
    if (!map) return;

    objects.placemarks?.features.map((feature) => {
      const iconUrl = feature.properties?.icon_url;

      iconUrl && !loadedIcons.current.has(iconUrl) && loadIcon(iconUrl);
    });
  }, [map, objects]);

  return (
    <>
      {mapTypes.gcPlacemarks.active && objects.placemarks && (
        <Source type="geojson" data={objects.placemarks}>
          <Layer {...gcPlacemarksLayer} />
        </Source>
      )}
      {mapTypes.gcFrontline.active && objects.frontline_min && (
        <Source type="geojson" data={objects.frontline_min}>
          <Layer
            {...gcFrontlineLayer('#702020')}
            id={'gc-frontline-lower'}
            beforeId={'gc-frontline-upper'}
          />
        </Source>
      )}
      {mapTypes.gcFrontline.active && objects.frontline_max && (
        <Source type="geojson" data={objects.frontline_max}>
          <Layer
            {...gcFrontlineLayer(ASTRA_COLORS.ERROR)}
            id={'gc-frontline-upper'}
            // mapbox fails to render a layer if it contains beforeId of non-existent layer
            beforeId={
              mapTypes.gcPlacemarks.active ? 'gc-placemarks' : undefined
            }
          />
        </Source>
      )}
      {isGeoconfirmedObjectPreviewPopupOpen && (
        <GeoconfirmedObjectPreviewPopup
          // required to make force render as modal partially reactive here for some reasons
          key={Date.now()}
          object={popupPlacemark}
          onClose={onGeoconfirmedObjectPreviewClose}
        />
      )}
    </>
  );
};
