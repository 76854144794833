import { FC, memo, useEffect } from 'react';
import { LayerProps } from 'react-map-gl';
import { DEFAULT_FLY_TO_SETTINGS, GEOCODER_FEATURE } from 'constants/map';
import { ASTRA_COLORS } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { GeocoderFeature } from 'interfaces/geocoder';
import { geocoderActions } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice';
import { geocoderResultSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice/selectors';

import { CustomMarker } from 'components/ui';
import { lngLatFromCoords } from 'utils';

import { PolygonSource } from '../PolygonSource';

interface GeocoderResultMarkerProps {
  geocoderResult: GeocoderFeature;
}

const mainFillConfig: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': 'transparent',
  },
};

const mainLineConfig: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#053C92',
    'line-width': 1,
  },
};

const GeocoderResultFeature: FC<GeocoderResultMarkerProps> = memo(
  ({ geocoderResult }) => {
    const dispatch = useAppDispatch();

    const handleClearGeocoderResult = () =>
      dispatch(geocoderActions.setGeocoderResult(null));

    useEscapeDown(handleClearGeocoderResult);

    if (
      geocoderResult?.subtype === 'place' ||
      geocoderResult?.subtype === 'coordinates'
    ) {
      const [lng, lat] = geocoderResult.geometry.coordinates;

      return (
        <CustomMarker
          longitude={lng}
          latitude={lat}
          onClick={handleClearGeocoderResult}
          onContextMenu={handleClearGeocoderResult}
          color={ASTRA_COLORS.MAIN_PRODUCT}
        />
      );
    }

    if (geocoderResult?.subtype === 'square') {
      return (
        <PolygonSource
          id={GEOCODER_FEATURE}
          data={{
            type: 'FeatureCollection' as const,
            features: [geocoderResult.properties.feature],
          }}
          mainFillConfig={mainFillConfig}
          mainLineConfig={mainLineConfig}
        />
      );
    }

    return null;
  }
);

GeocoderResultFeature.displayName = 'GeocoderResultFeature';

export const GeocoderSource = () => {
  const { mapRef } = useMapRef();
  const geocoderResult = useAppSelector(geocoderResultSelector);

  useEffect(() => {
    mapRef.current?.flyTo({
      ...DEFAULT_FLY_TO_SETTINGS,
      center: geocoderResult
        ? lngLatFromCoords(
            geocoderResult.geometry.coordinates as [number, number]
          )
        : undefined,
      zoom: 16,
    });
  }, [geocoderResult]);

  if (!geocoderResult) {
    return null;
  }

  return <GeocoderResultFeature geocoderResult={geocoderResult} />;
};
