import { FC, ReactElement, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppSelector, usePermission } from 'hooks';
import { IImageryObject } from 'interfaces';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';
import { TSetState } from 'types';

import { Loader } from 'components/ui';
import ClosableTooltip from 'components/ui/ClosableTooltip';

import ImageryItem from './ImageryItem';

import './style.scss';

interface IInfiniteImageryListProps {
  setPage: TSetState<number>;
  showOnlyActive: boolean;
  getTooltipOverlay: (imagery: IImageryObject) => JSX.Element;
}

const InfiniteImageryList: FC<IInfiniteImageryListProps> = ({
  setPage,
  showOnlyActive,
  getTooltipOverlay,
}) => {
  const { objects, pending, total } = useAppSelector(imagerySelector);
  const hasDeletePermission = usePermission('delete-imagery');

  const loadMoreItems = useCallback(() => {
    if (pending) return;

    setPage((page) => ++page);
  }, [pending, setPage]);

  const wrapInTooltip = (
    imagery: IImageryObject,
    index: number,
    element: ReactElement
  ) => (
    <ClosableTooltip
      overlay={getTooltipOverlay(imagery)}
      key={`imagery-body__items__row-${index}`}
    >
      {element}
    </ClosableTooltip>
  );

  const getImageryRow = (value: IImageryObject, index: number) => {
    return (
      <ImageryItem
        key={`imagery-body__items__row-${index}`}
        value={value}
        showOnlyActive={showOnlyActive}
      />
    );
  };

  const getDeletableImageryRow = (value: IImageryObject, index: number) =>
    wrapInTooltip(value, index, getImageryRow(value, index));

  const renderImagery = (objects: IImageryObject[]) =>
    objects.length
      ? objects.map((item) =>
          hasDeletePermission
            ? getDeletableImageryRow(item, item.id)
            : getImageryRow(item, item.id)
        )
      : null;

  return (
    <InfiniteScroll
      className="px-4 gap-3 flex flex-col overflow-auto"
      pageStart={0}
      initialLoad={false}
      loadMore={loadMoreItems}
      hasMore={total > objects.length && !pending}
      useWindow={false}
      threshold={1}
      loader={pending ? <Loader /> : <></>}
    >
      {renderImagery(objects)}
    </InfiniteScroll>
  );
};

export default InfiniteImageryList;
