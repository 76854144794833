import { IStaticFilters } from 'interfaces';
import { TMediaUnion, TPublicationUnion, TSourceUnion } from 'types';

export const dateOptionsList = [
  {
    label: 'Последняя неделя',
    value: 1,
  },
  {
    label: 'Последний месяц',
    value: 2,
  },
  {
    label: 'Последние 3 месяца',
    value: 3,
  },
];

export const sourceMedia: IStaticFilters<TSourceUnion>[] = [
  {
    label: 'Telegram',
    value: 'telegram',
    checked: false,
  },
  // {
  //   label: 'Twitter',
  //   value: 'twitter',
  //   checked: false,
  // },
  // {
  //   label: 'Whatsapp',
  //   value: 'whatsapp',
  //   checked: false,
  // },
  // {
  //   label: 'Facebook',
  //   value: 'facebook',
  //   checked: false,
  // },
  {
    label: 'Tiktok',
    value: 'tiktok',
    checked: false,
  },
  // {
  //   label: 'Instagram',
  //   value: 'instagram',
  //   checked: false,
  // },
];

export const mediaType: IStaticFilters<TMediaUnion>[] = [
  {
    label: 'С медиа',
    value: 'media',
    checked: false,
  },
  {
    label: 'Только текст',
    value: 'text',
    checked: false,
  },
];

export const postType: IStaticFilters<TPublicationUnion>[] = [
  {
    label: 'Сообщение в чате',
    value: 'chat',
    checked: false,
  },
  {
    label: 'Публикация в канале',
    value: 'channel',
    checked: false,
  },
  {
    label: 'Публикация в профиле',
    value: 'profile',
    checked: false,
  },
];

export const DEFAULT_SEARCH_STEP = 20;
