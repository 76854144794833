import { useCallback, useEffect, useRef } from 'react';
import { KeyDown } from 'services/listeners/keyDown';

export type TCallback = (...args: any) => void;

export const useKeyDown = (
  listener: KeyDown,
  callback: TCallback,
  target = true
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const id = listener.subscribe(() => target && callbackRef.current());

    if (!target) {
      listener.unsubscribe(id);
    }

    return () => listener.unsubscribe(id);
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
};

export const useKeyDownControlled = (
  listener: KeyDown,
  callback: TCallback
) => {
  const idRef = useRef<number | null>(null);

  const subscribe = useCallback(() => {
    const id = listener.subscribe(callback);
    idRef.current = id;
  }, [callback]);

  const unsubscribe = useCallback(() => {
    listener.unsubscribe(idRef.current);
    idRef.current = null;
  }, []);

  return [subscribe, unsubscribe];
};
