import { InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';

import './style.scss';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

export default function TextInputWithLabel({
  label,
  id,
  placeholder,
  onChange,
  ...rest
}: InputProps) {
  const [value, setValue] = useState(rest.defaultValue || rest.value || '');
  const inputValue = rest.value || value;
  return (
    <div className={classNames('text-input-container', rest.className)}>
      <input
        id={id}
        {...rest}
        value={inputValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
          onChange && onChange(e);
        }}
        className={'input'}
      />
      <label className={inputValue ? 'active' : ''} htmlFor={id}>
        {label || placeholder}
      </label>
    </div>
  );
}
