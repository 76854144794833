import dayjs from 'dayjs';

import {
  PersonReport,
  PersonReportBody1,
  PersonReportBodySegment,
  PersonReportMedia,
} from '../../interfaces/reports';
import { generateRandomString } from '../../utils';
import * as base64utils from '../../utils/helpers/base64';

import { PersonReportCardModel } from './PersonReportCard/PersonReportCard';
import { EditorPersonReportRelation } from './PersonReportRelationSelect/PersonReportRelationSelect';

const EMPTY_REPORT: PersonReportCardModel = {
  addresses: [],
  propertyAddresses: [],
  autos: [],
  documents: [],
  emails: [],
  generalConclusions: '',
  name: '',
  other: '',
  phones: [],
  position: '',
  socialNetworks: [],
  ordering: [],
  media: {},
  versionMedia: {},
  relations: [],
  customTextBlocks: [],
  additionalInfoContent: '',
};

const makeReportCardFromAPIRepresentation: (
  r: PersonReport
) => PersonReportCardModel = (r: PersonReport) => {
  if (r.body.bodyVersion === '1') {
    const firstPhotoFromBody = r.body.versionMedia.mainPicMediaId
      ? r.media[r.body.versionMedia.mainPicMediaId]
      : undefined;
    const rep: PersonReportCardModel = {
      ...r.body,
      birthDate: dayjs(r.body.birthDate),
      photo: firstPhotoFromBody
        ? {
            url: firstPhotoFromBody,
            type: 'image',
          }
        : undefined,
      versionId: r.version_id,
      headerId: r.header_id,
      name: r.name,
      media: r.media,
      relations: [], // will be additionally loaded and set later
    };
    return rep;
  } else {
    return { ...EMPTY_REPORT, versionId: r.version_id, headerId: r.header_id }; // allow at least version history to be loaded
  }
};
const makePersonReportFromCard: (
  c: PersonReportCardModel
) => Promise<PersonReport> = async (c: PersonReportCardModel) => {
  const mediaToUploadBase64 =
    c.photo?.url && c.photo.url.startsWith('blob:') // is fresh local file
      ? await base64utils
          .getBase64FromUrl(c.photo?.url)
          .then((base64Raw) => base64Raw.split(',')[1])
      : undefined;
  const mediaToUpload: PersonReportMedia = {};
  // add random because new mediaId may be derived from not latest report version and backend restricts using same id twice
  const mainPicMediaId =
    'mainPic' +
      (c.versionId || 0) +
      '_' +
      generateRandomString(6) +
      '.' +
      c.photo?.file?.name.split('.').pop() || 'jpg';
  if (mediaToUploadBase64) mediaToUpload[mainPicMediaId] = mediaToUploadBase64;
  const body: PersonReportBody1 = {
    addresses: c.addresses,
    autos: c.autos,
    documents: c.documents,
    emails: c.emails,
    generalConclusions: c.generalConclusions,
    ordering: c.ordering,
    other: c.other,
    phones: c.phones,
    position: c.position,
    propertyAddresses: c.propertyAddresses,
    socialNetworks: c.socialNetworks,
    birthDate: c.birthDate?.toJSON(),
    bodyVersion: '1',
    birthPlace: c.birthPlace,
    versionMedia: mediaToUploadBase64
      ? {
          mainPicMediaId,
        }
      : c.versionMedia,
    customTextBlocks: c.customTextBlocks,
    additionalInfoContent: c.additionalInfoContent,
  };
  return {
    version_id: c.versionId || 0,
    header_id: c.headerId || 0,
    body,
    media: Object.keys(mediaToUpload).length !== 0 ? {} : c?.media || {},
    media_to_upload: mediaToUpload,
    name: c.name,
  };
};

function getSegmentDisplayName(
  segment: PersonReportBodySegment,
  reportRelations: EditorPersonReportRelation[]
): string {
  if (segment.type === 'aboutSegment') {
    return 'О персоне';
  } else if (segment.type === 'documentsSegment') {
    return 'Документы';
  } else if (segment.type === 'linkedReport') {
    const found = reportRelations.find(
      (rr) => rr.toHeaderId === segment.headerId
    );
    if (found) {
      return `${found.title}: ${found.headerReportName}`;
    } else return ``;
  } else if (segment.type === 'customTextBlock') {
    return `${segment.title}`;
  } else return '';
}

export const ReportCardHelpers = {
  EMPTY_REPORT,
  makeReportCardFromAPIRepresentation,
  makePersonReportFromCard,
  getSegmentDisplayName,
};
