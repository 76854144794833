import { forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';
import { ReactComponent as Play } from 'images/icons/play.svg';
import { IMediaFile } from 'interfaces';

interface IMediaGallerySlideProps extends HTMLAttributes<HTMLDivElement> {
  mediaFile?: IMediaFile;
  size: number;
  isSelected: boolean;
}

export const MediaGallerySlide = forwardRef<
  HTMLDivElement,
  IMediaGallerySlideProps
>(({ mediaFile, size, isSelected, style, ...props }, ref) => {
  const isImage = !mediaFile?.type || mediaFile?.type === 'image';
  const isVideo = mediaFile?.type === 'video';

  return (
    <div
      ref={ref}
      className={cn(
        'shrink-0 bg-dark rounded-[5px] cursor-pointer [&>*]:rounded-[inherit]',
        isSelected &&
          'border-[5px] border-solid border-bright_product [&>*]:rounded-[unset]'
      )}
      style={{ width: size, height: size, ...style }}
      {...props}
    >
      {isImage && (
        <img
          src={mediaFile?.url}
          alt="Media gallery slide"
          className="w-full h-full overflow-hidden object-cover"
        />
      )}
      {isVideo && (
        <div className="w-full h-full flex justify-center items-center">
          <Play className="[&_path]:fill-white" />
        </div>
      )}
    </div>
  );
});

MediaGallerySlide.displayName = 'MediaGallerySlide';
