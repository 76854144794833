import { useCallback, useMemo, useState } from 'react';
import { uploadImagery } from 'api/map';
import {
  DATE_FORMAT,
  imageTypeOptions,
  imageUploadTypes,
  MAP_LAYER_NAME_LIMIT,
} from 'constants/map';
import { IMAGERY_UPLOAD_MODAL } from 'constants/modals';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks';
import { IMediaFile } from 'interfaces';
import { modalsActions } from 'store/slices/service/modalsSlice';

import { Button, DatePicker, Modal, Select, TextInput } from 'components/ui';
import Dropzone from 'components/ui/Dropzone';
import { notify } from 'utils';

const ImageryUploadModal = () => {
  const dispatch = useAppDispatch();
  const [imageName, setImageName] = useState<string>();
  const [imageDate, setImageDate] = useState<Date>();
  const [imageType, setImageType] = useState(imageUploadTypes.PNG);
  const [isLoading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<IMediaFile[]>([]);

  const handleClose = () => {
    dispatch(modalsActions.removeModal(IMAGERY_UPLOAD_MODAL));
  };

  const formatImageDate = (value: Date) => dayjs(value).format(DATE_FORMAT);

  const clearInputs = () => {
    [setImageName, setImageDate].forEach((fn) => fn(undefined));
    setSelectedFiles([]);
  };

  const handleUpload = useCallback(async () => {
    const formData = new FormData();
    selectedFiles
      .filter((value) => value.file)
      .forEach((value) => formData.append('file', value.file as File));

    setLoading(true);
    await uploadImagery({
      formData,
      upload_type: imageType,
      name: imageName,
      explicit_date: imageDate && formatImageDate(imageDate),
    })
      .then(() => {
        notify.success(
          'Снимки загружены на сервер и отобразятся на платформе через 5-10 минут',
          { pauseOnHover: true }
        );
        clearInputs();
      })
      .catch(() => notify.error('Некорретный формат снимка'))
      .finally(() => setLoading(false));
  }, [imageType, imageName, imageDate, selectedFiles]);

  const isUploadAllowed = useMemo(
    () => (imageDate || imageType !== 'tif') && selectedFiles.length > 0,
    [imageDate, imageType, selectedFiles]
  );

  const showTifDateWarning = useMemo(
    () => !imageDate && imageType === 'tif',
    [imageType, imageDate]
  );

  const handleAddFiles = (files: IMediaFile[]) =>
    setSelectedFiles((prevState) => [...prevState, ...files]);

  const handleDeleteFile = (f: IMediaFile) =>
    setSelectedFiles((prevState) =>
      prevState.filter((value) => value.url !== f.url)
    );

  const selectedFilesSection = useMemo(
    () =>
      selectedFiles.length > 0 ? (
        <>
          <p className="imagery-upload__options-name">Выбранные файлы:</p>
          {selectedFiles.map((value, index) => (
            <p
              className="imagery-upload__options-filename"
              key={`selected-file-name-${index}`}
              onClick={() => handleDeleteFile(value)}
            >
              {value.file?.name}
            </p>
          ))}
        </>
      ) : undefined,
    [selectedFiles]
  );

  const isBatchMode = useMemo(
    () => imageType === imageUploadTypes.BATCH,
    [imageType]
  );

  return (
    <Modal width={472} keyboard onClose={handleClose} isBlurred={true}>
      <div className="astra-modal">
        <h3 className="astra-modal__title">{'Загрузить снимки'}</h3>
        <div className="astra-modal__content">
          <div className="astra-modal__options">
            <p className="astra-modal__options-name">Название</p>
            <TextInput
              readOnly={isBatchMode}
              value={imageName ?? ''}
              placeholder="Будет использовано вместо имени файла"
              autoFocus
              maxLength={MAP_LAYER_NAME_LIMIT}
              onChange={setImageName}
              classNames={{ container: 'w-full' }}
            />
          </div>
          <div className="astra-modal__options">
            <p className="astra-modal__options-name">Дата снимка</p>
            {showTifDateWarning && (
              <p className="astra-modal__options-warning">
                Для формата .tif необходимо указать дату
              </p>
            )}
            <DatePicker
              readOnly={isBatchMode}
              selectsRange={false}
              selected={imageDate}
              onChange={(value) => setImageDate(value ?? undefined)}
            />
          </div>
          <div className="astra-modal__options">
            <p className="astra-modal__options-name">Формат изображения</p>
            <Select
              placeholder="Выберите формат"
              withEmpty={false}
              value={imageType}
              options={imageTypeOptions}
              onSelect={setImageType}
              theme="light"
            />
          </div>
          <div className="astra-modal__options">
            <Dropzone
              files={[]}
              onAdd={handleAddFiles}
              onDelete={() => null} // onDelete can be used on click by preview object, so we ignore it
              acceptedFormat={'.tif,.json,.png'}
            />
            {selectedFilesSection}
          </div>
        </div>
        <div className="astra-modal__actions">
          <Button
            className="py-2 px-8 w-full mt-4"
            disabled={!isUploadAllowed}
            isLoading={isLoading}
            title="Загрузить"
            onClick={handleUpload}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageryUploadModal;
