import { IStaticFilters } from 'interfaces';

import { Checkbox } from 'components/ui';
import { genericMemo } from 'utils';

interface IFilterProps<T> {
  title?: string;
  array: IStaticFilters<T>[];
  onChange: (value: T) => void;
}

const Filter = <T extends string>({
  title = '',
  array,
  onChange,
}: IFilterProps<T>) => (
  <div className="sidebar__filter">
    {title && (
      <h2 className="tpg-title-four colored-title subtitle">{title}</h2>
    )}
    <div className="sidebar__filter_list">
      {array.map(({ label, checked, value }, index) => (
        <Checkbox
          key={`source-${index}`}
          label={label}
          isChecked={checked}
          onChange={() => onChange(value)}
        />
      ))}
    </div>
  </div>
);

export default genericMemo(Filter);
