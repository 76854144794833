import { FC, memo } from 'react';

import './style.scss';

export interface ITableSkeletonProps {
  rowSize?: number;
  columnSize?: number;
}

const TableSkeleton: FC<ITableSkeletonProps> = ({
  rowSize = 3,
  columnSize = 6,
}) => (
  <tr className="table-skeleton">
    {[...Array(rowSize)].map((r, idx) => (
      <td className="table-skeleton__row" key={`skeleton-row-${idx + 1}`}>
        {[...Array(columnSize)].map((c, i) => (
          <div key={`coll-${i + 1}`} className="table-skeleton__row__coll">
            <span className="skeleton" />
          </div>
        ))}
      </td>
    ))}
  </tr>
);

export default memo(TableSkeleton);
