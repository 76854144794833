import { renderFigure, renderLegendElement } from '../index';

const InfrastructureInfo = () => (
  <div className="flex flex-row justify-between">
    {renderLegendElement(
      'Силовые линии (КВТ)',
      <div className="info__block tpg-c2">
        {renderFigure(<>&lt; 10 </>, '#7a7a85')}
        {renderFigure(<>&ge; 10 &lt; 35 </>, '#b7cbdc')}
        {renderFigure(<>35 </>, '#55b555')}
        {renderFigure(<>110 </>, '#b59f10')}
        {renderFigure(<>150 </>, '#b55d00')}
        {renderFigure(<>220 </>, '#c73030')}
        {renderFigure(<>330 </>, '#b54eb2')}
        {renderFigure(<>750 </>, '#00c1cf')}
        {renderFigure(<>&gt; 750 </>, '#4e01b5')}
      </div>
    )}
    {renderLegendElement(
      'Топливо',
      <div className="info__block tpg-c2">
        {renderFigure(<>Нефть</>, '#6b6b6b')}
        {renderFigure(<>Газ</>, '#bfbc6b')}
        {renderFigure(<>Топливо</>, '#cc9f83')}
        {renderFigure(<>Водородное топливо</>, '#cc78ab')}
        {renderFigure(<>CO2</>, '#7885cc')}
        {renderFigure(<>Другое</>, '#bababa')}
      </div>
    )}

    {renderLegendElement(
      'Вода',
      <div className="info__block tpg-c2">
        {renderFigure(<>Пресная вода</>, '#7b7cba')}
        {renderFigure(<>Горячая вода</>, '#ad4c4c')}
        {renderFigure(<>Пар</>, '#7bbaac')}
        {renderFigure(<>Сточные воды</>, '#baa87b')}
      </div>,
      true
    )}
  </div>
);
export default InfrastructureInfo;
