import { ReactComponent as DocumentIcon } from 'images/newIcons/documentText.svg';
import { ReactComponent as ArrowIcon } from 'images/newIcons/down.svg';
import { ReactComponent as DirectoryIcon } from 'images/newIcons/folder.svg';
import { DocumentNode } from 'types/documents';

export const renderNodeArrow = (node: DocumentNode) =>
  node.data.isFolder ? (
    <div className="[&>svg>path]:fill-tpg_base group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
      <ArrowIcon />
    </div>
  ) : null;

export const renderNodeIcon = (node: DocumentNode) => (
  <div className="[&>svg>path]:fill-tpg_base group-[.empty:not(.selected)]:[&>svg>path]:fill-tpg_light group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
    {node.data.isFolder ? <DirectoryIcon /> : <DocumentIcon />}
  </div>
);

export const renderNodeName = (node: DocumentNode) => (
  <span className="text-tpg_base group-[.selected]:text-[inherit] group-hover:text-[inherit]">
    {node.data.name}
  </span>
);
