import { combineReducers } from '@reduxjs/toolkit';

import { contextMenuReducer } from './contextMenuSlice';
import { settingsReducer } from './settingsSlice';
import { toolsReducer } from './toolsReducer';

export const mapReducer = combineReducers({
  settings: settingsReducer,
  contextMenu: contextMenuReducer,
  tools: toolsReducer,
});
