import { FC } from 'react';
import cn from 'classnames';

export interface EntityParamGroupTabsProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

export const EntityParamGroupTabs: FC<EntityParamGroupTabsProps> = ({
  tabs,
  activeTab,
  onTabChange,
}) => (
  <div className="flex w-full gap-2 px-4 py-2 bg-light">
    {tabs.map((tab) => (
      <span
        key={tab}
        className={cn(
          'cursor-pointer tpg-c2 text-tpg_base hover:text-bright_product',
          tab === activeTab && '!text-bright_product'
        )}
        onClick={() => onTabChange(tab)}
      >
        {tab}
      </span>
    ))}
  </div>
);
