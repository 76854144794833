export const clearStringFromMarkdown = (markdown: string) =>
  markdown
    .replace(/(\*\*|__)(.*?)\1/g, '$2') // Bold
    .replace(/(\*|_)(.*?)\1/g, '$2') // Italic
    .replace(/(~~)(.*?)\1/g, '$2') // Strikethrough
    .replace(/`(.*?)`/g, '$1') // Inline code
    .replace(/```[\s\S]*?```/g, '') // Code block
    .replace(/!\[.*?\]\(.*?\)/g, '') // Images
    .replace(/\[.*?\]\(.*?\)/g, '') // Links
    .replace(/^\s{0,3}>\s?/g, '') // Blockquotes
    .replace(/^-{3,}/g, '') // Horizontal rules
    .replace(/^(\*|\+|-)\s+/gm, '') // Unordered lists
    .replace(/^\d+\.\s+/gm, '') // Ordered lists
    .replace(/(#{1,6})\s*(.*?)\s*#*\s*$/gm, '$2') // Headers
    .replace(/\n{2,}/g, '\n\n') // Multiple newlines
    .replace(/\\(.)/g, '$1') // Backslash escapes
    .trim();
