import React, { FC, memo } from 'react';
import cn from 'classnames';

import './style.scss';

interface ISegmentedProps {
  options: string[];
  active: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}

const Segmented: FC<ISegmentedProps> = ({
  options,
  active,
  onChange,
  style = {},
}) => {
  const handleClick = (value: string) => () => {
    onChange(value);
  };

  return (
    <div className="segmented tpg-b2" style={style}>
      {options.map((option, index) => (
        <div
          className={cn('segmented__option', {
            active: active === option,
            'first-option': index === 0,
            'last-option': index === options.length - 1,
          })}
          onClick={handleClick(option)}
          key={`segment-${index}`}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default memo(Segmented);
