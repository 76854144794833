import { createSlice } from '@reduxjs/toolkit';

import { GEOSPOOF_REDUCER_NAMES } from '../constants';

import {
  SearchState,
  SetAreaCoordsAction,
  SetGroupsAction,
  SetObjectCoordsAction,
  SetProgressAction,
  SetUsersAction,
} from './types';

const initialState: SearchState = {
  areaCoords: null,
  objectCoords: null,
  progress: 0,
  groups: [],
  users: [],
};

const searchSlice = createSlice({
  name: GEOSPOOF_REDUCER_NAMES.SEARCH,
  initialState: initialState,
  reducers: {
    setAreaCoords(state, action: SetAreaCoordsAction) {
      const { areaCoords } = action.payload;

      state.areaCoords = areaCoords;
    },
    setObjectCoords(state, action: SetObjectCoordsAction) {
      const { objectCoords } = action.payload;

      state.objectCoords = objectCoords;
    },
    setProgress(state, action: SetProgressAction) {
      const { progress } = action.payload;

      state.progress = progress;
    },
    setGroups(state, action: SetGroupsAction) {
      const { groups } = action.payload;

      state.groups = groups;
    },
    setUsers(state, action: SetUsersAction) {
      const { users } = action.payload;

      state.users = users;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: searchActions, reducer: searchReducer } = searchSlice;
