import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { GeocoderState, SetGeocoderResultAction } from './types';

const initialState: GeocoderState = {
  geocoderResult: null,
};

const geocoderSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.GEOCODER,
  initialState: initialState,
  reducers: {
    setGeocoderResult(state, action: SetGeocoderResultAction) {
      state.geocoderResult = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: geocoderActions, reducer: geocoderReducer } =
  geocoderSlice;
