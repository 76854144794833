import { useAppSelector } from 'hooks';
import { IGeometry } from 'interfaces';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { Entity, EntityParameter, ParameterValue } from 'types/entities';

import {
  TITLE_KEY,
  TTableEntityRow,
} from 'components/ui/EntityCard/TableEntityCard';

const mockGeometry: IGeometry = {
  type: 'Point',
  coordinates: [12, 12],
};

export const prepareParamValueMapFromRows = (
  entityParamRows: TTableEntityRow[],
  entityParameters: EntityParameter[]
) => {
  return entityParamRows.reduce((acc, row) => {
    const processedParameter = entityParameters.find(
      (parameter) => parameter.title === row.key
    );
    if (processedParameter) {
      const paramId = processedParameter.id;

      // const paramValue = row.value; TODO replace mocked geometry parameter
      const paramValue =
        paramId === 1 ? mockGeometry : paramId === 7 ? [] : row.value;

      acc[paramId] = { value: paramValue };
    }
    return acc;
  }, {} as Record<string, ParameterValue>);
};

export const prepareUpsertEntityReqFromRows = (
  tableEntityRows: TTableEntityRow[],
  entityParameters: EntityParameter[],
  entityId?: number
): Entity => {
  const entityTitle =
    tableEntityRows.find((el) => el.key === TITLE_KEY)?.value || '';
  const entityParamRows = tableEntityRows.filter((el) => el.key !== TITLE_KEY);
  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, 'mapObject')
    ) || 0;

  const paramIdValueMap: Record<string, ParameterValue> =
    prepareParamValueMapFromRows(entityParamRows, entityParameters);

  return {
    id: entityId || 0,
    title: entityTitle,
    templateID: mapObjectTemplateId,
    createdBy: {},
    parameters: paramIdValueMap,
  };
};
