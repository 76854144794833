import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import PersonReportCard, {
  PersonReportCardModel,
} from 'components/PersonReport/PersonReportCard/PersonReportCard';

import {
  deleteReport,
  getPersonReport,
  updateReportName,
  updateReportRelations,
  uploadReportVersion,
} from '../../api/reports';
import { PersonReportPreview } from '../../components/PersonReport/PersonReportPreview';
import { ReportCardHelpers } from '../../components/PersonReport/reportCardHelpers';
import { appRoutes } from '../../constants/routes';
import { notify } from '../../utils';

import './style.scss';

const PersonReportPage = () => {
  const { reportId, versionId } = useParams<{
    reportId: string;
    versionId: string;
  }>();
  const navigate = useNavigate();

  const [report, setReport] = useState<PersonReportCardModel | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const reloadData = async (reportId: number, lastVersionId: number) => {
    setReport(undefined); // this will re-mount the card component and fill with new data when it comes back
    const report = await getPersonReport(reportId, lastVersionId);
    const reportCardMapped =
      ReportCardHelpers.makeReportCardFromAPIRepresentation(report);
    setReport(reportCardMapped);
  };

  useEffect(() => {
    if (reportId && versionId && reportId !== 'new') {
      reloadData(+reportId, +versionId).catch((e) => {
        console.log(e);
        notify.error(
          `Не удалось загрузить версию ${versionId} отчета ${reportId}`
        );
      });
    } else {
      setReport(ReportCardHelpers.EMPTY_REPORT);
    }
  }, [reportId, versionId]);

  const onSaveReport: (c: PersonReportCardModel) => Promise<void> = async (
    c: PersonReportCardModel
  ) => {
    const reportForUpload = await ReportCardHelpers.makePersonReportFromCard(c);

    if (reportForUpload.header_id > 0)
      await updateReportName(reportForUpload.header_id, c.name);
    const recentReportVersion = await uploadReportVersion(reportForUpload);
    const nonEmptyRelations = c.relations.flatMap((rel) =>
      rel.toHeaderId && rel.title
        ? [{ to: rel.toHeaderId, title: rel.title }]
        : []
    );
    await updateReportRelations(
      recentReportVersion.header_id,
      nonEmptyRelations
    );

    setReport(undefined);
    navigate(
      appRoutes.REPORT +
        `/${recentReportVersion.header_id}/version/${recentReportVersion.version_id}`
    );
    reloadData(recentReportVersion.header_id, recentReportVersion.version_id);
  };
  const onDeleteReport = async (headerId: number) => {
    await deleteReport(headerId);
    navigate(appRoutes.REPORTS);
  };
  const setReportAndStopLoading = useDebouncedCallback(
    (c: PersonReportCardModel) => {
      setReport(c);
      setIsLoading(false);
    },
    500
  );
  const onReportModelChanged = (c: PersonReportCardModel) => {
    setIsLoading(true);
    setReportAndStopLoading(c);
  };

  return (
    <section className="person-reports">
      {report && (
        <PersonReportCard
          report={report}
          onSaveReport={onSaveReport}
          onDeleteReport={onDeleteReport}
          setReport={onReportModelChanged}
        />
      )}

      <PersonReportPreview reportCard={report} isLoading={isLoading} />
    </section>
  );
};

export default PersonReportPage;
