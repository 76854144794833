import { convertCoordinates } from 'api/converter';
import { convertValidationErrors } from 'constants/errors';
import {
  INTEGER_REGEX,
  SK42_SQUARE_FULL_LENGTH,
  SK42_SQUARE_FULL_REGEX,
  SK42_SQUARE_SHORT_REGEX,
} from 'constants/map';
import { Position } from 'geojson';
import {
  CoordinateSystem,
  ICoordinates,
  Sk42ConverterResponse,
  XYHCoordinates,
} from 'interfaces';

import {
  calculateFeatureCenter,
  createFeature,
  getCoordinatesObject,
  getCoordinatesTuple,
  notify,
  replaceAt,
} from 'utils';

import {
  Coordinates,
  PossibleCoordinateEntriesFormat,
  PossibleCoordinatesEntriesCheck,
  PossibleSquare,
  PossibleSquareOption,
  SK42Square,
} from '../types';

import { getGeocoderFeature } from './common';

const getPossibleSquareValues = (
  coordinateSystem: CoordinateSystem,
  squareString: string,
  format: PossibleCoordinateEntriesFormat
): PossibleSquare | null => {
  switch (coordinateSystem) {
    case 'sk42': {
      const [squareCoordinatesString] = squareString.match(INTEGER_REGEX) ?? [
        '0000',
      ];

      const square = squareCoordinatesString
        .split('')
        .slice(0, SK42_SQUARE_FULL_LENGTH)
        .map((char) => +char) as SK42Square;

      return {
        coordinateSystem,
        square,
        format,
      };
    }
    default:
      return null;
  }
};

const getSK42SquarePosition = (
  coordinates: XYHCoordinates,
  square: SK42Square,
  format?: PossibleCoordinateEntriesFormat
): Position[] => {
  const { x, y } = coordinates;
  const stringX = String(x);
  const stringY = String(y);
  // Y can be 7 or 8 digits length
  const shouldCorrectY = !(stringY.length % 2);

  if (format === 'short') {
    const squareX = square.slice(0, 2).join('');
    const squareY = square.slice(2, 4).join('');

    const xBase = replaceAt(stringX, 2, squareX).slice(0, 4);
    const yBase = replaceAt(stringY, 2, squareY).slice(0, 4);
    const xMin = +`${xBase}000`;
    const xMax = +`${xBase}999`;
    const yMin = +`${yBase}${shouldCorrectY ? '0' : ''}000`;
    const yMax = +`${yBase}${shouldCorrectY ? '0' : ''}999`;

    return [
      [xMin, yMin],
      [xMax, yMin],
      [xMax, yMax],
      [xMin, yMax],
      [xMin, yMin],
    ];
  }

  if (format === 'full') {
    const squareX = square.slice(0, 3).join('');
    const squareY = square.slice(3, 6).join('');

    const xBase = replaceAt(stringX, 2, squareX).slice(0, 5);
    const yBase = replaceAt(stringY, 2, squareY).slice(0, 5);
    const xMin = +`${xBase}00`;
    const xMax = +`${xBase}99`;
    const yMin = +`${yBase}${shouldCorrectY ? '0' : ''}00`;
    const yMax = +`${yBase}${shouldCorrectY ? '0' : ''}99`;

    return [
      [xMin, yMin],
      [xMax, yMin],
      [xMax, yMax],
      [xMin, yMax],
      [xMin, yMin],
    ];
  }

  return [];
};

export const getPossibleSquares = (value?: string | null) => {
  if (!value) {
    return [];
  }

  const possibleSquares: PossibleSquare[] = [];
  const coordinateChecks: PossibleCoordinatesEntriesCheck[] = [
    {
      name: 'sk42',
      shortFormat: SK42_SQUARE_SHORT_REGEX,
      fullFormat: SK42_SQUARE_FULL_REGEX,
    },
  ];

  coordinateChecks.forEach((check) => {
    const isMatchedShortFormat = check.shortFormat?.test?.(value);
    const isMatchedFullFormat = check.fullFormat.test(value);
    const matchedFormat =
      (isMatchedShortFormat && 'short') ||
      (isMatchedFullFormat && 'full') ||
      null;

    if (matchedFormat) {
      const possibleSquareValues = getPossibleSquareValues(
        check.name,
        value,
        matchedFormat
      );

      possibleSquareValues && possibleSquares.push(possibleSquareValues);
    }
  });

  return possibleSquares;
};

// use reversed [lng, lat] coordinates order for mapbox & geocoder
const getSquareOption = async (
  possibleSquare: PossibleSquare,
  currentCoords: ICoordinates,
  currentCoordsMode: CoordinateSystem
): Promise<PossibleSquareOption | null> => {
  try {
    switch (possibleSquare.coordinateSystem) {
      case 'sk42': {
        const sk42Coordinates = await convertCoordinates<Sk42ConverterResponse>(
          currentCoordsMode,
          'sk42',
          currentCoords
        );

        const SK42SquarePosition = getSK42SquarePosition(
          sk42Coordinates.payload,
          possibleSquare.square,
          possibleSquare.format
        );

        const wgsSquarePosition = await Promise.all(
          SK42SquarePosition.map((point) =>
            convertCoordinates(
              'sk42',
              'wgs',
              getCoordinatesObject(point, 'sk42')
            )
          )
        );

        const coordinates = calculateFeatureCenter(
          createFeature(
            {
              type: 'Polygon',
              coordinates: [
                wgsSquarePosition.map(
                  (point) =>
                    getCoordinatesTuple(
                      point.payload,
                      'wgs',
                      'latLng'
                    ) as Position
                ),
              ],
            },
            {}
          )
        ) as Coordinates;

        const squarePolygonFeature = createFeature(
          {
            type: 'Polygon',
            coordinates: [
              wgsSquarePosition.map(
                (point) =>
                  getCoordinatesTuple(
                    point.payload,
                    'wgs',
                    'lngLat'
                  ) as Position
              ),
            ],
          },
          {}
        );

        return {
          id: possibleSquare.coordinateSystem,
          label: 'Перейти к квадрату по СК-42 координате',
          value: getGeocoderFeature(
            'square',
            coordinates,
            { feature: squarePolygonFeature },
            'lngLat'
          ),
        };
      }
      default:
        return null;
    }
  } catch (e) {
    notify.error(convertValidationErrors.GET_RESULT_ERROR);

    return null;
  }
};

export const getSquareOptions = async (
  possibleSquares: PossibleSquare[],
  currentCoords: ICoordinates,
  currentCoordsMode: CoordinateSystem
) => {
  try {
    const options: PossibleSquareOption[] = [];

    for (const possibleSquare of possibleSquares) {
      const option = await getSquareOption(
        possibleSquare,
        currentCoords,
        currentCoordsMode
      );

      if (option) {
        options.push(option);
      }
    }

    return options;
  } catch (e) {
    notify.error(convertValidationErrors.GET_RESULT_ERROR);

    return [];
  }
};
