import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { MeasurementState, SetConverterPositionAction } from './types';

const initialState: MeasurementState = {
  converterPosition: null,
};

const measurementSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.MEASUREMENT,
  initialState: initialState,
  reducers: {
    setConverterPosition(state, action: SetConverterPositionAction) {
      state.converterPosition = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: measurementActions, reducer: measurementReducer } =
  measurementSlice;
