import { modes } from '@mapbox/mapbox-gl-draw';
import { MapboxDrawCustomEvents } from 'constants/map';

const DrawLineStringModeOverride = Object.assign({}, modes.draw_line_string);

// events miss featureTarget sometimes for some reasons, so we need to add it explicitly
const _onMouseMove = DrawLineStringModeOverride.onMouseMove;
const _onTap = DrawLineStringModeOverride.onTap;

DrawLineStringModeOverride.onMouseMove = function (state, e) {
  _onMouseMove?.apply?.(this, [state, e]);

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, {
    ...e,
    featureTarget,
  });
};

DrawLineStringModeOverride.onTap = function (state, e) {
  _onTap?.apply?.(this, [state, e]);

  const featureTarget = state.line.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.TAP, {
    ...e,
    featureTarget,
  });
};

export { DrawLineStringModeOverride };
