import { FC, useCallback } from 'react';
import { DrawCreateEvent } from '@mapbox/mapbox-gl-draw';
import cn from 'classnames';
import { mapDraw } from 'configs/map/instances/mapDraw';
import { Ammunitions } from 'constants/ammunition';
import { MapboxDrawEvents } from 'constants/map';
import {
  MAP_CREATE_MODE_PREFIX,
  MAP_DRAW_MODES,
  MAP_MEASURE_MODES,
} from 'constants/mapControl';
import GeoJSON from 'geojson';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { useMapDrawEventSubscription } from 'hooks/useMapDrawEventsSubscription';
import { showCustomCursorsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { artilleryActions } from 'store/slices/mapV2/mapReducer/toolsReducer/artillerySlice';
import { drawActions } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice';
import { drawModeSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { artillerySelector } from 'store/slices/mapV2/mapReducer/toolsReducer/selectors';

import { Select } from 'components/ui';
import { getListOfRangeInMeters } from 'utils';

import { DrawControls } from './DrawControls';
import { Geocoder } from './Geocoder';
import { useMapDraw } from './useMapDraw';

import './Controls.scss';

const artilleryOptions = Ammunitions.map((ammunition) => ({
  label: ammunition.name,
  value: ammunition.name,
}));

interface ControlsProps {
  onCreate: (geometry: GeoJSON.Geometry) => void;
  forceUpdate: () => void;
}

const Controls: FC<ControlsProps> = ({ onCreate, forceUpdate }) => {
  const mapRef = useMapRef();
  const drawMode = useAppSelector(drawModeSelector);
  const selectedArtillery = useAppSelector(artillerySelector);
  const showCustomCursors = useAppSelector(showCustomCursorsSelector);

  const dispatch = useAppDispatch();

  const isArtilleryMode = drawMode === MAP_MEASURE_MODES.measure_artillery;

  const handleChange = (item: string) => {
    if (item) {
      const artillery = Ammunitions.find((ammo) => ammo.name === item);

      if (artillery) {
        dispatch(artilleryActions.setName(artillery.name));
        dispatch(
          artilleryActions.setRange(
            getListOfRangeInMeters(artillery.bulletRange)
          )
        );
      }
    }
  };

  const handleControlToggle = useCallback(
    (prevMode: string, currentMode: string) => {
      mapDraw.deleteAll();

      const isControlEnabled = currentMode === prevMode;
      const drawMode = isControlEnabled
        ? MAP_DRAW_MODES.simple_select
        : currentMode;

      // mapDraw.changeMode does not fire draw.modechange event outside draw mode for some reasons
      mapRef.mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE);
      mapDraw.changeMode(drawMode);
      forceUpdate();
      dispatch(drawActions.setDrawMode(drawMode));
    },
    [dispatch]
  );

  const handleControlSumbit = () => {
    // switching to simple select triggers internal map draw.create event
    mapRef.mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE);
    mapDraw.changeMode(MAP_DRAW_MODES.simple_select);
  };

  const handleControlCancel = () => {
    mapRef.mapRef.current?.fire(MapboxDrawEvents.MODE_CHANGE);
    mapDraw.changeMode(MAP_DRAW_MODES.simple_select);
    mapDraw.deleteAll();
    dispatch(drawActions.setDrawMode(MAP_DRAW_MODES.simple_select));
  };

  const handleDrawCreate = useCallback(
    (e: DrawCreateEvent) => {
      drawMode.startsWith(MAP_CREATE_MODE_PREFIX) &&
        onCreate(e.features[0].geometry);
    },
    [onCreate, drawMode]
  );

  useMapDraw();
  useMapDrawEventSubscription({
    onCreate: handleDrawCreate,
  });

  return (
    <>
      <Geocoder position="bottom-left" />
      <DrawControls
        measureMode={drawMode}
        onToggle={handleControlToggle}
        onSubmit={handleControlSumbit}
        onCancel={handleControlCancel}
      />
      {isArtilleryMode && (
        <div className="w-full h-full flex justify-center items-end">
          <div className="artillery-dropdown">
            <Select
              classNames={{
                options: cn({
                  '[&>li>div]:!cursor-custom-default': showCustomCursors,
                }),
                body: cn({ 'cursor-custom-default': showCustomCursors }),
              }}
              withSearch={true}
              options={artilleryOptions}
              value={selectedArtillery.name || null}
              onSelect={(el) => handleChange(el || '')}
              theme="light"
              menuPlacement="top"
              withEmpty={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Controls;
