import { AccessRuleCode } from 'constants/entities';

import { EntityNode } from './types';

export const renderNodePrefix = (node: EntityNode) => {
  const isReadonly = node.data.entity.accessType <= AccessRuleCode.READONLY;

  if (isReadonly) {
    return <span className="text-tpg_light">&nbsp;чтение</span>;
  }

  return null;
};
