import { FC } from 'react';

import {
  IObjectPreviewTableRow,
  ObjectPreviewTable,
} from './ObjectPreviewTable';

interface IPreviewDatesProps {
  rows: IObjectPreviewTableRow[];
  firstCellWidth?: string;
}

export const ObjectPreviewHorizontalTable: FC<IPreviewDatesProps> = ({
  rows,
  firstCellWidth,
}) => (
  <div className="flex flex-row justify-center">
    {rows.map((row, idx) => {
      return (
        <ObjectPreviewTable
          key={idx}
          rows={[row]}
          firstCellWidth={firstCellWidth}
        />
      );
    })}
  </div>
);
