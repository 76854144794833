import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { IFeatureGlobalFilters, ISortableField } from '../interfaces';
import { IReportsTableRequest } from '../interfaces/reports';
import { TReportSortUnion } from '../types/report';

export const useReportFilters = <
  T extends TReportSortUnion,
  S extends { id?: string | number }[]
>(
  list: S,
  pending: boolean
) => {
  const [filters, setFilters] = useState({} as IFeatureGlobalFilters);
  const [sortable, setSortable] = useState<ISortableField<T> | null>(null);
  const [search, setSearch] = useState('');

  const reportsLength = list.length;

  const setSearchValue = useDebouncedCallback(() => {
    setFilters((filters) => ({ ...filters, keyword: search }));
  }, 300);

  useEffect(() => {
    setSearchValue();
  }, [search]);

  const onSetSortField = (field: T) => {
    if (pending) return;

    let values = {
      field,
      direction: 'asc',
    } as ISortableField<T> | null;

    if (sortable?.field === field) {
      if (sortable.direction === 'desc') {
        values = null;
      } else {
        (values as ISortableField<T>).direction = 'desc';
      }
    }

    setSortable(values);
  };

  const reportParams = useMemo(() => {
    const params = {
      start: 0,
      size: 40,
      sort_by: 'last_version_timestamp',
      direction: 'desc',
    } as IReportsTableRequest<T>;

    if (filters.keyword) {
      params.keyword = filters.keyword;
    }

    if (sortable) {
      params['sort_by'] = sortable.field;
      params['direction'] = sortable.direction;
    }

    return params;
  }, [sortable]);

  return {
    sortable,
    onSetSortField,
    search,
    setSearch,
    reportParams,
    reportsLength,
  };
};
