import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import { useMap } from 'hooks/map';
import { ReactComponent as Chevron } from 'images/newIcons/down.svg';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { MAP_LAYERS_NAMES } from '../../../constants/mapTypes';
import { useAppSelector } from '../../../hooks';

import { DemMapInfo, ElevationInfo, InfrastructureInfo } from './LayerInfo';

import './style.scss';

const LAYERS_WITH_LEGEND = [
  { title: 'Карта высот 2.0', value: 'demMap' },
  { title: 'Слой инфраструктуры', value: 'infrastructure' },
  { title: 'Карта высот', value: 'elevation' },
];

export const renderLegendElement = (
  title: string,
  content: ReactElement,
  last = false
) => {
  return (
    <div
      className={cn('legend__element p-6', {
        'legend__element__with-border': !last,
      })}
    >
      <div className="tpg-c2 text-tpg_base pb-2">{title}</div>
      {content}
    </div>
  );
};

export const renderFigure = (
  title: ReactElement,
  color: string,
  figureClassname = 'figure__line'
) => {
  return (
    <div className="flex flex-row justify-start items-center">
      <div className={figureClassname} style={{ background: color }} />
      <div className="tpg-c2">{title}</div>
    </div>
  );
};

export const Legend = () => {
  const { isLegend } = useMap();
  const mapTypes = useAppSelector(typesSelector);
  const [isHide, setHide] = useState(false);

  const layersTitle = () => {
    const activeLayers = Object.keys(mapTypes).filter(
      (key) => mapTypes[key].active
    );
    const titles = LAYERS_WITH_LEGEND.filter((layerWithLegend) =>
      activeLayers.includes(layerWithLegend.value)
    ).map((el) => el.title);
    return titles.join(', ');
  };

  const showInfrastructure = mapTypes.infrastructure.active;
  const showElevation = mapTypes.elevation.active;
  const showDemMap = mapTypes.demMap.active;

  if (!isLegend || !(showInfrastructure || showElevation || showDemMap)) {
    return null;
  }

  return (
    <div className="legend rounded-md">
      <div className="legend__content">
        <div className="flex flex-col">
          {!isHide && (
            <div className="flex flex-row">
              {mapTypes[MAP_LAYERS_NAMES.demMap].active && <DemMapInfo />}
              {mapTypes[MAP_LAYERS_NAMES.elevation].active && <ElevationInfo />}
              {mapTypes[MAP_LAYERS_NAMES.infrastructure].active && (
                <InfrastructureInfo />
              )}
            </div>
          )}
          <div
            className="flex flex-row justify-between px-6 py-3 tpg-c1 cursor-pointer legend__title"
            onClick={() => setHide(!isHide)}
          >
            {layersTitle()}
            <Chevron
              className={cn('legend__chevron icon-container', {
                legend__chevron_up: isHide,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
