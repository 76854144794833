import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { deleteUser, getUserList } from 'api/access';
import { errorMessages, successMessages } from 'constants/errors';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import { PublicUserInfo } from 'interfaces/profile';

import AstraTable from 'components/ui/Table/AstraTable';
import { AstraTableHeadCell } from 'components/ui/Table/AstraTableHeader';
import { handleNotify } from 'utils/notifications';

import TableContextMenu, {
  ContextMenuState,
  MenuItemProps,
} from '../../ui/Table/TableContextMenu';
import UserDetailsModal, {
  IUserEditModalProps,
} from '../Modals/UserDetailsModal';

const USER_TABLE_HEAD_CELL: AstraTableHeadCell<any>[] = [
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Позывной',
  },
  {
    id: 'team',
    numeric: false,
    disablePadding: false,
    label: 'Системная роль',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Логин',
  },
];

interface IUserTableProps {
  searchQuery: string;
}

const UserTable: FC<IUserTableProps> = ({ searchQuery }) => {
  const [contextMenu, setContextMenu] = useState<ContextMenuState>();
  const [userDetailsModalProps, setUserDetailsModalProps] =
    useState<IUserEditModalProps>();

  const [reload, setReload] = useState(false);
  const fetchRows = useMemo(
    () => async () => {
      const response = await getUserList();
      return {
        rows: response,
        total: response.length,
      };
    },
    [reload]
  );

  const triggerReload = useCallback(() => {
    setReload((prev) => !prev);
  }, []);

  const handleContextMenu = (
    event: React.MouseEvent,
    row: any,
    rowId: string
  ) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      row,
      rowId,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu(undefined);
  };

  const handleEditRow = () => {
    if (!contextMenu) return;
    const currentUser = contextMenu.row as PublicUserInfo;
    setUserDetailsModalProps({
      onClose: () => {
        setUserDetailsModalProps(undefined);
      },
      onSuccess: () => {
        triggerReload();
      },
      user: currentUser,
    });
    handleContextMenuClose();
  };

  const handleDeleteRow = async () => {
    contextMenu?.row &&
      (await handleNotify(
        deleteUser(contextMenu.row.id),
        successMessages.USER_DELETE_SUCCESS,
        errorMessages.USER_DELETE_ERROR
      ));
    handleContextMenuClose();
  };

  const getRoleTitle = (title: string) => {
    switch (title) {
      case 'superadmin':
        return 'Суперадмин';
      case 'admin':
        return 'Админ';
      case 'user':
        return 'Пользователь';
    }
  };

  const renderRowCells: (row: PublicUserInfo) => ReactNode = (row) => {
    return (
      <>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {row.verboseName}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {getRoleTitle(row.sysRole)}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {row.username}
          </div>
        </TableCell>
      </>
    );
  };

  const contextMenuItems: MenuItemProps[] = [
    { title: 'Редактировать', onClick: handleEditRow },
    { title: 'Деактивировать', onClick: handleDeleteRow },
  ];

  const handleAddUser = () => {
    setUserDetailsModalProps({
      onClose: () => {
        setUserDetailsModalProps(undefined);
      },
      onSuccess: () => {
        triggerReload();
      },
    });
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-between tpg-h4 pb-4">
        <div>Пользователи</div>
        <Plus
          className="icon-container cursor-pointer"
          onClick={handleAddUser}
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <TableContainer
          style={{ maxHeight: '80vh', overflowY: 'auto', width: 'full' }}
        >
          <AstraTable
            searchQuery={searchQuery}
            handleContextMenuClick={(event, row, rowId) =>
              handleContextMenu(event, row, `${rowId}`)
            }
            fetchRows={fetchRows}
            rowIdExtractor={(row) => row.id}
            renderRowCells={renderRowCells}
            headCells={USER_TABLE_HEAD_CELL}
          />
        </TableContainer>
        {userDetailsModalProps && (
          <UserDetailsModal {...userDetailsModalProps} />
        )}
        <TableContextMenu
          contextMenu={contextMenu}
          onClose={handleContextMenuClose}
          menuItems={contextMenuItems}
        />
      </div>
    </div>
  );
};

export default UserTable;
