import { FC, useEffect, useMemo } from 'react';
import { ControlPosition } from 'react-map-gl';
import { MAPBOX_CTRL_PREFIX } from 'constants/mapControl';
import { useCustomControl } from 'hooks/useCustomControl';
import { useEnterDownControlled } from 'hooks/useEnterDown';
import { useEscapeDownControlled } from 'hooks/useEscapeDown';

import { MapControl } from './MapControl';

interface MapControlToggleProps {
  id: string;
  isActive: boolean;
  position: ControlPosition;
  className: string;
  activeClassName?: string;
  onToggle: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const keyDownFallback = () => null;

export const MapControlToggle: FC<MapControlToggleProps> = ({
  id,
  isActive,
  position,
  className,
  activeClassName,
  onToggle,
  onSubmit,
  onCancel,
}) => {
  const [subscribeToEscapeDown, unsubscribeFromEscapeDown] =
    useEscapeDownControlled(onCancel ?? keyDownFallback);

  const [subscribeToEnterDown, unsubscribeFromEnterDown] =
    useEnterDownControlled(onSubmit ?? keyDownFallback);

  const mapToggleControl = useMemo(
    () =>
      new MapControl({
        id,
        className,
        eventHandler: onToggle,
      }),
    [onToggle]
  );

  const handleCancel = () => {
    if (!onCancel) {
      return;
    }

    if (isActive) {
      subscribeToEscapeDown();
    } else {
      unsubscribeFromEscapeDown();
    }
  };

  const handleSubmit = () => {
    if (!onSubmit) {
      return;
    }

    if (isActive) {
      subscribeToEnterDown();
    } else {
      unsubscribeFromEnterDown();
    }
  };

  const setToggleActiveAppearance = () => {
    const control = document.getElementById(`${MAPBOX_CTRL_PREFIX}-${id}`);

    control?.classList?.[isActive ? 'add' : 'remove']?.(
      activeClassName || 'active'
    );
  };

  useCustomControl(mapToggleControl, position);

  useEffect(handleSubmit, [handleSubmit]);
  useEffect(handleCancel, [handleCancel]);
  useEffect(setToggleActiveAppearance, [setToggleActiveAppearance]);

  return null;
};
