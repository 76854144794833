import { FC } from 'react';
import cn from 'classnames';
import { ITag } from 'interfaces';

import { Tag } from 'components/ui';

import './style.scss';

export type TDrawableTag = Omit<ITag, 'path'> & {
  onRemove: () => void;
  title: string;
};

type TagContainerProps = {
  tagsRef?: React.RefObject<HTMLDivElement>;
  tags: TDrawableTag[];
  className?: string;
  tagClassName?: string;
};

const TagContainer: FC<TagContainerProps> = ({
  tags,
  className,
  tagClassName,
  tagsRef,
}) => {
  const renderTag = (tag: TDrawableTag, idx: number) => (
    <Tag
      label={tag.label}
      title={tag.title}
      key={`tag-${tag.label}${idx}`}
      onRemove={() => tag.onRemove()}
      className={tagClassName}
    />
  );

  return (
    <div ref={tagsRef} className={cn(`tags-container`, className)}>
      {tags.length > 0 && (
        <div className={cn('tags-container__list')}>{tags.map(renderTag)}</div>
      )}
    </div>
  );
};

export default TagContainer;
