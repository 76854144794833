import { useEffect } from 'react';
import {
  DrawCreateEvent,
  DrawModeChangeEvent,
  MapMouseEvent,
  MapTouchEvent,
} from '@mapbox/mapbox-gl-draw';
import { MapboxDrawCustomEvents, MapboxDrawEvents } from 'constants/map';

import { useMapRef } from './map';

interface UseMapboxDrawEventSubscriptionProps {
  onCreate?: (e: DrawCreateEvent) => void;
  onModeChange?: (e: DrawModeChangeEvent) => void;
  onMouseMove?: (e: MapMouseEvent) => void;
  onTap?: (e: MapTouchEvent) => void;
}

// useControl and MapboxDraw are not reactive so we need to subscribe to event with useEffect
export const useMapDrawEventSubscription = ({
  onCreate,
  onModeChange,
  onMouseMove,
  onTap,
}: UseMapboxDrawEventSubscriptionProps) => {
  const { mapRef } = useMapRef();

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onCreate && map.on(MapboxDrawEvents.CREATE, onCreate);

      return () => {
        onCreate && map.off(MapboxDrawEvents.CREATE, onCreate);
      };
    }
  }, [onCreate]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onModeChange && map.on(MapboxDrawEvents.MODE_CHANGE, onModeChange);

      return () => {
        onModeChange && map.off(MapboxDrawEvents.MODE_CHANGE, onModeChange);
      };
    }
  }, [onModeChange]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onMouseMove && map.on(MapboxDrawCustomEvents.MOUSE_MOVE, onMouseMove);

      return () => {
        onMouseMove && map.off(MapboxDrawCustomEvents.MOUSE_MOVE, onMouseMove);
      };
    }
  }, [onMouseMove]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      onTap && map.on(MapboxDrawCustomEvents.TAP, onTap);

      return () => {
        onTap && map.off(MapboxDrawCustomEvents.TAP, onTap);
      };
    }
  }, [onTap]);
};
