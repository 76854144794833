import React, { FC, ReactElement } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { OpacitySlider } from 'components/OpacitySlider';
import { Switch } from 'components/ui';

export interface IMapElementProps {
  index?: number;
  active: boolean;
  title: string;
  description: string;
  name: string;
  onSwitch: () => void;
  icon?: ReactElement;
  preview?: string;
  opacitySlider?: boolean;
}

const MapTypeElement: FC<IMapElementProps> = ({
  index,
  active,
  title,
  description,
  icon,
  preview,
  onSwitch,
  opacitySlider,
  name,
}) => {
  const dispatch = useAppDispatch();
  const mapTypes = useAppSelector(typesSelector);

  const handleOpacityChange = (opacity: number) => {
    const changedType = mapTypes[name];
    dispatch(
      settingsActions.setTypes({
        ...mapTypes,
        [name]: {
          ...changedType,
          opacity: opacity,
        },
      })
    );
  };

  return (
    <div>
      <div
        key={`map-element-item__items__row-${index}`}
        className="flex flex-row w-full h-12 mb-3"
      >
        {preview && (
          <img
            src={preview}
            className="map-element-item__preview"
            alt="image"
          />
        )}
        {icon && <div className="map-element-item__round absolute">{icon}</div>}
        <div
          className={cn('flex flex-col justify-center items-start w-full', {
            'ml-16': icon,
          })}
        >
          <div className="flex flex-row justify-between w-full h-5 items-center">
            <p className="tpg-b2">{title}</p>
            <Switch checked={active} onChange={onSwitch} />
          </div>
          {active && opacitySlider ? (
            <OpacitySlider opacityCallback={handleOpacityChange} />
          ) : (
            <div>
              <p className="tpg-c2 text-tpg_base h-5 pt-1">{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapTypeElement;
