import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { drawStyles } from '../common';
import { DirectSelectModeOverride } from '../drawModes/directSelectModeOverride';
import { DrawCircleMode } from '../drawModes/drawCircleMode';
import { DrawLineStringModeOverride } from '../drawModes/drawLineStringModeOverride';
import { DrawPolygonModeOverride } from '../drawModes/drawPolygonModeOverride';
import { SimpleSelectModeOverride } from '../drawModes/simpleSelectModeOverride';

export const mapDraw = new MapboxDraw({
  displayControlsDefault: false,
  userProperties: true,
  styles: drawStyles,
  keybindings: false,
  modes: {
    ...MapboxDraw.modes,
    simple_select: SimpleSelectModeOverride,
    direct_select: DirectSelectModeOverride,
    create_line_string: MapboxDraw.modes.draw_line_string,
    create_polygon: MapboxDraw.modes.draw_polygon,
    measure_line_string: DrawLineStringModeOverride,
    measure_polygon: DrawPolygonModeOverride,
    measure_artillery: MapboxDraw.modes.simple_select,
    measure_circle: DrawCircleMode,
  },
});
