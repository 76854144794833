import {
  addBookmark,
  getBookmarkIds,
  getFilters,
  getParsersRequest,
  searchPosts,
} from 'api/monitoring';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  IFilters,
  IGetFiltersResponse,
  ISearchRequest,
  ISearchResponse,
} from 'interfaces';
import {
  TDynamicFilterAction,
  TDynamicFilterCategory,
  TDynamicFilterItem,
} from 'types';

import { createThunk, isLastCategory } from 'utils';

import { deleteBookmark } from '../../../api/monitoring';

export const getFiltersThunk = createThunk<IGetFiltersResponse>(
  `${reducersNames.MONITORING}/${asyncActionsNames.GET_FILTERS}`,
  getFilters,
  errorMessages.GET_FILTERS_ERROR
);

export const searchPostsThunk = createThunk<ISearchResponse, ISearchRequest>(
  `${reducersNames.MONITORING}/${asyncActionsNames.SEARCH_POSTS}`,
  searchPosts,
  errorMessages.SEARCH_POSTS_ERROR
);

export const getParsersThunk = createThunk<string[]>(
  `${reducersNames.MONITORING}/${asyncActionsNames.GET_PARSERS}`,
  getParsersRequest,
  errorMessages.GET_PARSERS_ERROR
);

export const getBookmarkIdsThunk = createThunk<string[]>(
  `${reducersNames.MONITORING}/${asyncActionsNames.GET_BOOKMARK_IDS}`,
  getBookmarkIds,
  errorMessages.GET_BOOKMARK_IDS_ERROR
);

export const addBookmarkThunk = createThunk<null, string>(
  `${reducersNames.MONITORING}/${asyncActionsNames.ADD_BOOKMARK}`,
  addBookmark,
  errorMessages.ADD_BOOKMARK_ERROR
);

export const deleteBookmarkThunk = createThunk<null, string>(
  `${reducersNames.MONITORING}/${asyncActionsNames.DELETE_BOOKMARK}`,
  deleteBookmark,
  errorMessages.DELETE_BOOKMARK_ERROR
);

export const getDynamicFilters = (
  filters: IFilters = {},
  isRepead = false
): IFilters => {
  const state: IFilters = {};

  for (const key in filters) {
    let filter: IFilters = {
      label: filters[key],
    };

    if (typeof filters[key] === 'object') {
      filter = getDynamicFilters(filters[key] as IFilters, true);
    }

    if (isRepead) {
      filter.active = false;
    }

    state[key] = filter;
  }

  return state;
};

const setDynamicSubFilters = (category: IFilters, value: boolean) => {
  for (const key in category) {
    if (key !== 'active') {
      (category[key] as TDynamicFilterItem).active = value;
    }

    if (typeof category[key] === 'object') {
      const subCategory = category[key] as unknown as Record<
        string,
        TDynamicFilterItem
      >;

      if (isLastCategory(subCategory)) {
        for (const item in subCategory) {
          if (item !== 'active') {
            subCategory[item].active = value;
          }
        }
      } else {
        if (typeof subCategory.label === 'string') {
          (subCategory as unknown as TDynamicFilterItem).active = value;
        } else {
          setDynamicSubFilters(subCategory, value);
        }
      }
    }
  }
};

export const setDynamicFilters = ({
  obj,
  path,
  value,
  isSection,
}: Required<TDynamicFilterAction>): IFilters => {
  const [head, ...rest] = path.split('.');
  const category = obj[head] as TDynamicFilterCategory;

  if (isSection && !rest.length) {
    setDynamicSubFilters(category, value);
  }

  return {
    ...obj,
    [head]: rest.length
      ? setDynamicFilters({
          obj: category,
          path: rest.join('.'),
          value,
          isSection,
        })
      : { ...category, active: value },
  };
};
