import MapboxClient from '@mapbox/mapbox-sdk';
import MapboxGeocoder, {
  GeocodeRequest,
  GeocodeService,
} from '@mapbox/mapbox-sdk/services/geocoding';
import { MAP_BOX_TOKEN, MAPBOX_API } from 'constants/map';
import { Map } from 'mapbox-gl';

export interface GeocoderProps {
  map: Map | null;
  request?: GeocoderRequest;
}

export type GeocoderRequest = Omit<GeocodeRequest, 'query'>;

export class Geocoder {
  private map: Map | null;
  private geocoder: GeocodeService;
  private request: GeocoderRequest;

  constructor({ map, request = {} }: GeocoderProps) {
    this.map = map;
    this.geocoder = MapboxGeocoder(
      MapboxClient({ origin: MAPBOX_API, accessToken: MAP_BOX_TOKEN })
    );
    // adding limit param explicitly may cause geocode API error for numeric tuples and coordinates-like queries
    this.request = { language: ['ru-RU'], ...request };
  }

  private setOption<T = GeocoderRequest | undefined>(
    key: keyof T,
    value: T[keyof T]
  ) {
    (this.request as T)[key] = value;
  }

  private _updateProximity() {
    if (this.map && this.map.getZoom() > 9) {
      const center = this.map.getCenter().wrap();

      this.setOption('proximity', [center.lng, center.lat]);
    } else {
      this.setOption('proximity', undefined);
    }
  }

  private updateProximity = this._updateProximity.bind(this);

  public onAdd() {
    this?.map?.on?.('moveend', this.updateProximity);
  }

  public onRemove() {
    this?.map?.off?.('moveend', this.updateProximity);
  }

  public async forwardGeocode(request: GeocodeRequest) {
    const res = await this.geocoder
      .forwardGeocode({
        ...this.request,
        ...request,
      })
      .send();

    return res;
  }

  public async reverseGeocode(request: GeocodeRequest) {
    const res = await this.geocoder
      .reverseGeocode({
        ...this.request,
        ...request,
      })
      .send();

    return res;
  }
}
