import { Layer, Source } from 'react-map-gl';
import { googleSatelliteSource } from 'configs/map/google';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity, getSafeBeforeId } from 'utils';

export const GoogleSource = () => {
  const mapTypes = useAppSelector(typesSelector);
  const { mapRef } = useMapRef();

  const showGoogle = mapTypes.googleSatelliteUpper.active;

  if (!showGoogle) {
    return null;
  }

  return (
    <Source {...googleSatelliteSource} id="google-satellite-upper-source">
      <Layer
        id="google-satellite-upper-layer"
        source="google-satellite-upper-source"
        beforeId={getSafeBeforeId(mapRef.current, 'google-streets-layer')}
        type="raster"
        paint={{
          'raster-opacity': getProperOpacity(mapTypes.googleSatelliteUpper),
        }}
      />
    </Source>
  );
};
