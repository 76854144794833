import { FC, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppDispatch, useAppSelector } from 'hooks';
import { monitoringActions } from 'store';
import {
  bookmarkIdsSelector,
  postListSelector,
  showOnlyBookmarksSelector,
  staticFiltersSelector,
} from 'store/slices/monitoring/selectors';
import { TSetState } from 'types';

import { Card, CardSkeleton } from 'components/ui';

import './style.scss';

interface IInfiniteListProps {
  setPage: TSetState<number>;
  searchValue: string;
}

const InfiniteList: FC<IInfiniteListProps> = ({ setPage, searchValue }) => {
  const dispatch = useAppDispatch();
  const { list, total, pending } = useAppSelector(postListSelector);
  const { searchMode } = useAppSelector(staticFiltersSelector);
  const showOnlyBookmarks = useAppSelector(showOnlyBookmarksSelector);
  const bookmarkIds = useAppSelector(bookmarkIdsSelector);

  const checkBookmark = useCallback(
    (id: string) => bookmarkIds.includes(id),
    [bookmarkIds]
  );

  const handleShowModal = useCallback((files: string[]) => {
    dispatch(monitoringActions.setMediaFiles({ files }));
  }, []);

  const loadMoreItems = useCallback(() => {
    if (pending) return;

    setPage((page) => ++page);
  }, [pending, setPage]);

  return (
    <div
      className={`infinite-list__container ${
        showOnlyBookmarks ? 'bookmarks' : ''
      }`}
    >
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={loadMoreItems}
        hasMore={!!(total - list.length)}
        useWindow={false}
        threshold={1000}
        loader={<CardSkeleton key="card-skeleton" />}
      >
        {list.length ? (
          list.map((item, index) => (
            <Card
              key={`card-${index}`}
              mode={searchMode}
              searchedText={searchValue}
              post={item}
              onShowModal={handleShowModal}
              bookmarked={checkBookmark}
            />
          ))
        ) : (
          <div className="infinite-list__container__empty">
            {!pending && 'Нет данных'}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteList;
