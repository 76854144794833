import { FC, useState } from 'react';
import cn from 'classnames';

import { Switch } from 'components/ui';

import { EntityNode } from './types';

type TOnRootFolderToggle = () => void;

interface IRootFolderCounterProps {
  node: EntityNode;
  onFolderToggle: TOnRootFolderToggle;
}

interface IFolderCounterProps {
  node: EntityNode;
}

export const RootFolderCounter: FC<IRootFolderCounterProps> = ({
  node,
  onFolderToggle,
}) => {
  const stats = node.data.info?.stats;
  const hasSelectedobjects = !!stats?.selectedObjectsCount;
  const isAllObjectsSelected =
    stats?.selectedObjectsCount === stats?.totalObjectsCount;
  const hideCounter = !hasSelectedobjects || isAllObjectsSelected;
  const disableToggle = !stats?.totalObjectsCount;
  const [checked, setChecked] = useState(!!isAllObjectsSelected);

  if (!node.data.isFolder) {
    return null;
  }

  // react-arborist removes and recreates DOM nodes right after data change so toggle animation is not possible.
  // The only way to provide animation is debounced data source update.
  const handleToggle = () => {
    setChecked(!checked);
    onFolderToggle();
  };

  return (
    <div className="flex items-center gap-2">
      <span className={cn('text-tpg_base tpg-c2', hideCounter && 'invisible')}>
        {stats?.selectedObjectsCount} НА КАРТЕ ИЗ {stats?.totalObjectsCount}
      </span>
      <Switch
        checked={checked}
        disabled={disableToggle}
        onChange={handleToggle}
      />
    </div>
  );
};

export const FolderCounter: FC<IFolderCounterProps> = ({ node }) => {
  if (!node.data.isFolder) {
    return null;
  }

  const isDefaultFolder =
    String(node.data.id) === localStorage.getItem('default_project');

  const stats = node.data.info?.stats;
  const hasSelectedobjects = !!stats?.selectedObjectsCount;
  const isAllObjectsSelected =
    stats?.selectedObjectsCount === stats?.totalObjectsCount;
  const hideCounter = !hasSelectedobjects || isAllObjectsSelected;

  if (hideCounter) {
    return isDefaultFolder ? (
      <span className="tpg-c1">по умолчанию</span>
    ) : null;
  }

  return (
    <span className="tpg-c1">
      {isDefaultFolder && 'по умолчанию '}
      {stats.selectedObjectsCount} из {stats.totalObjectsCount ?? '?'}
    </span>
  );
};

export const renderNodeCounter = (
  node: EntityNode,
  onRootFolderToggle: TOnRootFolderToggle
) => {
  const getFolderCounter = () =>
    FolderCounter({ node }) && <FolderCounter node={node} />;

  /* 
  TODO: temporary hidden until access sharing is ready
  const getRootFolderCounter = () =>
    RootFolderCounter({ node, onFolderToggle: onRootFolderToggle }) && (
      <RootFolderCounter node={node} onFolderToggle={onRootFolderToggle} />
    );

  return node.level ? getFolderCounter() : getRootFolderCounter(); 
  */

  return getFolderCounter();
};
