import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertValidationErrors } from 'constants/errors';
import { GEOSPOOF_TABS } from 'constants/geospoof';
import { SEARCH_VALUE_MAX_LIMIT } from 'constants/map';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  IGeospoofEntity,
  IGeospoofVisibleItem,
  IRequestIdEntityPictures,
} from 'interfaces';
import { mapActions } from 'store';
import {
  getGeospoofThunk,
  getLoadedUserLocationThunk,
  getPicturesUploadIdThunk,
  getSearchHistoryThunk,
  getSearchInfoThunk,
  getUserLocationThunk,
} from 'store/slices/map/actions';
import {
  geospoofSelector,
  mediaPreviewModeSelector,
} from 'store/slices/map/selectors';
import {
  TAvatarUnion,
  TGeospoofObjectKeysUnion,
  TGeospoofTabTitleUnion,
  TPosition,
} from 'types';

import { Loader, Segmented, TextInput } from 'components/ui';
import {
  generateRandomString,
  getCoordinatesFromDecimalString,
  getMediaFiles,
  notify,
} from 'utils';
import { formatWgsCoords, getFormattedDate } from 'utils';
import { isValidCoords } from 'utils/validations/map';
import { isNumber } from 'utils/validations/number';

import 'dayjs/locale/ru';

import { NOTHING_WAS_FOUND_MESSAGE } from '../../../../constants/routes';
import { MediaGalleryModal } from '../../../MediaGalleryModal';
import EntityFieldsGroup from '../../../ui/EntityCard/EntityFieldsGroup';
import ConfirmModal from '../../../ui/Modal/ConfirmModal';

import GeospoofCard from './GeospoofCard';

import './style.scss';

dayjs.extend(RelativeTime);
dayjs.locale('ru');

interface ISetCurrent {
  currentItem: IGeospoofEntity | null;
  open: boolean;
  itemId: number;
  mustSetActive?: boolean;
}

// Логика пагинации временно отключена

const MapGeospoof = () => {
  const dispatch = useAppDispatch();
  const {
    searchPosition,
    runExternalRequest,
    pending,
    objects,
    current,
    isHistoryLoading,
    isSearchInfoLoading,
    loadingUserLocation,
    objectCoordinates,
    currentTab,
  } = useAppSelector(geospoofSelector);
  const mediaPreviewMode = useAppSelector(mediaPreviewModeSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState('');
  const [isUserLocationPolling, setUserLocationPolling] = useState(true);
  const [coordinatesLoader, setCoordinatesLoader] = useState(false);
  const [currentList, setCurrentList] = useState<IGeospoofEntity[]>([]);
  const [activeSearchItemId, setActiveSearchItemId] = useState<number>();
  const [geospoofTimestamp, setGeospoofTimestamp] = useState<string>(
    dayjs().utcOffset(3).toString()
  );
  const [processedUser, setProcessedUser] = useState<IGeospoofEntity>();
  const [isTriangulationModal, setIsTriangulationModal] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const pageScrollRef = useRef<HTMLDivElement>(null);
  const searchId = objects.searchId || null;
  const locationHistory = objects.userLocationHistory;

  const formattedCoordinates = useMemo(() => {
    if (objectCoordinates) {
      return `WGS: ${formatWgsCoords(
        objectCoordinates[1],
        objectCoordinates[0]
      )}`;
    }
  }, [objectCoordinates]);

  const formattedTimestamp = useMemo(() => {
    if (geospoofTimestamp) {
      return getFormattedDate(geospoofTimestamp);
    }
  }, [geospoofTimestamp]);

  const avatarType: TAvatarUnion = useMemo(() => {
    switch (currentTab) {
      case 'users':
        return 'user';
      case 'groups':
        return 'group';
      case 'history':
        return 'search';
    }
  }, [currentTab]);

  const twoWayTabConverter = (
    tabSlug: TGeospoofTabTitleUnion | TGeospoofObjectKeysUnion
  ): string | null => {
    const tabTitles: TGeospoofObjectKeysUnion[] = [
      'users',
      'groups',
      'history',
    ];
    const tabKeys: TGeospoofTabTitleUnion[] = [
      'Пользователи',
      'Группы',
      'История',
    ];

    const findSlugPair = (keyCol: string[], valueCol: string[]) => {
      const possibleIndex = keyCol.findIndex((item) => item === tabSlug);
      return possibleIndex === -1 ? null : valueCol[possibleIndex];
    };

    return findSlugPair(tabTitles, tabKeys) || findSlugPair(tabKeys, tabTitles);
  };

  const visibleTabName = useMemo(
    () => twoWayTabConverter(currentTab) || 'Пользователи',
    [currentTab]
  );

  const currentObject = useMemo(
    () => current[currentTab],
    [current, currentTab]
  );

  const setSearchObjOnPageOpen = () => {
    if (objects.searchId && !searchPosition) {
      const targetHistoryObj = objects.history.find(
        (obj) => obj.search_id === objects.searchId
      );
      targetHistoryObj &&
        handleSetCurrent({
          currentItem: targetHistoryObj,
          open: false,
          itemId: objects.searchId,
          mustSetActive: true,
        })();
    }
  };

  useEffect(() => {
    setSearchObjOnPageOpen();
  }, [current, currentTab, currentList]);

  const scrollIntoCard = (itemId: string | number) => {
    document.getElementById(`geospoof-card-${itemId}`)?.scrollIntoView({
      block: 'center',
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (currentTab !== 'history' && currentObject) {
        scrollIntoCard(currentObject?.id);
      }
    }, 0);
  }, [currentTab]);

  const isHistory = currentTab === 'history';

  const isShowListLoader = useMemo(
    () =>
      (isHistoryLoading && isHistory) ||
      (isSearchInfoLoading && !currentList.length),
    [isHistoryLoading, isHistory, isSearchInfoLoading, currentList]
  );

  const isShowProgress = useMemo(
    () =>
      isSearchInfoLoading &&
      !isHistory &&
      objects.progress !== undefined &&
      objects.progress !== 100,
    [isSearchInfoLoading, isHistory, objects]
  );

  const userLocationHistory = useMemo(
    () =>
      objects.userLocationHistory.map(
        ({ request_failed, request_id, requested_user, coordinates }) => ({
          ...requested_user,
          request_id,
          request_failed,
          coordinates,
          type: 'user' as TAvatarUnion,
        })
      ),
    [locationHistory]
  );

  const getMarkerLabel = (item: IGeospoofEntity) =>
    `${item.user}, ${dayjs().utc(true).to(dayjs(item.timestamp))}`;

  useEffect(() => {
    const visibleItems: IGeospoofVisibleItem[] = objects.history
      .filter((value) => value.coordinates)
      .map(
        (value) =>
          ({
            coordinates: value.coordinates,
            label: getMarkerLabel(value),
            onClick: handleSetCurrentPromise(value, getItemId(value)),
          } as IGeospoofVisibleItem)
      );
    dispatch(mapActions.setVisibleGeospoofObjects(visibleItems));
  }, [objects.history, objects.userLocationHistory]);

  useEffect(() => {
    dispatch(mapActions.setGeospoofStatus(true));

    const id = searchParams.get('id');

    if (id && isNumber(+id)) {
      dispatch(getSearchInfoThunk({ searchId: +id }));
    }

    dispatch(getSearchHistoryThunk());

    return () => {
      dispatch(mapActions.resetGeospoofState());
    };
  }, []);

  useEffect(() => {
    if (isUserLocationPolling && userLocationHistory.length) {
      userLocationHistory
        .filter((item) => !item.request_failed)
        .map(({ request_id, id, coordinates }) => {
          if (!loadingUserLocation[id] && request_id && !coordinates) {
            dispatch(mapActions.setLoadingUserLocation(id));
            dispatch(getLoadedUserLocationThunk({ request_id, user_id: id }));
          }
        });

      setUserLocationPolling(false);
    }
  }, [loadingUserLocation, userLocationHistory, isUserLocationPolling]);

  useEffect(() => {
    if (searchId) {
      setSearchParams(`id=${searchId}`);
    }
  }, [searchId]);

  const getCurrentList = (tab: TGeospoofObjectKeysUnion) => {
    const objectsList = [...objects[tab]];
    if (isHistory) {
      return [...userLocationHistory, ...objectsList];
    } else {
      return objectsList;
    }
  };

  useEffect(() => {
    setCurrentList(getCurrentList(currentTab));
  }, [objects, currentTab]);

  useEffect(() => {
    if (searchPosition) {
      const [longitude, latitude] = searchPosition;

      ref.current && ref.current.focus();

      if (runExternalRequest) {
        dispatch(mapActions.setExternalGeospoofRequest(null));
        runGeospoofSearch(latitude, longitude);
      }
    }
  }, [searchPosition, runExternalRequest]);

  const runGeospoofSearch = async (latitude: number, longitude: number) => {
    if (pending || coordinatesLoader) return;

    if (!isValidCoords([latitude, longitude] as TPosition)) {
      notify.error(convertValidationErrors.UNCORRECT_COORDINATES);
      return;
    }

    setCoordinatesLoader(true);

    try {
      dispatch(mapActions.setGeospoofCoordinates([longitude, latitude]));
      dispatch(mapActions.setGeospoofTab('users'));
      dispatch(
        getGeospoofThunk({
          lon: longitude ?? 0,
          lat: latitude ?? 0,
        })
      ).then(
        (val) =>
          val.meta.requestStatus !== 'rejected' &&
          setGeospoofTimestamp(dayjs().utcOffset(3).toString())
      );
    } catch (e) {
      notify.error('Не удалось получить данные');
    }

    setCoordinatesLoader(false);
  };
  const handleSearch = useCallback(async () => {
    const requestCoordinates = getCoordinatesFromDecimalString(searchValue);

    if (requestCoordinates) {
      const [latitude, longitude] = requestCoordinates;
      runGeospoofSearch(latitude, longitude);
    }
  }, [searchValue, pending, coordinatesLoader]);

  const handleSetCurrent = useCallback(
    ({ currentItem, open, itemId, mustSetActive = false }: ISetCurrent) =>
      async () => {
        setActiveSearchItemId(itemId);
        const coordinates = currentItem?.coordinates;
        setGeospoofTimestamp(
          currentItem?.timestamp ?? dayjs().utcOffset(3).toString()
        );

        if (currentItem?.id) {
          dispatch(
            mapActions.setCurrentGeospoofObject({
              field: currentTab,
              data: currentItem,
            })
          );
        } else {
          dispatch(mapActions.resetCurrentObject());
        }
        if (isHistory || mustSetActive) {
          if (currentItem?.id) {
            dispatch(mapActions.setGeospoofCoordinates(null));
          }

          dispatch(mapActions.setGeospoofCoordinates(coordinates));

          if (currentItem?.search_id) {
            if (!objectCoordinates) {
              dispatch(mapActions.setObjectCoordinates());
            }

            if (searchId === currentItem.search_id) return;

            await dispatch(
              getSearchInfoThunk({ searchId: currentItem.search_id })
            );
          }
        }
        if (open && currentItem?.id) {
          if (currentItem.mediaArray) {
            dispatch(
              mapActions.setMediaPreviewMode({
                list: getMediaFiles(currentItem.mediaArray),
                mode: 'geospoof',
              })
            );
          } else {
            if (isShowProgress || currentItem.isMediaLoading) return;

            dispatch(
              getPicturesUploadIdThunk({
                [avatarType === 'user' || currentItem?.type === 'user'
                  ? 'user_id'
                  : 'group_id']: currentItem.id,
              } as unknown as IRequestIdEntityPictures)
            );
          }
        }
      },
    [
      activeSearchItemId,
      isHistory,
      mediaPreviewMode,
      avatarType,
      searchId,
      isShowProgress,
      searchPosition,
      currentTab,
    ]
  );

  const handleSetCurrentPromise =
    (value: IGeospoofEntity, itemId: number) => async () => {
      scrollIntoCard(itemId);
      const setCurrentPromise = handleSetCurrent({
        currentItem: value,
        open: false,
        itemId,
        mustSetActive: true,
      });
      await setCurrentPromise();
    };

  const onUserLocationClick = useCallback(
    (item: IGeospoofEntity) => async () => {
      if (isShowProgress || !item.id || isHistory) return;
      setProcessedUser(item);
      setIsTriangulationModal(true);
    },
    [searchId, isShowProgress, isHistory]
  );

  const handleToggleSegment = useCallback(
    (tab: TGeospoofTabTitleUnion) => {
      const targetTab = twoWayTabConverter(tab);
      if (targetTab) {
        dispatch(
          mapActions.setGeospoofTab(targetTab as TGeospoofObjectKeysUnion)
        );
        setActiveSearchItemId(undefined);
      }
    },
    [current]
  );

  const isItemActive = (itemId: number) => itemId === activeSearchItemId;

  const getItemId = (item: IGeospoofEntity) =>
    item.search_id || parseInt(item.id);

  const renderCard = (item: IGeospoofEntity) => {
    const itemId = getItemId(item);
    return (
      <GeospoofCard
        {...item}
        id={itemId.toString()}
        type={item.type ?? (avatarType as TAvatarUnion)}
        active={isItemActive(itemId)}
        key={generateRandomString(20)}
        onClick={handleSetCurrent({
          currentItem: { ...item, type: avatarType },
          open: false,
          itemId,
        })}
        onAvatarClick={handleSetCurrent({
          currentItem: { ...item, type: avatarType },
          open: true,
          itemId,
        })}
        onUserLocation={isHistory ? undefined : onUserLocationClick(item)}
        loading={loadingUserLocation[item.id]}
      />
    );
  };

  const getDayFromTimestamp = (timestamp: string): string => {
    return timestamp.slice(0, 10);
  };

  const getGroupTitle = (entity: IGeospoofEntity) =>
    entity.distance
      ? `${entity.distance} метров`
      : entity.timestamp?.slice(2, 10).split('-').reverse().join('.');

  const wrapInGroup = (entities: IGeospoofEntity[], title?: string) => (
    <EntityFieldsGroup
      key={generateRandomString(20)}
      title={title || getGroupTitle(entities[0]) || ''}
    >
      <div className="flex flex-col items-center self-stretch gap-3">
        {entities.map(renderCard)}
      </div>
    </EntityFieldsGroup>
  );

  const groupCurrentList = (
    objects: IGeospoofEntity[],
    groupingPropGetter: (v: IGeospoofEntity) => string | undefined
  ): JSX.Element[] => {
    const groupedList = objects.reduce(
      (acc: { [key: string]: IGeospoofEntity[] }, obj) => {
        const groupingProp = groupingPropGetter(obj);
        if (groupingProp) {
          acc[groupingProp] = [...(acc[groupingProp] || []), obj];
        }
        return acc;
      },
      {}
    );

    return Object.values(groupedList)
      .filter((objs: IGeospoofEntity[]) => objs.length > 0)
      .map((objs: IGeospoofEntity[]) => wrapInGroup(objs));
  };

  const groupCurrentListCallback = useCallback(groupCurrentList, [
    currentList,
    activeSearchItemId,
  ]);

  const timestampGrouper = (entity: IGeospoofEntity) =>
    entity.timestamp && getDayFromTimestamp(entity.timestamp);
  const distanceGrouper = (entity: IGeospoofEntity) =>
    entity.distance?.toString();
  const groupingPropGetter = useMemo(
    () => (isHistory ? timestampGrouper : distanceGrouper),
    [isHistory]
  );

  const getLoader = () => (
    <div className="h-full flex">
      <Loader />
    </div>
  );

  if (pending || coordinatesLoader) return getLoader();

  const renderGeospoofContent = () => {
    return isShowListLoader ? (
      getLoader()
    ) : (
      <>
        {isHistory &&
          wrapInGroup(userLocationHistory, 'Поиск по пользователям')}
        {currentList &&
          groupCurrentListCallback(currentList, groupingPropGetter)}
        {currentList.length === 0 && (
          <div className="text-tpg_base tpg-c1">
            {NOTHING_WAS_FOUND_MESSAGE}
          </div>
        )}
      </>
    );
  };

  const handleCloseTriangulationModal = () => setIsTriangulationModal(false);

  const handleTriangulationStart = async () => {
    if (processedUser) {
      dispatch(mapActions.setLoadingUserLocation(processedUser.id));

      if (processedUser.request_id) {
        dispatch(
          getLoadedUserLocationThunk({
            request_id: processedUser.request_id,
            user_id: processedUser.id,
          })
        );
      } else {
        await dispatch(
          getUserLocationThunk({
            user_id: processedUser.id,
            search_id: searchId ?? 0,
          })
        );
      }
    }
    handleCloseTriangulationModal();
  };

  const handleCloseMediaModal = () =>
    dispatch(mapActions.setMediaPreviewMode(null));

  return (
    <>
      {isTriangulationModal && (
        <ConfirmModal
          title="Местоположение"
          description="Это может занять 10+ минут, уверен?"
          confirmText="Да, уверен"
          onConfirm={handleTriangulationStart}
          onClose={handleCloseTriangulationModal}
        />
      )}
      {mediaPreviewMode && mediaPreviewMode.mode == 'geospoof' && (
        <MediaGalleryModal
          isLoading={mediaPreviewMode.isLoading}
          onClose={handleCloseMediaModal}
          mediaFiles={mediaPreviewMode.list}
        />
      )}
      <div className="geospoof-body">
        <div className="geospoof-body__content">
          <div className="geospoof-body__content__tabs">
            <Segmented
              options={GEOSPOOF_TABS}
              active={visibleTabName}
              onChange={(tab) =>
                handleToggleSegment(tab as TGeospoofTabTitleUnion)
              }
            />
          </div>
          {formattedCoordinates && formattedTimestamp && (
            <div className="pt-4 px-4 tpg-c1 flex justify-between align-center self-stretch">
              <div className="text-tpg_base">{formattedCoordinates}</div>
              <div className="text-tpg_light">{formattedTimestamp}</div>
            </div>
          )}
          <div className="geospoof-body__content__controls">
            <TextInput
              placeholder={'Поиск...'}
              resetIcon
              searchIcon
              onChange={setSearchValue}
              onEnter={handleSearch}
              maxLength={SEARCH_VALUE_MAX_LIMIT}
              value={searchValue}
              ref={ref}
              classNames={{ container: 'w-full' }}
            />
          </div>
          <div className="flex flex-col justify-start grow shrink basis-0 self-stretch">
            <div
              ref={pageScrollRef}
              className="px-4 pb-4 flex flex-col overflow-y-auto grow shrink basis-0 gap-6"
            >
              {renderGeospoofContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapGeospoof;
