import { toast, ToastOptions } from 'react-toastify';

import { notify } from 'utils';

import '../styles/main.scss';

export const error = (
  message = 'Неизвестная ошибка',
  options?: ToastOptions<Record<string, unknown>>
) =>
  toast.error(message, {
    pauseOnHover: true,
    progressClassName: 'progress-bar bg-error',
    ...options,
  });

export const success = (
  message: string,
  options?: ToastOptions<Record<string, unknown>>
) =>
  toast.success(message, {
    progressClassName: 'progress-bar bg-success',
    ...options,
  });

export const warning = (
  message: string,
  options?: ToastOptions<Record<string, unknown>>
) =>
  toast.warning(message, {
    progressClassName: 'progress-bar bg-error',
    ...options,
  });

export const handleNotify = (
  promise: Promise<any>,
  successMessage: string,
  errorMessage: string
) => {
  return promise
    .then(() => notify.success(successMessage))
    .catch(() => notify.error(errorMessage));
};
