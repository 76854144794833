import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { labelConfig } from 'configs/map/common';
import { LABEL_SOURCE } from 'constants/map';
import { MapGeoJSONObject } from 'interfaces';

interface LabelSourceProps extends MapGeoJSONObject {
  mainConfig?: LayerProps;
}

export const LabelSource: FC<LabelSourceProps> = memo(
  ({ id = LABEL_SOURCE, data, mainConfig = labelConfig, ...props }) => (
    <Source id={id} type="geojson" data={data} {...props}>
      <Layer id={`${id}-main`} {...mainConfig} />
    </Source>
  )
);

LabelSource.displayName = 'LabelSource';
