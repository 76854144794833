import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { Position } from 'geojson';
import { BLHCoordinates, ICoordinates, XYHCoordinates } from 'interfaces';

import { notify } from 'utils';

import { CoordinateSystem } from '../types';

import { convertWgsDegreeToDecimal } from './converterUtils';

export const getCoordinatesFromDecimalString = (value: string) =>
  value ? value.split(',').map((item) => parseFloat(item)) : null;

export const getCoordinatesFromDegreeString = (value: string) =>
  value
    ? value
        .split(' ')
        .map((coordinate) => convertWgsDegreeToDecimal(coordinate))
    : null;

export const formatWgsCoords = (lat: number, lng: number) =>
  `${lat.toFixed(6)}, ${lng.toFixed(6)}`;

export const formatXYCoords = (x: number, y: number) => `X:${x}, Y:${y}`;

export const copyToClipboard = (
  text: string,
  message = 'Скопировано в буфер обмена'
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notify.success(message);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getVisibleCoordMode = (coordsMode: CoordinateSystem) => {
  switch (coordsMode) {
    case 'wgs':
      return 'WGS';
    case 'sk42':
      return 'СК-42';
    case 'usk2000':
      return 'УСК2000';
  }
};

export const getCoordinatesTuple = (
  object: ICoordinates,
  system: CoordinateSystem,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
): [number, number] | string => {
  switch (system) {
    case 'mgrs': {
      return object as string;
    }
    case 'wgs': {
      const { b, l } = object as BLHCoordinates;
      const tuple = [b, l];

      return (coordinatesOrder === 'latLng' ? tuple : tuple.reverse()) as [
        number,
        number
      ];
    }
    case 'sk42':
    case 'usk2000': {
      const { x, y } = object as XYHCoordinates;
      const tuple = [x, y];

      return (coordinatesOrder === 'latLng' ? tuple : tuple.reverse()) as [
        number,
        number
      ];
    }
    default:
      return [0, 0];
  }
};

export const getCoordinatesObject = (
  coordinates: Position | string,
  system: CoordinateSystem,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
): ICoordinates => {
  switch (system) {
    case 'mgrs': {
      return coordinates as string;
    }
    case 'wgs': {
      const [b, l] = (
        coordinatesOrder === 'latLng'
          ? coordinates
          : (coordinates as [number, number]).reverse()
      ) as [number, number];

      return { b, l, h: DEFAULT_CONVERTER_HEIGHT };
    }
    case 'sk42':
    case 'usk2000': {
      const [x, y] = (
        coordinatesOrder === 'latLng'
          ? coordinates
          : (coordinates as [number, number]).reverse()
      ) as [number, number];

      return { x, y, h: DEFAULT_CONVERTER_HEIGHT };
    }
    default:
      return '';
  }
};
