import React, { FC, useMemo, useState } from 'react';
import { ReactComponent as MaigretIcon } from 'images/icons/maigret.svg';
import { ReactComponent as SecurityBreachIcon } from 'images/icons/security-breach.svg';

import TextInputWithLabel from 'components/ui/TextInputWithLabel/TextInputWithLabel';

import { searchMaigretUser } from '../../../api/dumps';
import { Button, Modal } from '../../ui';

import './MaigretUserSearchModal.scss';

interface UserSearchModalProps {
  onClose: () => void;
}

type SEARCH_MODE = 'MAIGRET';
// color palette: https://colorhunt.co/palette/000000282a3a735f32c69749

export const MaigretUserSearchModal: FC<UserSearchModalProps> = ({
  onClose,
}) => {
  const [searchMode, setSearchMode] = useState<SEARCH_MODE>('MAIGRET');
  const [searchQuery, setSearchQuery] = useState('');
  const [maigretSearchResultHTML, setMaigretSearchResultHTML] = useState<
    string | undefined
  >(undefined);
  const onSearchButtonPressed = useMemo(
    () => async () => {
      if (searchMode === 'MAIGRET') {
        return searchMaigretUser(searchQuery).then(setMaigretSearchResultHTML);
      } else {
        return Promise.resolve();
      }
    },
    [searchQuery, searchMode]
  );
  const searchPanel = useMemo(
    () => (
      <div className="flex flex-col justify-center items-center my-auto grow modal-element">
        <div className="flex gap-x-2">
          {searchMode === 'MAIGRET' ? (
            <MaigretIcon className="search-source" />
          ) : (
            <SecurityBreachIcon className="search-source" />
          )}
          <TextInputWithLabel
            label="Поиск по юзернейму"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            style={{ height: 58, minWidth: 150 }}
            isAsync
            title={'Искать'}
            onClick={() => onSearchButtonPressed()}
          />
        </div>
      </div>
    ),
    [searchMode, searchQuery]
  );
  return (
    <Modal
      onClose={onClose}
      keyboard
      width=""
      className="bg-primary user-search-modal"
    >
      <div
        className={`h-full p-4 flex flex-col justify-start items-center ${
          maigretSearchResultHTML ? 'w-full' : 'w-5/6'
        }`}
        style={{ minHeight: '95vh' }}
      >
        {searchPanel}
        <iframe
          className="w-full modal-element"
          srcDoc={maigretSearchResultHTML}
          style={{ flexGrow: maigretSearchResultHTML ? 99 : 0.001 }}
        />
      </div>
    </Modal>
  );
};
