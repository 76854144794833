import { createSlice } from '@reduxjs/toolkit';

import { GEOSPOOF_REDUCER_NAMES } from '../constants';

import { HistoryState, SetAreasAction, SetUsersAction } from './types';

const initialState: HistoryState = {
  areas: [],
  users: [],
};

const historySlice = createSlice({
  name: GEOSPOOF_REDUCER_NAMES.HISTORY,
  initialState: initialState,
  reducers: {
    setAreas(state, action: SetAreasAction) {
      const { areas } = action.payload;

      state.areas = areas;
    },
    setUsers(state, action: SetUsersAction) {
      const { users } = action.payload;

      state.users = users;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: historyActions, reducer: historyReducer } =
  historySlice;
