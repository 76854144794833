import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import { GeocodeResponse } from '@mapbox/mapbox-sdk/services/geocoding';
import { Option } from 'interfaces';
import { GeocoderFeature, GeocoderPlaceFeature } from 'interfaces/geocoder';

import { Coordinates } from '../types';

export const getGeocoderFeature = <T = GeocoderFeature>(
  subtype: string,
  coordinates: Coordinates,
  properties?: Record<string, any>,
  coordinatesOrder: 'latLng' | 'lngLat' = 'latLng'
) =>
  ({
    type: 'Feature',
    subtype: subtype as any,
    geometry: {
      type: 'Point',
      coordinates:
        coordinatesOrder === 'latLng' ? coordinates : coordinates.reverse(),
    },
    properties: properties,
  } as T);

export const getOptionsFromGeocoderResult = (
  result: MapiResponse<GeocodeResponse>
): Option<GeocoderPlaceFeature>[] =>
  result.request.response?.body?.features?.map?.((feature) => ({
    id: feature.id,
    value: { ...feature, subtype: 'place' },
    label: feature.place_name.split(',')[0],
  })) ?? [];
