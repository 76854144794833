import { useEffect, useRef } from 'react';
import { Geocoder } from 'services/mapbox/geocoder';

import { useMapRef } from './map';

export const useGeocoder = () => {
  const map = useMapRef();
  const geocoder = useRef(new Geocoder({ map: map.mapRef.current }));

  useEffect(() => {
    geocoder.current.onAdd();

    return () => {
      geocoder.current.onRemove();
    };
  }, [map]);

  return geocoder.current;
};
