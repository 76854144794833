import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import { Link } from '../Link';
import Spinner from '../Spinner';

import './style.scss';

type SyncClickPropsType = {
  onClick: () => void;
  isAsync?: undefined | false;
};
type AsyncOnClickPropsType = {
  onClick: () => Promise<void>;
  isAsync: true;
};
type SmartButtonProps = {
  className?: string;
  textClassName?: string;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  style?: CSSProperties;
} & (SyncClickPropsType | AsyncOnClickPropsType);

const Button: FC<PropsWithChildren<SmartButtonProps>> = ({
  className,
  textClassName,
  children,
  title,
  isLoading,
  onClick,
  disabled,
  isAsync,
  type = 'button',
  style,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(isLoading);

  useEffect(() => {
    setIsButtonLoading(isLoading);
  }, [isLoading]);

  const handleClick = () => {
    if (isLoading || disabled) return;
    if (!isAsync) {
      onClick();
    } else if (isAsync) {
      setIsButtonLoading(true);
      onClick().finally(() => setIsButtonLoading(false));
    }
  };

  return (
    <>
      {isButtonLoading && <Spinner />}
      {!isButtonLoading && (
        <Link
          className={cn(
            'smart-button',
            { 'smart-button__disabled': disabled },
            className
          )}
          title={title}
          textClassName={cn('smart-button__text', textClassName || 'tpg-c2')}
          onClick={handleClick}
          type={type}
          style={style}
        >
          {!title && children}
        </Link>
      )}
    </>
  );
};

export default Button;
