import { FC, PropsWithChildren } from 'react';

export const ObjectPreviewTitle: FC<PropsWithChildren> = ({ children }) => (
  <div
    className={`flex justify-center items-center px-3 py-1 m-[-1px] 
bg-light border border-solid border-tpg_light`}
  >
    <span className="tpg-b1">{children}</span>
  </div>
);
