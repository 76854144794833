import { RootState } from 'store';

export const dynamicFiltersSelector = (state: RootState) =>
  state.monitoring.dynamicFilters;

export const staticFiltersSelector = (state: RootState) =>
  state.monitoring.staticFilters;

export const parserSelector = (state: RootState) => state.monitoring.parsers;

export const postListSelector = (state: RootState) => state.monitoring.posts;

export const mediaFilesSelector = (state: RootState) =>
  state.monitoring.mediaFiles;

export const checkedLangCorpusesSelector = (state: RootState) =>
  state.monitoring.checkedLangCorpuses;

export const showOnlyBookmarksSelector = (state: RootState) =>
  state.monitoring.showOnlyBookmarks;

export const bookmarkIdsSelector = (state: RootState) =>
  state.monitoring.bookmarkIds;
