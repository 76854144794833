import { getAttributes } from 'api/map';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IMapAttributes } from 'interfaces';

import { createThunk } from 'utils';

import { COMMON_REDUCER_NAMES } from '../constants';

export const getAttributesThunk = createThunk<IMapAttributes>(
  `${reducersNames.COMMON}/${COMMON_REDUCER_NAMES.ATTRIBUTES}/${asyncActionsNames.GET_ATTRIBUTES}`,
  getAttributes,
  errorMessages.GET_ATTRIBUTES_ERROR
);
