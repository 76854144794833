import { Commands } from '@uiw/react-markdown-editor/cjs/components/ToolBar';

export const MARKDOWN_COMMANDS: Commands[] = [
  'undo',
  'redo',
  'bold',
  'italic',
  'header',
  'underline',
  'quote',
  'olist',
  'ulist',
  'link',
];
