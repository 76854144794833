export const setToken = (token: string) => {
  localStorage.setItem('access_token', token);
};

export const setTileToken = (token: string) => {
  localStorage.setItem('tile_token', token);
};

export const getToken = () => localStorage.getItem('access_token') || null;

export const getTileToken = () => localStorage.getItem('tile_token') || null;

export const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('tile_token');
};
