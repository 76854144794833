import { MAPBOX_MAP_CONTAINER } from 'constants/map';
import { curry } from 'ramda';

// Параметр определяет максимальный процент ширины родительского элемента, который будет занимать линейка
const SCALE_SCREEN_RATIO = 0.15;
// https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Resolution_and_Scale
const TILE_SIZE_METERS_AT_0_ZOOM = 156543.03;

const SCALE_STEPS_IN_METERS = [
  5,
  10,
  20,
  50,
  100,
  200,
  500,
  1000,
  2 * 1000,
  5 * 1000,
  10 * 1000,
  20 * 1000,
  50 * 1000,
  100 * 1000,
  200 * 1000,
  500 * 1000,
  1000 * 1000,
  2000 * 1000,
  4000 * 1000,
];

const SCALE_TEXT_IN_METERS = [
  '5м',
  '10м',
  '20м',
  '50м',
  '100м',
  '200м',
  '500м',
  '1км',
  '2км',
  '5км',
  '10км',
  '20км',
  '50км',
  '100км',
  '200км',
  '500км',
  '1000км',
  '2000км',
  '4000км',
];

const getMapContainerWidth = () => {
  const mapContainer = document.getElementById(MAPBOX_MAP_CONTAINER);

  if (mapContainer) {
    return mapContainer.offsetWidth;
  }

  return 0;
};

const trim = curry(
  (precision: number, currentNumber: number): number =>
    +Number(currentNumber).toFixed(precision)
);

const trimTo7digits = trim(7);

const getResolutionFromZoomLevel = (zoom: number, latitude: number): number =>
  (TILE_SIZE_METERS_AT_0_ZOOM * Math.cos((latitude * Math.PI) / 180)) /
  Math.pow(2, zoom);

const getScaleBarSizeInMeters = (
  scale_step: number,
  resolution: number
): number =>
  trimTo7digits((2 * SCALE_STEPS_IN_METERS[scale_step]) / resolution);

const selectBestScaleStepFromResolution = curry(
  (
    resolution: number,
    best_scale_step: number,
    scale: number,
    current_step: number
  ): number => {
    return getScaleBarSizeInMeters(current_step, resolution) /
      getMapContainerWidth() <
      SCALE_SCREEN_RATIO
      ? current_step
      : best_scale_step;
  }
);

const getScaleStepFromResolution = (resolution: number): number => {
  const selectBestScaleStep = selectBestScaleStepFromResolution(resolution);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return SCALE_STEPS_IN_METERS.reduce(selectBestScaleStep, 0);
};

const getScaleTextIMetersFromScaleStep = (scale_step: number): string =>
  SCALE_TEXT_IN_METERS[scale_step];

export const getScaleBarInfoFromZoomLevel = (
  zoom: number,
  latitude: number
): {
  scaleBarSizeInMeters: number;
  scaleBarTextInMeters: string;
} => {
  const resolution = getResolutionFromZoomLevel(zoom, latitude);
  const scale_step = getScaleStepFromResolution(resolution);

  return {
    scaleBarSizeInMeters: getScaleBarSizeInMeters(scale_step, resolution),
    scaleBarTextInMeters: getScaleTextIMetersFromScaleStep(scale_step),
  };
};
