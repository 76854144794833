import {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';
import cn from 'classnames';

import {
  useSliderHorizontalNavigation,
  useSliderVerticalNavigation,
} from './useSliderNavigation';

import styles from './slider.module.scss';
interface ISliderProps extends HTMLAttributes<HTMLElement> {
  outterRef?: (element: HTMLElement | null) => void;
  currentSlide: number;
  orientation?: 'horizontal' | 'vertical';
  gap?: number;
  size?: number;
}

const orientationClassName = {
  horizontal: 'w-full flex-row',
  vertical: 'h-full flex-col',
};

export const Slider: FC<PropsWithChildren<ISliderProps>> = ({
  outterRef,
  currentSlide,
  children,
  orientation = 'horizontal',
  gap = 12,
  size = 50,
  className,
  ...props
}) => {
  const innerRef = useRef<HTMLDivElement | null>(null);

  const refCallback = (node: HTMLDivElement) => {
    innerRef.current = node;
    outterRef?.(node);
  };

  const renderSlide = (children: ReactNode) =>
    Children.map(children, (child, index) => {
      if (!isValidElement(child)) {
        return child;
      }

      return cloneElement(child as ReactElement, {
        key: index,
        role: 'button',
        className: cn(
          child.props.className,
          'shrink-0 overflow-hidden cursor-pointer object-cover'
        ),
        style: { width: size, height: size },
      });
    });

  useSliderHorizontalNavigation(
    innerRef,
    currentSlide,
    size,
    gap,
    orientation === 'horizontal'
  );

  useSliderVerticalNavigation(
    innerRef,
    currentSlide,
    size,
    gap,
    orientation === 'vertical'
  );

  return (
    <div
      ref={refCallback}
      className={cn(
        styles.container,
        orientationClassName[orientation],
        className
      )}
      style={{ gap }}
      {...props}
    >
      {renderSlide(children)}
    </div>
  );
};
