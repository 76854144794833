import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import { DocumentСoordinateState, SetCoordinatesAction } from './types';

const initialState: DocumentСoordinateState = {
  coordinates: [],
};

const documentCoordinatesSlice = createSlice({
  name: TOOLS_REDUCER_NAMES.DOCUMENT_COORDINATES,
  initialState: initialState,
  reducers: {
    setCoordinates(state, action: SetCoordinatesAction) {
      state.coordinates = action.payload;
    },
    resetDocumentCoordinates(state) {
      state.coordinates = [];
    },
  },
});

export const {
  actions: documentCoordinatesActions,
  reducer: documentCoordinatesReducer,
} = documentCoordinatesSlice;
