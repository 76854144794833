import { BaseSyntheticEvent, FC, memo, SyntheticEvent, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as Eye } from 'images/icons/eye.svg';
import { ReactComponent as Group } from 'images/icons/group.svg';
import { ReactComponent as Person } from 'images/icons/person.svg';
import { ReactComponent as GeospoofSearch } from 'images/newIcons/geospoofSearch.svg';
import { TAvatarUnion } from 'types';

import './style.scss';

interface AvatarProps {
  src: string | null;
  altText?: string;
  theme?: 'blue' | 'orange' | 'grey';
  type?: TAvatarUnion;
  size?: number;
  showHoverIcon?: boolean;
  isMediaLoading?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

const Avatar: FC<AvatarProps> = ({
  src,
  type = 'user',
  theme = 'blue',
  size = 44,
  altText = 'avatar',
  showHoverIcon = false,
  isMediaLoading = false,
  isActive = false,
  onClick,
}) => {
  const [isLoading, setIsLoading] = useState(!!src);
  const [isError, setError] = useState(false);

  const handleLoadImage = ({ type }: BaseSyntheticEvent) => {
    if (type === 'error') {
      setError(true);
    }
    setIsLoading(false);
  };

  const handleClick = (e: SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onClick && onClick();
  };

  const renderDefaultIcon = () => {
    switch (type) {
      case 'user':
        return <Person />;
      case 'group':
        return <Group />;
      case 'search':
        return <GeospoofSearch className="avatar__search-icon" />;
      default:
        return <Person />;
    }
  };

  return (
    <div
      className={cn(`avatar avatar__theme-${theme}`, {
        'border-solid border-2 border-bright_product': !src && isActive,
      })}
      style={{ width: size, minWidth: size, height: size }}
      onClick={handleClick}
    >
      {isMediaLoading && <div className="skeleton avatar__loader" />}
      {src && !isError ? (
        <>
          <img
            className={cn('avatar_image', {
              'border-solid border-2 border-bright_product': isActive,
            })}
            src={src}
            alt={altText}
            onLoad={handleLoadImage}
            onError={handleLoadImage}
          />
        </>
      ) : (
        renderDefaultIcon()
      )}
      {showHoverIcon && !isMediaLoading && (
        <span className="avatar__expand">
          <Eye className="avatar__eye-icon" />
        </span>
      )}
    </div>
  );
};

export default memo(Avatar);
