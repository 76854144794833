import { TNodeClickEvent, TNodeDragEvent } from './types';

export const preventInteraction = (
  e: TNodeDragEvent | TNodeClickEvent,
  shouldPrevent: boolean
) => {
  if (shouldPrevent) {
    e.preventDefault();
    e.stopPropagation();
  }
};
