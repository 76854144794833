import { useAppDispatch, useAppSelector } from 'hooks';
import { boundaryGridActions } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice';
import { boundaryGridMapSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice/selectors';
import { ImageryBoundaryGeoJSON } from 'types';

import { getBoundaryGrid } from 'utils';

import { ContextPopup, ContextPopupOption } from './ContextPopup';

interface DrawnFeatureContextMenuProps {
  longitude: number;
  latitude: number;
  options: ContextPopupOption[];
  imageryBoundary: ImageryBoundaryGeoJSON;
  onClose: () => void;
}

export const ImageryContextMenu = ({
  longitude,
  latitude,
  options: optionsProp,
  imageryBoundary,
  onClose,
}: DrawnFeatureContextMenuProps) => {
  const boundaryGridMap = useAppSelector(boundaryGridMapSelector);
  const dispatch = useAppDispatch();

  const hasBoundaryGrid =
    !!boundaryGridMap[imageryBoundary.properties.relatedFeatureId];

  const addBoundaryGrid = (size: number) => {
    const boundaryGrid = getBoundaryGrid(imageryBoundary, size);

    dispatch(boundaryGridActions.addBoundaryGrid(boundaryGrid));
  };

  const showBoundaryGrid = () => {
    dispatch(
      boundaryGridActions.updateBoundaryGrid({
        relatedFeatureId: imageryBoundary.properties.relatedFeatureId,
        properties: { visible: true },
      })
    );
  };

  const options = hasBoundaryGrid
    ? [...optionsProp, { title: 'Показать сетку', onClick: showBoundaryGrid }]
    : [
        ...optionsProp,
        { title: 'Сетка 3Х3', onClick: () => addBoundaryGrid(3) },
        { title: 'Сетка 6Х6', onClick: () => addBoundaryGrid(6) },
        { title: 'Сетка 9Х9', onClick: () => addBoundaryGrid(9) },
        {
          title: 'Сетка 12Х12',
          onClick: () => addBoundaryGrid(12),
        },
      ];

  return (
    <ContextPopup
      options={options}
      longitude={longitude}
      latitude={latitude}
      keyboard
      onClose={onClose}
    />
  );
};
