import { getAllGCObjects, getGCAvailableRanges } from 'api/map';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  IAllGCObjects,
  IDateRangeFilter,
  IGCAvailableRanges,
} from 'interfaces';

import { buildThunk } from 'utils';

import { MAP_V2_REDUCER_NAMES } from '../../constants';
import { TABS_REDUCER_NAMES } from '../constants';

export const getGCRangesThunk = buildThunk<IGCAvailableRanges<string>>(
  `${reducersNames.MAP}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.OPERATIONAL}/${asyncActionsNames.GET_GC_RANGES}`,
  errorMessages.GET_GC_RANGES_ERROR,
  getGCAvailableRanges
);

export const getAllGCObjectsThunk = buildThunk<IAllGCObjects, IDateRangeFilter>(
  `${reducersNames.MAP}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.OPERATIONAL}/${asyncActionsNames.GET_ALL_GC_OBJECTS}`,
  errorMessages.GET_GC_FEATURES_ERROR,
  getAllGCObjects
);
