import { FC, useRef } from 'react';
import cn from 'classnames';
import { Tag as ITag } from 'interfaces/documents';

import { Tag } from 'components/ui';

interface DocumentTagProps {
  tag: ITag;
  onClick: () => void;
  isActive: boolean;
  isMoreTag?: boolean;
}

export const DocumentTag: FC<DocumentTagProps> = ({
  tag,
  onClick,
  isActive,
  isMoreTag,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const documentTagClassname = isMoreTag
    ? 'border border-solid border-dark_product [&>span]:text-tpg_title cursor-pointer !py-[5px]'
    : cn('bg-ultrablack [&>span]:text-tpg_title cursor-pointer !py-[5px]', {
        '!bg-dark_product': isActive,
      });

  return (
    <div ref={containerRef} className="relative" onClick={onClick}>
      <Tag
        title={tag.value}
        label={String(tag.count)}
        closeIcon={false}
        className={documentTagClassname}
      />
    </div>
  );
};

export default DocumentTag;
