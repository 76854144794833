import { RootState } from 'store';

export const objectsSelector = (state: RootState) =>
  state.map_v2.tabs.imagery.objects;

export const visibleObjectsSelector = (state: RootState) =>
  state.map_v2.tabs.imagery.visibleObjects;

export const showPreviewSelector = (state: RootState) =>
  state.map_v2.tabs.imagery.showPreview;
