import { configureStore } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';

import {
  authReducer,
  commonReducer,
  mapReducer,
  mapV2Reducer,
  monitoringReducer,
  serviceReducer,
} from './slices';

export const store = configureStore({
  reducer: {
    [reducersNames.MONITORING]: monitoringReducer,
    [reducersNames.MAP]: mapReducer,
    [reducersNames.MAP_V2]: mapV2Reducer,
    [reducersNames.AUTH]: authReducer,
    [reducersNames.SERVICE]: serviceReducer,
    [reducersNames.COMMON]: commonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from './slices';
