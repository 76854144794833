import { FC, PropsWithChildren, ReactElement } from 'react';

import {
  ContextMenuTooltip,
  IContextMenuTooltipItem,
} from 'components/ContextMenuTooltip';

interface IContextMenuWrapper {
  contextMenu?: IContextMenuTooltipItem[];
}

export const ContextMenuWrapper: FC<PropsWithChildren<IContextMenuWrapper>> = ({
  contextMenu,
  children,
}) => {
  const hasContextMenu = !!contextMenu?.length;

  return (
    <>
      {hasContextMenu ? (
        <ContextMenuTooltip items={contextMenu} keyboard>
          {children as ReactElement}
        </ContextMenuTooltip>
      ) : (
        children
      )}
    </>
  );
};
