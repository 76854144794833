import React, { FC, memo, useEffect, useState } from 'react';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  diffSourcePlugin,
  frontmatterPlugin,
  headingsPlugin,
  imagePlugin,
  InsertTable,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import { ContextStore } from '@uiw/react-md-editor';

import '@mdxeditor/editor/style.css';
import './style.scss';

export interface MarkdownEditorProps {
  value: string;
  onChange: (
    value?: string,
    event?: React.ChangeEvent<HTMLTextAreaElement>,
    state?: ContextStore
  ) => void;

  ref?: React.MutableRefObject<any>;
}

const CustomMarkdownEditor: FC<MarkdownEditorProps> = ({
  value,
  onChange,
  ref,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  const toolbarContent = (
    <>
      <UndoRedo />
      <Separator />
      <InsertThematicBreak />
      <BlockTypeSelect />
      <Separator />
      <BoldItalicUnderlineToggles />
      <Separator />
      <CodeToggle />
      <CreateLink />
      <Separator />
      <ListsToggle />
      <Separator />
      <InsertTable />
    </>
  );

  return (
    <MDXEditor
      className="dark-theme markdown-editor"
      markdown={internalValue}
      onChange={onChange}
      plugins={[
        // toolbarPlugin({ toolbarContents: () => <KitchenSinkToolbar /> }),
        toolbarPlugin({ toolbarContents: () => toolbarContent }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        imagePlugin(),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
        markdownShortcutPlugin(),
      ]}
    />
  );
};

export default memo(CustomMarkdownEditor);
