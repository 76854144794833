import { SetStateAction, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';

import { notify } from 'utils';

import { Header } from '../../components/Header/Header';
import { Button, Table, TextInput } from '../../components/ui';
import { appRoutes } from '../../constants/routes';
import { useAppDispatch } from '../../hooks';
import { useReportFilters } from '../../hooks/useReportFilters';
import {
  IReportsTableRequest,
  PersonReportSummary,
} from '../../interfaces/reports';
import { getReportsTableThunk } from '../../store/slices/reports/actions';
import { TReportSortUnion } from '../../types/report';

import { getReportColumns } from './consts';

import './style.scss';

const ReportsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [reports, setReports] = useState<SetStateAction<any>>();
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(false);
  const [list, setList] = useState<PersonReportSummary[]>([]);

  const {
    sortable,
    onSetSortField,
    search,
    setSearch,
    reportParams,
    reportsLength,
  } = useReportFilters<TReportSortUnion, PersonReportSummary[]>(list, pending);

  const [params, setParams] =
    useState<IReportsTableRequest<TReportSortUnion>>(reportParams);

  useEffect(() => {
    setPending(true);
    dispatch(getReportsTableThunk(params))
      .then((response) => {
        setReports(response.payload);
      })
      .catch((error) => {
        notify.error('Не удалось получить список отчётов: ', error);
      });
    setPending(false);
  }, [params]);

  useEffect(() => {
    setParams({ ...reportParams, keyword: search });
  }, [reportParams, search]);

  useEffect(() => {
    if (reports) {
      setList(reports.objects);
      setTotal(reports.total_count);
    }
  }, [reports]);

  const columns = getReportColumns();

  useEffect(() => {
    dispatch(
      getReportsTableThunk(params as IReportsTableRequest<TReportSortUnion>)
    );
  }, []);

  const onLoadMore = useCallback(() => {
    if (pending) return;

    dispatch(getReportsTableThunk({ ...params, start: reportsLength ?? 0 }));
  }, [pending, params, reportsLength]);

  const onOpenReport = (row: PersonReportSummary | null) => {
    if (!row) {
      navigate(appRoutes.REPORT + `/new/version/0`);
    } else {
      navigate(
        appRoutes.REPORT + `/${row.header_id}/version/${row.last_version_id}`
      );
    }
  };

  return (
    <>
      <Header />
      <div className="reports">
        <div className="reports__filters">
          <TextInput
            searchIcon
            resetIcon
            placeholder="Поиск..."
            onChange={setSearch}
            value={search}
            classNames={{ container: 'w-full' }}
          />
          <Button
            className="reports__create h-10"
            title="+ Новый"
            onClick={() => onOpenReport(null)}
          />
        </div>
        <div className="reports__table">
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={onLoadMore}
            hasMore={list.length < total}
            useWindow={false}
            threshold={1000}
          >
            <Table<PersonReportSummary, TReportSortUnion>
              columns={columns}
              onRowClick={onOpenReport}
              data={list}
              sortable={sortable}
              tableClassName="report-table"
              rowClassName="report-table__row"
              isLoading={false}
              onSort={onSetSortField}
              isChecked={false}
            />
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default ReportsTable;
