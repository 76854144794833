import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { ElementPlacementUnion, ElementThemeUnion } from 'constants/routes';
import { useClickOutside } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { ISelectOption } from 'interfaces';

import { genericMemo } from 'utils';

import OptionsList from './OptionsList';

import './style.scss';

interface TreeSelectClassNames {
  body?: string;
  text?: string;
  options?: string;
}

interface ISelectProps<T> {
  disabled?: boolean;
  isCreating?: boolean;
  isLoading?: boolean;
  onCreate?: (value: string) => void;
  onSelect: (value: T) => void;
  options: ISelectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  value: T;
  creationText?: string;
  withEmpty?: boolean;
  withSearch?: boolean;
  isAddMode?: boolean;
  theme?: ElementThemeUnion;
  isExpandable?: boolean;
  menuPlacement?: ElementPlacementUnion;
  visibleBackPropagate?: (isVisible: boolean) => void;
  onlyRenderOptions?: boolean;
  classNames?: TreeSelectClassNames;
}

const TreeSelect = <T extends string | number | null>({
  disabled = false,
  isCreating,
  isLoading = false,
  onCreate,
  onSelect,
  options,
  placeholder = 'Выберите',
  searchPlaceholder = '',
  value,
  creationText,
  withEmpty = true,
  withSearch = false,
  isAddMode = false,
  theme = 'dark',
  isExpandable = false,
  menuPlacement = 'bottom',
  onlyRenderOptions = false,
  classNames,
  visibleBackPropagate,
}: ISelectProps<T>) => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const selectRef = useRef(null);

  const handleSetOptionsVisible = (isVisible: boolean) => {
    setOptionsVisible(isVisible);
    visibleBackPropagate && visibleBackPropagate(isVisible);
  };

  useClickOutside<HTMLUListElement, void>(selectRef, () =>
    handleSetOptionsVisible(false)
  );

  const findOptionById = useCallback(
    (acc: ISelectOption | null, item: ISelectOption): ISelectOption | null => {
      if (item.value === value) return item;
      if (item.children) return item.children.reduce(findOptionById, acc);
      return acc;
    },
    [value, options]
  );

  const optionLabel = useMemo(
    () =>
      value || value === 0
        ? options.reduce(findOptionById, null)?.label ?? 'Неизвестная опция'
        : placeholder,
    [value, options]
  );

  const handleClick = useCallback(
    (value: T) => {
      onSelect(value);
      handleSetOptionsVisible(false);
    },
    [onSelect]
  );

  const onChangeVisible = () => {
    if (disabled) return;

    handleSetOptionsVisible(!isOptionsVisible);
  };

  const renderSelectorBody = () => (
    <div
      className={cn(
        'select__body rounded-md group',
        {
          'bg-dark': theme === 'dark',
          'bg-light': theme === 'light',
        },
        classNames?.body
      )}
      onClick={onChangeVisible}
    >
      <div className="select__body__container">
        <span
          className={cn(
            'select__body__value tpg-input text-tpg_title',
            classNames?.text
          )}
        >
          {optionLabel}
        </span>
      </div>
      <div className="select__body__icon icon-container">
        <Down
          className={cn('chevron', {
            chevron_up: isOptionsVisible,
          })}
        />
      </div>
    </div>
  );

  const renderOptions = () => (
    <OptionsList<T>
      handleClick={handleClick}
      isCreating={isCreating}
      isLoading={isLoading}
      onCreate={onCreate}
      options={options}
      placeholder={searchPlaceholder}
      value={value}
      creationText={creationText}
      withEmpty={withEmpty}
      withSearch={withSearch}
      isAddMode={isAddMode}
      theme={theme}
      isExpandable={isExpandable}
      menuPlacement={menuPlacement}
      className={classNames?.options}
    />
  );

  return onlyRenderOptions ? (
    <>{renderOptions()}</>
  ) : (
    <div
      ref={selectRef}
      className={cn('select', {
        select__disabled: disabled,
      })}
    >
      {renderSelectorBody()}
      {isOptionsVisible ? renderOptions() : null}
    </div>
  );
};

export default genericMemo(TreeSelect);
