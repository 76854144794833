import { IGeometry } from 'interfaces';
import { Entity, EntityParameter, ParameterValue } from 'types/entities';

import {
  AstraTableCell,
  AstraTableCellExtraProps,
  EditableTableCellType,
} from 'components/ui/Table/AstraTableCell';
import { AstraTableHeadCell } from 'components/ui/Table/AstraTableHeader';
import { roundCoordinates } from 'utils';
import { clearStringFromMarkdown } from 'utils/markdown';

export const toStringParamValueByType = (
  value: string,
  type: EditableTableCellType
) => {
  switch (type) {
    case 'text' || 'selector' || 'date':
      return clearStringFromMarkdown(value || '');

    case 'number':
      return value.toString();
    case 'geometry': {
      const str = JSON.stringify(value);
      const obj: IGeometry = JSON.parse(str);
      return obj.coordinates
        .map((el) => {
          if (typeof el === 'number') {
            return el.toFixed(6);
          } else {
            return roundCoordinates(el);
          }
        })
        .join()
        .replaceAll(',', ', ');
    }
    case 'checkbox':
      return value === 'true' ? 'Да' : 'Нет';
    case 'media':
      return undefined; // TODO what should be here?
    default:
      return '';
  }
};

// TODO update
export const getInitialValueByType = (type: EditableTableCellType) => {
  switch (type) {
    case 'text':
      return '';
    case 'number':
      return '';
    case 'checkbox':
      return false;
    case 'selector':
      return '';
    case 'date':
      return '';
    case 'media':
      return undefined;
  }
};

export const constructTableHeaderWithTitleByParams = (
  parameters: EntityParameter[]
): AstraTableHeadCell<any>[] => {
  // check if this correct way to sort head cells
  const sortedParameters = parameters.sort((a, b) => a.id - b.id);
  const headCells: AstraTableHeadCell<any>[] = [
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Наименование',
    },
  ];

  const createHeadCell = (item: EntityParameter) => ({
    id: item.id.toString(),
    numeric: item.parameterType === 'number',
    disablePadding: false,
    label: item.title,
    sortableProperty: item.settings.sortable || true, // TODO update after api rework
    allowedValues: item.settings.allowedValues || undefined,
  });

  const updatedHeadCells = sortedParameters.map(createHeadCell);
  return headCells.concat(updatedHeadCells);
};

export const renderEntityTableRow = (
  row: Entity,
  props: AstraTableCellExtraProps<Entity>,
  relatedParameters: EntityParameter[]
): React.ReactNode => {
  function getAstraTableCell(key: string, param: ParameterValue) {
    // TODO update after redux rework
    const relatedParameter = relatedParameters.find(
      (el) => el.id === parseInt(key)
    );
    const parameterType = relatedParameter?.parameterType;

    if (parameterType === undefined) return <></>;

    return (
      <AstraTableCell
        {...props}
        propName="parameters"
        initialValue={getInitialValueByType(parameterType)}
        cellType={parameterType}
        key={`param-${key}-${row.id}`}
      >
        {toStringParamValueByType(param.value, parameterType)}
      </AstraTableCell>
    );
  }

  return (
    <>
      <AstraTableCell
        {...props}
        propName="title"
        initialValue={''}
        cellType="text"
        key={`title-${row.id}`}
      >
        <div>{row.title}</div>
      </AstraTableCell>
      {Object.entries(row.parameters).map(([key, param], index) =>
        getAstraTableCell(key, param)
      )}
    </>
  );
};
