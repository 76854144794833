import { FC } from 'react';
import { ControlPosition } from 'react-map-gl';

import { ControlContainer } from '../ControlContainer';

import { GeocoderAutocomplete } from './GeocoderAutocomplete';

interface GeocoderProps {
  position: ControlPosition;
}

export const Geocoder: FC<GeocoderProps> = ({ position }) => (
  <ControlContainer position={position}>
    <GeocoderAutocomplete />
  </ControlContainer>
);
