import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface FieldWrapperProps {
  label: string;
  element: ReactNode;
  className?: string;
}

export const FieldWrapper: FC<FieldWrapperProps> = ({
  label,
  element,
  className,
}) => (
  <div
    className={cn(
      'pr-4 py-3 [&_svg]:opacity-0 [&:hover_svg]:opacity-100',
      className
    )}
  >
    <div className="tpg-c2 text-tpg_light">{label}</div>
    <div>{element}</div>
  </div>
);
