import { constructIdentifier } from 'utils/entity';

import { EntityNode } from './types';

export const renderNodeName = (node: EntityNode) => {
  /*
   TODO: temporary hidden until access sharing is ready
  if (node.data.isFolder && !node.level) {
    return (
      <span className="tpg-b2 text-tpg_title group-[.selected]:text-[inherit] group-hover:text-[inherit]">
        {node.data.name}
      </span>
    );
  }
  */

  const constructText = (text: string, colorClassName: string) => (
    <span
      className={`${colorClassName} text-tpg group-[.selected]:text-[inherit] group-hover:text-[inherit]`}
    >
      {text}
    </span>
  );

  return (
    <p className="flex flex-row gap-1">
      {!node.data.isFolder &&
        constructText(constructIdentifier(node.data.id), 'text-tpg_light')}
      {constructText(node.data.name, 'text-tpg_base')}
    </p>
  );
};
