import { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { GroupBase } from 'react-select';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ISelectOption, IStaticFilters } from 'interfaces';
import { monitoringActions } from 'store';
import {
  parserSelector,
  staticFiltersSelector,
} from 'store/slices/monitoring/selectors';
import {
  TDateFiltersKeys,
  TFiltersKeys,
  TMediaUnion,
  TPublicationUnion,
  TSourceUnion,
} from 'types';

import DateRange from 'components/DateRange';
import { RadioGroup } from 'components/ui';

import Filter from './Filter';

const StaticFilters = () => {
  const dispatch = useAppDispatch();
  const { source, startDate, endDate, media, post, searchMode } =
    useAppSelector(staticFiltersSelector);
  const parsers = useAppSelector(parserSelector);

  const currentId = useMemo(() => {
    return (
      media.find((item: IStaticFilters<TMediaUnion>) => item.checked)?.value ||
      ''
    );
  }, [media]);

  const handleChangeDate = useCallback(
    (field: TDateFiltersKeys) => (date: Date | null) => {
      dispatch(monitoringActions.setDateRange({ field, date }));
    },
    []
  );

  const handleToggle = useCallback((value: string) => {
    dispatch(monitoringActions.toggleMediaCheckbox(value));
  }, []);

  const handleChangeCheckbox = useCallback(
    (field: TFiltersKeys) => (value: TSourceUnion | TPublicationUnion) => {
      dispatch(monitoringActions.toggleCheckbox({ field, value }));
    },
    []
  );

  const [selectedParsers, setSelectedParsers] = useState<ISelectOption[]>([]);

  useEffect(() => {
    const newParsersState = parsers.map((parser) => {
      const isSelected = selectedParsers.some(
        (el) => el.value === parser.value
      );
      return isSelected
        ? { ...parser, checked: true }
        : { ...parser, checked: false };
    });

    dispatch(
      monitoringActions.setParsers(newParsersState as IStaticFilters<string>[])
    );
  }, [selectedParsers]);

  return (
    <>
      <Filter<TSourceUnion>
        title="Источник"
        array={source}
        onChange={handleChangeCheckbox('source')}
      />
      <div className="sidebar__filter colored-title">
        <RadioGroup<string>
          title="Медиа-файлы"
          options={media}
          currentId={currentId}
          onChange={handleToggle}
        />
      </div>
      <Filter<TPublicationUnion>
        title="Тип публикации"
        array={post}
        onChange={handleChangeCheckbox('post')}
      />
      <div className="sidebar__datepicker">
        <h2 className="tpg-title-four  colored-title subtitle">Дата</h2>
        <DateRange
          startDate={startDate}
          endDate={endDate}
          onStartChange={handleChangeDate('startDate')}
          onEndChange={handleChangeDate('endDate')}
        />
      </div>
      <div className="sidebar__datepicker">
        <h2 className="tpg-title-four colored-title subtitle">Выбор парсера</h2>
        <Select<ISelectOption, true, GroupBase<ISelectOption>>
          className="astra-selector"
          classNamePrefix="astra-selector"
          value={selectedParsers}
          options={parsers}
          menuPlacement="auto"
          isMulti
          isClearable
          placeholder="Выбрать"
          onChange={(item) =>
            setSelectedParsers(
              item.map((item) => {
                return { ...item };
              })
            )
          }
        />
      </div>
    </>
  );
};

export default StaticFilters;
