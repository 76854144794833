import { memo } from 'react';
import { IFilters } from 'interfaces';
import { TDynamicFilterItem } from 'types';

export const isLastCategory = (
  obj: string | boolean | TDynamicFilterItem | IFilters
) => {
  const keys = Object.keys(obj).filter((item) => item !== 'active');

  return keys.every((item) => !isNaN(+item));
};

export const isVideo = (url: string) =>
  url.indexOf('astra-video') !== -1 ||
  url.includes('mp4') ||
  url.includes('avi');

export const genericMemo: <T>(component: T) => T = memo;
