const properParseToInt = (number: number | string | null | undefined) => {
  switch (typeof number) {
    case 'number':
      return number;
    case 'string':
      return parseInt(number);
    default:
      return 0;
  }
};

const getProperNumber = (number: number, squared = false) => {
  const KM10 = squared ? 10000 * 1000 : 10000;

  return number < KM10
    ? `${number.toFixed(2)} м${squared ? '2' : ''}`
    : `${(number / (squared ? 1000 * 1000 : 1000)).toFixed(2)} км${
        squared ? '2' : ''
      }`;
};

export const getMeasureString = (value: number | null, squared?: boolean) =>
  `${getProperNumber(properParseToInt(value), squared)}`;
