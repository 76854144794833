import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { NavLinkProps } from 'interfaces';

import './style.scss';

const Container: FC<Omit<NavLinkProps, 'link'>> = memo(
  ({ passiveIcon, activeIcon, title, isActive, onClick }) => (
    <div
      className={cn(
        'header-link',
        {
          'header-link__active': isActive,
        },
        'py-2 px-6'
      )}
      onClick={onClick}
    >
      {isActive ? activeIcon : passiveIcon}
      <p className="header-link__title tpg-b1">{title}</p>
    </div>
  )
);

Container.displayName = 'Container';

export const HeaderLink: FC<NavLinkProps> = memo(
  ({ passiveIcon, title, link, isActive, onClick }) => {
    if (link) {
      return (
        <Link to={link} className="my-auto">
          <Container
            passiveIcon={passiveIcon}
            title={title}
            isActive={isActive}
            onClick={onClick}
          />
        </Link>
      );
    }

    return (
      <Container
        passiveIcon={passiveIcon}
        title={title}
        isActive={isActive}
        onClick={onClick}
      />
    );
  }
);

HeaderLink.displayName = 'HeaderLink';
