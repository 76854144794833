import { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';

import { notify } from 'utils';

import {
  createLangCorpus,
  deleteLangCorpus,
  getLangCorpus,
  updateLangCorpus,
} from '../../../api/monitoring';
import { ILangCorpus, ILangCorpusFeature } from '../../../interfaces';
import { Button, Modal, TextArea, TextInput } from '../../ui';
import ConfirmModal from '../../ui/Modal/ConfirmModal';
import { LangModalProps } from '../Sidebar/Filters/LangCorpusTree';

const LangCorpusModal = ({
  feature,
  handleCloseModal,
}: LangModalProps<ILangCorpusFeature>) => {
  const [isBodyLoading, setBodyLoading] = useState(true);
  const [corpus, setCorpus] = useState<ILangCorpus>();
  const [isDeleteModal, setDeleteModal] = useState(false);

  const modalTitle = useMemo(
    () => (feature?.id ? 'Редактор ЛСЯ' : 'Новое ЛСЯ'),
    []
  );

  const initializeCorpus = () => {
    if (feature) setCorpus({ body: '', name: '', group_id: feature.groupId });
  };

  useEffect(() => {
    const asyncSetCurrentCorpus = async () => {
      if (feature?.id) {
        await getLangCorpus(feature?.id)
          .then((response: AxiosResponse<ILangCorpus>) =>
            setCorpus(response.data)
          )
          .catch(() => notify.error('Не удалось получить ЛСЯ'));
      } else {
        initializeCorpus();
      }
      setBodyLoading(false);
    };
    asyncSetCurrentCorpus();
  }, []);

  const handleCorpusChange =
    <T extends keyof ILangCorpus>(key: T) =>
    (value: ILangCorpus[T]) => {
      if (corpus) {
        setCorpus({ ...(corpus as ILangCorpus), [key]: value });
      }
    };

  const handleSave = async () => {
    if (corpus?.id) {
      await updateLangCorpus(corpus.id, corpus).catch(() =>
        notify.error('Не удалось изменить ЛСЯ')
      );
    } else if (corpus) {
      await createLangCorpus(corpus).catch(() =>
        notify.error('Не удалось создать ЛСЯ')
      );
    }
    handleCloseModal();
  };

  const handleDelete = async () => {
    if (corpus?.id) {
      await deleteLangCorpus(corpus.id).catch(() =>
        notify.error('Не удалось удалить ЛСЯ')
      );
    }
    setDeleteModal(false);
    handleCloseModal();
  };

  return (
    <Modal width={794} keyboard onClose={handleCloseModal}>
      {isDeleteModal && (
        <ConfirmModal
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDelete}
        />
      )}
      <div className={'astra-modal'}>
        <h3 className={'astra-modal__title'}>{modalTitle}</h3>
        <div className={'astra-modal__content'}>
          <div className={'astra-modal__options'}>
            <TextInput
              value={corpus?.name ?? ''}
              placeholder="Название ЛСЯ"
              onChange={handleCorpusChange('name')}
            />
            <TextArea
              readOnly={isBodyLoading}
              rows={7}
              placeholder={'Тело ЛСЯ'}
              value={corpus?.body ?? ''}
              onChange={handleCorpusChange('body')}
            />
          </div>
          <div className={'astra-modal__actions'}>
            <Button
              title={'Удалить'}
              disabled={!corpus?.id}
              onClick={() => {
                setDeleteModal(true);
              }}
            ></Button>
            <Button
              title={'Сохранить'}
              disabled={!(corpus?.body && corpus?.name)}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LangCorpusModal;
