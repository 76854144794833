import { useCallback, useEffect, useState } from 'react';
import { getGroupLinkedEntities, getGroupList } from 'api/access';
import { errorMessages } from 'constants/errors';
import { useAppSelector } from 'hooks';
import { GroupInfo, GroupLinkedEntity } from 'interfaces';
import { accountSelector } from 'store/slices/auth/selectors';

import AccessSidebar, {
  GroupNode,
} from 'components/Access/Sidebar/AccessSidebar';
import LinkedEntitiesTable from 'components/Access/Tables/LinkedEntitiesTable';
import LinkedUsersTable from 'components/Access/Tables/LinkedUsersTable';
import UserTable from 'components/Access/Tables/UserTable';
import { Switch } from 'components/ui';
import { notify } from 'utils';

import { Header } from '../../components/Header/Header';

const AccessScreen = () => {
  const account = useAppSelector(accountSelector);

  const isSuperAdmin = account?.sysRole === 'superadmin';
  const [reload, setReload] = useState(false);
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeNodes, setActiveNodes] = useState<GroupNode[]>([]);
  const [groupLinkedEntities, setGroupLinkedEntities] = useState<
    GroupLinkedEntity[]
  >([]);

  const [groupInfo, setGroupInfo] = useState<GroupInfo>();
  const [isSuperAdminTableView, setSuperAdminTableView] = useState(false);

  useEffect(() => {
    if (activeNodes.length !== 1) {
      setGroupLinkedEntities([]);
      setGroupInfo(undefined);
      return;
    }
    const groupId = Number(activeNodes[0].id.replace('group-', ''));
    const linkedGroupInfo = groupList.find((el) => el.id === groupId);
    setGroupInfo(linkedGroupInfo);

    const fetchGroupLinkedEntities = async () => {
      await getGroupLinkedEntities(groupId)
        .then((res) => setGroupLinkedEntities(res))
        .catch((err) =>
          notify.error(errorMessages.GET_GROUP_LINKED_ENTITIES_ERROR)
        );
    };

    setSuperAdminTableView(false);

    fetchGroupLinkedEntities();
  }, [activeNodes, groupList, reload]);

  useEffect(() => {
    const fetchGroupList = async () => {
      await getGroupList()
        .then((res) => {
          const filteredRes = res.filter(
            (el) => el.myMembership && el.myMembership.role !== 'user'
          );
          setGroupList(filteredRes);
        })
        .catch((err) => notify.error(errorMessages.GET_GROUP_LIST_ERROR));
    };
    fetchGroupList();
  }, [reload]);

  useEffect(() => {
    // disable scroll for access page
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const triggerReload = useCallback(() => {
    setReload((prev) => !prev);
  }, []);

  return (
    <div>
      <Header className="w-full">
        <div className="w-full flex justify-end items-center">
          {isSuperAdmin && (
            <div className="flex items-center gap-2">
              <div className="">Таблица сисадмина</div>
              <Switch
                checked={isSuperAdminTableView}
                onChange={() => setSuperAdminTableView(!isSuperAdminTableView)}
              />
            </div>
          )}
        </div>
      </Header>
      <div className="w-full">
        <AccessSidebar
          data={groupList}
          activeNodes={activeNodes}
          setActiveNodes={setActiveNodes}
          onOperationSuccess={triggerReload}
        />
        <div
          className={`w-[calc(100%-474px)] p-6 ml-[474px] transition-[width,margin] duration-300 ease-in-out`}
        >
          {!isSuperAdminTableView && !groupInfo && (
            <div className="tpg-h4">Выберите группу для начала работы</div>
          )}
          {isSuperAdminTableView ? (
            <div>
              <UserTable searchQuery={searchQuery} />
            </div>
          ) : (
            <div>
              {groupInfo && (
                <div>
                  <div className="flex justify-between">
                    <div className="tpg-h4 pb-4">
                      Правила доступа{' '}
                      {groupLinkedEntities.length === 0 && 'отсутствуют'}
                    </div>
                  </div>
                  {groupLinkedEntities && groupLinkedEntities.length > 0 && (
                    <LinkedEntitiesTable data={groupLinkedEntities} />
                  )}
                </div>
              )}
              {groupInfo && (
                <LinkedUsersTable
                  groupInfo={groupInfo}
                  onSuccessAddUser={triggerReload}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessScreen;
