import { FC, useCallback, useEffect, useState } from 'react';
import { dateOptionsList } from 'constants/filters';
import dayjs from 'dayjs';

import { RadioGroup } from 'components/ui';

import DatePicker from '../ui/DatePicker';

import './style.scss';

type TDate = Date | null;

interface IDateRangeProps {
  startDate: TDate;
  endDate: TDate;
  onStartChange: (date: Date | null) => void;
  onEndChange: (date: Date | null) => void;
}

const DateRange: FC<IDateRangeProps> = ({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
}) => {
  const date = dayjs();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (!startDate && !endDate) {
      setCurrentIndex(0);
    }
  }, [startDate, endDate]);

  const handleRadioClick = useCallback(
    (id: number) => {
      setCurrentIndex((index) => (index !== id ? id : 0));
      onEndChange(date.toDate());

      let currendDate = date;

      switch (id) {
        case 1:
          currendDate = currendDate.subtract(1, 'week');
          break;
        case 2:
          currendDate = currendDate.subtract(1, 'M');
          break;
        case 3:
          currendDate = currendDate.subtract(3, 'M');
          break;
        default:
          currendDate = currendDate.subtract(1, 'd');
          break;
      }

      onStartChange(currendDate.toDate());
    },
    [onStartChange, onEndChange]
  );

  const handleChangeDate = useCallback(
    (setDate: (date: Date | null) => void) => (date: Date | null) => {
      setDate(date);
      setCurrentIndex(0);
    },
    []
  );

  return (
    <div className="date-range__container">
      <div className="datepicker-label tpg-body-one">
        От
        <DatePicker
          selectsStart
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeDate(onStartChange)}
        />
      </div>
      <div className="datepicker-label tpg-body-one">
        До
        <DatePicker
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          selected={endDate}
          onChange={handleChangeDate(onEndChange)}
        />
      </div>
      <div className="radio-range">
        <RadioGroup<number>
          options={dateOptionsList}
          currentId={currentIndex}
          onChange={handleRadioClick}
        />
      </div>
    </div>
  );
};

export default DateRange;
