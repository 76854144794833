import { getImagery, updateImagery } from 'api/map';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  IImageryObject,
  IImageryRequest,
  ImageryResponse,
  ImageryUpdateRequest,
} from 'interfaces';

import { buildThunk } from 'utils';

export const getImageryThunk = buildThunk<ImageryResponse, IImageryRequest>(
  `${reducersNames.MAP_V2}/${asyncActionsNames.GET_IMAGERY}`,
  errorMessages.GET_IMAGERY_ERROR,
  getImagery
);

export const updateImageryThunk = buildThunk<
  IImageryObject,
  ImageryUpdateRequest
>(
  `${reducersNames.MAP_V2}/${asyncActionsNames.UPDATE_IMAGERY}`,
  errorMessages.UPDATE_IMAGERY_ERROR,
  updateImagery
);

export const getImageryPreviewThunk = buildThunk<
  ImageryResponse,
  IImageryRequest
>(
  `${reducersNames.MAP_V2}/${asyncActionsNames.GET_IMAGERY_PREVIEW}`,
  errorMessages.GET_IMAGERY_PREVIEW_ERROR,
  getImagery
);
