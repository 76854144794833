import {
  MapboxEvent,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
} from 'react-map-gl';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { ReactComponent as AimIcon } from 'images/newIcons/aim.svg';
import { showAimSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { CustomMarker } from 'components/ui';

interface AimSourceProps {
  handleMapContextMenuOpen: (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => void;
}

export const AimSource = ({ handleMapContextMenuOpen }: AimSourceProps) => {
  const { mapRef } = useMapRef();
  const showAim = useAppSelector(showAimSelector);

  if (!showAim || !mapRef.current) {
    return null;
  }

  const mapCenter = mapRef.current.getCenter();

  const mapContainer = mapRef.current.getContainer();

  const setMapContextMenuWithDelay = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    setTimeout(() => {
      handleMapContextMenuOpen(e);
    }, 100);
  };

  const constructMapLayerEvent = (e: MapboxEvent<MouseEvent>) => {
    return {
      ...e,
      lngLat: { lng: mapCenter.lng, lat: mapCenter.lat },
      point: {
        x: mapContainer.clientWidth / 2,
        y: mapContainer.clientHeight / 2,
      },
    };
  };

  return (
    <CustomMarker
      longitude={mapCenter.lng}
      latitude={mapCenter.lat}
      icon={<AimIcon />}
      onClick={(e) =>
        setMapContextMenuWithDelay(
          constructMapLayerEvent(e) as MapLayerMouseEvent
        )
      }
    />
  );
};
