import { FC, memo, SyntheticEvent } from 'react';
import cn from 'classnames';
import { ReactComponent as Alert } from 'images/icons/alert-circle.svg';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';
import { IGeospoofEntity } from 'interfaces';
import { TAvatarUnion } from 'types';

import Avatar from 'components/ui/Avatar';
import { TooltipWrap } from 'components/ui/Tooltip';
import { copyToClipboard, getFormattedDate, getFullName } from 'utils';

import './style.scss';

export interface IGeospoofCardProps extends IGeospoofEntity {
  type: TAvatarUnion;
  active: boolean;
  onClick?: () => void;
  onAvatarClick?: () => void;
  onUserLocation?: () => void;
  loading?: boolean;
}

const GeospoofCard: FC<IGeospoofCardProps> = ({
  name,
  link,
  id,
  type,
  active = false,
  username,
  firstname,
  lastname,
  machine,
  mediaArray,
  timestamp = null,
  thumbnail = null,
  isMediaLoading = false,
  user,
  onClick,
  onAvatarClick,
  onUserLocation,
  request_failed = false,
}) => {
  const getTitle = () => {
    let title = '';

    switch (type) {
      case 'user':
        title = getFullName(firstname, lastname);
        break;
      case 'group':
        title = name || '';
        break;
      case 'search':
        title = user || getFullName(firstname, lastname);
        break;
    }

    return title.replaceAll("'", '');
  };

  const isSearch = type === 'search';

  const visibleUsername = username ? ` @${username.replaceAll("'", '')}` : null;

  const visibleId = `ID: ${id}`;

  const onUserLocationClick = (e: SyntheticEvent<HTMLDivElement>) => {
    if (onUserLocation) {
      e.stopPropagation();
      onUserLocation();
    }
  };

  const handleCopy = (value: string) => (e: SyntheticEvent) => {
    e.stopPropagation();
    copyToClipboard(value);
  };

  const renderDate = () =>
    isSearch && timestamp ? (
      <div>{getFormattedDate(timestamp)}</div>
    ) : undefined;

  const renderUserLocationButton = () =>
    onUserLocation ? (
      <div onClick={onUserLocationClick} className="hover:text-tpg_title">
        Вычислить
      </div>
    ) : undefined;

  const getCardInfo = () =>
    isSearch ? (
      <div>{machine}</div>
    ) : (
      <div className="flex justify-end gap-2">
        {visibleUsername && (
          <div
            className="geospoof-card-info flex items-center gap-1"
            onClick={handleCopy(visibleUsername)}
          >
            <TooltipWrap title={visibleUsername}>
              <div className="truncate max-w-[90px]">{visibleUsername}</div>
            </TooltipWrap>
            <Copy />
          </div>
        )}
        <div
          className="geospoof-card-info flex items-center gap-1"
          onClick={handleCopy(id)}
        >
          <TooltipWrap title={visibleId}>
            <div className="truncate max-w-[90px]">{visibleId}</div>
          </TooltipWrap>
          <Copy />
        </div>
      </div>
    );

  return (
    <div
      id={`geospoof-card-${id}`}
      className={cn('flex items-center self-stretch gap-4 cursor-pointer')}
      onClick={onClick}
    >
      <div className="w-12 h-12 justify-center items-center flex">
        <Avatar
          size={48}
          type={type}
          theme={'grey'}
          src={thumbnail}
          onClick={onAvatarClick}
          isMediaLoading={isMediaLoading}
          showHoverIcon={type !== 'search'}
          isActive={active}
        />
      </div>
      <div className="group flex flex-col items-start gap-1 grow">
        <div
          className={cn(
            'flex items-center justify-between self-stretch tpg-b2',
            active
              ? 'text-bright_product'
              : 'text-tpg_title group-hover:text-tpg_base'
          )}
        >
          {getTitle()}
          {request_failed && (
            <TooltipWrap
              placement="right"
              title="Координаты пользователя недоступны"
            >
              <Alert />
            </TooltipWrap>
          )}
        </div>
        <div className="flex justify-between items-center self-stretch gap-2.5 tpg-c2 text-tpg_base">
          <div>{renderDate() || renderUserLocationButton()}</div>
          <div className="flex justify-end items-center g row gap-2 max-w-[230px]">
            {getCardInfo()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GeospoofCard);
