import { FC } from 'react';
import cn from 'classnames';

import { Modal } from 'components/ui';
import { IModalProps } from 'components/ui/Modal';
import { CloseButton } from 'components/ui/Modal/CloseButton';

import { IMediaGalleryProps, MediaGallery } from './MediaGallery';

type TMediaGalleryModalProps = Omit<IModalProps, 'children'> &
  IMediaGalleryProps;

export const MediaGalleryModal: FC<TMediaGalleryModalProps> = ({
  mediaFiles,
  actions,
  isDraggable,
  isLoading,
  width = '100%',
  className,
  onSlideDrop,
  onClose,
  ...props
}) => (
  <Modal
    keyboard
    width={width}
    interceptFocus
    className={cn('!w-full h-full p-6 bg-ultrablack', className)}
    onClose={onClose}
    {...props}
  >
    <CloseButton className="p-6" onClick={onClose} />
    <MediaGallery
      mediaFiles={mediaFiles}
      actions={actions}
      isDraggable={isDraggable}
      isLoading={isLoading}
      onSlideDrop={onSlideDrop}
    />
  </Modal>
);
