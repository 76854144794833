import { RefObject, useCallback, useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement, A>(
  { current }: RefObject<T>,
  callback: () => A
) => {
  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [current]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as T;

      if (current && !current?.contains(target)) {
        callback();
        event.stopPropagation();
      }
    },
    [callback, current]
  );
};
