import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Action } from 'redux';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import {
  clusterLayersObjectsSelector,
  showAimSelector,
  showCustomCursorsSelector,
  showLabelsSelector,
  showLayersSelector,
} from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { documentCoordinatesActions } from 'store/slices/mapV2/mapReducer/toolsReducer/documentCoordinateSlice';
import { documentCoordinatesSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/documentCoordinateSlice/selectors';

import { Header } from 'components/Header/Header';
import { burgerLocalStorage } from 'utils/localStorage';

import { Counters } from './Counters';

export const MapHeader = () => {
  const showAim = useAppSelector(showAimSelector);
  const showCustomCursors = useAppSelector(showCustomCursorsSelector);
  const showLayers = useAppSelector(showLayersSelector);
  const showLabels = useAppSelector(showLabelsSelector);
  const clusterObjects = useAppSelector(clusterLayersObjectsSelector);
  const documentCoordinates = useAppSelector(documentCoordinatesSelector);

  const currentBurgerSettings = {
    showAim,
    showCustomCursors,
    showLabels,
    showLayers,
    clusterObjects,
  };

  const [burgerSettings, setBurgerSettings] = burgerLocalStorage(
    currentBurgerSettings
  );

  const dispatch = useAppDispatch();

  const getNewBurgerSettings = (item: string, value: boolean) => {
    return {
      ...currentBurgerSettings,
      [item]: value,
    };
  };

  const handleToggleMenuItem =
    (item: string, value: boolean, action: (value: boolean) => Action) =>
    () => {
      dispatch(action(!value));
      setBurgerSettings(getNewBurgerSettings(item, !value));
    };

  const resetDocumentCoordinates = () =>
    dispatch(documentCoordinatesActions.resetDocumentCoordinates());

  const burgerMenuItems = [
    {
      title: `${showAim ? 'Выключить' : 'Включить'} прицел`,
      onClick: handleToggleMenuItem(
        'showAim',
        showAim,
        settingsActions.setShowAim
      ),
    },
    {
      title: `${
        showCustomCursors ? 'Отключить' : 'Включить'
      } кастомные курсоры`,
      onClick: handleToggleMenuItem(
        'showCustomCursors',
        showCustomCursors,
        settingsActions.setShowCursors
      ),
    },
    {
      title: showLabels
        ? 'Скрыть названия с карты'
        : 'Отобразить названия на карте',
      onClick: handleToggleMenuItem(
        'showLabels',
        showLabels,
        settingsActions.setShowLabels
      ),
    },
    {
      title: showLayers
        ? 'Скрыть объекты на карте'
        : 'Показать объекты на карте',
      onClick: handleToggleMenuItem(
        'showLayers',
        showLayers,
        settingsActions.setShowLayers
      ),
    },
    {
      title: clusterObjects
        ? 'Разгруппировать объекты'
        : 'Группировать объекты',
      onClick: handleToggleMenuItem(
        'clusterObjects',
        clusterObjects,
        settingsActions.setClusterLayersObjects
      ),
    },
  ];

  useEffect(() => {
    dispatch(settingsActions.setShowAim(burgerSettings.showAim));
    dispatch(settingsActions.setShowCursors(burgerSettings.showCustomCursors));
    dispatch(settingsActions.setShowLabels(burgerSettings.showLabels));
    dispatch(settingsActions.setShowLayers(burgerSettings.showLayers));
    dispatch(
      settingsActions.setClusterLayersObjects(burgerSettings.clusterObjects)
    );
  }, []);

  return (
    <Header burgerMenuItems={burgerMenuItems} className="w-full">
      <div className="flex justify-between items-center w-full mx-11">
        <div className="flex items-center">
          <Counters />
          <div className="pr-8" />
          {!!documentCoordinates.length && (
            <div className="tpg-c2">
              <span
                className="cursor-pointer text-bright_product hover:text-dark_product"
                onClick={resetDocumentCoordinates}
              >
                сбросить
              </span>
              <span className="text-tpg_base pl-3">
                объектов из документа: {documentCoordinates.length}{' '}
              </span>
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};
