import { featurePermission } from 'constants/profile';
import { authSelector } from 'store/slices/auth/selectors';

import { useAppSelector } from './redux';

export const usePermission = (feature: string) => {
  const { privileges } = useAppSelector(authSelector);

  if (privileges?.role) {
    return featurePermission[feature].includes(privileges.role);
  }

  return false;
};
