import { SourceProps } from 'react-map-gl';
import { RasterLayer, Style } from 'mapbox-gl';

export const googleSatelliteSource: SourceProps = {
  type: 'raster',
  tiles: ['https://mt0.google.com/vt/lyrs=s&hl=ru&x={x}&y={y}&z={z}'],
  name: 'google-satellite-source',
  id: 'google-satellite-source',
  maxzoom: 20,
  tileSize: 256,
};
export const googleSatelliteLayer: RasterLayer = {
  id: 'google-satellite-layer',
  source: 'google-satellite-source',
  type: 'raster',
  minzoom: 0,
};
export const googleLabelSource: SourceProps = {
  type: 'raster',
  tiles: ['https://mt0.google.com/vt/lyrs=h&hl=ru&x={x}&y={y}&z={z}'],
  name: 'google-streets-source',
  id: 'google-streets-source',
  tileSize: 256,
};
export const googleLabelLayer: RasterLayer = {
  id: 'google-streets-layer',
  source: 'google-streets-source',
  type: 'raster',
  minzoom: 0,
};
export const googleSatelliteStyle: Style = {
  version: 8,
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {
    'google-satellite-source': googleSatelliteSource,
  },
  layers: [googleSatelliteLayer],
};
