import { FC, Fragment, memo, useMemo } from 'react';
import { FeatureCollection } from '@turf/turf';
import { DOCUMENT_POINT } from 'constants/map';
import { Point } from 'geojson';
import { useAppSelector } from 'hooks';
import { DocCoordinates } from 'interfaces/documents';
import { clusterLayersObjectsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { MapFeatureProperties } from 'types';

import { PointSource } from '../PointSource';

import { getPointLabelConfig, getZoomBasedPointConfig } from './config';

interface DocumentSourceProps {
  points: FeatureCollection<Point, MapFeatureProperties>;
  mapRef: mapboxgl.Map | null;
}

const CLUSTER_TYPE_LABEL = {
  label: [
    [
      'concat',
      ['get', 'label'],
      [
        'case',
        ['in', ['accumulated'], ['get', 'label']],
        '',
        ['concat', ', ', ['accumulated']],
      ],
    ],
    ['get', 'type'],
  ],
};

export const useDocumentSourceData = (entities: DocCoordinates[]) => {
  const points = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: entities.map(({ title, coordinates }) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coordinates[1], coordinates[0]],
        },
        properties: {
          label: title,
          title,
          width: 16,
        },
      })),
    } as FeatureCollection<Point, MapFeatureProperties>;
  }, [entities]);

  return { points };
};

export const DocumentsSource: FC<DocumentSourceProps> = memo(
  ({ points, mapRef }) => {
    const clusterObjects = useAppSelector(clusterLayersObjectsSelector);
    const rerenderPointsTrigger = String(clusterObjects);

    return (
      <Fragment key={rerenderPointsTrigger}>
        <PointSource
          id={`${DOCUMENT_POINT}`}
          mainConfig={getZoomBasedPointConfig()}
          mainLabelConfig={getPointLabelConfig()}
          data={points}
          cluster={clusterObjects}
          clusterMaxZoom={15}
          clusterRadius={30}
          clusterProperties={CLUSTER_TYPE_LABEL}
        />
      </Fragment>
    );
  }
);

DocumentsSource.displayName = 'DocumentsSource';
