import { modes } from '@mapbox/mapbox-gl-draw';
import { MapboxDrawCustomEvents } from 'constants/map';

const DrawPolygonModeOverride = Object.assign({}, modes.draw_polygon);

// events miss featureTarget sometimes for some reasons, so we need to add it explicitly
const _onMouseMove = DrawPolygonModeOverride.onMouseMove;
const _onTap = DrawPolygonModeOverride.onTap;

DrawPolygonModeOverride.onMouseMove = function (state, e) {
  _onMouseMove?.apply?.(this, [state, e]);

  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, {
    ...e,
    featureTarget,
  });
};

DrawPolygonModeOverride.onTap = function (state, e) {
  _onTap?.apply?.(this, [state, e]);

  // onTap event misses featureTarget for some reasons, so we need to add it explicitly
  const featureTarget = state.polygon.toGeoJSON();

  this.map.fire(MapboxDrawCustomEvents.TAP, {
    ...e,
    featureTarget,
  });
};

export { DrawPolygonModeOverride };
