import { FC, memo } from 'react';

import './style.scss';

type SwitchSizeUnion = 's' | 'm';

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  size?: SwitchSizeUnion;
}

const Switch: FC<SwitchProps> = ({
  checked,
  disabled,
  onChange,
  size = 's',
}) => (
  <label className={`switch-${size}`}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <span className={`switch-${size}__handle`} />
  </label>
);

export default memo(Switch);
