import { SymbolLayer } from 'react-map-gl';
import { LineLayer, VectorSource } from 'mapbox-gl';

import { labelLayout } from '../common';

export const RAILROADS_URL = process.env.REACT_APP_RAILROADS_URL ?? '';

export const railroadsSource: VectorSource = {
  type: 'vector',
  id: 'railroad-vector-source',
  url: RAILROADS_URL,
};

export const railroadsLineLayer: LineLayer = {
  id: 'zhd-line-layer',
  type: 'line',
  source: 'railroad-vector-source',
  'source-layer': 'dissolvedv2',
  paint: {
    'line-width': 2.5,
    'line-color': '#D3D3D3',
  },
};

export const railroadsSymbolLayer: SymbolLayer = {
  id: 'zhd-symbol-layer',
  type: 'symbol',
  source: 'railroad-vector-source',
  'source-layer': 'dissolvedv2',
  layout: {
    ...labelLayout,
    'text-field': ['get', 'NAME'],
    'text-size': 9,
  },
  paint: {
    'text-color': '#c0c0c0',
    'text-halo-color': '#232222',
    'text-halo-width': 10,
  },
};
