import { FC } from 'react';
import cn from 'classnames';

import { IMediaGalleryAction } from './types';

interface IMediaGalleryControlsProps {
  slidesCount: number;
  currentSlide: number;
  isPrevSlideDisabled: boolean;
  isNextSlideDisabled: boolean;
  actions?: IMediaGalleryAction[];
  onCurrentSlideChange: (currentSlide: number) => void;
  onPrevSlide: () => void;
  onNextSlide: () => void;
}

export const MediaGalleryControls: FC<IMediaGalleryControlsProps> = ({
  slidesCount,
  currentSlide,
  isPrevSlideDisabled,
  isNextSlideDisabled,
  actions,
  onCurrentSlideChange,
  onPrevSlide,
  onNextSlide,
}) => {
  const currentSlideDisplayable = currentSlide + 1;

  return (
    <div className="flex justify-between py-4 tpg-c2 text-tpg_title">
      <div className="flex gap-8">
        <span>
          {currentSlideDisplayable} / {slidesCount}
        </span>
        <div className="flex gap-2">
          <span
            className={cn(
              'select-none cursor-pointer',
              isPrevSlideDisabled && 'cursor-defualt text-tpg_base'
            )}
            onClick={onPrevSlide}
          >
            Назад
          </span>
          <span
            className={cn(
              'select-none cursor-pointer',
              isNextSlideDisabled && 'cursor-default text-tpg_base'
            )}
            onClick={onNextSlide}
          >
            Вперед
          </span>
        </div>
        <span className="text-tpg_base">
          Приложение №{currentSlideDisplayable}
        </span>
      </div>
      {!!actions?.length && (
        <div className="flex gap-3">
          {actions.map((action) => (
            <span
              key={action.title}
              className="select-none cursor-pointer"
              onClick={() =>
                action.onClick(slidesCount, currentSlide, onCurrentSlideChange)
              }
            >
              {action.title}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
