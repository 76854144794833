import { useCallback, useEffect, useMemo, useState } from 'react';
import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMap, useMapRef } from 'hooks/map';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { ReactComponent as Minus } from 'images/newIcons/minus.svg';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import mapboxgl from 'mapbox-gl';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import { coordsModeSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { useDebouncedCallback } from 'use-debounce';

import { ScaleBar } from 'components/Map/ScaleBar';
import ClosableTooltip from 'components/ui/ClosableTooltip';
import { copyToClipboard, getZoomPercent } from 'utils';
import * as utils from 'utils/coordinates';

import './MapFooter.scss';

const ZOOM_CHANGE_STEP = 1;

export const MapFooter = () => {
  const mapContext = useMap();
  const { mapRef } = useMapRef();
  const coordsMode = useAppSelector(coordsModeSelector);
  const [zoom, setZoom] = useState('0');
  const [formattedCoords, setFormattedCoords] = useState('0.000, 0.000');
  const [wgsCoords, setWgsCoords] = useState(new mapboxgl.LngLat(0, 0));

  const dispatch = useAppDispatch();

  const [minZoom, maxZoom] = useMemo(
    () => [mapRef.current?.getMinZoom(), mapRef.current?.getMaxZoom()],
    [mapRef]
  );

  const visibleCoordsMode = utils.getVisibleCoordMode(coordsMode);

  const setCoords = (lat: number, lng: number) => {
    setFormattedCoords(utils.formatWgsCoords(lat, lng));
    dispatch(
      settingsActions.setCenterCoords({
        b: lat,
        l: lng,
        h: DEFAULT_CONVERTER_HEIGHT,
      })
    );
  };

  const setConvertedCoords = async (
    lat: number,
    lng: number,
    targetCrs: 'sk42' | 'usk2000'
  ) => {
    const data = await convertCoordinates('wgs', targetCrs, {
      b: lat,
      l: lng,
      h: DEFAULT_CONVERTER_HEIGHT,
    });

    if (data.to === targetCrs) {
      setFormattedCoords(utils.formatXYCoords(data.payload.x, data.payload.y));
      dispatch(settingsActions.setCenterCoords(data.payload));
    }
  };

  const debouncedSetCoords = useDebouncedCallback(setCoords, 20);

  const debouncedSetConvertedCoords = useDebouncedCallback(
    setConvertedCoords,
    20
  );

  const handleZoomUpdate = (zoomProcessor: (currentZoom: number) => number) => {
    mapRef.current?.flyTo({
      zoom: zoomProcessor(mapRef.current?.getZoom()),
      center: mapRef.current?.getCenter(),
    });
  };

  const handleMinusClick = () =>
    handleZoomUpdate((zoomLvl) => zoomLvl - ZOOM_CHANGE_STEP);

  const handlePlusClick = () =>
    handleZoomUpdate((zoomLvl) => zoomLvl + ZOOM_CHANGE_STEP);

  const updateZoom = (map: mapboxgl.Map) => {
    const zoomPercentage = getZoomPercent(
      map.getZoom(),
      minZoom,
      maxZoom
    ).toFixed();

    setZoom(zoomPercentage);
  };

  const updateCoords = (map: mapboxgl.Map) => setWgsCoords(map.getCenter());

  const debouncedUpdater = useDebouncedCallback((map) => {
    updateZoom(map);
    updateCoords(map);
  }, 5);

  const getTooltipOverlay = () => (
    <>
      <div
        className="bar-droplist-option hover:text-tpg_title cursor-pointer select-none"
        onClick={() => dispatch(settingsActions.setCoordsMode('wgs'))}
      >
        WGS
      </div>
      <div
        className="bar-droplist-option hover:text-tpg_title cursor-pointer select-none"
        onClick={() => dispatch(settingsActions.setCoordsMode('sk42'))}
      >
        СК-42
      </div>
      <div
        className="bar-droplist-option hover:text-tpg_title cursor-pointer select-none"
        onClick={() => dispatch(settingsActions.setCoordsMode('usk2000'))}
      >
        УСК2000
      </div>
    </>
  );

  const handleCopyCoords = useCallback(
    () => copyToClipboard(formattedCoords),
    [formattedCoords]
  );

  useEffect(() => {
    mapRef.current && debouncedUpdater(mapRef.current);
  }, [mapContext]);

  useEffect(() => {
    if (coordsMode === 'wgs') {
      debouncedSetCoords(wgsCoords.lat, wgsCoords.lng);
    } else if (coordsMode === 'sk42' || coordsMode === 'usk2000') {
      debouncedSetConvertedCoords(wgsCoords.lat, wgsCoords.lng, coordsMode);
    }
  }, [coordsMode, wgsCoords]);

  return (
    <footer className="tpg-c2 map_footer flex flex-row justify-between items-center py-2 px-6">
      <ScaleBar
        zoom={mapContext.viewport.zoom}
        latitude={mapContext.viewport.latitude}
      />
      <div className="flex items-center">
        <ClosableTooltip
          overlay={getTooltipOverlay()}
          trigger="click"
          overlayClassName="bar-droplist"
        >
          <div className="flex items-center mr-2 cursor-pointer">
            {visibleCoordsMode}
            <Down className="icon-container" />
          </div>
        </ClosableTooltip>
        <div className="flex items-center mr-6">
          <span className="mr-2">{formattedCoords}</span>
          <Copy
            className="icon-container copy-icon cursor-pointer"
            onClick={handleCopyCoords}
          />
        </div>
        <span className="mr-1">МАСШТАБ {zoom}%</span>
        <Minus
          className="icon-container cursor-pointer"
          onClick={handleMinusClick}
        />
        <Plus
          className="icon-container cursor-pointer"
          onClick={handlePlusClick}
        />
      </div>
    </footer>
  );
};
