import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';

import { notify } from 'utils';

import { getPermissionGroups, getUserList } from '../../api/access';
import { AccessRuleCode } from '../../constants/entities';
import { errorMessages } from '../../constants/errors';
import { useAppSelector } from '../../hooks';
import { ISelectOption } from '../../interfaces';
import { EntityAccessRule, SubjectType } from '../../interfaces/entity';
import { accountSelector } from '../../store/slices/auth/selectors';
import { EntityWithRelations } from '../../types/entities';

import CustomAccessRule from './CustomAccessRule';

interface AccessControlFormProps {
  entity: EntityWithRelations;
  maxAccessRulesLength?: number;
  accessRules: EntityAccessRule[];
  setAccessRules: (accessRules: EntityAccessRule[]) => void;
  subjectOptionsFilter?: (options: ISelectOption) => boolean;
  enforcedAccessRule?: AccessRuleCode;
  removeGroupsIfPersonalSpace?: boolean;
}

export const getSubjectID = (id: number, subjectType: SubjectType) =>
  `${subjectType}-${id}`;

export const parseSubjectID = (subjectId: string): [SubjectType, number] => {
  const [type, id] = subjectId.split('-');
  return [type as SubjectType, Number.parseInt(id)];
};

export const AccessControlForm: FC<AccessControlFormProps> = ({
  entity,
  accessRules,
  setAccessRules,
  maxAccessRulesLength,
  subjectOptionsFilter = () => true,
  enforcedAccessRule,
  removeGroupsIfPersonalSpace = true,
}) => {
  const currentUser = useAppSelector(accountSelector);
  const [subjectOptions, setSubjectOptions] = useState<ISelectOption[]>([]);

  const selectedSubjectIds = useMemo(
    () =>
      new Set(accessRules.map((i) => getSubjectID(i.subjectID, i.subjectType))),
    [accessRules]
  );

  const getPermissionGroupsIfNonPersonal = useCallback(async () => {
    if (removeGroupsIfPersonalSpace && entity.isPersonalSpace) {
      return [];
    } else {
      return await getPermissionGroups();
    }
  }, [entity, removeGroupsIfPersonalSpace]);

  const getEmptyAccessRule = (entityID: number): EntityAccessRule => ({
    subjectTitle: currentUser ? currentUser.verboseName : '',
    subjectID: currentUser ? currentUser.id : 0,
    subjectType: 'user',
    ACL: AccessRuleCode.READWRITE,
    entityID,
  });

  useEffect(() => {
    const fetchAndSetSubjectSelectOptions = async () => {
      await Promise.all([getPermissionGroupsIfNonPersonal(), getUserList()])
        .then((result) => {
          const [groups, users] = result;
          const unfilteredSubjectOptions = [
            ...users.map(
              (i): ISelectOption => ({
                label: i.verboseName,
                value: getSubjectID(i.id, 'user'),
              })
            ),
            ...groups
              .filter(
                (i) =>
                  i.myMembership &&
                  ['owner', 'admin'].includes(i.myMembership.role)
              )
              .map(
                (i): ISelectOption => ({
                  label: i.title,
                  value: getSubjectID(i.id, 'group'),
                })
              ),
          ];

          setSubjectOptions(
            unfilteredSubjectOptions.filter(subjectOptionsFilter)
          );
        })
        .catch(() =>
          notify.error(errorMessages.GET_ACCESS_RULE_SUBJECTS_ERROR)
        );
    };
    fetchAndSetSubjectSelectOptions();
  }, []);

  // if no updatedAccessRule is provided - element with target index is removed from array
  const updateAccessRules = (
    targetIdx: number,
    updatedAccessRule?: EntityAccessRule
  ) => {
    const updatedAccessRules = accessRules.filter(
      (_, idx) => idx !== targetIdx
    );
    if (updatedAccessRule) {
      updatedAccessRules.splice(targetIdx, 0, updatedAccessRule);
    }
    setAccessRules(updatedAccessRules);
  };

  const updateActionSubject =
    (accessRule: EntityAccessRule, targetIdx: number) =>
    (updatedSubjectStrId: string) => {
      if (!updatedSubjectStrId) {
        return;
      }
      const [subjectType, subjectID] = parseSubjectID(updatedSubjectStrId);
      updateAccessRules(targetIdx, { ...accessRule, subjectType, subjectID });
    };

  const updateActionRule =
    (accessRule: EntityAccessRule, targetIdx: number) =>
    (updatedRuleCode: AccessRuleCode) =>
      updateAccessRules(targetIdx, { ...accessRule, ACL: updatedRuleCode });

  const removeAction = (targetIdx: number) => () =>
    updateAccessRules(targetIdx);

  const addNewAccessRule = () =>
    setAccessRules([...accessRules, getEmptyAccessRule(entity.entity.id)]);

  const canAddNewRule = useMemo(() => {
    const isSomeAccessRuleEmpty = accessRules.some(
      (rule) => rule.subjectID === 0
    );
    const isAccessRulesLengthLimitExceeded =
      maxAccessRulesLength && accessRules.length >= maxAccessRulesLength;

    return !isSomeAccessRuleEmpty && !isAccessRulesLengthLimitExceeded;
  }, [accessRules, maxAccessRulesLength]);

  // function excludes all already selected subject options from subject list,
  // but not the current option, so it's still can be rendered
  const excludeSelectedSubjects = (
    options: ISelectOption[],
    thisRule: EntityAccessRule
  ) =>
    options.filter(
      (option) =>
        option.value &&
        (!selectedSubjectIds.has(option.value.toString()) ||
          option.value ===
            getSubjectID(thisRule.subjectID, thisRule.subjectType))
    );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="tpg-b2 pb-3">Доступы</div>
        <Plus
          className={`icon-container ${
            canAddNewRule ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
          }`}
          onClick={canAddNewRule ? addNewAccessRule : undefined}
        />
      </div>
      <div className="pb-3">
        {accessRules.map((accessRule, idx) => (
          <CustomAccessRule
            subject={getSubjectID(accessRule.subjectID, accessRule.subjectType)}
            rule={accessRule.ACL}
            key={`access-rule-${idx}`}
            onUpdateSubject={updateActionSubject(accessRule, idx)}
            onUpdateRule={updateActionRule(accessRule, idx)}
            onRemove={removeAction(idx)}
            subjectSelectOptions={excludeSelectedSubjects(
              subjectOptions,
              accessRule
            )}
            enforcedAccessRule={enforcedAccessRule}
          />
        ))}
      </div>
    </div>
  );
};
