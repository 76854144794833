import { FC, ReactNode } from 'react';
import cn from 'classnames';

export interface EntityControlProps {
  icon: ReactNode;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}

export const EntityControl: FC<EntityControlProps> = ({
  icon,
  title,
  disabled,
  onClick,
}) => (
  <div
    className={cn(
      'flex gap-1 items-center cursor-pointer hover:text-bright_product',
      disabled && '!cursor-default !text-tpg_base [&>svg>path]:fill-tpg_base'
    )}
    onClick={disabled ? undefined : onClick}
  >
    {icon}
    <span className="tpg-c2">{title}</span>
  </div>
);
