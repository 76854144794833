import { useEffect, useMemo, useState } from 'react';

import { notify } from 'utils';

import {
  createLangCorpusGroup,
  updateLangCorpusGroup,
} from '../../../api/monitoring';
import { ILangCorpusGroup, ILangCorpusGroupFeature } from '../../../interfaces';
import { Button, Modal, TextInput } from '../../ui';
import { LangModalProps } from '../Sidebar/Filters/LangCorpusTree';

const LangCorpusGroupModal = ({
  feature,
  handleCloseModal,
}: LangModalProps<ILangCorpusGroupFeature>) => {
  const [corpusGroup, setCorpusGroup] = useState<ILangCorpusGroup>();

  const modalTitle = useMemo(
    () => (feature?.id ? 'Редактор группы' : 'Новая группа'),
    []
  );

  const initializeCorpusGroup = () => {
    if (feature)
      setCorpusGroup({
        parent_group_id: feature.parentGroupId,
        name: feature.name || '',
        id: feature.id,
      });
  };

  useEffect(() => {
    initializeCorpusGroup();
  }, []);

  const handleSave = async () => {
    if (corpusGroup?.id) {
      await updateLangCorpusGroup(corpusGroup.id, corpusGroup).catch(() =>
        notify.error('Не удалось изменить группу')
      );
    } else if (corpusGroup) {
      await createLangCorpusGroup(corpusGroup).catch(() =>
        notify.error('Не удалось создать группу')
      );
    }
    handleCloseModal();
  };

  const handleCorpusGroupChange =
    <T extends keyof ILangCorpusGroup>(key: T) =>
    (value: ILangCorpusGroup[T]) => {
      if (corpusGroup) {
        setCorpusGroup({ ...corpusGroup, [key]: value });
      }
    };

  return (
    <Modal width={472} keyboard onClose={handleCloseModal}>
      <div className={'astra-modal'}>
        <h3 className={'astra-modal__title'}>{modalTitle}</h3>
        <div className={'astra-modal__content'}>
          <div className={'astra-modal__options'}>
            <TextInput
              value={corpusGroup?.name || ''}
              placeholder="Название группы"
              onChange={handleCorpusGroupChange('name')}
              autoFocus
            />
          </div>
          <div className={'astra-modal__actions'}>
            <Button
              title={'Сохранить'}
              disabled={!corpusGroup?.name}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LangCorpusGroupModal;
