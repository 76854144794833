export const openInfraMapConfig = {
  tilejson: '2.2.0',
  name: 'openinframap',
  attribution:
    '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, <a href="https://openinframap.org/copyright">OpenInfraMap</a>',
  bounds: [-180, -85.0511, 180, 85.0511],
  center: [0, 0, 0],
  format: 'pbf',
  scheme: 'xyz',
  minzoom: 2,
  maxzoom: 17,
  tiles: ['https://openinframap.org/tiles/{z}/{x}/{y}.pbf'],
  vector_layers: [
    { id: 'power_line', description: 'Power lines and cables', fields: {} },
    { id: 'power_tower', description: 'Power towers and poles', fields: {} },
    { id: 'power_substation', description: 'Power substations', fields: {} },
    {
      id: 'power_substation_point',
      description: 'Power substations centroids',
      fields: {},
    },
    { id: 'power_plant', description: 'Power plants', fields: {} },
    {
      id: 'power_plant_point',
      description: 'Power plant centroids',
      fields: {},
    },
    { id: 'power_generator', description: 'Power generators', fields: {} },
    {
      id: 'power_generator_area',
      description: 'Power generators mapped as areas',
      fields: {},
    },
    {
      id: 'power_heatmap_solar',
      description: 'Solar generation heatmap points',
      fields: {},
    },
    { id: 'power_transformer', description: 'Power transformers', fields: {} },
    { id: 'power_compensator', description: 'Power compensators', fields: {} },
    { id: 'power_switch', description: 'Power switches', fields: {} },
    {
      id: 'telecoms_communication_line',
      description: 'Telecoms lines',
      fields: {},
    },
    {
      id: 'telecoms_data_center',
      description: 'Telecoms datacenters and exchanges',
      fields: {},
    },
    { id: 'telecoms_mast', description: 'Communications masts', fields: {} },
    {
      id: 'petroleum_pipeline',
      description: 'Petroleum pipelines',
      fields: {},
    },
    { id: 'petroleum_well', description: 'Petroleum wells', fields: {} },
    { id: 'petroleum_site', description: 'Petroleum sites', fields: {} },
    { id: 'water_pipeline', description: 'Water pipelines', fields: {} },
    { id: 'telecoms_line', description: 'telecoms_line', fields: {} },
    {
      id: 'telecoms_data_center',
      description: 'telecoms_data_center',
      fields: {},
    },
    {
      id: 'telecoms_data_center_symbol',
      description: 'telecoms_data_center_symbol',
      fields: {},
    },
    {
      id: 'telecoms_line_label',
      description: 'telecoms_line_label',
      fields: {},
    },
    { id: 'power_line_label', description: 'power_line_label', fields: {} },
  ],
};

export const heatmapConfig = {
  tilejson: '2.2.0',
  name: 'OpenInfraMap Heatmap',
  attribution:
    '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, <a href="https://openinframap.org/copyright">OpenInfraMap</a>',
  bounds: [-180, -85.0511, 180, 85.0511],
  center: [0, 0, 0],
  format: 'pbf',
  minzoom: 2,
  maxzoom: 17,
  tiles: ['https://openinframap.org/heatmap/{z}/{x}/{y}.pbf'],
  vector_layers: [
    {
      id: 'power_heatmap_solar',
      description: 'Solar power heatmap',
      fields: {},
    },
  ],
};
