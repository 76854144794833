import { FC, memo, useEffect, useMemo, useState } from 'react';
import { sourceMedia } from 'constants/filters';
import { useAppDispatch } from 'hooks';
import { ReactComponent as Marker } from 'images/icons/marker.svg';
import { ReactComponent as Star } from 'images/icons/star.svg';
import { ReactComponent as StarHighlight } from 'images/icons/star-highlight.svg';
import { IMediaFile, IPost } from 'interfaces';
import Tooltip from 'rc-tooltip';
import {
  addBookmarkThunk,
  deleteBookmarkThunk,
  getBookmarkIdsThunk,
} from 'store/slices/monitoring/actions';
import { TSearchModeUnion } from 'types';

import PreviewMediaFiles from 'components/PreviewMediaFiles';
import { Accordion } from 'components/ui';
import { getFormattedDate, isVideo } from 'utils';

import './style.scss';

interface CardProps {
  post: IPost;
  onGoToMap?: (coordinates: string[]) => void;
  onAddFavorite?: (postId: string) => void;
  onShowModal: (files: string[]) => void;
  bookmarked: (id: string) => boolean;
  mode?: TSearchModeUnion;
  format?: string;
  searchedText: string;
}

const Card: FC<CardProps> = ({
  post,
  onGoToMap,
  onAddFavorite,
  onShowModal,
  bookmarked,
  format = 'DD.MM.YY HH:mm',
  mode,
  searchedText,
}) => {
  const dispatch = useAppDispatch();
  const { link, name, platform, text, media_array, timestamp, match_array } =
    post;
  const postDate = useMemo(
    () => getFormattedDate(timestamp, format),
    [timestamp]
  );
  const [mediaFiles, setMediaFiles] = useState<IMediaFile[]>([]);

  const source = useMemo(() => {
    const type = sourceMedia.find((file) => file.value === platform);

    return type?.label ?? null;
  }, [platform]);

  const match = useMemo(() => {
    if (mode === 'processed' && match_array.length) {
      return match_array.join(', ');
    }

    return null;
  }, [mode, match_array]);

  const onClickMarker = () => {
    //
  };

  useEffect(() => {
    setMediaFiles(
      media_array.map((media) => {
        return { url: media, type: isVideo(media) ? 'video' : 'image' };
      })
    );
  }, [media_array]);

  const onClickFavorite = () => {
    if (bookmarked(post.id)) {
      dispatch(deleteBookmarkThunk(post.id)).then(() =>
        dispatch(getBookmarkIdsThunk())
      );
    } else {
      dispatch(addBookmarkThunk(post.id)).then(() =>
        dispatch(getBookmarkIdsThunk())
      );
    }
  };

  return (
    <div className="card">
      <div className="card__head">
        <div className="card__source-container">
          {postDate && (
            <span className="card__source-item card__source-item_date">
              {postDate}
            </span>
          )}
          <Tooltip
            overlayClassName="card__tooltip"
            placement="top"
            overlay={<span>{name}</span>}
          >
            <span className="card__source-item_name">{name}</span>
          </Tooltip>
        </div>
        {match && (
          <>
            <div className="card__match-container">
              <Tooltip
                overlayClassName="card__tooltip"
                placement="top"
                overlay={<span>{match}</span>}
              >
                <span className="card__match-items">Совпадения: {match}</span>
              </Tooltip>
            </div>
          </>
        )}
        <div className="card__origin-container">
          {!link && source && (
            <span className="tpg-title-two card__origin-parser">{source}</span>
          )}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              className="card__origin-link"
            >
              {`Оригинал ${source ? `в ${source}` : ''}`}
            </a>
          )}
        </div>
      </div>
      <div className="card__content">
        {media_array && !!media_array.length && (
          <div className="card__media-files">
            <PreviewMediaFiles
              files={mediaFiles || []}
              onClick={(files) => onShowModal(files.map((file) => file.url))}
            />
          </div>
        )}
        {text && <Accordion searchedText={searchedText} text={text} />}
      </div>
      <div className="card__actions">
        <Marker className="card__actions-icon" onClick={onClickMarker} />
        {bookmarked(post.id) ? (
          <StarHighlight
            className="card__actions-icon"
            onClick={onClickFavorite}
          />
        ) : (
          <Star className="card__actions-icon" onClick={onClickFavorite} />
        )}
      </div>
    </div>
  );
};

export default memo(Card);
