import { FC, useState } from 'react';
import cn from 'classnames';
import { TDateRange } from 'types';

import { DatePickerDropdown } from 'components/ui/DatePickerDropdown/DatePickerDropdown';

import BaseFilter from './BaseFilter';
import { FilterCriteriaDropdown } from './FilterCriteriaDropdown';
import {
  ActiveFilterCriteria,
  FilterCriteria,
  FilterCriteriaType,
} from './types';

import styles from './Filters.module.scss';

interface FilterDropdownProps {
  criterias: FilterCriteria[];
  onCriteriaApply: (criteria: ActiveFilterCriteria) => void;
}

interface FilterCriteriaDropdownOption<T = unknown> {
  id?: number;
  type: FilterCriteriaType;
  label: string;
  value: T;
}

export const FilterDropdown: FC<FilterDropdownProps> = ({
  criterias,
  onCriteriaApply,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeCriteria, setActiveCriteria] = useState<FilterCriteria | null>(
    null
  );

  const showDropdown =
    activeCriteria?.type === 'number' || activeCriteria?.type === 'text';

  const showDatePicker = activeCriteria?.type === 'date';

  const criteriasOptions: FilterCriteriaDropdownOption<FilterCriteria>[] =
    criterias.map((criteria) => ({
      id: criteria.id,
      type: criteria.type,
      label: criteria.label,
      value: criteria,
    }));

  const selectedCriteriaValuesOptions: FilterCriteriaDropdownOption[] =
    activeCriteria?.values?.map((value) => ({
      type: activeCriteria.type,
      label: value.label,
      value: value.value,
    })) ?? [];

  const handleCriteriaSelect = (
    criteria: FilterCriteria | null,
    option: FilterCriteriaDropdownOption<FilterCriteria>
  ) => setActiveCriteria(option.value);

  const handleCriteriaDropdownValueApply = (
    criteria: FilterCriteria | null,
    option: FilterCriteriaDropdownOption
  ) => {
    onCriteriaApply({
      id: criteria?.id ?? 0,
      label: criteria?.label ?? '',
      value: option,
      name: criteria?.name ?? '',
      type: criteria?.type ?? 'text',
    });

    setActiveCriteria(null);
    setIsOpen(false);
  };

  const handleCriteriaDatePickerValueApply = (dateRange: TDateRange) => {
    onCriteriaApply({
      id: activeCriteria?.id ?? 0,
      label: activeCriteria?.label ?? '',
      value: { label: activeCriteria?.label ?? '', value: dateRange },
      name: activeCriteria?.name ?? '',
      type: activeCriteria?.type ?? 'date',
    });

    setActiveCriteria(null);
    setIsOpen(false);
  };

  const handleCriteriaDropdownArrowClick = () => setActiveCriteria(null);

  const renderDropdown = () => (
    <>
      <FilterCriteriaDropdown<FilterCriteria>
        name={activeCriteria?.label ?? 'Параметр'}
        criteria={activeCriteria}
        options={criteriasOptions}
        isOpen={!activeCriteria}
        onSelect={handleCriteriaSelect}
        onArrowClick={handleCriteriaDropdownArrowClick}
      />
      {showDropdown && (
        <FilterCriteriaDropdown
          name="Значение"
          criteria={activeCriteria}
          options={selectedCriteriaValuesOptions ?? []}
          isOpen={!!activeCriteria}
          onSelect={handleCriteriaDropdownValueApply}
        />
      )}
      {showDatePicker && (
        <div className="flex justify-between items-center px-3 py-[6px]">
          <span className="tpg-c2 text-tpg_base">Выберите дату</span>
          <DatePickerDropdown
            className={styles['datepicker-dropdown']}
            iconClassName={cn('ml-auto', styles['datepicker-dropdown_icon'])}
            onValueChange={handleCriteriaDatePickerValueApply}
          />
        </div>
      )}
    </>
  );

  return (
    <BaseFilter
      isOpen={isOpen}
      dropdownContent={renderDropdown()}
      setIsOpen={setIsOpen}
      iconSize={20}
    />
  );
};
