import { FC } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { IMediaFile } from 'interfaces';

import { Slider } from 'components/Slider';

import { SLIDE_SIZE } from './constants';
import { MediaGallerySlide } from './MediaGallerySlide';

interface IMediaGallerySliderProps {
  mediaFiles: IMediaFile[];
  currentSlide: number;
  isDraggable?: boolean;
  onDragEnd: (dropResult: DropResult) => void;
  onSlideClick: (slide: number) => void;
}

export const MediaGallerySlider: FC<IMediaGallerySliderProps> = ({
  mediaFiles,
  currentSlide,
  isDraggable,
  onDragEnd,
  onSlideClick,
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable
      droppableId="media-gallery-slider"
      direction="vertical"
      isDropDisabled={!isDraggable}
    >
      {(provided) => (
        <Slider
          outterRef={provided.innerRef}
          orientation="vertical"
          currentSlide={currentSlide}
          size={SLIDE_SIZE}
          className="shrink-0"
          {...provided.droppableProps}
        >
          {mediaFiles.map((item, index) => (
            <Draggable
              key={`${item.id}-${index}`}
              index={index}
              draggableId={`${item.id}-${index}`}
              isDragDisabled={!isDraggable}
            >
              {(provided) => {
                const { style, ...restDraggableProps } =
                  provided.draggableProps;

                return (
                  <MediaGallerySlide
                    ref={provided.innerRef}
                    mediaFile={item}
                    size={SLIDE_SIZE}
                    isSelected={index === currentSlide}
                    style={style}
                    onClick={() => onSlideClick(index)}
                    {...restDraggableProps}
                    {...provided.dragHandleProps}
                  />
                );
              }}
            </Draggable>
          ))}
        </Slider>
      )}
    </Droppable>
  </DragDropContext>
);
