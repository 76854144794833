/* eslint-disable */
//@ts-nocheck
export const local_name_tags = ['ru']
  .map((code) => `name_${code}`)
  .concat(['name']);
export const local_name = ['coalesce'].concat(
  local_name_tags.map((tag) => ['get', tag])
);

export const text_paint = {
  'text-halo-width': 4,
  'text-halo-blur': 2,
  'text-color': 'hsl(0, 0%, 20%)',
  'text-halo-color': 'rgba(230, 230, 230, 1)',
};

export const operator_text = [
  'step',
  ['zoom'],
  ['get', 'name'],
  14,
  [
    'case',
    ['has', 'operator'],
    ['concat', ['get', 'name'], ' (', ['get', 'operator'], ')'],
    ['get', 'name'],
  ],
];

export const underground_p = [
  'any',
  ['==', ['get', 'location'], 'underground'],
  ['==', ['get', 'location'], 'underwater'],
  ['==', ['get', 'tunnel'], true],
  [
    'all', // Power cables are underground by default
    ['==', ['get', 'type'], 'cable'],
    ['==', ['get', 'location'], ''],
  ],
];

export const font = ['Noto Sans SemiCondensed'];
