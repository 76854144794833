import { createSlice } from '@reduxjs/toolkit';

import { MAP_REDUCER_NAMES } from '../constants';

import { ContextMenuState, SetContextMenuAction } from './types';

const initialState: ContextMenuState = {
  contextMenu: null,
};

const contextMenuSlice = createSlice({
  name: MAP_REDUCER_NAMES.CONTEXT_MENU,
  initialState: initialState,
  reducers: {
    setContextMenu(state, action: SetContextMenuAction) {
      state.contextMenu = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: contextMenuActions, reducer: contextMenuReducer } =
  contextMenuSlice;
