import { FC, useState } from 'react';
import { addUser, updateUser, updateUserPassword } from 'api/access';
import { errorMessages, successMessages } from 'constants/errors';
import { USER_ROLE_OPTIONS } from 'constants/profile';
import { AddUserRequest, PublicUserInfo } from 'interfaces';

import { Button, Modal, Select, TextInput } from 'components/ui';
import { generateCryptoSecurePassword } from 'utils';
import { handleNotify } from 'utils/notifications';

export interface IUserEditModalProps {
  user?: PublicUserInfo;
  onClose: () => void;
  onSuccess: () => void;
}

interface UserDetailsForm {
  id: number;
  verboseName: string;
  username: string;
  sysRole: string;
  password: string;
}

const defaultFormData = (user?: PublicUserInfo): UserDetailsForm => ({
  id: user?.id || 0,
  verboseName: user?.verboseName || '',
  username: user?.username || '',
  sysRole: user?.sysRole || 'user',
  password: user?.password || generateCryptoSecurePassword(),
});

const UserDetailsModal: FC<IUserEditModalProps> = ({
  user,
  onClose,
  onSuccess,
}) => {
  const [isResetPassword, setResetPassword] = useState(false);

  const [formData, setFormData] = useState<UserDetailsForm>(
    defaultFormData(user)
  );

  const handleInputChange = (field: keyof PublicUserInfo, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      if (user) {
        // Обновление существующего пользователя
        if (isResetPassword) {
          const resetPasswordReq = { password: formData.password };
          await handleNotify(
            updateUserPassword(resetPasswordReq, user.id),
            successMessages.USER_PASSWORD_UPDATE,
            errorMessages.USER_PASSWORD_UPDATE_ERROR
          );
        }

        const updateUserReq = {
          role: 'user',
          sysRole: formData.sysRole,
          verbose: formData.verboseName,
        };

        await handleNotify(
          updateUser(updateUserReq, user.id),
          successMessages.USER_UPDATE_SUCCESS,
          errorMessages.USER_UPDATE_ERROR
        );
      } else {
        // Добавление нового пользователя
        const reqParams: AddUserRequest = {
          username: formData.username,
          password: formData.password,
          sysRole: formData.sysRole,
          verbose: formData.verboseName,
          role: 'user',
        };

        await handleNotify(
          addUser(reqParams),
          successMessages.USER_CREATE_SUCCESS,
          errorMessages.USER_CREATION_ERROR
        );
      }

      // Успешное завершение
      onSuccess();
      onClose();
    } catch (error) {
      // Ошибка уже обрабатывается в handleNotify
      console.error(error);
    }
  };

  const handleResetPassword = () => {
    setResetPassword(true);
    handleInputChange('password', '');
  };

  const isSaveButtonDisabled =
    !formData.verboseName ||
    !formData.username ||
    !formData.sysRole ||
    !formData.password;

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center">
          {user ? 'Изменение пользователя' : 'Новый пользователь'}
        </div>
        <div className="flex flex-col gap-3 py-6">
          <TextInput
            value={formData.verboseName}
            onChange={(v) => handleInputChange('verboseName', v)}
            placeholder="Позывной"
          />
          <Select
            value={formData.sysRole}
            onSelect={(v: string) => handleInputChange('sysRole', v)}
            options={USER_ROLE_OPTIONS}
            theme="light"
            placeholder="Выберите роль"
          />
          <TextInput
            value={formData.username}
            onChange={user ? () => {} : (v) => handleInputChange('username', v)}
            placeholder="Введите логин"
          />
          <TextInput
            value={formData.password}
            onChange={
              isResetPassword
                ? (v) => handleInputChange('password', v)
                : () => {}
            }
            placeholder="Введите пароль"
            isPassword
          >
            <div
              className="tpg-c2 text-main_product cursor-pointer pr-4"
              onClick={handleResetPassword}
            >
              сбросить
            </div>
          </TextInput>
        </div>
        <Button
          onClick={handleSave}
          title={user ? 'Сохранить' : 'Создать'}
          className="w-full"
          disabled={isSaveButtonDisabled}
        />
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
