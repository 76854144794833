import { SKELETON_SLIDES, SLIDE_SIZE } from './constants';

export const SliderSkeleton = () => (
  <div className="flex flex-col gap-3 shrink-0 overflow-hidden">
    {SKELETON_SLIDES.map((slide) => (
      <div
        key={slide}
        className="shrink-0 bg-dark rounded-[5px]"
        style={{ width: SLIDE_SIZE, height: SLIDE_SIZE }}
      />
    ))}
  </div>
);

export const MediaGalleryControlsSkeleton = () => <div className="h-[54px]" />;
