import { FC, useState } from 'react';
import { TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { deleteUserToGroupLink } from 'api/access';
import { errorMessages, successMessages } from 'constants/errors';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import { GroupInfo, GroupMember } from 'interfaces';

import AstraTable, {
  AstraTableFetchParams,
} from 'components/ui/Table/AstraTable';
import TableContextMenu, {
  ContextMenuState,
  MenuItemProps,
} from 'components/ui/Table/TableContextMenu';
import { handleNotify } from 'utils/notifications';

import GroupMemberDetailsModal, {
  GroupMemberDetailsModalProps,
} from '../Modals/GroupMemberDetailsModal';

interface LinkedUsersTableProps {
  groupInfo: GroupInfo;
  onSuccessAddUser: () => void;
}

export const LINKED_USERS_HEAD_CELLS = [
  {
    id: 'linkedUserVerbose',
    numeric: false,
    disablePadding: false,
    label: 'Позывной',
  },
  {
    id: 'linkedUserRole',
    numeric: false,
    disablePadding: false,
    label: 'Роль в группе',
  },
  {
    id: 'linkedUserPosition',
    numeric: false,
    disablePadding: false,
    label: 'Должность',
  },
];

const LinkedUsersTable: FC<LinkedUsersTableProps> = ({
  groupInfo,
  onSuccessAddUser,
}) => {
  const [contextMenu, setContextMenu] = useState<ContextMenuState>();
  const [editModalProps, setEditModalProps] =
    useState<GroupMemberDetailsModalProps>();
  const [isAddGroupMemberModal, setAddGroupMemberModal] = useState(false);

  const handleContextMenu = (
    event: React.MouseEvent,
    row: any,
    rowId: string
  ) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      row,
      rowId,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu(undefined);
  };

  const handleEditRow = () => {
    if (!contextMenu) return;
    const member = contextMenu.row as GroupMember;
    setEditModalProps({
      group: groupInfo,
      member: member,
      onClose: handleClouseMemberModal,
      onSuccess: onSuccessAddUser,
    });
    handleContextMenuClose();
  };

  const handleDeleteRow = async () => {
    if (!contextMenu) return;
    await handleNotify(
      deleteUserToGroupLink(Number(groupInfo.id), Number(contextMenu.row.id)),
      successMessages.DELETE_USER_TO_GROUP_LINK_SUCCESS,
      errorMessages.DELETE_USER_TO_GROUP_LINK_ERROR
    ).then(() => onSuccessAddUser());
    handleContextMenuClose();
  };

  const contextMenuItems: MenuItemProps[] = [
    { title: 'Редактировать', onClick: handleEditRow },
    { title: 'Удалить из группы', onClick: handleDeleteRow },
  ];

  const handleClouseMemberModal = () => {
    setEditModalProps(undefined);
  };

  const fetchRows = async (params: AstraTableFetchParams<any>) => {
    return Promise.resolve({
      rows: groupInfo.members,
      total: groupInfo.members.length,
    });
  };

  const getRole = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Владелец';
      case 'admin':
        return 'Администратор';
      default:
        return 'Пользователь';
    }
  };

  const renderRowCells: (row: GroupMember) => React.ReactNode = (row) => (
    <>
      <TableCell align="left">
        <div className="column-with-newlines colored-title">
          {row.verboseName}
        </div>
      </TableCell>
      <TableCell align="left">
        <div className="column-with-newlines colored-title">
          {getRole(row.role)}
        </div>
      </TableCell>
      <TableCell align="left">
        <div className="column-with-newlines colored-title">{row.position}</div>
      </TableCell>
    </>
  );
  const handleAddGroupMember = () => {
    setAddGroupMemberModal(true);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="tpg-h4 py-4">Пользователи</div>
        <Plus
          className="icon-container cursor-pointer"
          onClick={handleAddGroupMember}
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <TableContainer
          style={{ maxHeight: '35vh', overflowY: 'auto', width: 'full' }}
        >
          <AstraTable
            fetchRows={fetchRows}
            rowIdExtractor={(row) => row.id}
            renderRowCells={renderRowCells}
            headCells={LINKED_USERS_HEAD_CELLS}
            handleContextMenuClick={(event, row, rowId) =>
              handleContextMenu(event, row, `${rowId}`)
            }
          />
        </TableContainer>
        <TableContextMenu
          contextMenu={contextMenu}
          onClose={handleContextMenuClose}
          menuItems={contextMenuItems}
        />
        {editModalProps && <GroupMemberDetailsModal {...editModalProps} />}
      </div>
      {isAddGroupMemberModal && groupInfo && (
        <GroupMemberDetailsModal
          group={groupInfo}
          onClose={() => setAddGroupMemberModal(false)}
          onSuccess={onSuccessAddUser}
        />
      )}
    </div>
  );
};

export default LinkedUsersTable;
