import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import {
  clusterConfig as clusterDefaultConfig,
  clusterCounterConfig as clusterCounterDefaultConfig,
  clusterLabelConfig as clusterLabelDefaultConfig,
  labelConfig,
  pointConfig,
} from 'configs/map/common';
import { POINT_SOURCE } from 'constants/map';
import { MapGeoJSONObject } from 'interfaces';

interface PointSourceProps extends MapGeoJSONObject {
  mainConfig?: LayerProps;
  mainLabelConfig?: LayerProps;
  clusterConfig?: LayerProps;
  clusterCounterConfig?: LayerProps;
  clusterLabelConfig?: LayerProps;
}

export const PointSource: FC<PointSourceProps> = memo(
  ({
    id = POINT_SOURCE,
    data,
    mainConfig = pointConfig,
    mainLabelConfig = labelConfig,
    clusterConfig = clusterDefaultConfig,
    clusterCounterConfig = clusterCounterDefaultConfig,
    clusterLabelConfig = clusterLabelDefaultConfig,
    cluster,
    ...props
  }) => (
    <Source id={id} type="geojson" data={data} cluster={!!cluster} {...props}>
      <Layer id={`${id}-main`} {...mainConfig} />
      <Layer id={`${id}-main-label`} {...mainLabelConfig} />
      <Layer id={`${id}-cluster`} {...clusterConfig} />
      <Layer id={`${id}-cluster-counter`} {...clusterCounterDefaultConfig} />
      <Layer id={`${id}-cluster-label`} {...clusterLabelConfig} />
    </Source>
  )
);

PointSource.displayName = 'PointSource';
