import { createSlice } from '@reduxjs/toolkit';

import { TOOLS_REDUCER_NAMES } from '../constants';

import {
  ArtilleryState,
  SetNameAction,
  SetPositionAction,
  SetRangeAction,
  SetStateAction,
} from './types';

const initialState: ArtilleryState = {
  name: '',
  range: [],
  position: [0, 0],
};

const artillerySlice = createSlice({
  name: TOOLS_REDUCER_NAMES.ARTILLERY,
  initialState: initialState,
  reducers: {
    setState(state, action: SetStateAction) {
      return action.payload;
    },
    setName(state, action: SetNameAction) {
      state.name = action.payload;
    },
    setRange(state, action: SetRangeAction) {
      state.range = action.payload;
    },
    setPosition(state, action: SetPositionAction) {
      state.position = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: artilleryActions, reducer: artilleryReducer } =
  artillerySlice;
