import { useEffect } from 'react';

import Switch from '.';

interface SwitchWithTitleProps {
  active: boolean;
  onChange: () => void;
  title: string;
  localStorageTag?: string;
}

export const SwitchWithTitle: React.FC<SwitchWithTitleProps> = ({
  active,
  onChange,
  title,
  localStorageTag,
}) => {
  useEffect(() => {
    localStorageTag && localStorage.setItem(localStorageTag, active.toString());
  }, [active]);

  return (
    <div className="flex flex-row justify-between">
      <div className="tpg-c1">{title}</div>
      <Switch checked={active} onChange={onChange} />
    </div>
  );
};

export default SwitchWithTitle;
