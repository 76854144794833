import { FC } from 'react';
import { Popup, PopupEvent, PopupProps } from 'react-map-gl';
import cn from 'classnames';
import { useEscapeDown } from 'hooks/useEscapeDown';

import styles from './object-popup.module.scss';

export interface IObjectPopupProps extends PopupProps {
  tip?: boolean;
  keyboard?: boolean;
}

const popupEventMock = {} as PopupEvent;

export const ObjectPopup: FC<IObjectPopupProps> = ({
  children,
  tip,
  keyboard,
  className,
  onClose,
  ...props
}) => {
  useEscapeDown(() => onClose?.(popupEventMock), keyboard);

  return (
    <Popup
      className={cn(
        styles['object-popup'],
        !tip && styles['object-popup_tip_hidden'],
        className
      )}
      onClose={onClose}
      {...props}
    >
      {children}
    </Popup>
  );
};
