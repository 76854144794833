import { FC } from 'react';
import { ReactComponent as Close } from 'images/newIcons/cross.svg';
import { ReactComponent as Planning } from 'images/newIcons/planning.svg';

interface EntityHeaderProps {
  title?: string;
  subtitle?: string | null;
  onClose: () => void;
}

export const EntityHeader: FC<EntityHeaderProps> = ({
  title,
  subtitle,
  onClose,
}) => (
  <div className="flex flex-col gap-3 px-[10px] py-2">
    <div className="w-full grid grid-cols-[auto_1fr_auto] gap-3">
      <div className="h-[48px] w-[48px] flex items-center justify-center rounded-full bg-light">
        <Planning />
      </div>
      <div className="flex flex-col overflow-hidden">
        <div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap tpg-b1 text-tpg_title">
          {title || 'Без названия'}
        </div>
        {subtitle && <div className="tpg-c1 text-tpg_base">{subtitle}</div>}
      </div>
      <Close className="icon-container cursor-pointer" onClick={onClose} />
    </div>
  </div>
);
