import React, { memo } from 'react';

import { copyToClipboard } from 'utils';

import Button from '../Button';
import { Modal, TextInput } from '../index';

import './style.scss';

interface ShareModalProps {
  id: string;
  onClose: () => void;
  subdomain?: string;
}

export interface ShareModalState {
  isOpen: boolean;
  entityId: string;
}

const ShareModal: React.FC<ShareModalProps> = ({
  id,
  onClose,
  subdomain = 'map',
}) => {
  const constructLink = (id: string) =>
    `${window.location.origin}/${subdomain}/${id}`;
  const link = constructLink(id);

  const handleClick = () => {
    copyToClipboard(link);
    onClose();
  };

  return (
    <Modal onClose={onClose} isBlurred keyboard>
      <div className="confirm-modal flex-col gap-6 !absolute !m-0 bottom-[25px]">
        <div className="tpg-h4">Поделиться ссылкой</div>
        <TextInput
          value={link}
          onChange={() => null}
          classNames={{ container: 'w-full', input: '!text-bright_product' }}
        />
        <Button title="Скопировать" onClick={handleClick} className="w-full" />
      </div>
    </Modal>
  );
};

export default memo(ShareModal);
