import { FC, useMemo } from 'react';

import { Avatar, Modal } from 'components/ui';

import { DumpSearchTableDocument } from '../../../interfaces/dumps';
import { copyToClipboard, getFullName } from '../../../utils';
import { getDictPropertyDisplayName } from '../../../utils/dumpUtils';

import './style.scss';

interface DumpViewModalProps {
  dump: DumpSearchTableDocument;
  onClose: () => void;
}

const CopyableValueWithTitle = (props: {
  title: string;
  value: string;
  copyTitle?: boolean;
}) => {
  const titleFragment = useMemo(
    () =>
      props.copyTitle ? (
        <a
          onClick={() => copyToClipboard(props.title)}
          className="cursor-pointer"
        >
          {props.title}
        </a>
      ) : (
        <span>{props.title}:</span>
      ),
    [props]
  );
  const valueFragment = useMemo(
    () =>
      props.copyTitle ? (
        <span
          onClick={() => copyToClipboard(props.value)}
          className="pl-1 cursor-pointer"
        >
          {props.value}
        </span>
      ) : (
        <a>{props.value}</a>
      ),
    [props]
  );
  return (
    <div className="pt-1">
      {titleFragment} {valueFragment}
    </div>
  );
};
const DumpViewModal: FC<DumpViewModalProps> = ({ dump, onClose }) => {
  const docs: { title: string; value: string }[] = Object.entries(
    dump.document || {}
  ).flatMap(([key, value]) => {
    if (value) {
      return [
        {
          title: getDictPropertyDisplayName(key),
          value,
        },
      ];
    } else {
      return [];
    }
  });
  const addresses = dump.address || [];

  return (
    <Modal width={820} keyboard onClose={onClose}>
      <div className="dump_modal bg-primary p-3 border-r-4 pt-7 pb-7">
        <Avatar src={null} size={64} />
        {dump?.name?.ru && (
          <div className="py-1 tpg-title-one">
            {getFullName(
              dump.name.ru.first,
              dump.name.ru.last,
              dump.name.ru.patronymic
            )}
          </div>
        )}
        {dump?.name?.ua && (
          <div className="py-1">
            {getFullName(
              dump.name.ua.first,
              dump.name.ua.last,
              dump.name.ua.patronymic
            )}
          </div>
        )}
        <div className="flex flex-col items-start w-full px-10 pt-5">
          {dump.birthday && (
            <div>
              <div className="tpg-title-four pt-2">Дата рождения:</div>
              <div>{dump.birthday}</div>
            </div>
          )}
          {docs.length > 0 && (
            <div>
              <div className="tpg-title-four pt-2">Документы:</div>
              {docs.map((doc, idx) => (
                <CopyableValueWithTitle
                  key={idx}
                  title={doc.title}
                  value={doc.value}
                />
              ))}
            </div>
          )}
          {addresses.length > 0 && (
            <div>
              <div className="tpg-title-four pt-2">Адреса:</div>
              {addresses.map((addr, idx) => (
                <CopyableValueWithTitle
                  key={idx}
                  title={addr.value}
                  value={`(источник: ${addr.source})`}
                  copyTitle
                />
              ))}
            </div>
          )}
          {dump?.other.length > 0 && (
            <div>
              <div className="tpg-title-four pt-2">Дополнительно:</div>
              {dump?.other.map((etc, idx) => (
                <div key={idx} className="pt-1">
                  <div className="mb-1" style={{ fontSize: 12 }}>
                    Источник — {etc.source}:
                  </div>
                  {Object.entries(etc.data).map(([key, value], idx) => {
                    return (
                      <div key={idx}>
                        {`${getDictPropertyDisplayName(key)}: `}
                        <a
                          onClick={() => copyToClipboard(value)}
                          className="cursor-pointer"
                        >
                          {value}
                        </a>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DumpViewModal;
