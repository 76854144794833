import { useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  RAILSTATIONS_MARKER,
  RAILSTATIONS_MARKER_SVG,
  railStationsMarkerLayer,
  railStationsSource,
  railStationsSymbolLayer,
} from '../../../../configs/map/railStations';
import { useAppSelector } from '../../../../hooks';
import { useMapRef } from '../../../../hooks/map';
import { typesSelector } from '../../../../store/slices/mapV2/mapReducer/settingsSlice/selectors';

export const RailStationsSource = () => {
  const map = useMapRef();
  const mapTypes = useAppSelector(typesSelector);
  const showRailStations = mapTypes.railStations.active;

  useEffect(() => {
    const curMap = map.mapRef.current;
    if (curMap && !curMap.hasImage(RAILSTATIONS_MARKER)) {
      const img = new Image(20, 20);
      img.onload = () => curMap.addImage(RAILSTATIONS_MARKER, img);
      img.src = RAILSTATIONS_MARKER_SVG;
    }
  }, [map.mapRef]);

  if (!showRailStations) {
    return null;
  }

  return (
    <Source {...railStationsSource}>
      <Layer {...railStationsMarkerLayer} />
      <Layer {...railStationsSymbolLayer} />
    </Source>
  );
};
