import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MapBox, {
  GeoJSONSource,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
} from 'react-map-gl';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { mapDraw } from 'configs/map/instances/mapDraw';
import {
  DUMMY_ACCESS_RULES,
  MAP_ENTITY_PARAM_VALUES,
  mapEntityParams,
  predefinedTemplates,
} from 'constants/entities';
import {
  BOUNDARY_GRID_CELL_LAYER,
  DEFAULT_FLY_TO_SETTINGS,
  DRAW_ACTIVE_COLD_LAYER_ID,
  DRAW_ACTIVE_HOT_LAYER_ID,
  DRAW_INACTIVE_COLD_LAYER_ID,
  DRAW_INACTIVE_HOT_LAYER_ID,
  ENTITY_TYPE_LAYER,
  ENTITY_TYPE_OBJECT,
  GEOCODER_FEATURE_MAIN_FILL,
  IMAGERY_BOUNDARY_LAYER,
  IMAGERY_PREVIEW_FILL_LAYER,
  LAYERS_LINE_MAIN_FILL,
  LAYERS_POINT,
  LAYERS_POLYGON_MAIN_FILL,
  LAYERS_POLYGON_MAIN_LINE,
  MAP_BOX_TOKEN,
  OFFSET_TO_FIT_PREVIEW,
} from 'constants/map';
import { MAP_DRAW_MODES, MAP_MEASURE_MODES } from 'constants/mapControl';
import {
  ENTITY_DETAILS_MODAL,
  ENTITY_PREVIEW_MODAL,
  IMAGERY_UPLOAD_MODAL,
  SHARE_MODAL,
} from 'constants/modals';
import { AstraCursors, AstraCursorTitle, mapRoutes } from 'constants/routes';
import { Geometry } from 'geojson';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMap, useMapRef } from 'hooks/map';
import { useDoubleTap } from 'hooks/useDoubleTap';
import { IImageryPreview } from 'interfaces';
import { authSelector } from 'store/slices/auth/selectors';
import { contextMenuActions } from 'store/slices/mapV2/mapReducer/contextMenuSlice';
import { contextMenuSelector } from 'store/slices/mapV2/mapReducer/contextMenuSlice/selectors';
import { showCustomCursorsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { artilleryActions } from 'store/slices/mapV2/mapReducer/toolsReducer/artillerySlice';
import { boundaryGridActions } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice';
import { boundaryGridMapSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice/selectors';
import { documentCoordinatesSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/documentCoordinateSlice/selectors';
import { drawActions } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice';
import { drawModeSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { geocoderActions } from 'store/slices/mapV2/mapReducer/toolsReducer/geocoderSlice';
import { getImageryThunk } from 'store/slices/mapV2/tabsReducer/imagerySlice/actions';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  getMapEntityChildrenThunk,
  getMapEntityCountersThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  entitiesMapSelector,
  predefinedTemplateSelector,
  temporaryEntitiesSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { getInitialMapEntity } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/utils';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { modalSelector } from 'store/slices/service/modalsSlice/selectors';
import {
  CameraBounds,
  ContextMenu as IContextMenu,
  PointClusterGeoJSON,
} from 'types';
import {
  BoundaryGridCellGeoJSON,
  ImageryBoundaryGeoJSON,
  MapEntity,
} from 'types';
import { Entity } from 'types/entities';
import { useDebouncedCallback } from 'use-debounce';

import { ContextMenu } from 'components/Map/ContextMenu';
import Controls from 'components/Map/Controls/Controls';
import { EntityDetails } from 'components/Map/EntityDetails';
import { EntityPreview } from 'components/Map/EntityPreview';
import FeatureCollection from 'components/Map/FeatureCollection';
import { GeoconfirmedObjectsSource } from 'components/Map/GeoconfirmedObjectsSource';
import { Legend } from 'components/Map/Legend';
import { AimSource } from 'components/Map/Sources/AimSource';
import { ArtillerySource } from 'components/Map/Sources/ArtillerySource';
import { BoundaryGridSource } from 'components/Map/Sources/BoundaryGridSource';
import { DemSource } from 'components/Map/Sources/DemSource';
import {
  DocumentsSource,
  useDocumentSourceData,
} from 'components/Map/Sources/DocumentSource';
import { ElevationSource } from 'components/Map/Sources/ElevationSource';
import { FireSource } from 'components/Map/Sources/FireSource';
import { GeocoderSource } from 'components/Map/Sources/GeocoderSource';
import { GoogleSource } from 'components/Map/Sources/GoogleSource';
import { ImagerySource } from 'components/Map/Sources/ImagerySource';
import { InfrastructureSource } from 'components/Map/Sources/InfrastructureSource';
import { LabelsSource } from 'components/Map/Sources/LabelsSource';
import {
  LayersSource,
  useLayersSourceData,
} from 'components/Map/Sources/LayersSource';
import { MapboxSource } from 'components/Map/Sources/MapboxSource';
import { MeasureSource } from 'components/Map/Sources/MeasureSource';
import { RailroadsSource } from 'components/Map/Sources/RailroadsSource';
import ImageryUploadModal from 'components/Map/Tabs/Imagery/ImageryUploadModal';
import ShareModal from 'components/ui/Modal/ShareModal';
import {
  calculateGeometryCenter,
  getGeoJSONCompletedFeature,
  getInitialEntity,
  getQueriedFeaturesBounds,
  isMobileDevice,
  safe,
  useForceUpdate,
} from 'utils';
import {
  entityTypeFilterFunc,
  getBounds,
  getEntityParametersIdTitleMap,
  getMapEntityChildrenId,
  getMapEntityFullPath,
  getMapObjectEntityValues,
} from 'utils/entity';
import { transformTileRequest } from 'utils/tileserver';

import { RailStationsSource } from '../../components/Map/Sources/RailStationsSource';

export const Map: FC = () => {
  const { tileToken } = useAppSelector(authSelector);
  const { viewport, defaultConfig } = useMap();
  const { mapRef } = useMapRef();
  const { entityId } = useParams<{
    entityId: string;
  }>();
  const navigate = useNavigate();

  const showCustomCursors = useAppSelector(showCustomCursorsSelector);
  const documentCoordinates = useAppSelector(documentCoordinatesSelector);
  const contextMenu = useAppSelector(contextMenuSelector);
  const drawMode = useAppSelector(drawModeSelector);
  const mapObjectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const mapLayerTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_LAYER)
  );
  const entitiesMap = useAppSelector(entitiesMapSelector);
  const temporaryEntities = useAppSelector(temporaryEntitiesSelector);
  const imagery = useAppSelector(imagerySelector);
  const boundaryGridsMap = useAppSelector(boundaryGridMapSelector);
  const imageryUploadModal = useAppSelector((state) =>
    modalSelector(state, IMAGERY_UPLOAD_MODAL)
  );
  const entityPreviewModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_PREVIEW_MODAL)
  );
  const entityDetailsModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_DETAILS_MODAL)
  );
  const shareModal = useAppSelector((state) =>
    modalSelector(state, SHARE_MODAL)
  );
  const [isGrab, setIsGrab] = useState(false);
  const [isCursorOnFeature, setIsCursorOnFeature] = useState(false);
  const [cursor, setCursor] = useState<AstraCursorTitle | undefined>();
  const [shouldInitiateShare, setShouldInitiateShare] = useState(false);

  const dispatch = useAppDispatch();
  const forceUpdate = useForceUpdate();

  const paramIdMap = useMemo(
    () =>
      getEntityParametersIdTitleMap<Record<mapEntityParams, string>>(
        mapObjectTemplate?.parameters ?? [],
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [mapObjectTemplate]
  );

  const isMobile = isMobileDevice();
  const mode = mapRef.current && mapDraw.getMode();

  const layersSourceData = useLayersSourceData(
    entitiesMap,
    temporaryEntities,
    mapObjectTemplate
  );

  const documentsSorceData = useDocumentSourceData(documentCoordinates);

  const isEntityPreviewModalOpen =
    entityPreviewModal?.isOpen &&
    entityPreviewModal?.props &&
    mapObjectTemplate;

  const isEntityDetailsModalOpen =
    entityDetailsModal?.isOpen &&
    entityDetailsModal?.props &&
    mapObjectTemplate;

  const isShareModalOpen = shareModal?.isOpen && shareModal.props;

  const entityPreviewEntity = entityPreviewModal?.props?.entity;
  const entityDetailsEntity = entityDetailsModal?.props?.entity;

  const useCloseModalIfPredicate = (modalId: string, predicate: boolean) => {
    useEffect(() => {
      if (predicate) {
        modalId === ENTITY_DETAILS_MODAL
          ? handleCloseDetailsModal()
          : handleClosePreviewModal();
      }
    }, [predicate]);
  };

  const useCloseModalIfEntityDeleted = (entity: Entity, modalId: string) => {
    const isTemporaryEntities = temporaryEntities.length > 0;
    // if any modal is open and its entity no longer exists and no entity is being drawn
    const predicate = Boolean(
      (isEntityDetailsModalOpen || isEntityPreviewModalOpen) &&
        (!entity || !entitiesMap[String(entity.id)]) &&
        !isTemporaryEntities
    );
    useCloseModalIfPredicate(modalId, predicate);
  };

  const useCloseModalIfAnotherOpen = () => {
    useCloseModalIfPredicate(
      ENTITY_PREVIEW_MODAL,
      Boolean(isEntityDetailsModalOpen && isEntityPreviewModalOpen)
    );
  };

  const setViewport = useDebouncedCallback(
    () => localStorage.setItem('viewport', JSON.stringify(viewport)),
    500
  );

  const enableDblClickZoom = () => {
    mapRef.current?.doubleClickZoom.enable();
  };

  const disableDblClickZoom = () => {
    mapRef.current?.doubleClickZoom.disable();
  };

  const queryFeaturesByLayer = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    layers?: string[]
  ) =>
    mapRef.current?.queryRenderedFeatures(getQueriedFeaturesBounds(e.point), {
      layers: layers?.filter((i) => !!mapRef.current?.getLayer(i)),
    });

  const queryLayers = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    const pointEntityIds = Object.keys(layersSourceData.points).map(
      (key) => `${LAYERS_POINT}-${key}-main`
    );

    return queryFeaturesByLayer(e, [
      LAYERS_POLYGON_MAIN_FILL,
      LAYERS_POLYGON_MAIN_LINE,
      LAYERS_LINE_MAIN_FILL,
      ...pointEntityIds,
    ]);
  };

  const queryImagery = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    layer: string,
    imagery: IImageryPreview[]
  ) => {
    const selectedFeatures = queryFeaturesByLayer(e, [layer]);

    if (
      selectedFeatures?.length &&
      selectedFeatures[0].properties &&
      selectedFeatures[0].properties['relatedFeatureId']
    ) {
      const targetId = selectedFeatures[0].properties['relatedFeatureId'];
      const targetImagery = imagery.find((i) => i.id === targetId);

      return targetImagery;
    }

    return null;
  };

  const openModalWithEntity = (mapEntity: MapEntity, modalId: string) =>
    dispatch(
      modalsActions.addModal({
        id: modalId,
        isOpen: true,
        props: mapEntity,
      })
    );

  const openModalWithEntityId = (entityID: string, modalId: string) => {
    const entity = entitiesMap[entityID];
    entity && openModalWithEntity(entity, modalId);
  };

  const handleClosePreviewModal = () =>
    dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));

  const handleCloseDetailsModal = () => {
    dispatch(modalsActions.removeModal(ENTITY_DETAILS_MODAL));
    dispatch(drawActions.setDrawMode(MAP_DRAW_MODES.simple_select));
    dispatch(
      mapEntitiesActions.setTemporaryEntities(
        temporaryEntities.filter((entity) => !entity.state.draft)
      )
    );

    mapDraw.deleteAll();
  };

  const handleCloseShareModal = () =>
    dispatch(modalsActions.removeModal(SHARE_MODAL));

  const handleAddEntity = useCallback(
    (geometry: Geometry) => {
      if (mapObjectTemplate && mapLayerTemplate) {
        const parentEntityId =
          Number(localStorage.getItem('default_project')) || 0;

        const entity = getInitialEntity(
          0,
          mapObjectTemplate.template.id,
          paramIdMap,
          geometry,
          parentEntityId
        );

        const mapEntity = getInitialMapEntity(
          entity,
          mapLayerTemplate.template.id,
          mapObjectTemplate.template.id,
          DUMMY_ACCESS_RULES,
          parentEntityId
        );

        mapEntity.state.draft = true;
        mapEntity.state.active = true;

        dispatch(
          mapEntitiesActions.setTemporaryEntities([
            ...temporaryEntities,
            mapEntity,
          ])
        );
        safe(() => mapDraw.deleteAll());
        openModalWithEntity(mapEntity, ENTITY_DETAILS_MODAL);
      }
    },
    [mapObjectTemplate, mapLayerTemplate, temporaryEntities]
  );

  const handleBoundaryGridClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    const boundaryGridCell = queryFeaturesByLayer(e, [
      BOUNDARY_GRID_CELL_LAYER,
    ])?.[0] as BoundaryGridCellGeoJSON | undefined;

    if (!boundaryGridCell) {
      return;
    }

    dispatch(
      boundaryGridActions.updateBoundaryGridCell({
        relatedFeatureId: boundaryGridCell.properties.relatedFeatureId,
        id: boundaryGridCell.id || boundaryGridCell.properties.id,
        properties: { filled: !boundaryGridCell.properties.filled },
      })
    );
  };

  const handleGeocoderFeatureClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    const geocoderFeature = queryFeaturesByLayer(e, [
      GEOCODER_FEATURE_MAIN_FILL,
    ])?.[0];

    if (!geocoderFeature) {
      return;
    }

    dispatch(geocoderActions.setGeocoderResult(null));
  };

  const handleImageryClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    const targetImagery = queryImagery(
      e,
      IMAGERY_PREVIEW_FILL_LAYER,
      imagery.previews
    );

    if (targetImagery && !imagery.pending) {
      dispatch(
        getImageryThunk({
          id_list: [targetImagery.id],
          isPreviewRequest: true,
        })
      );
    }
  };

  const queryFeaturesOpenModal = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    modalId: string
  ) => {
    const queriedFeatures = queryLayers(e);

    if (
      queriedFeatures &&
      queriedFeatures?.length > 0 &&
      queriedFeatures[0].id
    ) {
      disableDblClickZoom();
      openModalWithEntityId(String(queriedFeatures[0].id), modalId);
    }
  };

  const handleEntityClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) =>
    !entityDetailsModal?.isOpen &&
    queryFeaturesOpenModal(e, ENTITY_PREVIEW_MODAL);

  const handlePointEntitiesClusterClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    const pointEntityClusterIds = Object.keys(layersSourceData.points).reduce(
      (acc, curr) => [
        ...acc,
        `${LAYERS_POINT}-${curr}-cluster`,
        `${LAYERS_POINT}-${curr}-cluster-counter`,
      ],
      [] as string[]
    );

    const cluster = queryFeaturesByLayer(
      e,
      pointEntityClusterIds
    )?.[0] as PointClusterGeoJSON;

    if (!cluster) {
      return;
    }

    const source = mapRef.current?.getSource(cluster.source) as
      | GeoJSONSource
      | undefined;

    source?.getClusterExpansionZoom(
      cluster.properties.cluster_id,
      (error, zoom) => {
        const center = cluster?.geometry?.coordinates as [number, number];

        if (error) {
          return console.log(error);
        }

        mapRef.current?.easeTo({
          center: center,
          zoom: zoom,
        });
      }
    );
  };

  const handleMapDoubleClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    enableDblClickZoom();
    handleClosePreviewModal();
    if (isMobile && contextMenu) {
      disableDblClickZoom();
      handleContextMenuClose();
    }
    queryFeaturesOpenModal(e, ENTITY_DETAILS_MODAL);
  };

  const withMapMeasureEnabledClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    measureMode: string
  ) => {
    const { features } = mapDraw.getAll();

    if (measureMode === MAP_MEASURE_MODES.measure_artillery) {
      dispatch(artilleryActions.setPosition([e.lngLat.lng, e.lngLat.lat]));
    }

    if (features.length > 1) {
      mapDraw.delete(features[0].id as string);
    }
  };

  const withMapMeasureDisabledClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    handleBoundaryGridClick(e);
    handleGeocoderFeatureClick(e);
    handleImageryClick(e);
    handleEntityClick(e);
    handlePointEntitiesClusterClick(e);
  };

  const handleMapSingleClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) =>
    drawMode && drawMode !== 'simple_select'
      ? withMapMeasureEnabledClick(e, drawMode)
      : withMapMeasureDisabledClick(e);

  const handleMapClickDebounced = useDebouncedCallback(
    (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
      if (e.originalEvent.detail === 1) {
        handleMapSingleClick(e);
      } else {
        handleMapDoubleClick(e);
      }
    },
    250
  );

  const handleMapClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    e.preventDefault();
    isMobile ? handleMapTouch(e) : handleMapClickDebounced(e);
  };

  const handleMapTouch = useDoubleTap(
    handleMapSingleClick,
    handleMapDoubleClick
  );

  const getCursorType = () => {
    // TODO: Map doesn't instantly render proper cursor, despite memo returning proper if else branch
    if (showCustomCursors && mapRef.current) {
      if (isGrab) {
        return AstraCursorTitle.GRABBING;
      } else if (mode?.startsWith('measure_') || mode?.startsWith('create_')) {
        return AstraCursorTitle.COPY;
      } else if (isCursorOnFeature) {
        return AstraCursorTitle.POINTER;
      } else {
        return AstraCursorTitle.CROSSHAIR;
      }
    } else {
      mapRef.current && (mapRef.current.getCanvas().style.cursor = '');
      return undefined;
    }
  };

  const getMapContexMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ): IContextMenu => {
    const { lngLat } = e;

    return {
      type: 'default',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      props: {},
    };
  };

  const getDrawnFeatureContextMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    drawMode: string
  ): IContextMenu | null => {
    const drawnFeature = queryFeaturesByLayer(e, [
      DRAW_INACTIVE_COLD_LAYER_ID,
      DRAW_INACTIVE_HOT_LAYER_ID,
      DRAW_ACTIVE_COLD_LAYER_ID,
      DRAW_ACTIVE_HOT_LAYER_ID,
    ])?.[0];

    const { lngLat } = e;

    if (!drawnFeature || drawMode !== MAP_MEASURE_MODES.measure_circle) {
      return null;
    }

    const hasUserGeometry = !!drawnFeature?.properties?.user_geometry;

    const geometry: Geometry = hasUserGeometry
      ? JSON.parse(drawnFeature?.properties?.user_geometry)
      : drawnFeature.geometry;

    const feature: MapboxGeoJSONFeature = {
      ...drawnFeature,
      geometry: geometry,
    };

    return {
      type: 'drawn_feature',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      props: { feature },
    };
  };

  const getBoundaryGridContextMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ): IContextMenu | null => {
    const boundaryGridCell = queryFeaturesByLayer(e, [
      BOUNDARY_GRID_CELL_LAYER,
    ])?.[0] as BoundaryGridCellGeoJSON | undefined;

    if (!boundaryGridCell) {
      return null;
    }

    const boundaryGrid =
      boundaryGridsMap[boundaryGridCell.properties.relatedFeatureId];

    const { lngLat } = e;

    return {
      type: 'boundary_grid',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      relatedFeatureId: boundaryGridCell.properties.relatedFeatureId,
      props: { boundaryGrid, boundaryGridCell },
    };
  };

  const getImageryContextMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ): IContextMenu | null => {
    const imageryBoundary = queryFeaturesByLayer(e, [
      IMAGERY_BOUNDARY_LAYER,
    ])?.[0] as ImageryBoundaryGeoJSON | undefined;

    const { lngLat } = e;

    if (!imageryBoundary) {
      return null;
    }

    const completeImageryBoundary = getGeoJSONCompletedFeature(imageryBoundary);

    return {
      type: 'imagery',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      relatedFeatureId: completeImageryBoundary.properties.relatedFeatureId,
      props: { imageryBoundary: completeImageryBoundary },
    };
  };

  const handleContextMenuOpen = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    const drawnFeatureContextMenu = getDrawnFeatureContextMenu(e, drawMode);
    const boundaryGridContextMenu = getBoundaryGridContextMenu(e);
    const imageryContextMenu = getImageryContextMenu(e);
    const mapContexMenu = getMapContexMenu(e);

    const contextMenu =
      drawnFeatureContextMenu ||
      boundaryGridContextMenu ||
      imageryContextMenu ||
      mapContexMenu;

    dispatch(contextMenuActions.setContextMenu(contextMenu));
  };

  const handleContextMenuClose = () =>
    dispatch(contextMenuActions.setContextMenu(null));

  const handleDragStart = () => setIsGrab(true);
  const handleDragEnd = () => setIsGrab(false);

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    const features = queryLayers(e);

    features && features.length > 0
      ? setIsCursorOnFeature(true)
      : setIsCursorOnFeature(false);
  };

  useCloseModalIfAnotherOpen();
  useCloseModalIfEntityDeleted(entityDetailsEntity, ENTITY_DETAILS_MODAL);
  useCloseModalIfEntityDeleted(entityPreviewEntity, ENTITY_PREVIEW_MODAL);

  useEffect(() => {
    setViewport();
  }, [viewport]);

  useEffect(() => {
    if (entityId) {
      dispatch(mapEntitiesActions.setSharedEntityId(entityId));
      entitiesMap[entityId] &&
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityID: Number(entityId),
            maxDepth: 9999,
            storage: 'full',
          })
        ).then(() => setShouldInitiateShare(true));
    }
  }, [entityId, entitiesMap]);

  useEffect(() => {
    if (
      entityId &&
      entitiesMap &&
      entitiesMap[entityId] &&
      mapObjectTemplate?.template.id &&
      mapLayerTemplate?.template.id &&
      shouldInitiateShare
    ) {
      setShouldInitiateShare(false);
      const entity = entitiesMap[entityId];
      const parents = getMapEntityFullPath(entitiesMap, entity.entity.id);
      const isFolder =
        entity.entity.templateID === mapLayerTemplate.template.id;
      const parametersIdTitleMap = getEntityParametersIdTitleMap(
        mapObjectTemplate?.parameters || [],
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      );

      const updateCounters = (entityIds: number[]) =>
        entityIds.forEach((parentId) => {
          dispatch(
            getMapEntityCountersThunk({
              parentEntityID: parentId,
              ...(mapObjectTemplate && {
                templateIDs: [mapObjectTemplate.template.id],
              }),
            })
          );
        });

      if (!isFolder) {
        const entityParams = getMapObjectEntityValues(
          parametersIdTitleMap,
          entity.entity
        );

        const geometryCenter = entityParams.geometry
          ? calculateGeometryCenter(entityParams.geometry)
          : undefined;

        mapRef.current?.flyTo({
          ...DEFAULT_FLY_TO_SETTINGS,
          zoom: 17,
          center: geometryCenter
            ? [geometryCenter[0], geometryCenter[1] + OFFSET_TO_FIT_PREVIEW]
            : undefined,
        });

        openModalWithEntityId(entityId, ENTITY_PREVIEW_MODAL);
      } else {
        const childrenObjects = getMapEntityChildrenId(
          entitiesMap,
          Number(entityId),
          entityTypeFilterFunc(ENTITY_TYPE_OBJECT)
        );

        mapRef.current?.fitBounds(
          getBounds(entitiesMap, parametersIdTitleMap, childrenObjects).map(
            (val, idx) =>
              idx < 2
                ? val - OFFSET_TO_FIT_PREVIEW
                : val + OFFSET_TO_FIT_PREVIEW
          ) as CameraBounds,
          { speed: 6 }
        );
      }

      const childrenLayers = isFolder
        ? getMapEntityChildrenId(
            entitiesMap,
            Number(entityId),
            entityTypeFilterFunc(ENTITY_TYPE_LAYER)
          )
        : [];

      updateCounters(parents.concat(childrenLayers));
      setTimeout(() => navigate(mapRoutes.LAYERS), 1000);
    }
  }, [
    entityId,
    entitiesMap,
    mapObjectTemplate,
    mapLayerTemplate,
    shouldInitiateShare,
  ]);

  useEffect(() => {
    const cursorToSet = getCursorType();

    setCursor(cursorToSet ?? undefined);
  }, [getCursorType, cursor]);

  return MAP_BOX_TOKEN ? (
    <div
      className={cn('w-full h-full relative', {
        'map-container__wrapper__svg-pointer': showCustomCursors,
      })}
      // Sets cursor to random number for cursor enum, this is used to
      // to fire rerender of Map.tsx and apply the cursor properly
      onMouseEnter={() => setCursor(Math.random() % 7)}
    >
      <MapBox
        ref={(ref) => (mapRef.current = ref && ref.getMap())}
        {...defaultConfig}
        {...viewport}
        terrain={{ source: 'mapbox-dem', exaggeration: 2 }}
        onContextMenu={handleContextMenuOpen}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleMapClick}
        onDblClick={handleMapClick}
        onTouchEnd={handleMapClick}
        onMouseMove={handleMouseMove}
        transformRequest={(url, type) =>
          transformTileRequest(url, type, tileToken)
        }
        cursor={cursor ? AstraCursors[cursor] : undefined}
      >
        <GoogleSource />
        <MapboxSource />
        <LabelsSource />
        <ElevationSource />
        <DemSource beforeId={LAYERS_LINE_MAIN_FILL} />
        <FireSource />
        <InfrastructureSource />
        <RailStationsSource />
        <RailroadsSource />
        <AimSource handleMapContextMenuOpen={handleContextMenuOpen} />
        <MeasureSource />
        <GeocoderSource />
        <ArtillerySource />
        <GeoconfirmedObjectsSource />
        <LayersSource {...layersSourceData} />
        <DocumentsSource {...documentsSorceData} mapRef={mapRef.current} />
        <ImagerySource />
        <FeatureCollection />
        <BoundaryGridSource />
        <Legend />
        <Controls onCreate={handleAddEntity} forceUpdate={forceUpdate} />
        {contextMenu && (
          <ContextMenu
            contextMenu={contextMenu}
            onClose={handleContextMenuClose}
            handleAddEntity={handleAddEntity}
          />
        )}
        {imageryUploadModal?.isOpen && <ImageryUploadModal />}
        {isEntityPreviewModalOpen && (
          <EntityPreview
            key={entityPreviewModal?.props?.entity?.id}
            entity={entityPreviewModal?.props as MapEntity}
            mapObjectTemplate={mapObjectTemplate}
            onClose={handleClosePreviewModal}
          />
        )}
        {isEntityDetailsModalOpen && (
          <EntityDetails
            key={
              entityDetailsModal?.props?.entity
                ? entityDetailsModal.props.entity.id
                : new Date().getTime()
            }
            entity={entityDetailsModal?.props as MapEntity}
            mapObjectTemplate={mapObjectTemplate}
            onClose={handleCloseDetailsModal}
          />
        )}
        {isShareModalOpen && (
          <ShareModal
            id={shareModal?.props?.entityId}
            onClose={handleCloseShareModal}
          />
        )}
      </MapBox>
    </div>
  ) : (
    <p>Не удалось загрузить карту. Отсутствует токен</p>
  );
};
