import { getReverseGeocoding } from 'api/entities';
import { MAP_BOX_TOKEN } from 'constants/map';

export const reverseGeocodeFromCoords = async (lng: number, lat: number) =>
  getReverseGeocoding({
    longitude: lng,
    latitude: lat,
    limit: 1,
    types: ['place', 'region'],
    language: 'ru',
    access_token: MAP_BOX_TOKEN,
  });
