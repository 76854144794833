import {
  LATITUDE_MAX,
  LATITUDE_MIN,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
} from 'constants/map';
import {
  TCoordinatesArray,
  TCoordinatesObject,
  TCoordinatesPart,
  TPosition,
} from 'types';

export const getValidCoords = (
  param1: TCoordinatesPart | TCoordinatesArray | TCoordinatesObject,
  param2?: TCoordinatesPart
): TPosition | null => {
  let latitude = null;
  let longitude = null;

  // 'lat, lng'
  if (typeof param1 === 'string' && typeof param2 === 'undefined') {
    const [lat, lng] = param1.split(',');

    latitude = lat;
    longitude = lng;
  }

  // [lat, lng]
  else if (Array.isArray(param1)) {
    const [lat, lng] = param1;

    if (isNaN(lat) || isNaN(lat)) {
      return null;
    }

    latitude = lat;
    longitude = lng;
  }

  // { lat, lng }
  else if (typeof param1 === 'object' && param1 !== null) {
    const parts = param1 as TCoordinatesObject;

    latitude = parts.latitude ?? parts.lat;
    longitude = parts.longitude ?? parts.lng ?? parts.lon ?? parts.long;
  }

  if (
    (!latitude && typeof latitude !== 'number') ||
    (!longitude && typeof longitude !== 'number')
  ) {
    return null;
  }

  latitude = Number(latitude);
  longitude = Number(longitude);

  if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
    return null;
  }

  if (longitude < LONGITUDE_MIN || longitude > LONGITUDE_MAX) {
    longitude = (((longitude % 360) + 540) % 360) - 180;
  }

  if (latitude < LATITUDE_MIN || latitude > LATITUDE_MAX) {
    return null;
  }

  return [latitude, longitude];
};

export const isValidCoords = (
  ...args: Parameters<typeof getValidCoords>
): boolean => {
  return Boolean(getValidCoords(...args));
};
