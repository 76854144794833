import { useDebouncedCallback } from 'use-debounce';

interface IBaseClickEvent {
  detail: number;
}

export const useDoubleClick = <T extends IBaseClickEvent>(
  onClick: (e: T) => void,
  onDoubleClick: (e: T) => void,
  delay = 250
) => {
  const handleClick = (e: T) => {
    if (e.detail === 1) {
      onClick?.(e);
    } else {
      onDoubleClick?.(e);
    }
  };

  const handleClickDebounced = useDebouncedCallback(handleClick, delay);

  return handleClickDebounced;
};
