import { getReportsTable } from '../../../api/reports';
import { errorMessages } from '../../../constants/errors';
import { asyncActionsNames, reducersNames } from '../../../constants/reducers';
import {
  IReportsTableRequest,
  ReportsTableResponse,
} from '../../../interfaces/reports';
import { TReportSortUnion } from '../../../types/report';
import { createThunk } from '../../../utils';

export const getReportsTableThunk = createThunk<
  ReportsTableResponse,
  IReportsTableRequest<TReportSortUnion>
>(
  `${reducersNames.REPORTS}/${asyncActionsNames.GET_REPORTS_TABLE}`,
  getReportsTable,
  errorMessages.GET_REPORTS_ERROR,
  (response, request) =>
    ({ ...response, start: request?.start } as ReportsTableResponse)
);
