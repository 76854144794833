import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { notify } from 'utils';

import { TABS_REDUCER_NAMES } from '../constants';

import { getAllGCObjectsThunk, getGCRangesThunk } from './actions';
import {
  OperationalState,
  SetAvailableRangesAction,
  SetDateRangeAction,
  SetObjectsAction,
} from './types';

const initialState: OperationalState = {
  availableRanges: null,
  dateRange: [null, null],
  objects: {},
};

const operationalSlice = createSlice({
  name: TABS_REDUCER_NAMES.OPERATIONAL,
  initialState: initialState,
  reducers: {
    setAvailableRanges(state, action: SetAvailableRangesAction) {
      state.availableRanges = action.payload;
    },
    setRange(state, action: SetDateRangeAction) {
      state.dateRange = action.payload;
    },
    setObjects(state, action: SetObjectsAction) {
      state.objects = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllGCObjectsThunk.fulfilled, (state, action) => {
        state.objects = action.payload;
      })
      .addCase(getAllGCObjectsThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getGCRangesThunk.fulfilled, (state, action) => {
        state.availableRanges = {
          frontline_min: dayjs(action.payload.frontline_min).toDate(),
          frontline_max: dayjs(action.payload.frontline_max).toDate(),
          placemark_min: dayjs(action.payload.placemark_min).toDate(),
          placemark_max: dayjs(action.payload.placemark_max).toDate(),
        };
      })
      .addCase(getGCRangesThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      }),
});

export const { actions: operationalActions, reducer: operationalReducer } =
  operationalSlice;
