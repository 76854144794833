import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { formatWgsCoords, getFormattedDate } from 'utils';

import { ObjectPreviewPopup } from '../ObjectPreview/ObjectPreviewPopup';
import { ObjectPreviewTable } from '../ObjectPreview/ObjectPreviewTable';
import { ObjectPreviewText } from '../ObjectPreview/ObjectPreviewText';
import { ObjectPreviewTitle } from '../ObjectPreview/ObjectPreviewTitle';

const LINE_BREAK_TAG_REGEX = /<br\s*\/?>/gi;
const URL_REGEX = /(https?:\/\/\S+)/g;

const getFeatureCoordinates = (feature: MapboxGeoJSONFeature | null) =>
  feature?.geometry.type === 'Point' ? feature.geometry.coordinates : [0, 0];

const parseUrls = (value?: string) => {
  const clearText = value?.replace(LINE_BREAK_TAG_REGEX, '\n');
  const urls = clearText?.match(URL_REGEX);

  return urls || [];
};

const getAnchorNodes = (urls: string[]) =>
  urls.map((url, index, arr) => {
    const isLastUrl = index + 1 === arr.length;
    const urlTitle = isLastUrl
      ? `Источник ${index + 1}`
      : `Источник ${index + 1}, `;

    return (
      <a key={index} href={url} target="_blank" rel="noopener noreferrer">
        {urlTitle}
      </a>
    );
  });

export const GeoconfirmedObjectPreviewPopup = ({
  object,
  onClose,
}: {
  object: MapboxGeoJSONFeature;
  onClose: () => void;
}) => {
  const [popupLon, popupLat] = getFeatureCoordinates(object);
  const descriptionRows = [
    {
      label: 'Дата',
      value:
        getFormattedDate(object.properties?.date, 'DD.MM.YYYY', true) ??
        'Нет даты',
    },
    {
      label: 'Координаты',
      value:
        popupLon && popupLat
          ? formatWgsCoords(popupLon, popupLat)
          : 'Нет координат',
      copyable: true,
    },
    {
      label: 'Описание',
      value: object.properties?.description || 'Нет описания',
    },
  ];

  const geolocationUrls = getAnchorNodes(
    parseUrls(object.properties?.geolocation)
  );

  const sourceUrls = getAnchorNodes(parseUrls(object.properties?.source));

  return (
    <ObjectPreviewPopup
      longitude={popupLon}
      latitude={popupLat}
      offset={34}
      onClose={onClose}
    >
      <ObjectPreviewTitle>Описание</ObjectPreviewTitle>
      <ObjectPreviewTable
        rows={descriptionRows}
        classNames={{ cell: 'line-clamp-[10]' }}
      />
      {!!geolocationUrls.length && (
        <>
          <ObjectPreviewTitle>Геолокация</ObjectPreviewTitle>
          <ObjectPreviewText>{geolocationUrls}</ObjectPreviewText>
        </>
      )}
      {!!sourceUrls.length && (
        <>
          <ObjectPreviewTitle>Источники</ObjectPreviewTitle>
          <ObjectPreviewText>{sourceUrls}</ObjectPreviewText>
        </>
      )}
    </ObjectPreviewPopup>
  );
};
