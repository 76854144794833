import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { labelConfig, lineConfig } from 'configs/map/common';
import { LINE_SOURCE } from 'constants/map';
import { MapGeoJSONObject } from 'interfaces';

interface LineSourceProps extends MapGeoJSONObject {
  mainConfig?: LayerProps;
  mainLabelConfig?: LayerProps;
}

export const LineSource: FC<LineSourceProps> = memo(
  ({
    id = LINE_SOURCE,
    data,
    mainConfig = lineConfig,
    mainLabelConfig = labelConfig,
  }) => (
    <Source id={id} type="geojson" data={data}>
      <Layer id={`${id}-main-fill`} {...mainConfig} />
      <Layer id={`${id}-main-label`} {...mainLabelConfig} />
    </Source>
  )
);

LineSource.displayName = 'LineSource';
