import { ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as SortDown } from 'images/icons/sort-down.svg';
import { ReactComponent as SortUp } from 'images/icons/sort-up.svg';
import { ISortableField } from 'interfaces';

import { Checkbox, TableSkeleton } from 'components/ui';
import { genericMemo } from 'utils';

import './style.scss';

export interface ITableColumn<T> {
  title: string;
  accessor: string;
  className?: string;
  sortable?: boolean;
  render: (item: T) => ReactNode;
}

interface TableProps<T, S> {
  data: T[];
  isLoading: boolean;
  columns: ITableColumn<T>[];
  tableClassName?: string;
  rowClassName?: string;
  sortable?: ISortableField<S> | null;
  onSort?: (field: S) => void;
  checkedAll?: boolean;
  onCheckAll?: () => void;
  onRowClick?: (item: T) => void;
  isChecked?: boolean;
}

const Table = <
  T extends {
    id?: number | string;
  },
  S = string
>({
  columns,
  data,
  isLoading,
  sortable,
  tableClassName = '',
  rowClassName = '',
  onSort,
  onCheckAll,
  checkedAll = false,
  onRowClick,
  isChecked,
}: TableProps<T, S>) => {
  const handleCheckAll = () => {
    if (onCheckAll) {
      onCheckAll();
    }
  };

  const renderTableHead = (item: ITableColumn<T>, idx: number) => (
    <th key={`table-head-item-${idx}`} className="tpg-text-six">
      {isChecked && item.accessor === 'actions' && onCheckAll && (
        <Checkbox label={''} isChecked={checkedAll} onChange={handleCheckAll} />
      )}
      {item.title}
      {item.sortable && onSort && (
        <span
          className="table__sort-actions"
          onClick={() => onSort(item.accessor as S)}
        >
          <>
            <SortDown
              className={cn('table__sort-actions__icon', {
                'table__sort-actions__icon-active':
                  sortable?.field === item.accessor &&
                  sortable.direction === 'asc',
              })}
            />
            <SortUp
              className={cn('table__sort-actions__icon', {
                'table__sort-actions__icon-active':
                  sortable?.field === item.accessor &&
                  sortable.direction === 'desc',
              })}
            />
          </>
        </span>
      )}
    </th>
  );

  const renderTableRow = (item: T, idx: number) => (
    <tr
      key={`row-item-${item.id}-${idx}`}
      className={rowClassName}
      onClick={onRowClick ? () => onRowClick(item) : undefined}
    >
      {columns.map((column: ITableColumn<T>, index: number) => (
        <td
          className={column.className || ''}
          key={`column-${column.accessor}-${index}`}
        >
          {column.render(item)}
        </td>
      ))}
    </tr>
  );

  return (
    <table className={`table ${tableClassName}`}>
      <thead>
        <tr>{columns.map(renderTableHead)}</tr>
      </thead>
      <tbody>
        {data.map(renderTableRow)}
        {isLoading && <TableSkeleton columnSize={columns.length} />}
      </tbody>
    </table>
  );
};

export default genericMemo(Table);
