import { MutableRefObject, useEffect } from 'react';

export const useSliderVerticalNavigation = <T extends HTMLElement>(
  container: MutableRefObject<T | null>,
  currentSlide: number,
  size: number,
  gap: number,
  shouldNavigate: boolean
) => {
  useEffect(() => {
    if (!shouldNavigate) {
      return;
    }

    const slideHeight = size + gap;

    const visibleSlidesCount =
      (container.current &&
        Math.trunc((container.current.clientHeight + gap) / slideHeight)) ??
      0;

    const scrollPosition = container.current?.scrollTop ?? 0;
    const centerSlideCorrection =
      visibleSlidesCount - Math.ceil(visibleSlidesCount / 2);

    const currentSlideScrollPosition =
      (currentSlide - centerSlideCorrection) * slideHeight;

    const scrollCorrection = scrollPosition - currentSlideScrollPosition;

    container?.current?.scroll({
      top: scrollPosition - scrollCorrection,
      behavior: 'smooth',
    });
  }, [container, currentSlide, size, gap, shouldNavigate]);
};

export const useSliderHorizontalNavigation = <T extends HTMLElement>(
  container: MutableRefObject<T | null>,
  currentSlide: number,
  size: number,
  gap: number,
  shouldNavigate: boolean
) => {
  useEffect(() => {
    if (!shouldNavigate) {
      return;
    }

    const slideWidth = size + gap;

    const visibleSlidesCount =
      (container.current &&
        Math.trunc((container.current.clientWidth + gap) / slideWidth)) ??
      0;

    const scrollPosition = container.current?.scrollLeft ?? 0;
    const centerSlideCorrection =
      visibleSlidesCount - Math.ceil(visibleSlidesCount / 2);

    const currentSlideScrollPosition =
      (currentSlide - centerSlideCorrection) * slideWidth;

    const scrollCorrection = scrollPosition - currentSlideScrollPosition;

    container?.current?.scroll({
      left: scrollPosition - scrollCorrection,
      behavior: 'smooth',
    });
  }, [container, currentSlide, size, gap, shouldNavigate]);
};
