import { FC, useEffect, useMemo, useState } from 'react';

import { notify } from 'utils';

import { importKML } from '../../../api/entities';
import { predefinedTemplates } from '../../../constants/entities';
import { errorMessages, successMessages } from '../../../constants/errors';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { IMediaFile, ISelectOption } from '../../../interfaces';
import {
  getMapEntitiesThunk,
  getMapEntityChildrenThunk,
} from '../../../store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { predefinedTemplateIdSelector } from '../../../store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { getSelectOptionsFromSearch } from '../../../utils/entity';
import { Button, Modal, Switch } from '../../ui';
import Dropzone from '../../ui/Dropzone';
import TreeSelect from '../../ui/TreeSelect';

export interface ImportDetailsProps {
  parentEntityID: number;
  onClose: () => void;
}

const ImportDetails: FC<ImportDetailsProps> = ({
  parentEntityID: initialParentEntityID,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const [parentEntityID, setParentEntityID] = useState(initialParentEntityID);
  const [ignoreMedia, setIgnoreMedia] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [layerOptions, setLayerOptions] = useState<ISelectOption[]>([]);
  const [files, setFiles] = useState<IMediaFile[]>([]);

  const file: IMediaFile | undefined = files[0];

  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_OBJECT)
    ) || 0;
  const mapLayerTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_LAYER)
    ) || 0;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await getSelectOptionsFromSearch(
        mapObjectTemplateId,
        setLayerOptions,
        [mapLayerTemplateId],
        [],
        true
      ).finally(() => setIsLoading(false));
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    if (!file?.file) {
      return;
    }
    const splitFilename = file.file.name.split('.');
    const extension = splitFilename[splitFilename.length - 1];
    const filename = splitFilename.slice(0, -1).join('');

    const allowedExtensions = ['kmz', 'kml'];
    if (!allowedExtensions.includes(extension)) {
      notify.error(
        'Недопустимый формат файла, импортировать возможно .kml и .kmz файлы'
      );
      return;
    }

    setIsLoading(true);
    await importKML(file.file, {
      entityID: parentEntityID,
      file: extension,
      filename,
      ignoreMedia,
    })
      .then(() => {
        if (parentEntityID === 0) {
          dispatch(getMapEntitiesThunk({ maxDepth: 3 }));
        } else {
          dispatch(
            getMapEntityChildrenThunk({
              parentEntityID,
              storage: 'partial',
              maxDepth: 3,
            })
          );
        }
        notify.success(successMessages.IMPORT_SUCCESS);
        onClose();
      })
      .catch(() => notify.error(errorMessages.IMPORT_ERROR))
      .finally(() => setIsLoading(false));
  };

  const noFolderPlaceholder = 'Создать папку с тем же названием';
  const baseSelectOptions: ISelectOption[] = [
    { label: noFolderPlaceholder, value: 0 },
  ];
  const extendedSelectOptions = useMemo(
    () => baseSelectOptions.concat(layerOptions),
    [layerOptions]
  );

  const dropzonePlaceholder =
    file && file.file?.name ? file.file.name : 'Загрузите файл';

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12 flex flex-col items-center gap-6">
        <div className="tpg-h4 flex justify-center">Импорт данных</div>
        <div className="flex flex-col gap-4 flex-1 w-full">
          <Dropzone
            placeholder={dropzonePlaceholder}
            files={[]}
            acceptedFormat={'.kml,.kmz,.xml'}
            theme={'light'}
            onAdd={setFiles}
            onDelete={(deletedFile) =>
              setFiles((files) =>
                files.filter((file) => file.id !== deletedFile.id)
              )
            }
          />
          <TreeSelect
            placeholder={noFolderPlaceholder}
            withEmpty={false}
            withSearch
            value={parentEntityID}
            options={extendedSelectOptions}
            onSelect={setParentEntityID}
            isExpandable
            theme={'light'}
          />
          <div className="flex justify-between items-center">
            <span className="tpg-c1">Импортировать без медиа</span>
            <Switch
              checked={ignoreMedia}
              onChange={() => setIgnoreMedia((prev) => !prev)}
            />
          </div>
          <Button
            className="w-full"
            title="Создать сущности"
            onClick={handleSave}
            isLoading={isLoading}
            disabled={!file}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImportDetails;
