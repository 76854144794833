import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useEscapeDown } from 'hooks/useEscapeDown';

import { CloseButton } from './CloseButton';

import './style.scss';

export interface IModalProps {
  children: ReactNode;
  title?: string;
  keyboard?: boolean;
  width?: string | number;
  onClose: () => void;
  containerClassName?: string;
  className?: string;
  closeOnOutsideClick?: boolean;
  style?: CSSProperties;
  isBlurred?: boolean;
  interceptFocus?: boolean;
  closeIcon?: boolean;
}

const Modal: FC<IModalProps> = ({
  children,
  containerClassName,
  className,
  onClose,
  title,
  width = 'max-content',
  keyboard = false,
  closeOnOutsideClick = true,
  style,
  isBlurred = false,
  interceptFocus,
  closeIcon,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const modalStyle: CSSProperties = {
    pointerEvents: 'all',
    maxWidth: width,
    ...style,
  };

  useEffect(() => {
    interceptFocus && modalRef.current?.focus();
  }, [modalRef, interceptFocus]);

  useEscapeDown(onClose, keyboard);

  return (
    <div
      ref={modalRef}
      tabIndex={interceptFocus ? 0 : undefined}
      className={cn(
        'modal',
        {
          modal__blurred: isBlurred,
        },
        containerClassName
      )}
      onClick={() => closeOnOutsideClick && onClose()}
      style={{ pointerEvents: closeOnOutsideClick ? 'unset' : 'none' }}
    >
      {closeIcon && <CloseButton onClick={onClose} />}
      <div
        className={cn(`modal__body ${className || ''}`, {
          'pb-24': isBlurred,
        })}
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        {title && <div>{title}</div>}
        {children}
      </div>
    </div>
  );
};

export default Modal;
