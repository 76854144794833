import cn from 'classnames';
import { Option } from 'interfaces';
import { GeocoderFeature } from 'interfaces/geocoder';

import { AutocompleteDropdownOptionProps } from 'components/ui/Autocomplete/AutocompleteDropdownOption';

type GeocoderAutocompleteOptionProps =
  AutocompleteDropdownOptionProps<GeocoderFeature>;

const getTitles = (option: Option<GeocoderFeature>) => {
  if (option.value.subtype === 'place') {
    const placeName = option.value.place_name.split(',');
    const subtitle = placeName.splice(1, placeName.length).join(',');

    return { title: option.label, subtitle };
  } else {
    return { title: option.label };
  }
};

export const GeocoderAutocompleteOption = ({
  option,
  onClick,
  className,
}: GeocoderAutocompleteOptionProps) => {
  const { title, subtitle } = getTitles(option);

  return (
    <li
      role="button"
      className={cn(
        'px-3 py-[6px] list-none bg-light text-tpg_base cursor-pointer hover:bg-dark',
        className
      )}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => onClick(option)}
    >
      <p className="text-[13px] font-['Proxima_Nova'] font-bold leading-5 line-clamp-1 overflow-ellipsis">
        {title}
      </p>
      <p className="text-[13px] font-['Proxima_Nova'] font-normal leading-5 line-clamp-1 overflow-ellipsis">
        {subtitle}
      </p>
    </li>
  );
};
