import { FC, ReactNode } from 'react';
import { ReactComponent as SpoofMarker } from 'images/newIcons/spoofMarker.svg';

import './style.scss';

interface SpoofMarkerProps {
  title?: string;
  className?: string;
  icon?: ReactNode;
}

export const GeospoofMarker: FC<SpoofMarkerProps> = ({
  title,
  className,
  icon,
}) => (
  <div className={`w-8 h-8 grid proxima-font ${className}`}>
    <SpoofMarker style={{ gridArea: '1/1' }} />
    {icon ? (
      <div style={{ gridArea: '1/1' }}>{icon}</div>
    ) : (
      <span
        style={{ gridArea: '1/1' }}
        className="tpg-b1 text-tpg_title w-auto h-auto m-auto"
      >
        {title}
      </span>
    )}
  </div>
);
