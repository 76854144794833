import { createSlice } from '@reduxjs/toolkit';

import { SERVICE_REDUCER_NAMES } from '../constants';

import {
  AddLoaderAction,
  LoadersState,
  RemoveLoaderAction,
  SetLoadersAction,
  UpdateLoaderAction,
} from './types';

export const initialState: LoadersState = {
  loaders: {},
};

const loadersSlice = createSlice({
  name: SERVICE_REDUCER_NAMES.LOADERS,
  initialState: initialState,
  reducers: {
    setLoaders(state, action: SetLoadersAction) {
      state.loaders = action.payload;
    },
    addLoader(state, action: AddLoaderAction) {
      const loader = action.payload;

      state.loaders = { ...state.loaders, [loader.id]: loader };
    },
    updateLoader(state, action: UpdateLoaderAction) {
      const { id, loader } = action.payload;

      state.loaders[id] = { ...state.loaders[id], ...loader };
    },
    removeLoader(state, action: RemoveLoaderAction) {
      const id = action.payload;
      const { [id]: _removedLoader, ...restLoaders } = state.loaders;

      state.loaders = restLoaders;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: loadersActions, reducer: loadersReducer } =
  loadersSlice;
