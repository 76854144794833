import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentCoordinates, getDocumentInPdf } from 'api/documents';
import { predefinedTemplates } from 'constants/entities';
import { errorMessages, infoMessages } from 'constants/errors';
import { appRoutes } from 'constants/routes';
import { SIDEBAR_WIDTH } from 'constants/ui';
import { useAppDispatch } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { ReactComponent as Rollback } from 'images/newIcons/rollback.svg';
import { DocumentRes, GetDocCoordinatesRes } from 'interfaces/documents';
import { getPredefinedTemplateThunk } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { DocumentNode } from 'types/documents';

import { DocumentList } from 'components/Documents/DocumentList/DocumentList';
import DocumentViewer from 'components/Documents/DocumentViewer';
import { TagSidebar } from 'components/Documents/Sidebars/Tags/TagSidebar';
import { Header } from 'components/Header/Header';
import { Loader, Switch, TextInput } from 'components/ui';
import ShareModal from 'components/ui/Modal/ShareModal';
import { downloadObject, notify } from 'utils';

import { DocumentSidebar } from '../../components/Documents/Sidebars/Documents/DocumentSidebar';

export interface DocumentFilters {
  tagIDs: number[];
  searchQuery: string;
  activeEntityIDs: number[];
}

interface IDocumentContext {
  nerEnabled: boolean;
}

const PDF_EXTENSION = 'pdf';
const DocumentContext = createContext<IDocumentContext>({ nerEnabled: false });

export const useDocumentContext = () => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDocumentContext must be used inside DocumentProvider');
  }
  return context;
};

export const DocumentsScreen = () => {
  const { documentId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [previewedDocument, setPreviewedDocument] = useState<Blob>();
  const [activeDocumentNodes, setActiveDocumentNodes] = useState<
    DocumentNode[]
  >([]);
  const [documentCoordinates, setDocumentCoordinates] =
    useState<GetDocCoordinatesRes>();
  const [searchQuery, setSearchQuery] = useState('');
  const [previewedDocumentTitle, setPreviewedDocumentTitle] = useState('');
  const [previewedEntityId, setPreviewedEntityId] = useState(0);
  const [tagIDs, setTagIDs] = useState<number[]>([]);
  const [activeEntityIDs, setActiveEntityIDs] = useState<number[]>([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [nerEnabled, setNerEnabled] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const viewerWidth = useMemo(
    () => window.innerWidth - SIDEBAR_WIDTH * (nerEnabled ? 2 : 1),
    [nerEnabled]
  );
  const viewerHeight = window.innerHeight - 100;

  const handleShareClick = () => setShareModal(true);
  const handleCloseShareModal = () => setShareModal(false);

  const navigateToHomeDocumentPage = () =>
    documentId && navigate(appRoutes.DOCUMENTS);

  const handleBackClick = () => {
    setActiveDocumentNodes(
      activeDocumentNodes.filter((el) => el.data.isFolder)
    );
    setPreviewedDocument(undefined);
    navigateToHomeDocumentPage();
  };

  const resetActiveDocuments = () => {
    setActiveDocumentNodes([]);
    previewedDocument && setPreviewedDocument(undefined);
  };

  const handleDocumentClick = (document: DocumentRes) => {
    fetchDocumentPdf(document.ID, false);
    setPreviewedDocumentTitle(document.fullPath);
    setPreviewedEntityId(document.entityID);
  };

  const fetchDocumentPdf = async (docId: number, byEntityId: boolean) => {
    setLoadingPdf(true);
    try {
      const res = await getDocumentInPdf(docId, byEntityId);
      const contentType = res.headers['content-disposition'];
      const filenameMatch = contentType.match(/filename=([^;]+)/);
      const filename = filenameMatch[1].trim().replace(/^"|"$/g, '');
      const extension = filename.split('.').pop() || '';
      if (extension !== PDF_EXTENSION) {
        notify.success(infoMessages.TOO_BIG_FILE);
        downloadObject(res.data, filename, { type: contentType });
      } else {
        setPreviewedDocument(res.data);
        const docCoordinates = getDocumentCoordinates(docId);
        setDocumentCoordinates(docCoordinates);
      }
    } catch (err) {
      notify.error(errorMessages.GET_DOCUMENT_PREVIEW_ERROR);
    } finally {
      setLoadingPdf(false);
    }
  };

  const fetchPredefinedTemplates = async () => {
    dispatch(getPredefinedTemplateThunk(predefinedTemplates.DOCUMENT));
    dispatch(
      getPredefinedTemplateThunk(predefinedTemplates.DOCUMENT_DIRECTORY)
    );
  };

  useEscapeDown(handleBackClick);

  useEffect(() => {
    fetchPredefinedTemplates();
  }, []);

  useEffect(() => {
    const documentsNumber = activeDocumentNodes.filter(
      (el) => !el.data.isFolder
    ).length;
    if (documentsNumber === 0) setPreviewedDocument(undefined);

    if (documentsNumber === 1) {
      const nodeData = activeDocumentNodes.find((el) => !el.data.isFolder);
      if (!nodeData) return;
      if (nodeData.data.isFolder) {
        const selectedFolderId = Number(nodeData.data.id);
        setActiveEntityIDs([selectedFolderId]);
      } else {
        const entityId = Number(nodeData.data.id);
        fetchDocumentPdf(entityId, true);
        setPreviewedDocumentTitle(nodeData.data.name);
        setPreviewedEntityId(entityId);
        navigateToHomeDocumentPage();
      }
    } else {
      const entityIds = activeDocumentNodes.map((el) => Number(el.data.id));
      setActiveEntityIDs(entityIds);
    }
  }, [activeDocumentNodes]);

  useEffect(() => {
    if (!documentId) return;
    fetchDocumentPdf(Number(documentId), true);
  }, [documentId]);

  return (
    <DocumentContext.Provider value={{ nerEnabled: nerEnabled }}>
      <div className="flex flex-col w-full h-full relative overflow-hidden">
        <Header>
          <div className="w-full flex tpg-c2">
            <TextInput
              classNames={{ container: 'ml-[44px]', input: '!min-w-[370px]' }}
              value={searchQuery}
              placeholder="Поиск по содержимому документов"
              searchIcon
              maxLength={99999}
              onChange={setSearchQuery}
            />
            <div className="flex w-full items-center justify-between mx-6">
              {previewedDocument && (
                <div>
                  <div className="flex">
                    <div
                      className="flex items-center cursor-pointer hover:text-main_product"
                      onClick={handleBackClick}
                    >
                      <Rollback />
                      <div className="cursor-pointer pl-1.5">
                        Вернуться назад (esc)
                      </div>
                    </div>
                    <div className="pl-6 text-tpg_base">
                      {previewedDocumentTitle}
                    </div>
                    <div
                      onClick={handleShareClick}
                      className="text-main_product hover:text-bright_product cursor-pointer pl-6"
                    >
                      Поделиться
                    </div>
                  </div>
                </div>
              )}
              <div className="flex absolute right-0 items-center mr-6">
                <div className="pr-3">Разметка NER</div>
                <Switch
                  checked={nerEnabled}
                  onChange={() => setNerEnabled(!nerEnabled)}
                />
              </div>
            </div>
          </div>
        </Header>
        <DocumentSidebar
          activeNodes={activeDocumentNodes}
          setActiveNodes={setActiveDocumentNodes}
          resetActiveDocuments={resetActiveDocuments}
        />
        {loadingPdf ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        ) : (
          <div className="overflow-auto">
            <DocumentList
              width={viewerWidth}
              className={`ml-[474px] overflow-hidden`}
              onDocumentClick={handleDocumentClick}
              tagIDs={tagIDs}
              searchQuery={searchQuery}
              activeEntityIDs={activeEntityIDs}
            />
            {previewedDocument && (
              <div
                className={`absolute top-[60px] left-[474px] transition-all duration-300 z-10  border-l border-r border-solid border-ultrablack`}
              >
                {/*todo enable coordinate viewer when backend is ready*/}
                {/*{documentCoordinates &&*/}
                {/*  !!documentCoordinates?.coordinates.length && (*/}
                {/*    <DocumentCoordinates*/}
                {/*      coordinates={documentCoordinates.coordinates}*/}
                {/*    />*/}
                {/*  )}*/}
                <DocumentViewer
                  blob={previewedDocument}
                  width={viewerWidth}
                  height={viewerHeight}
                />
              </div>
            )}
          </div>
        )}
        {nerEnabled && (
          <TagSidebar
            tagIDs={tagIDs}
            setTagIDs={setTagIDs}
            searchQuery={searchQuery}
            activeEntityIDs={activeEntityIDs}
          />
        )}
      </div>
      {shareModal && (
        <ShareModal
          id={String(previewedEntityId)}
          onClose={handleCloseShareModal}
          subdomain="documents"
        />
      )}
    </DocumentContext.Provider>
  );
};
