import {
  DumpSearchPersonNameInfo,
  DumpSearchTableDocument,
  KnownDumpSearchDocumentType,
} from '../interfaces/dumps';

import { getFullName } from './helpers/string';

type DumpSearchTableDocumentName = DumpSearchTableDocument['name'];
type DumpSearchTableDocuments = DumpSearchTableDocument['document'];

export const getDumpDocumentPersonFullName = (
  names: DumpSearchTableDocumentName
) => {
  let result = '';

  if (names) {
    const uaName: DumpSearchPersonNameInfo | undefined = names.ua;
    const ruName: DumpSearchPersonNameInfo | undefined = names.ru;

    if (uaName)
      result += getFullName(uaName.first, uaName.last, uaName.patronymic);

    if (ruName)
      result += uaName
        ? ' | ' + getFullName(ruName.first, ruName.last, ruName.patronymic)
        : '';
  }

  return result;
};

export const getDumpDocumentPersonShortName = (
  names: DumpSearchTableDocumentName
) => {
  if (names) {
    const uaName: DumpSearchPersonNameInfo | undefined = names.ua;
    const ruName: DumpSearchPersonNameInfo | undefined = names.ru;

    if (uaName)
      return getFullName(uaName.first, uaName.last, uaName.patronymic);

    if (ruName)
      return getFullName(ruName.first, ruName.last, ruName.patronymic);
  }
  return '';
};

export const getDumpDocumentsText = (document: DumpSearchTableDocuments) => {
  let result = '';

  if (document) {
    Object.entries(document).map(([key, value]) => {
      result += `${getDictPropertyDisplayName(key)}: ${value}\n`;
    });
  }

  return result;
};

export const getDictPropertyDisplayName = (
  key: KnownDumpSearchDocumentType | string
) => {
  if (key === 'passport') {
    return 'Паспорт';
  } else if (key === 'INN') {
    return 'ИНН';
  } else if (key === 'driver_license') {
    return 'В/У';
  } else return key;
};
