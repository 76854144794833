import DatePickerElement, { ReactDatePickerProps } from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import cn from 'classnames';
import ru from 'date-fns/locale/ru';

import { genericMemo } from 'utils';

import { DatePickerCustomDay } from './DatePickerCustomDay';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './date-picker.module.scss';

export interface IDatePickerProps<T extends boolean | undefined>
  extends ReactDatePickerProps<never, T> {
  black?: boolean;
}

registerLocale('ru', ru);

const DatePicker = <T extends boolean | undefined>({
  locale = 'ru',
  dateFormat = 'dd.MM.yyyy',
  selected,
  placeholderText = 'ДД.ММ.ГГГГ',
  isClearable,
  className,
  calendarClassName = 'date-picker',
  black = false,
  onChange,
  ...props
}: IDatePickerProps<T>) => (
  <div className={styles['datepicker-container']}>
    <DatePickerElement<never, T>
      locale={locale}
      dateFormat={dateFormat}
      selected={selected}
      placeholderText={placeholderText}
      disabledKeyboardNavigation
      shouldCloseOnSelect
      calendarClassName={cn(calendarClassName, {
        [styles['custom-datepicker']]: black,
      })}
      className={cn(
        styles['custom-datepicker-input'],
        {
          [styles['custom-datepicker-input_black']]: black,
          [styles['custom-datepicker-input_clearable']]: isClearable,
        },
        'tpg-input',
        className
      )}
      renderDayContents={(day, date) => (
        <DatePickerCustomDay
          day={day}
          date={date}
          className={styles['custom-datepicker-day']}
        />
      )}
      onChange={onChange}
      {...props}
    />
  </div>
);

export default genericMemo(DatePicker);
