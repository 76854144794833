import { RootState } from 'store';

export const geocoderSelector = (state: RootState) =>
  state.map_v2.map.tools.geocoder;

export const drawSelector = (state: RootState) => state.map_v2.map.tools.draw;

export const artillerySelector = (state: RootState) =>
  state.map_v2.map.tools.artillery;

export const measurementSelector = (state: RootState) =>
  state.map_v2.map.tools.measurement;

export const boundaryGridSelector = (state: RootState) =>
  state.map_v2.map.tools.boundaryGrid;
