import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { PersonReportBodySegment } from '../../../interfaces/reports';
import { EditorPersonReportRelation } from '../PersonReportRelationSelect/PersonReportRelationSelect';
import { ReportCardHelpers } from '../reportCardHelpers';

import './style.scss';

interface Props {
  values: PersonReportBodySegment[];
  reportRelations: EditorPersonReportRelation[];
  onChange: (v: PersonReportBodySegment[]) => void;
}

const PersonReportReorderableHeading: React.FC<Props> = ({
  values,
  reportRelations,
  onChange,
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = [...values];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    onChange(reorderedItems);
  };

  return (
    <div className="person-report-heading">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="heading-container"
            >
              {values.map((item, index) => {
                const itemDisplayName = ReportCardHelpers.getSegmentDisplayName(
                  item,
                  reportRelations
                );
                return (
                  <Draggable
                    key={itemDisplayName}
                    draggableId={itemDisplayName}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                        className="heading-item"
                      >
                        {index + 1}. {itemDisplayName}
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default PersonReportReorderableHeading;
