import { MAPBOX_CTRL_PREFIX } from 'constants/mapControl';

export class MapControl {
  _id: string;
  _className: string;
  _title: string;
  _btn: HTMLButtonElement | undefined;
  _eventHandler: () => void;
  _container: HTMLDivElement | undefined;

  constructor({
    id = '',
    className = '',
    title = '',
    eventHandler = function () {},
  }) {
    this._id = id;
    this._className = className;
    this._title = title;
    this._eventHandler = eventHandler;
  }

  onAdd() {
    this._btn = document.createElement('button');
    this._btn.id = `${MAPBOX_CTRL_PREFIX}-${this._id}`;
    this._btn.className = `mapboxgl-ctrl-icon ${this._className}`;
    this._btn.type = 'button';
    this._btn.innerText = this._title;
    this._btn.onclick = this._eventHandler;

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
  }
}
