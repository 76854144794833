import copyCursor from '../images/cursors/cursorCopy.svg';
import crosshairCursor from '../images/cursors/cursorCrosshair.svg';
import defaultCursor from '../images/cursors/cursorDefault.svg';
import grabCursor from '../images/cursors/cursorGrab.svg';
import grabbingCursor from '../images/cursors/cursorGrabbing.svg';
import moveCursor from '../images/cursors/cursorMove.svg';
import pointerCursor from '../images/cursors/cursorPointer.svg';

export const appRoutes = {
  MONITORING: '/monitoring',
  ENTITIES: '/entities',
  MAP: '/map',
  REPORT: '/report',
  REPORTS: '/reports',
  DUMPS: '/dumps',
  ACCESS: '/access',
  ENCRYPTOR: '/encryptor',
  TRANSLATOR: '/translator',
  DOCUMENTS: '/documents',
};

export const authRoutes = {
  SIGN_IN: '/sign-in',
};

export const mapRoutes = {
  SEARCH: '/map/search',
  LAYERS: '/map/layers',
  TYPES: '/map/types',
  IMAGERY: '/map/imagery',
  OPERATIONAL: '/map/operational',
  LOGISTIC: '/map/logistic',
};

export const entitiesRoutes = {
  TABLE: '/entities',
  NEW_ENTITY: '/entities/new',
};

export const monitoringRoutes = {
  FEED: '/monitoring/feed',
  BOOKMARKS: '/monitoring/bookmarks',
};

export enum ASTRA_COLORS {
  DARK_PRODUCT = '#053C92',
  MAIN_PRODUCT = '#1D65D4',
  BRIGHT_PRODUCT = '#4282e6',
  ULTRABLACK = '#0a0c17',
  DARK = '#171925',
  LIGHT = '#1D1F2D',
  BRIGHT = '#20242E',
  TPG_TITLE = '#e3e6f1',
  TPG_BASE = '#9599a6',
  TPG_LIGHT = '#4D5064',
  SUCCESS = '#42BC1B',
  ERROR = '#EA3737',
}

export const ASTRA_CURSORS = {
  DEFAULT: `url(${defaultCursor}), default`,
  CROSSHAIR: `url(${crosshairCursor}) 12 12, crosshair`,
  GRAB: `url(${grabCursor}) 12 12, grab`,
  GRABBING: `url(${grabbingCursor}), grabbing`,
  COPY: `url(${copyCursor}), copy`,
  MOVE: `url(${moveCursor}), move`,
  POINTER: `url(${pointerCursor}), pointer`,
};

export enum AstraCursorTitle {
  DEFAULT,
  CROSSHAIR,
  GRAB,
  GRABBING,
  COPY,
  MOVE,
  POINTER,
}

type AstraCursorStorage = {
  [key in AstraCursorTitle]: string;
};

export const AstraCursors: AstraCursorStorage = {
  [AstraCursorTitle.DEFAULT]: `url(${defaultCursor}), default`,
  [AstraCursorTitle.CROSSHAIR]: `url(${crosshairCursor}) 12 12, crosshair`,
  [AstraCursorTitle.GRAB]: `url(${grabCursor}) 12 12, grab`,
  [AstraCursorTitle.GRABBING]: `url(${grabbingCursor}), grabbing`,
  [AstraCursorTitle.COPY]: `url(${copyCursor}), copy`,
  [AstraCursorTitle.MOVE]: `url(${moveCursor}), move`,
  [AstraCursorTitle.POINTER]: `url(${pointerCursor}), pointer`,
};

export type ElementThemeUnion = 'dark' | 'light' | 'ultrablack';

export type ElementDirectionUnion = 'left' | 'right';

export type ElementPlacementUnion = 'top' | 'bottom';

export const NOTHING_WAS_FOUND_MESSAGE = 'Ничего не найдено, увы...';
