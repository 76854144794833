import { FC, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as CalendarIcon } from 'images/newIcons/calendar.svg';
import { TDateRange } from 'types';

import DatePicker from 'components/ui/DatePicker';

import styles from './DatePickerDropdown.module.scss';

interface CalendarDropdownProps {
  iconClassName?: string;
  className?: string;
  onValueChange: (v: TDateRange) => void;
}

export const DatePickerDropdown: FC<CalendarDropdownProps> = ({
  iconClassName,
  className,
  onValueChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<TDateRange>([null, null]);
  const [startDate, endDate] = dateRange;

  const onChange = (range: TDateRange) => {
    const [rangeStart, rangeEnd] = range;

    setDateRange(range);

    if (rangeStart && rangeEnd) {
      onValueChange(range);
      setIsOpen(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className={cn('absolute z-10', className)}>
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            inline
            onChange={onChange}
          />
        </div>
      )}
      <div className="icon-container">
        <CalendarIcon
          className={cn(iconClassName, isOpen && styles['open-icon'])}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        />
      </div>
    </>
  );
};
