import { FC, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

import { Button, Modal } from 'components/ui';
import { notify } from 'utils';

import { getEntityAccessRules } from '../../api/access';
import {
  deleteEntityAccessRules,
  upsertEntityAccessRules,
} from '../../api/entities';
import { errorMessages, successMessages } from '../../constants/errors';
import {
  EntityAccessRule,
  UpdateEntityAccessRulesRequest,
} from '../../interfaces/entity';
import { EntityWithRelations } from '../../types/entities';

import { AccessControlForm, getSubjectID } from './AccessControlForm';

export interface AccessControlDetailsProps {
  entity: EntityWithRelations;
  onClose: () => void;
}

export const AccessControlDetails: FC<AccessControlDetailsProps> = ({
  entity,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [accessRules, setAccessRules] = useState<EntityAccessRule[]>([]);
  const [initialAccessRules, setInitialAccessRules] = useState<
    EntityAccessRule[]
  >([]);

  useEffect(() => {
    const fetchGroupLinkedEntities = async () =>
      await getEntityAccessRules(entity.entity.id)
        .then((fetchedAccessRules) => {
          setAccessRules(fetchedAccessRules);
          setInitialAccessRules(fetchedAccessRules);
        })
        .catch(() => notify.error(errorMessages.GET_ENTITY_ACCESS_RULE_ERROR))
        .finally(() => setLoading(false));

    setLoading(true);
    fetchGroupLinkedEntities();
  }, []);

  const handleUpdate = async () => {
    setLoading(true);
    const initialAccessRulesMap = new Map<string, EntityAccessRule>();
    initialAccessRules.forEach((rule) =>
      initialAccessRulesMap.set(
        getSubjectID(rule.subjectID, rule.subjectType),
        rule
      )
    );

    const currentAccessRulesMap = new Map<string, EntityAccessRule>();
    accessRules.forEach((rule) =>
      currentAccessRulesMap.set(
        getSubjectID(rule.subjectID, rule.subjectType),
        rule
      )
    );

    const rulesToUpsert = accessRules
      .map((rule) => {
        const initialRule = initialAccessRulesMap.get(
          getSubjectID(rule.subjectID, rule.subjectType)
        );
        if (!initialRule || rule.ACL != initialRule.ACL) {
          return rule;
        } else return [];
      })
      .flatMap((v) => v);
    const rulesToDelete = initialAccessRules
      .map((rule) => {
        if (
          !currentAccessRulesMap.has(
            getSubjectID(rule.subjectID, rule.subjectType)
          )
        ) {
          return rule;
        } else return [];
      })
      .flatMap((v) => v);

    const sendNonEmptyRequest = async (
      rules: EntityAccessRule[],
      request: (data: UpdateEntityAccessRulesRequest) => Promise<AxiosResponse>
    ) => rules.length > 0 && request({ rules: rules });

    await Promise.all([
      sendNonEmptyRequest(rulesToUpsert, upsertEntityAccessRules),
      sendNonEmptyRequest(rulesToDelete, deleteEntityAccessRules),
    ])
      .then(() => {
        notify.success(successMessages.ENTITY_ACCESS_UPDATE_SUCCESS);
        onClose();
      })
      .catch((err) => {
        if ('response' in err && err.response.status === 424) {
          notify.error(errorMessages.DELETE_LAST_ENTITY_ACCESS_RULE_ERROR);
        } else {
          notify.error(errorMessages.ENTITY_ACCESS_UPDATE_ERROR);
        }
      })
      .finally(() => setLoading(false));
  };

  const isUpdateDisallowed = useMemo(
    () =>
      _.isEqual(accessRules, initialAccessRules) ||
      accessRules.some((rule) => rule.subjectID === 0),
    [accessRules, initialAccessRules]
  );

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center pb-4">
          Управление доступом
        </div>
        <AccessControlForm
          accessRules={accessRules}
          setAccessRules={setAccessRules}
          entity={entity}
        />
        <Button
          className="w-full"
          title="Обновить"
          onClick={handleUpdate}
          isLoading={isLoading}
          disabled={isUpdateDisallowed}
        />
      </div>
    </Modal>
  );
};
